import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import { CgFileDocument, CgMail } from 'react-icons/cg'
import ScheduleIcon from '@material-ui/icons/Schedule'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import GroupIcon from '@material-ui/icons/Group'

class TreatmentGoals extends Component {
  constructor() {
    super()
    this.state = {
      getDataList: [],
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var get_tracker_day = []
    var get_tracker_week = []

    // tracker day //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_current_treatment_goal_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid, }
    })
    .then((result) => {
        //console.log(result['data'])
        this.setState({ getDataList: result['data'] })
      }
    )
  }

  render() {
    return (
      <div className="box_border box_space_top1 treatment_goals">
        <div className="row">
            <div className="small-12 columns head2">
              Your Treatment Goals
            </div>
            <div className="small-12 columns content1 space_bottom1">
              {this.state.getDataList.length > 0 &&
              <ol>{this.state.getDataList.map((each, index) =>
                each.val != null &&
                <li key={'tg_'+index}>{each.val}</li>
              )}</ol>}
              {this.state.getDataList.length == 0 && <div className="align_center">-</div>}
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentGoals)
