import React, { Component } from 'react'

class Profile extends Component {

  componentDidMount() { }

  render() {
    return (
      <div>Profile</div>
    );
  }
}

export default Profile;
