import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/pyramid-3-1.png'
import Image2 from '../../../images/olivia3.png'

class Intro8 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction9')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction7')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_video2"></div>
            <div className="row">
              <div className="small-12 medium-7 columns content2">
                <img src={Image} alt="pyramid" className="w_100percent margin-top2"/>
              </div>
              <div className="small-12 medium-5 columns gray_bg4">
                <div className="margin-top1"><img src={Image2} alt="pyramid" className="w_80percentc margin-top1" /></div>
                <div className="content_under_picture2">
                  <p>Three more weeks pass. Olivia had been doing well on STEP TWO,  but falls into a period of severe depression.</p>
                  <p>Her team psychopharmacologist works with her to adjust her medication.</p>
                  <p>Olivia also moves up to STEP THREE to start a group psychotherapy and mindfulness training.</p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro8)
