export const treatment_targets = [
  {
    label: 'Symptom Reduction',
    value: 1,
  }, {
    label: 'Reduce anger and irritability ',
    value: 11,
  }, {
    label: 'Reduce impulsive/anger-driven behaviors',
    value: 12,
  }, {
    label: 'Reduce other manic symptoms',
    value: 13,
  }, {
    label: 'Decrease negative thinking/helpless/hopeless/suicidal thoughts',
    value: 14,
  }, {
    label: 'Increase pleasurable experiences/activities/reduce anhedonia',
    value: 15,
  }, {
    label: 'Decrease rumination',
    value: 16,
  }, {
    label: 'Increase energy/ability to start tasks',
    value: 17,
  }, {
    label: 'Improve other depressive symptoms',
    value: 18,
  }, {
    label: 'Reduce anxiety/thoughts that something bad is about to happen',
    value: 19,
  }, {
    label: 'Lifestyle',
    value: 2,
  }, {
    label: 'Improve social connections/friendships',
    value: 21,
  }, {
    label: 'Improve sleep quality/schedule',
    value: 22,
  }, {
    label: 'Improve nutrition/diet',
    value: 23,
  }, {
    label: 'Improve physical activity',
    value: 24,
  }, {
    label: 'Other Skills',
    value: 3,
  }, {
    label: 'Improve awareness/identification of mood symptoms',
    value: 31,
  }, {
    label: 'Manage physical symptoms',
    value: 32,
  }, {
    label: 'Improve cognition/ability to make decisions',
    value: 33,
  }, {
    label: 'Increase meaning/satisfaction with life ',
    value: 34,
  }, {
    label: 'Improve crisis management skills',
    value: 35,
  }, {
    label: 'Improve ability to stay in the present moment',
    value: 36,
  }, {
    label: 'Increase medication adherence',
    value: 37,
  }, {
    label: 'Improve emotional dysregulation',
    value: 38,
  }, {
    label: 'Reduce avoidance',
    value: 39,
  }, {
    label: 'Decrease self-harm behaviors',
    value: 40,
  }, {
    label: 'Other',
    value: 4,
  },
]

export const treatment_modality = [
  {
    label: 'Acceptance Strategies',
    value: 1,
  }, {
    label: 'Behavioral Activation',
    value: 2,
  }, {
    label: 'Behavioral Experiments',
    value: 3,
  }, {
    label: 'Chain Analysis',
    value: 4,
  }, {
    label: 'Cognitive Diffusion',
    value: 5,
  }, {
    label: 'Cognitive Remediation',
    value: 6,
  }, {
    label: 'Cognitive Re-Structuring',
    value: 7,
  }, {
    label: 'Commitment-Based Strategies',
    value: 8,
  }, {
    label: 'Contingency Management',
    value: 9,
  }, {
    label: 'Distress Tolerance',
    value: 10,
  }, {
    label: 'Emotional Regulation',
    value: 11,
  }, {
    label: 'Exposure Therapy',
    value: 12,
  }, {
    label: 'Interpersonal Effectiveness',
    value: 13,
  }, {
    label: 'Mindfulness',
    value: 14,
  }, {
    label: 'Mood Monitoring',
    value: 15,
  }, {
    label: 'Neuropsych Evaluation',
    value: 16,
  }, {
    label: 'Peer Support',
    value: 17,
  }, {
    label: 'Psychoeducation',
    value: 18,
  }, {
    label: 'Relaxation Training',
    value: 19,
  }, {
    label: 'Values Clarification',
    value: 20,
  }, {
    label: 'Other',
    value: 21,
  },
]

export const intervention_format = [
  {
    label: 'Individual',
    value: 1,
  }, {
    label: 'Group',
    value: 2,
  }, {
    label: 'Self-guided program',
    value: 11,
  }, {
    label: 'Self-guided online program',
    value: 3,
  }, {
    label: 'Coached online program',
    value: 4,
  }, {
    label: 'Book/reading material',
    value: 5,
  },  {
    label: 'Peer support',
    value: 6,
  },  {
    label: 'Family therapy',
    value: 7,
  },  {
    label: 'Psychopharmacology',
    value: 8,
  },  {
    label: 'Neuropscychology',
    value: 9,
  }, {
    label: 'Other',
    value: 10,
  },
]

export const adherence_reason = [
  {
    label: 'Lack of motivation',
    value: 1,
  }, {
    label: 'Unwilling/Active Avoidance',
    value: 2,
  }, {
    label: 'Fear of change/improving',
    value: 3,
  }, {
    label: 'Perceived lack of time',
    value: 4,
  }, {
    label: 'New life event/change in treatment focus',
    value: 5,
  }, {
    label: 'Adverse effect of treatment',
    value: 6,
  }, {
    label: 'Increase/change in symptoms',
    value: 7,
  }, {
    label: 'Task ineffective/Perceived to be ineffective',
    value: 8,
  }, {
    label: 'Patient reports unable to complete',
    value: 9,
  }, {
    label: 'Other',
    value: 10,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const completion = [
  {
    label: 'Full',
    value: 1,
  }, {
    label: 'Partial',
    value: 2,
  }, {
    label: 'None',
    value: 3,
  },
]

export const completion2 = [
  {
    label: 'None',
    value: 1,
  }, {
    label: 'Partial',
    value: 2,
  }, {
    label: 'Full',
    value: 3,
  },
]

/*
export const todolist_status2 = [
  {
    label: 'Completed',
    value: 1,
  }, {
    label: 'Ongoing',
    value: 2,
  }, {
    label: 'Terminated',
    value: 3,
  },
]*/

export const todolist_status = [
  {
    label: 'Completed',
    value: 1,
  }, {
    label: 'Ongoing',
    value: 2,
  }, {
    label: 'Terminated',
    value: 3,
  }, {
    label: 'Not Discussed',
    value: 4,
  },
]

export const todolist_status2 = [
  {
    label: 'Completed',
    value: 1,
  }, {
    label: 'Ongoing',
    value: 2,
  }, {
    label: 'Terminated',
    value: 3,
  },
]

export const todolist_status3 = [
  {
    label: 'Completed',
    value: 1,
  }, {
    label: 'Ongoing',
    value: 2,
  }, {
    label: 'Terminated',
    value: 3,
  }, {
    label: 'Modified',
    value: 4,
  },
]

export const reason_change_target = [
  {
    label: 'Patient preference',
    value: 1,
  }, {
    label: 'New life event',
    value: 2,
  }, {
    label: 'New symptoms',
    value: 3,
  }, {
    label: 'Lack of response',
    value: 5,
  }, {
    label: 'Other',
    value: 4,
  },
]
