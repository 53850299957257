export const q5_questions = [
  {
    label: '(a) ...cannot get to sleep within 30 minutes',
    value: 1,
  }, {
    label: '(b) ...wake up in the middle of the night or early morning',
    value: 2,
  }, {
    label: '(c) ...have to get up to use bathroom',
    value: 3,
  }, {
    label: '(d) ...cannot breathe comfortably',
    value: 4,
  }, {
    label: '(e) ...cough or snore loudly',
    value: 5,
  }, {
    label: '(f) ...feel too cold',
    value: 6,
  }, {
    label: '(g) ...feel too hot',
    value: 7,
  }, {
    label: '(h) ...had bad dreams',
    value: 8,
  }, {
    label: '(i) ...have pain',
    value: 9,
  }, /*{
    label: '(j) ...Other reason(s), please describe',
    value: 10,
  },*/
]

export const q5_choices = [
  {
    label: 'Not during the past month',
    value: 0,
  }, {
    label: 'Less than once a week',
    value: 1,
  }, {
    label: 'Once or twice a week',
    value: 2,
  }, {
    label: 'Three or more times a week',
    value: 3,
  },
]

export const q6_choices = [
  {
    label: 'Very good',
    value: 0,
  }, {
    label: 'Fairly good',
    value: 1,
  }, {
    label: 'Fairly bad',
    value: 2,
  }, {
    label: 'Very bad',
    value: 3,
  },
]

export const q7_choices = [
  {
    label: 'Not during the past month',
    value: 0,
  }, {
    label: 'Less than once a week',
    value: 1,
  }, {
    label: 'Once or twice a week',
    value: 2,
  }, {
    label: 'Three or more times a week',
    value: 3,
  },
]

export const q9_choices = [
  {
    label: 'No problem at all',
    value: 0,
  }, {
    label: 'Only a very slight problem',
    value: 1,
  }, {
    label: 'Somewhat of a problem',
    value: 2,
  }, {
    label: 'A very big problem',
    value: 3,
  },
]

export const q10_choices = [
  {
    label: 'No bed partner or roommate',
    value: 1,
  }, {
    label: 'Partner/roommate in other room',
    value: 2,
  }, {
    label: 'Partner in the same room, but not the same bed',
    value: 3,
  }, {
    label: 'Partner in same bed',
    value: 4,
  },
]

export const q10_add_questions = [
  {
    label: '(a) ...loud snoring',
    value: 1,
  }, {
    label: '(b) ...long pauses between breaths while asleep',
    value: 2,
  }, {
    label: '(c) ...legs twitching or jerking while you sleep',
    value: 3,
  }, {
    label: '(d) ...episodes of disorientation or confusion during sleep',
    value: 4,
  }, {
    label: '(e) Other restlessness while you sleep; please describe (if none, enter NA).',
    value: 5,
  },
]
export const q10_add_choices = [
  {
    label: 'Not during the past month',
    value: 1,
  }, {
    label: 'Less than once a week',
    value: 2,
  }, {
    label: 'Once or twice a week',
    value: 3,
  }, {
    label: 'Three or more times a week',
    value: 4,
  },
]
