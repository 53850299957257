import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom"
//import { Router  } from "react-router-dom"
import { Provider } from 'react-redux'
import App from './components/App.jsx';
//import * as serviceWorker from './utils/serviceWorker'
import configureStore from './stores/configureStore'

import './styles/index.css';
import './styles/assets/css/admin/form1.css';
import './styles/assets/css/admin/form2.css';
import './styles/assets/css/admin/form3.css';
import './styles/assets/css/admin/header1.css';
import './styles/assets/css/admin/list1.css';
import './styles/assets/css/admin/list2.css';
import './styles/assets/css/admin/patient_contract.css';
import './styles/assets/css/foundation-float.css';
import './styles/assets/css/foundation-icons/foundation-icons.css';
import './styles/assets/css/foundation.css';
import './styles/assets/css/pages/assessments1.css';
import './styles/assets/css/pages/dashboard.css';
import './styles/assets/css/pages/form1.css';
import './styles/assets/css/pages/form2.css';
import './styles/assets/css/pages/form3.css';
import './styles/assets/css/pages/form4.css';
import './styles/assets/css/pages/form5.css';
import './styles/assets/css/pages/form6.css';
import './styles/assets/css/pages/form7.css';
import './styles/assets/css/pages/graph.css';
import './styles/assets/css/pages/mtf.css';
import './styles/assets/css/pages/patient_contract.css';
import './styles/assets/css/pages/settings.css';
import './styles/assets/css/pages/signup.css';
import './styles/assets/css/pages/stepper1.css';
import './styles/assets/css/template.css';

if (typeof window !== 'undefined') {
  ReactDOM.render(
    <Provider store={configureStore()} key="provider">
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
