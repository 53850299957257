import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import validator from 'validator'

class CommunicationConsent extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          uid: 0,
          receive_communication: 0,
          cell_phone: '',
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        //console.log(result['data'])
        if(result['data'].reg_step !== 'communication_consent') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var chk_status = 1
    if(this.state.receive_communication === 0) {
      chk_status = 2
      this.setState({
        message: 'Please select how would you like to receive communications ',
      })
    } else {
      if(this.state.receive_communication == 2 && this.state.cell_phone.length == 0) {
        chk_status = 2
        this.setState({
          message: 'Please provide cellphone number',
        })
      } else if(this.state.receive_communication == 2 && this.state.cell_phone.length > 0) {
        if(!this.validatePhoneNumber(this.state.cell_phone)) {
          chk_status = 2
          this.setState({
            message: 'Please correct your mobile phone number.',
          })
        }
      }
    }
    if(chk_status == 1) {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_communication_consent',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          communication_consent: this.state.receive_communication,
          cell_phone: this.state.cell_phone,
        }
      })
      .then((result) => {
          if(result['data'] === 'treatment_consent') {
            this.props.onLoadLocation('/member/treatment_consent')
            this.props.history.push('/member/treatment_consent')
          }

        }
      )
    }

  }

  validatePhoneNumber = (input_str) => {
    return validator.isMobilePhone(input_str)
    //var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    //return re.test(input_str)
  }

  render() {

    return (
      <div className="communication_consent box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Communication Preferences
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={3}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
            <div className="">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <p className="bold">Please read the following unencrypted email disclosure:</p>
                    <p>The Partners standard is to send email securely through a Patient Gateway account, which you would access using a password. These “encrypted” emails require the recipient to go through a cumbersome, multi-step verification process to open and read each and every email. Because the FITT-BD program involves regular emails to patients, this would make our communication with you very burdensome. Instead, as a patient at the FITT-BD clinic, you will receive "unencrypted" email that is sent directly to your email address.</p>
                    <p>To protect your privacy, the emails we send you will not include any clinical information or mention of a diagnosis. However, please note that these emails are not secure and could potentially result in the unauthorized use or disclosure of your information. If you agree to receive communications by unencrypted email despite these risks, Partners HealthCare will not be held responsible.</p>
                    <p>Your preference to receive unencrypted email will apply only to emails sent from the FITT-BD Clinic.</p>
                  </div>
                  <div className="small-12 columns bold">
                    <p>As a patient at the FITT-BD Clinic, I agree to receive communications via:</p>
                  </div>
                  <div className="small-12 columns bottom_space">
                    <input type="radio" name="receive_communication" value="1" onChange={(e) => this.handleChange(e)}/>
                      Unencrypted emails
                  </div>
                  { this.state.receive_communication == 2 &&
                  <div className="small-12 columns bottom_space">
                      <TextField label="Please provide cell phone number"
                      name="cell_phone" onChange={(e) => this.handleChange(e)}
                      variant="outlined" InputProps={{ maxLength: 20 }}  />
                  </div>}
                </div>
            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
        {this.state.status_submit &&
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        {!this.state.status_submit &&
          <Button variant="contained" disabled
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CommunicationConsent)
