import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../utils/global'
import { loadLocationWithMessage } from '../../actions/location'

//import imageSignature from '../../../../impact_bd_server/uploads/signatures/patient_contract/signature_57_8.png'

class GetPDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pdf: '',
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var pid = 0
    if(this.props.authReducer.role == 5) {
      pid = this.props.authReducer.uid
    } else {
      pid = this.props.match.params.pid
    }

    var path_get_file = ''
    var ftype = ''
    if(this.props.path == 'patient_contract_pdf') {
      path_get_file = 'patient/enroll/get_patient_contract_pdf_no_gen'
    } else if(this.props.path == 'roi_pdf') {
      path_get_file = 'patient/enroll/get_roi_pdf_chk_before_gen' // get_roi_pdf_no_gen
    } else if(this.props.match.params.fid > 0) {
      path_get_file = 'admin/patient/get_uploaded_file'
    } else if(this.props.path == 'admin_uploaded_file9') {
      path_get_file = 'patient/enroll/get_admin_uploaded_file'
    } else if(this.props.path == 'clinicpolicy') {
      path_get_file = 'admin/patient/consent'
      ftype = 'clinicpolicy'
    } else if(this.props.path == 'patientwaiver') {
      path_get_file = 'admin/patient/consent'
      ftype = 'patientwaiver'
    } else if(this.props.path == 'communication') {
      path_get_file = 'admin/patient/consent'
      ftype = 'communication'
    } else if(this.props.path == 'treatment') {
      path_get_file = 'admin/patient/consent'
      ftype = 'treatment'
    } else if(this.props.path == 'information_sheet') {
      path_get_file = 'admin/patient/consent'
      ftype = 'information_sheet'
    }
    // get image //
    axios({
      method: 'post',
      url: connectNode + path_get_file,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        contract_id: this.props.match.params.contract_id,
        fid: this.props.match.params.fid,
        ftype: ftype,
        //add_string: Date.now(),
      },
    })
    .then((result) => {
      this.setState({
          pdf: result['data']
      })
    })
  }

  downloadPDF = (e, pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`
    const downloadLink = document.createElement("a")
    const fileName = "download_"+Date.now()+".pdf"
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.target = "_blank"
    //downloadLink.setAttribute('target', '_blank')
    downloadLink.click()
  }

    render() {
      //console.log('---'+this.state.pdf.data)
      return (
        <div>
        {this.state.pdf.data === null &&
          <div className="loading1">The file is not existed</div>
        }
        {this.state.pdf.data === undefined &&
          <div className="loading1">Loading...</div>
        }
        {this.state.pdf.data !== undefined && this.state.pdf.data !== null &&
        <><embed src={`data:application/pdf;base64,${this.state.pdf.data}`}
              type="application/pdf" className="pdf_size_1" />
        <div className="text_align_right bold"><p>&nbsp;</p>
          <a target="_blank" onClick={(e)=>this.downloadPDF(e, this.state.pdf.data)}>Download</a>
        </div>
        </>
        }</div>
      )
    }
  }

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GetPDF)
