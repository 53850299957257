import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocation } from '../../../actions/location'
import Button from '@material-ui/core/Button'

class CompleteBaseline extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_baseline_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'complete_baseline') {
          var type = this.props.match.params.type
          this.props.history.push('/member/assessment/'+result['data']+'/'+type+'/'+this.props.match.params.timepoint)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()

  }

  render() {

    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Thank you!
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
              You have completed the new patient questionnaires.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CompleteBaseline)
