import React, { Component } from 'react'
import '../../../styles/assets/css/pages/dashboard.css'
import { CgFileDocument } from 'react-icons/cg'

class PatientContract extends Component {
  constructor() {
    super()
    this.state = {
      contract_id: 0,
    }
  }

  render() {
    return (
      <div className="box_border box_space_top1 patient_contract">
        <div className="row">
            <div className="small-12 columns head3 pat_contract">
                <a href={"member/patient_contract_list"} >
                  <div><CgFileDocument /></div>
                  <div className="txt_link">Patient Contract</div>
                </a>
            </div>
        </div>
      </div>
    );
  }
}

export default PatientContract
