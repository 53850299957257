import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import Cookies from 'js-cookie'

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocation } from '../../../actions/location'
import Button from '@material-ui/core/Button'

class CompletePreRegistration extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() { }

  render() {

    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border6">
            <div className="row">
              <div className="small-12 columns headerPage2">
                Thank you for your interest in FITT-BD!
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CompletePreRegistration)
