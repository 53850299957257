import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { calculateTimepoint1 } from '../../functions/timepoint.js'
import '../../../styles/assets/css/pages/mtf.css'

import TextField from '@material-ui/core/TextField'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import CancelIcon from '@material-ui/icons/Cancel'
import StepperAssm from './stepper_assm'
import { antipsychotic, antidepressants, anticonvulsants,
        mood_stabilizers, anxiolytics, anticholinergic, antihistamine, sedatives,
       } from '../variables/medical_history.js'
import { SelectLine } from '../../shared/select_line'

class Mtf extends Component {
  constructor() {
      super()
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'complete_assessments',
          medications: [],
          new_medications: [{ id:1, med: '', dose: 0, frequency: ''}],
          medication_list: [],
          frequency_list: [],
          med_category: [],
      }
      //this.getCompletedData = this.getCompletedData.bind(this)
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint < 1 || this.props.match.params.type !== 'm') {
      this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
      this.props.history.push('/member/assessment_list')
    } else {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_medication_list',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { }
      })
      .then((list) => {
        //console.log(list['data'].medication_list)
        this.setState({
          medication_list: list['data'].medication_list,
          frequency_list: list['data'].frequency_list,
          med_category: list['data'].med_category,
        })
      })

      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          assm: 'patients_registration_medical_history_medication',
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        //console.log(calTimepoint)
        //console.log(result['data'])
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].status == 1 && result['data'].step !== 'mtf') {
              this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            } else {
              var st = 1
              var link_pull_data = 'get_last_medication_history_mtf'
              if(result['data'].status == 2) {
                // Completed //
                st = 2
                link_pull_data = 'get_this_medication_history_mtf'
              }
              // Pull from medical history //
              this.getCompletedData(st, link_pull_data)
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3, 'get_this_medication_history_mtf')
        }

      })
    }
  }

  getCompletedData = (status, link_pull_data) => {
    //console.log(link_pull_data)
    if(link_pull_data != undefined) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/'+link_pull_data,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      })
      .then((result) => {
        //console.log(result['data'])
        var medications = []
        var med_cat = []
        var buff = ''
        result['data'].map(each => {
          buff = this.state.med_category.find(c => c.value == each.med_category).label
          if(buff == 'antidepressants') {
            med_cat = antidepressants
          } else if(buff == 'anticonvulsants') {
            med_cat = anticonvulsants
          } else if(buff == 'mood_stabilizers') {
            med_cat = mood_stabilizers
          } else if(buff == 'antipsychotic') {
            med_cat = antipsychotic
          } else if(buff == 'anxiolytics') {
            med_cat = anxiolytics
          } else if(buff == 'anticholinergic') {
            med_cat = anticholinergic
          } else if(buff == 'antihistamine') {
            med_cat = antihistamine
          } else if(buff == 'sedatives') {
            med_cat = sedatives
          }
          /*if(each.med_category >= 1 && each.med_category <= 8) {
            buff = med_cat[each.med_id].label
          } else {
            buff = each.med_other
          }*/
          medications.push({
            med_category: each.med_category,
            med_id: each.med_id,
            name: each.med_other, //buff,
            dose: each.y_dose,
            change: 0,
            new_dose: 0,
          })
        })
        var next_step = 'complete_assessments'
        if(this.props.match.params.type == 'tm') {
          next_step = 'covid'
        }
        this.setState({
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          medications: medications,
          next_step: next_step,
          status: status,
        })
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }

  appendInputMedication3 = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, med: '', dose: 0
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = 'complete_assessments'
    if(type == 'tm') {
      next_step = 'covid'
    }
    if(this.state.status === 1) {
      var chk = 0
      this.state.medications.map(each=>{
        if(each.change === 0) {
          chk = 1
        } else if(each.change === 2) {
          if(each.new_dose === 0) {
            chk = 1
          }
        }
      })
      this.state.new_medications.map(each=>{
        if(each.med !== '' && each.dose === '') { // || each.frequency === ''
          chk = 1
        }
      })

      if(chk === 1) {
        this.setState({
          message: 'Please select and fill all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        const newData = this.state.new_medications.filter(x => x.med !== "").map(x => ({...x,
          med_id: x.med, med: this.state.medication_list.find(m => m.id === x.med).med_name,
          frequency: null,
          //frequency: x.frequency, frequency_other: this.state.frequency_list.find(m => m.vid === x.frequency).name
        }));
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_mtf',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: {...this.state, new_medications: newData},
            type: type,
            timepoint: timepoint,
          }
        })
        .then((result) => {
          //console.log(result['data'])
            if(result['data'] == next_step) {
              this.props.onLoadLocation('/member/assessment/'+result['data']+'/'+type+'/'+timepoint)
              this.props.history.push('/member/assessment/'+result['data']+'/'+type+'/'+timepoint)
            }

          }
        )
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    const medicationNamesMap = this.state.medication_list.map(each => ({id: each.id, name: each.med_name}));
    const frequencyMap = this.state.frequency_list.map(each => ({id: each.vid, name: each.name}));
    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Medication Tracking Form
          </div>
          {//this.state.status == 1 &&
          <StepperAssm activeStep={5} assm={'MTF'} type={this.props.match.params.type}
          status={this.state.status} timepoint={this.props.match.params.timepoint} />}
          {/*this.state.status > 1 &&
          <StepperAssm activeStep={5} assm={'MTF'} type={this.props.match.params.type}
          status={this.state.status} timepoint={this.props.match.params.timepoint} />*/}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box1">
            <div className="row">
              <div className="small-12 columns bold">Instructions</div>
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
              <div className="small-12 columns">
              Below are the psychiatric medications you’ve reported that you are currently taking.
              Please go over these to make sure that you are still taking them,
              that the amount you are taking is correct, and to add any new psychiatric medications.
              Note that this list will not include any non-psychiatric medications.
              If you are still taking the medication,
              but the amount has changed, click on "Change in amount taken every day" and input
              your new amount. If you are no longer taking the medication, click
              on "no longer taking."
              </div>
              <div className="small-12 columns top_space2">
              If you are taking any new medications, please add them below.
              </div>
            </div>
        </div>
        <div className="small-12 columns box1">
          { this.state.medications.map((each, index) =>
            <div className="row" key={'m'+index}>
              <div className="small-12 columns">
                <b>Medication:</b> {each.name}
              </div>
              <div className="small-12 columns">
                <b>Amount you take in a day:</b> {each.dose} mg
              </div>
              { this.state.status === 1 &&
              <div>
                <div className="small-12 columns">
                  <input type="radio" value="1" name={'med_ch'+(index)}
                      onChange={(e) => this.handleChange5(e, 'medications', index, 'change')}/>
                      No Change
                </div>
                <div className="small-12 columns">
                  <div className="float_left_1">
                    <input type="radio" value="2" name={'med_ch'+(index)}
                      onChange={(e) => this.handleChange5(e, 'medications', index, 'change')}/>
                      Change in amount taken every day
                  </div>
                  {this.state.medications[index].change == 2 &&
                  <div>
                    <div className="float_left_2">
                      <ArrowRightIcon /> New Amount:
                    </div>
                    <div className="float_left_2">
                      <TextField label="" type="text" onChange={(e) => this.handleChange5(e, 'medications', index, 'new_dose')}
                      variant="outlined" InputProps={{ maxLength: 10 }}
                      onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                        }
                      }} />
                    </div>
                    <div className="float_left_2 top_space1">mg</div>
                  </div>}

                </div>
                <div className="small-12 columns">
                  <div className="float_left_1">
                  <input type="radio" value="3" name={'med_ch'+(index)}
                      onChange={(e) => this.handleChange5(e, 'medications', index, 'change')}/>
                      No Longer Taking
                      </div>
                {this.state.medications[index].change == 3 &&
                <div>
                  <div className="float_left_2">
                    <ArrowRightIcon /> When did you stop taking:
                  </div>
                  <div className="float_left_2">
                    <TextField label="" type="date" variant="outlined" className="mtf_stop_date"
                    onChange={(e) => this.handleChange5(e, 'medications', index, 'stop_date')} />
                  </div>
                </div>}
                </div>
              </div>}
              {index < this.state.medications.length - 1 &&
              <div className="small-12 columns">
                <div className="sep_line2">&nbsp;</div>
              </div>}
            </div>
          )}
        </div>
        { this.state.status === 1 &&
        <div className="small-12 columns box1">
          <div className="row">
            <div className="small-12 columns bold">ADD New psychiatric medication below (Select from list)</div>
            <div className="small-12 columns">
              <div className="sep_line1">&nbsp;</div>
            </div>
            <div className="small-12 columns">
              <div>
                <div className="small-12 columns">
                  <div>
                  {this.state.new_medications.map((val, index) =>
                    <div className="row each_m_set" key={"m_"+val.id} >
                    {/*val.id == 1 && <div className="space_first_med">&nbsp;</div>*/}
                      <div className="small-12 medium-4 columns">
                          <div className="med_q1 bold">Medication Name:
                          {val.id > 1 && <CancelIcon onClick={ () => this.removeInputSet1('new_medications', val.id, this.state.new_medications) } />}</div>
                          <div className="med_q1_ans">
                          <SelectLine value={this.state.new_medications[index].med} width="15rem"
                            name={`med_name_${index}`} onChange={(e) => this.handleChange3(e, 'new_medications', index, 'med')} menuItems={medicationNamesMap} noClass  />
                          </div>
                          { this.state.new_medications[index].med == 59 &&
                            <div className="med_q1_ans">
                            <div className="row">
                            <TextField label="" variant="outlined"
                            InputProps={{ maxLength: 50, placeholder: 'Specify medication name', }}
                            onChange={(e) => this.handleChange5(e, 'new_medications', index, 'other_name')} />
                            </div>
                          </div>}
                      </div>

                      { this.state.new_medications[index].med != '' &&
                      <div className="small-12 medium-4 columns" >
                        <div className="med_q1 bold">Amount you take in one day (mg):</div>
                        <div className="med_q1_ans">
                          <div className="row">
                          <TextField label="" type="number" onChange={(e) => this.handleChange5(e, 'new_medications', index, 'dose')} variant="outlined"
                          InputProps={{ maxLength: 10 }}  /></div>
                        </div>
                      </div>}

                      { this.state.new_medications[index].frequency == 12 &&
                      <div>
                        <div className="medium-4 columns show-for-medium" >&nbsp;</div>
                        <div className="small-12 medium-4 columns" >
                          <div className="med_q1_ans">
                          <div className="row">
                          <TextField label="" variant="outlined"
                          InputProps={{ maxLength: 50, placeholder: 'Specify frequency', }}
                          onChange={(e) => this.handleChange5(e, 'new_medications', index, 'other_frequency')} />
                          </div></div>
                        </div>
                      </div>}

                      <div className="small-12 columns sep_line2">&nbsp;</div>
                    </div>
                  )} {/* End row */}
                  </div>
                </div>
                <div className="small-12 columns ">
                  <button type="button" className="addInput1"
                     onClick={ () => this.appendInputMedication3('new_medications', this.state.new_medications) }>
                      Add Another Medication
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>}
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Mtf)
