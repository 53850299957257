export const mrtf_type_change = [
  {
    label: 'Med Discontinued',
    value: 1,
  }, {
    label: 'Dose Decreased',
    value: 2,
  }, {
    label: 'Dose Increased',
    value: 3,
  }, {
    label: 'Error correction',
    value: 4,
  },
]

export const mtf_type_change = [
  {
    label: 'No Change',
    value: 1,
  }, {
    label: 'Dosage Change',
    value: 2,
  }, {
    label: 'No Longer Taking',
    value: 3,
  }, {
    label: 'Error correction',
    value: 4,
  },
]

export const mrtf_report = [
  {
    label: 'Self report',
    value: 1,
  }, {
    label: 'Medical decision',
    value: 2,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const reason_change = [
  {
    label: 'Symptoms',
    value: 1,
  }, {
    label: 'New',
    value: 11,
  }, {
    label: 'Persistent',
    value: 12,
  }, {
    label: 'Worsened',
    value: 13,
  }, {
    label: 'Improved',
    value: 14,
  }, {
    label: 'Side effects',
    value: 2,
  }, {
    label: 'New',
    value: 21,
  }, {
    label: 'Persistent',
    value: 22,
  }, {
    label: 'Worsened',
    value: 23,
  }, {
    label: 'Improved',
    value: 24,
  }, {
    label: 'Planned dose titration',
    value: 3,
  }, {
    label: 'Patient choice',
    value: 4,
  }, {
    label: 'Drug availability',
    value: 5,
  }, {
    label: 'Patient/Clinician error',
    value: 7,
  }, {
    label: 'Other reason',
    value: 6,
  }, {
    label: 'None',
    value: 8,
  },
]

export const reason_change_primary = [
  {
    label: 'Symptoms',
    value: 1,
  }, {
    label: 'New',
    value: 11,
  }, {
    label: 'Persistent',
    value: 12,
  }, {
    label: 'Worsened',
    value: 13,
  }, {
    label: 'Improved',
    value: 14,
  }, {
    label: 'Side effects',
    value: 2,
  }, {
    label: 'New',
    value: 21,
  }, {
    label: 'Persistent',
    value: 22,
  }, {
    label: 'Worsened',
    value: 23,
  }, {
    label: 'Improved',
    value: 24,
  }, {
    label: 'Planned dose titration',
    value: 3,
  }, {
    label: 'Patient choice',
    value: 4,
  }, {
    label: 'Drug availability',
    value: 5,
  }, {
    label: 'Patient/Clinician error',
    value: 7,
  }, {
    label: 'Other reason',
    value: 6,
  },
]

export const mrtf_filter_med_type = [
  {
    label: 'All',
    value: 1,
  }, {
    label: 'Current',
    value: 4,
  }, {
    label: 'Current Psychiatric',
    value: 2,
  },
  {
   label: 'Non psychiatric',
   value: 3,
 },
]
