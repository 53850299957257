import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no } from '../../variables/treatment_plan.js'

class NewTreatmentTarget extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_open: false,
          contract_id: 0,
          completion_goal: 0,
          completion_status: 0,
          getData: [],
          treatment_target: [{
              id: 1,
              target: 0,
              target_other: '',
              notes: '',
              modality: 0,
              modality_other: '',
              intervention: '',
              intervention_format: 0,
              intervention_format_other: '',
              modailty_date: new Date().toISOString().substring(0, 10),
            }],
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid, tid: this.props.match.params.tpid }
    })
    .then((result) => {
      this.setState({
        getData: result['data'],
        completion_status: result['data'][0].completion,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange4 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    if(field == 'change' && e.target.value == 2) {
      getStateVal[id].reason_change = 0
      getStateVal[id].reason_change_other = ''
      getStateVal[id].reason_change_notes = ''
    } else if(field == 'completion' && e.target.value == 1) {
      getStateVal[id].change = 0
      getStateVal[id].reason_change = 0
      getStateVal[id].reason_change_other = ''
      getStateVal[id].reason_change_notes = ''
    }
    this.setState({
      [name]: getStateVal
    })
  }

  appendInputSet2 = (name, fields) => {
    var newInput = fields[fields.length]
    var newInputID = 2
    if(fields.length > 0) {
      newInput = fields[fields.length-1]
      newInputID = newInput.id + 1
    }
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID,
          target: 0,
          target_other: '',
          notes: '',
          modality: 0,
          modality_other: '',
          intervention: '',
          intervention_format: 0,
          intervention_format_other: '',
          modailty_date: new Date().toISOString().substring(0, 10),
        }])
      }
    ))

  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex >= 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  handleSubmit = (e) => {
    //console.log(' --- handleSubmit ---')
    //console.log(this.state)
    e.preventDefault()
    var chk = true
    this.state.treatment_target.map(val => {
      if(val.target == 0 || val.modality == 0 || val.intervention === '' ||
         val.intervention_format == 0 || (val.target == 4 && val.target_other === '') ||
        (val.modality == 21 && val.modality_other === '') ||
        (val.intervention_format == 6 && val.intervention_format_other === '')) {
          //console.log('-- case 4 --')
          chk = false
      }
    })

    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/add_treatment_target',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          tpid: this.props.match.params.tpid,
          newInput: this.state.treatment_target,
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/4', 'Added treatment targets successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/1/4')
        }
      })
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const options_adherence = [];
    for (let i=0; i <= 100; i += 10) { options_adherence.push(i); }
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Treatment Plan
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns">
                        <div className="small-12 columns box1"><span className="bold">Long-Term Treatment Goal: </span>
                        {this.state.getData.length > 0 && this.state.getData[0].tm_goal}
                        {this.state.getData.length > 0 && this.state.getData[0].treatment_goal_other}
                        </div>
                      </div>
                    </div>
                    {this.state.treatment_target.map((val, index) =>
                      <div className="row no_margin_bottom" key={'ttm'+index}>
                        <div className="small-12 columns ">
                        <div className="box1">
                          <div className="small-11 columns bold">Intermediate Goal</div>
                          <div className="small-1 columns">
                          {val.id > 1 && <CancelIcon className="icon_rm" onClick={ () => this.removeInputSet1('treatment_target', val.id, this.state.treatment_target) } />}
                          </div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.treatment_target[index].target}
                                onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'target')}
                                variant="outlined" label="" className="width_auto">
                                {treatment_targets.map( each => {
                                  if (each.value < 4)
                                    return(<ListSubheader key={'tt'+index+each.value}>{each.label}</ListSubheader>)
                                  if (each.value > 3)
                                    return(<MenuItem key={'tt'+index+each.value} value={each.value}>{each.label}</MenuItem>)
                                })}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.treatment_target[index].target > 0 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'notes')}
                              value={this.state.treatment_target[index].notes}
                              InputProps={{ inputProps: { maxLength: 240, placeholder: 'Notes' }}} />
                            </div>}
                          {this.state.treatment_target[index].target == 4 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'target_other')}
                              value={this.state.treatment_target[index].target_other}
                              InputProps={{ inputProps: { maxLength: 190, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">Treatment Modality</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.treatment_target[index].modality}
                                onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'modality')}
                                variant="outlined" label="" className="width_auto">
                                {treatment_modality.map( each =>
                                  <MenuItem key={'tm'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.treatment_target[index].modality == 21 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'modality_other')}
                              value={this.state.treatment_target[index].modality_other}
                              InputProps={{ inputProps: { maxLength: 95, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">To Do List item</div>
                          <div className="small-12 columns">
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'intervention')}
                            value={this.state.treatment_target[index].intervention}
                            InputProps={{ inputProps: { maxLength: 240 }}} />
                          </div>
                          <div className="small-12 columns bold space_top_1">To Do List item Format</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.treatment_target[index].intervention_format}
                                onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'intervention_format')}
                                variant="outlined" label="" className="width_auto">
                                {intervention_format.map( each =>
                                  <MenuItem key={'if'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.treatment_target[index].intervention_format == 6 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'intervention_format_other')}
                              value={this.state.treatment_target[index].intervention_format_other}
                              InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">Date To Do List item assigned</div>
                          <div className="small-12 columns float_left width_auto">
                            <TextField variant="outlined" type="date" format={'MM/DD/YYYY'}
                            onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'modailty_date')}
                            value={this.transformDate(this.state.treatment_target[index].modailty_date)} />
                          </div>
                        </div>
                        </div>
                      </div>
                    )}
                    {this.state.getData.length > 0 && this.state.completion_status != 1 && this.state.completion_goal != 1 &&
                    <div className="row">
                      <div className="small-12 columns align_right">
                      <button type="button" className="addInput1 width_auto_right"
                        onClick={ () => this.appendInputSet2('treatment_target', this.state.treatment_target) }>
                         Add treatment target
                      </button>
                      </div>
                    </div>}
                  </div>
                </div>

                {this.state.getData.length > 0 && this.state.completion_status != 1 &&
                  <div className="row top_space1">
                    <div className="small-12 columns text_align_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewTreatmentTarget)
