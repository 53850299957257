import React from "react";
import { Line } from "./line";
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

export const SelectLine = (props) => {
  return (
    <Line label={props.label} noClas={props.noClass} padding={props.padding}>
      <div className={props.noClass ? "" : "small-12 medium-9 columns"}>
        <FormControl variant="outlined">
          <Select
            style={{width: props.width}}
            value={props.value}
            variant="outlined"
            name={props.name}
            label=""
            onChange={props.onChange}
          >
            {props.menuItems && props.menuItems.map((each) => (
                <MenuItem key={"dc" + each.id} value={each.id}>
                  {each.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </Line>
  );
};
