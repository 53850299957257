import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import Spinner from 'react-spinner-material'
import '../../../../styles/assets/css/admin/list2.css'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { smoke_often, yes_no, reason_stopping, diagnosed, diabetes, hypertension }
        from '../../../patients/variables/medical_history.js'

class MedicalHistory extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: {},
          dataItemsMultiple: [],
          loading: false,
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_overall_medical_history',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      var dataItems = []
      var loading = false
      var dataItemsMultiple = []

      console.log(result['data'])

      if(result['data'][0].status == "fulfilled" && result['data'][0].value != 0) {
        dataItems = result['data'][0].value
      }
      if(result['data'][1].status == "fulfilled" && result['data'][1].value != 0) {
        dataItemsMultiple = result['data'][1].value
      }

      if(result['data'][0].status == "fulfilled") {
        loading = true
      }

      this.setState({
        dataItems: dataItems,
        loading: loading,
        dataItemsMultiple: dataItemsMultiple,
      })

    })

    /*axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_medical_history',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        this.setState({
          dataItems: result['data'],
          loading: true,
        })
      }
    )
    // multiple //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_medical_history_medication',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          this.setState({
            dataItemsMultiple: result['data'],
          })
        }
      }
    )*/
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="general_information">
      {!this.state.loading &&
        <div className="row">
          <div className="small-12 columns loading2">
            <Spinner color={"#bbbbbb"} radius={50}/>
          </div>
        </div>}
      {this.state.loading &&
        <div className="row">
          <div className="small-12 columns">
            <div className="w50m_w100s title1">Medical History</div>
            <div className="w50m_w100s title1 right_date">Submitted: {this.state.dataItems.submitted_time}</div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns title3">
                  1. Primary care provider (PCP)
                </div>
                <div className="small-12 columns">
                  <div className="small-12 medium-3 columns title2">Name:</div>
                  <div className="small-12 medium-9 columns txt1">{this.state.dataItems['pcp_name']}</div>
                  <div className="small-12 medium-3 columns title2">Email/Phone:</div>
                  <div className="small-12 medium-9 columns txt1">{this.state.dataItems['pcp_contact']}</div>
                </div>
                <div class="small-12 columns"><div class="sep_line1">&nbsp;</div></div>
                <div className="small-12 columns title3">
                  Therapist(s) outside of FITT-BD
                </div>
                <div className="small-12 columns">
                  <div className="small-12 medium-3 columns title2">Name:</div>
                  <div className="small-12 medium-9 columns txt1">{this.state.dataItems['therapist_name']}</div>
                  <div className="small-12 medium-3 columns title2">Email/Phone:</div>
                  <div className="small-12 medium-9 columns txt1">{this.state.dataItems['therapist_contact']}</div>
                </div>
              </div>
            </div>
            <div className="bg1">
              <div className="row">
                <div className="small-12 medium-8 columns title2">
                  2. Have you smoked within the last year?
                </div>
                <div className="small-12 medium-4 columns txt1">
                  {this.findLabelByValue(yes_no, this.state.dataItems['smoke'])}
                </div>
              </div>
              { this.state.dataItems['smoke'] == 1 &&
              <div className="row">
                <div className="small-12 medium-8 columns title2">
                  How often?
                </div>
                <div className="small-12 medium-4 columns txt1">
                  {this.findLabelByValue(smoke_often, this.state.dataItems['smoke_often'])}
                </div>
              </div>}
              <div className="row">
                <div className="small-12 medium-8 columns title2">
                  3.	Do you currently practice any mind-body techniques (e.g., meditation, relaxation, repetitive prayer, yoga)?
                </div>
                <div className="small-12 medium-4 columns txt1">
                  {this.findLabelByValue(yes_no, this.state.dataItems['mindbody'])}
                </div>
              </div>
              { this.state.dataItems['mindbody'] == 1 &&
              <div className="row">
                <div className="small-12 medium-8 columns title2">
                  How many days of the week do you practice any of these techniques?
                </div>
                <div className="small-12 medium-4 columns txt1">
                  {this.state.dataItems['mindbody_days']}
                </div>
              </div>}
            </div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns title3">
                  4. Please indicate the psychiatric medications you have taken in the past or are currently taking:
                </div>
                { this.state.dataItemsMultiple.map(each =>
                  each.med_category > 0 && each.med_category < 10 &&
                <div>
                  <div className="small-12 columns">
                    <div className="sep_line1"></div>
                  </div>
                  <div className="small-12 columns txt1 bold">
                    <ArrowRightIcon /> {each.med_category_name}: {each.med_other}
                  </div>
                  <div className="small-6 columns txt2">
                    Are you currently taking this medication?
                  </div>
                  <div className="small-6 columns">
                    {this.findLabelByValue(yes_no, each.currently_taking)}&nbsp;
                  </div>
                  {each.currently_taking == 1 &&
                  <div>
                    <div className="small-6 columns txt2">
                      What is the recommended total daily dose?
                    </div>
                    <div className="small-6 columns">{each.y_dose} </div>
                  </div>}
                  {each.currently_taking == 2 &&
                  <div>
                    <div className="small-6 columns txt2">
                      Taken for at least 3 months?
                    </div>
                    <div className="small-6 columns">
                      {this.findLabelByValue(yes_no, each.n_taken_3_months)}&nbsp;
                    </div>
                    <div className="small-6 columns txt2">
                      Reason for stopping
                    </div>
                    <div className="small-6 columns">
                      {this.findLabelByValue(reason_stopping, each.n_reason_stop)}&nbsp;
                    </div>
                  </div>}
                </div>)}
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns title3">
                  5. Please list any non-psychiatric medications you are currently taking:
                </div>
                { this.state.dataItemsMultiple.map(each =>
                  each.med_category == 10 &&
                <div>
                  <div className="small-12 columns">
                    <div className="sep_line1"></div>
                  </div>
                  <div className="small-12 columns txt1 bold">
                    <ArrowRightIcon /> {each.med_category_name}: {each.med_other}
                  </div>
                  <div className="small-2 columns txt2">
                    Dosage:
                  </div>
                  <div className="small-10 columns">{each.y_dose} mg</div>
                </div>)}
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns title3">
                  6. Have you ever been diagnosed with the following?
                </div>
                <div className="small-12 columns">
                  <div className="sep_line1"></div>
                </div>
                {diagnosed.map(each =>
                <div className="row">
                  <div className="small-6 columns title2">
                    {each.label}
                  </div>
                  <div className="small-6 columns txt1">
                    {this.findLabelByValue(yes_no, this.state.dataItems['diagnosed_'+each.value])}
                  </div>
                  {each.value == 1 && this.state.dataItems['diagnosed_'+each.value] == 1 &&
                  <div>
                    <div className="small-6 columns title2">Approximate date of most recent injury</div>
                    <div className="small-6 columns txt1">{this.state.dataItems['diagnosed_brain_date']}</div>
                    <div className="small-6 columns title2">Please describe</div>
                    <div className="small-6 columns txt1">{this.state.dataItems['diagnosed_brain_text']}</div>
                  </div>}
                  {each.value == 2 && this.state.dataItems['diagnosed_'+each.value] == 1 &&
                  <div>
                    <div className="small-6 columns title2">Type of diabetes</div>
                    <div className="small-6 columns txt1">{this.findLabelByValue(diabetes, this.state.dataItems['diagnosed_diabetes'])}</div>
                  </div>}
                  {each.value == 3 && this.state.dataItems['diagnosed_'+each.value] == 1 &&
                  <div>
                    <div className="small-6 columns title2">Are you currently being treated for high blood pressure with medication(s)?</div>
                    <div className="small-6 columns txt1">{this.findLabelByValue(hypertension, this.state.dataItems['diagnosed_hypertension'])}</div>
                  </div>}
                  {each.value == 15 && this.state.dataItems['diagnosed_'+each.value] == 1 &&
                  <div>
                    <div className="small-6 columns title2">Please describe</div>
                    <div className="small-6 columns txt1">{this.state.dataItems['diagnosed_cancer_txt']}</div>
                  </div>}
                  {each.value == 23 && this.state.dataItems['diagnosed_'+each.value] == 1 &&
                  <div>
                    <div className="small-6 columns title2">Please write down your medical condition</div>
                    <div className="small-6 columns txt1">{this.state.dataItems['diagnosed_other']}</div>
                  </div>}
                </div>)}
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MedicalHistory)
