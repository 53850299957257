import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { q1, q2, q3, q4, q5 } from '../variables/assessments/asrm.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import StepperAssm from './stepper_assm'

class Asrm extends Component {
  constructor() {
      super()
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'gad7',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'] !== 'asrm') {
            this.props.history.push('/member/assessment/'+result['data']+'/'+this.props.match.params.type+'/'+timepoint)
          }
        }
      )
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/get_assessments_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid:  this.props.authReducer.uid,
        }
      })
      .then((result) => {
        var next_step = 'gad7'
        if(timepoint == -2) {
          next_step = 'gad7'
        } else {
          if(result['data'].m_gad7 == 0) {
            next_step = 'psqi'
            if(result['data'].m_psqi == 0) {
              next_step = 'mtf'
              if(result['data'].m_mtf == 0) {
                next_step = 'complete_assessments'
              }
            }
          }
        }
        this.setState({
          next_step: next_step,
        })
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/chk_each_assm',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          assm: 'patients_assessment_asrm',
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].m_asrm == 0) {
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/update_skip_step',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
                update_step: 'gad7',
               }
            })
            .then((result) => {
              this.props.history.push('/member/assessment/gad7/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            })
          }
          if(result['data'].status !== null) {
            if(result['data'].assm_status == 1) {
              // Completed //
              axios({
                method: 'post',
                url: connectNode + 'patient/assessment/get_assm_data',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                  uid: this.props.authReducer.uid,
                  type: this.props.match.params.type,
                  timepoint: this.props.match.params.timepoint,
                  frm: 'asrm',
                 }
              }).then((result) => {
                this.setState({
                  status: 2,
                  q1: result['data'].q1,
                  q2: result['data'].q2,
                  q3: result['data'].q3,
                  q4: result['data'].q4,
                  q5: result['data'].q5,
                })
                //console.log(this.state)
              })
            } else {
              if(result['data'].status == 1 && result['data'].step !== 'asrm') {
                this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
              }
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          if(this.props.match.params.timepoint != -2) {
            // Past timepoint //
            this.setState({ status: 3 })
          } else {
            // Completed //
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/get_assm_data',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
                frm: 'asrm',
               }
            }).then((result) => {
              this.setState({
                status: 2,
                q1: result['data'].q1,
                q2: result['data'].q2,
                q3: result['data'].q3,
                q4: result['data'].q4,
                q5: result['data'].q5,
              })
            })
          }
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = this.state.next_step
    if(this.state.status === 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 ) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        var timepoint = this.props.match.params.timepoint
        var type = this.props.match.params.type
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_asrm',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            type: type,
            timepoint: timepoint,
          }
        })
        .then((result) => {
          //console.log(result['data'])
          if(result['data'] === next_step) {
            this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
            this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
          }
        })
      }
    } else {
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    return (
      <div className="assm_asrm box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Altman Self-Rating Mania Scale (ASRM)
          </div>
          {//this.state.status == 1 &&
          <StepperAssm activeStep={2} assm={'ASRM'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box2">
            <p className="bold2">Instructions:</p>
            <p><ol>
              <li>There are five statement groups on this questionnaire: read each group of statements carefully.</li>
              <li>Choose the one statement in each group that best describes the way you have been feeling for the PAST WEEK.</li>
              <li>Click on the button next to the number/statement selected.</li>
              <li>Please note: The word “occasionally” when used here means once or twice; “often” means several times or more and “frequently” means most of the time.</li>
            </ol></p>
            <p><b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.</p>
          </div>
          <div className="small-12 columns box1">
            <div className="row">
                <div className="small-12 columns bold3 margin-bottom1">
                  Question 1
                </div>
                {q1.map(each =>
                  <div className="small-12 columns" key={'q1'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    checked={this.state['q1'] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  Question 2
                </div>
                {q2.map(each =>
                  <div className="small-12 columns">
                    <input type="radio" name="q2" value={each.value}
                    checked={this.state['q2'] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  Question 3
                </div>
                {q3.map(each =>
                  <div className="small-12 columns">
                    <input type="radio" name="q3" value={each.value}
                    checked={this.state['q3'] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  Question 4
                </div>
                {q4.map(each =>
                  <div className="small-12 columns">
                    <input type="radio" name="q4" value={each.value}
                    checked={this.state['q4'] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  Question 5
                </div>
                {q5.map(each =>
                  <div className="small-12 columns">
                    <input type="radio" name="q5" value={each.value}
                    checked={this.state['q5'] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}

              </div>

        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Asrm)
