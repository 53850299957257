import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/pyramid1.png'
//import AudioMP3 from '../../../audios/sample1.mp3'

class Intro4 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction5')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction3')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_1 margin-bottom1">STEPPED CARE</div>
            <div className="content1 margin-top1">
              <p>Stepped care means exactly what it sounds like: you start treatment on the lowest “step,” or level, that is likely to be effective for you.</p>
              <p>To make sure this level of care is working for you, we keep very close track of how you’re doing. If you’re doing well, you’ll stay where you are. If you’re not, you’ll go up to a more intensive level of care.</p>
              <p>As your treatment progresses, you may move up or down the “steps.” We will continue to work with you to make sure you are getting the level of care that you need.</p>
              <p>&nbsp;</p>
            </div>
            <div className="row">
              <div className="small-4 columns back_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro4)
