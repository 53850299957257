import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import ContactTablet from "./contact/contact_tablet"
import ContactDetermination from "./contact/contact_determination"

class TabContact extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: 1,
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    if(this.props.match.params.secondtab > 0) {
      this.setState({
        tab_status: this.props.match.params.secondtab,
      })
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }
  genTabList = (tab_status, patient_status, loan_tablet, determine_bipolar) => {
    var gen = null
    if((patient_status == 7 || patient_status == 71 || loan_tablet > 0) && patient_status != 8 && patient_status != 81 && determine_bipolar == null) {
      gen = <TabContext value={tab_status}>
      <AppBar position="static">
      <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
        <Tab label="Tablet Loan" value="1" />
      </TabList>
      </AppBar>
      <TabPanel value="1" index={1} ><ContactTablet /></TabPanel>
      </TabContext>
    } else if((patient_status == 8 || patient_status == 81 || determine_bipolar > 0) && patient_status != 7 && patient_status != 71 && loan_tablet == null) {
      gen = <TabContext value={tab_status}>
      <AppBar position="static">
      <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
        <Tab label="BD Screening" value="1" />
      </TabList>
      </AppBar>
      <TabPanel value="1" index={1} ><ContactDetermination /></TabPanel>
      </TabContext>
    } else if((determine_bipolar > 0 && loan_tablet > 0)
    || (loan_tablet > 0 && (patient_status == 8 || patient_status == 81))
    || (determine_bipolar > 0 && (patient_status == 8 || patient_status == 81))) {
      gen = <TabContext value={tab_status}>
      <AppBar position="static">
      <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
        <Tab label="Tablet Loan" value="1" />
        <Tab label="BD Screening" value="2" />
      </TabList>
      </AppBar>
      <TabPanel value="1" index={1} >
        {this.state.tab_status == 1 && <ContactTablet />}
      </TabPanel>
      <TabPanel value="2" index={2} >
        {this.state.tab_status == 2 && <ContactDetermination />}
      </TabPanel>
      </TabContext>
    }
    return gen
  }

  render() {
    //console.log(this.props.patient_status, this.props.loan_tablet, this.props.determine_bipolar)
    return (
      <div className="admin_make_appointment">
        <div className="row">
          <div className="small-12 columns tab_data1">
              { this.genTabList(this.state.tab_status, this.props.patient_status, this.props.loan_tablet, this.props.determine_bipolar) }
          </div>
        </div>
      </div>
    )}
}

const mapStateToProps = (state) => {
  //console.log('tab contact STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabContact)
