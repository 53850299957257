import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { mini_steps, mini_q_j3, mini_scale1 } from '../variables/assessments/mini.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperMINI from './stepper'

class Mini6 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'mini7',
          j25: 0,
          j26: 0,
          j27: 0,
          j28: 0,
          j29: 0,
          j30: 0,
          j31: 0,
          j32: 0,
          j33: 0,
          j34: 0,
          j35: 0,
          j36: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_mini_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          if(result['data'].mini_step !== 'mini6') {
            this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    if(this.state.j25 === 0 || this.state.j26 === 0 || this.state.j27 === 0
      || this.state.j28 === 0 || this.state.j29 === 0 || this.state.j30 === 0
      || this.state.j31 === '' || this.state.j32 === 0 || this.state.j33 === 0
      || this.state.j34 === 0 || this.state.j35 === 0 || this.state.j36 === 0 ) {
      this.setState({
        message: 'Please select all answers ',
      })
      window.scrollTo(0, 0)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/update_mini6',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          user: this.state,
          pid: pid,
          timepoint: timepoint,
        }
      })
      .then((result) => {
          if(result['data'] === this.state.next_step) {
            this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
            this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          }
        }
      )
    }
  }

  render() {

    return (
      <div className="assm_mini box_border4">
        { this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
          <StepperMINI activeStep={5} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { this.state.appt_id > 0 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1">
              <div className="row show-for-medium bold">
                <div className="medium-6 columns">&nbsp;</div>
                {mini_scale1.map(each =>
                  <div className="medium-2 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>

              <div className="scroll_box1">
                <div className="scroll_box1_content">
                {mini_q_j3.map(eachQ =>
                  <div className="row" key={'qa'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      J{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                        <input type="radio" name={"j"+eachQ.value} value={each.value} onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 36 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                </div>
              </div>
            </div>

          <div className="small-12 columns align_right">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Mini6)
