export const sccs_questions = [
  {
    label: 'My beliefs about myself often conflict with one another.',
    value: 1,
  }, {
    label: 'On one day I might have one opinion of myself and on another day I might have a different opinion.',
    value: 2,
  }, {
    label: 'I spend a lot of time wondering about what kind of person I really am.',
    value: 3,
  }, {
    label: 'Sometimes I feel that I am not really the person that I appear to be.',
    value: 4,
  }, {
    label: 'When I think about the kind of person I have been in the past, I\'m not sure what I was really like.',
    value: 5,
  }, {
    label: 'I seldom experience conflict between the different aspects of my personality.',
    value: 6,
  }, {
    label: 'Sometimes I think I know other people better than I know myself.',
    value: 7,
  }, {
    label: 'My beliefs about myself seem to change very frequently.',
    value: 8,
  }, {
    label: 'If I were asked to describe my personality, my description might end up being different from one day to another day.',
    value: 9,
  }, {
    label: 'Even if I wanted to, I don\'t think I could tell someone what I\'m really like.',
    value: 10,
  }, {
    label: 'In general, I have a clear sense of who I am and what I am.',
    value: 11,
  }, {
    label: 'It is often hard for me to make up my mind about things because I don\'t really know what I want.',
    value: 12,
  },
]

export const sccs_scale = [
  {
    label: 'Strongly disagree',
    value: 1,
  }, {
    label: ' ',
    value: 2,
  }, {
    label: ' ',
    value: 3,
  }, {
    label: ' ',
    value: 4,
  }, {
    label: 'Strongly agree',
    value: 5,
  },
]
