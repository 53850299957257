import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PatientHead1 from "../patient/patient_head1"
import { clinicians, appt_type, appt_sub_type, appt_visit,
  frequency, frequency_time } from '../variables/patient.js'

class EditAppointment extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          warning_status: 0,
          message: '',
          clinician_list: [],
          clinician: 0,
          clinician_other: '',
          clinician_initial: '',
          type: 0,
          frequency: 0,
          recurrent: 0,
          recurrent_end_date: '',
          visit: 0,
          date: '',
          time: '',
          duration: 0,
          reason: 0,
          reason_other: '',
      }
  }

  componentDidMount() {
    this.getPatientAppt()
  }

  getPatientAppt = async () => {
    // get clinician list //
    await axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_clinician_list2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
    }).then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          clinician_list: result['data']
        })
      }
    })
    // get appointment //
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_appt_detail',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        aid: this.props.match.params.aid,
        pid: pid
      }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          this.setState({
            clinician: result['data'][0].cid,
            clinician_other: result['data'][0].c_other_name,
            clinician_initial: result['data'][0].c_other_initial,
            epic_type: result['data'][0].type,
            type: result['data'][0].sub_type,
            frequency: result['data'][0].frequency,
            recurrent: result['data'][0].recurrent,
            recurrent_end_date: result['data'][0].recurrent_end_date,
            visit: result['data'][0].visit,
            date: result['data'][0].appt_date,
            time: result['data'][0].appt_time,
            duration: result['data'][0].duration,
            reason: result['data'][0].reason_name,
            reason_other: result['data'][0].reason_other,
          })
        } else {
          this.setState({
            message: 'Something went wrong. There is no this appointment in the system'
          })
        }
      }
    )
  }

  get_outcome_and_reasons = (editMode = false) => {
    axios({
      method: 'post',
      url: connectNode + 'functions/patient/get_dd_variable2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { v_type: 8, }
    })
    .then((result) => {
      this.setState({
        reason_list: result['data']
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleCancelAppointment = (e) => {
    document.getElementById('warning_box').style.display = 'block'
  }

  confirmCancel = (e) => {
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/cancel_appointment',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        aid: this.props.match.params.aid,
      }
    })
    .then((result) => {
        if(result['data'] === this.props.match.params.pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/1', 'Canceled appointment successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
        }
      }
    )
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.clinician == 0 || this.state.visit == 0
      || this.state.date === '' || this.state.time === '' || this.state.duration == 0
      || (this.state.clinician === 1 && (this.state.clinician_other === '' || this.state.clinician_initial === ''))
      || (this.state.type > 1 && this.state.frequency == 2 && this.state.recurrent == 0 && this.state.recurrent_end_date === '')) {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/edit_appointment',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          aid: this.props.match.params.aid,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] === this.props.match.params.pid) {
            this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/1', 'Rescheduled appointment successfully!')
            this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
          }
        }
      )
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_make_appointment box_border4" id="admin_edit_appt">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Appointment: Edit
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg8">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>
            }
            {this.state.message === '' &&
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead1 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns">
                        <div className="small-12 medium-2 columns bold_txt4">EPIC: </div>
                        <div className="small-12 medium-10 columns">
                          {this.state.epic_type > 0 && this.findLabelByValue(appt_type, this.state.epic_type)}
                        </div>
                      </div>
                      {this.state.reason != 0 &&
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt4">Reason: </div>
                        <div className="small-12 medium-10 columns">
                          {this.state.reason}
                        </div>
                      </div>}
                      {this.state.type > 1 &&
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt4">Frequency: </div>
                        <div className="small-12 medium-5 columns float_left">
                          {this.state.frequency > 0 && this.findLabelByValue(frequency, this.state.frequency)}
                        </div>
                      </div>
                      }
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Provider: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.clinician} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange2(e, 'clinician')}>
                              {this.state.clinician_list.map( each =>
                                <MenuItem key={'cn'+each.sub_role+each.uid} value={each.uid}>{each.fullname}</MenuItem>
                              )}
                              <MenuItem key='cn_other' value='1' >Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {this.state.clinician === 1 &&
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Provider Name: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="text" defaultValue={this.state.clinician_other}
                          value={this.state.clinician_other}
                          InputProps={{ inputProps: { name: "clinician_other" }}} />
                        </div>
                        <div className="small-12 medium-1 columns bold_txt3">Initial: </div>
                        <div className="small-12 medium-2 columns float_left no_padding_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="text" defaultValue={this.state.clinician_initial}
                          value={this.state.clinician_initial}
                          InputProps={{ inputProps: { name: "clinician_initial" }}} />
                        </div>
                      </div>}
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Format: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.visit} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange2(e, 'visit')}>
                              {appt_visit.map( each =>
                                <MenuItem key={'appt_visit'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Date: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="date" value={this.state.date}
                          InputProps={{ inputProps: { name: "date" }}} />
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Time: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="time" value={this.state.time}
                          InputProps={{ inputProps: { name: "time" }}} />
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Duration: </div>
                        <div className="small-11 medium-5 columns">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="number" value={this.state.duration}
                          InputProps={{ inputProps: { name: "duration" }}} />
                        </div>
                        <div className="small-1 medium-1 columns vertical_middle float_left">mins</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 medium-6 columns">
                  <Link className="cancel_appt top_space1" to={"/admin/patient_chart/"+this.props.match.params.pid+"/1/1"}>Go Back</Link>
                  </div>
                  <div className="small-12 medium-6 columns text_align_right">
                    <Link to="#" className="cancel_appt" onClick={this.handleCancelAppointment}>Cancel Appointment</Link>
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>

              </form>
            </div>}
          </div>
        </div>
        {//this.state.warning_status == 2 &&
        <div className="warning_box" id="warning_box">
          <div className="row">
            <div className="small-12 columns">
            <WarningIcon /> Are you sure you want to cancel this appointment?
            </div>
            <div className="small-12 columns margin_top_space1">
            <Button variant="contained" color="primary" className="margin_right_1" disableElevation
             onClick={this.confirmCancel} type="button">Yes</Button>
           <Button variant="contained" color="primary" disableElevation
            onClick={this.closeWarningBox} type="button">No</Button>
            </div>
          </div>
        </div>}
      </div>
    )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditAppointment)
