import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'
import GetPDF from '../../functions/get_pdf.jsx'

class ConsentPDF extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          pdf: '',
          title: '',
          //pdf_path: '',
      }
  }

  componentDidMount() {
    //this.getData()
    const page = this.props.match.params.consent
    const pid = this.props.authReducer.uid
    var title = ''

    if(page == 'clinicpolicy') {
      title = 'Clinic Policies'
    } else if(page == 'patientwaiver') {
      title = 'Patient Waiver'
    } else if(page == 'communication') {
      title = 'Communication Preferences'
    } else if(page == 'treatment') {
      title = 'Treatment Consent Form'
    } else if(page == 'information_sheet') {
      title = 'FITT-BD Information Sheet'
    }

    this.setState({
      title: title,
    })
  }

  render() {
    //console.log(this.state)
    return (
      <div className="patient_contract_pdf box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            {this.state.title}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <GetPDF path={this.props.match.params.consent}  />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConsentPDF)
