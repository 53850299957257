import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import Button from '@material-ui/core/Button'

class PatientViewNote extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          description: '',
          date: '',
          time: '',
          duration: 0,
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] > 0) {
          //this.props.onLoadPages(response)
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
          /*this.setState({
            message: 'Adding new patient completed!'
          })*/
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {

    return (
      <div className="admin_make_appointment box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Contact Log
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className="warning_msg2">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="small-12 columns box_inner3">
                  <div className="row">
                    <div className="small-12 medium-4 columns">
                      <span className="bold_txt1">Patient Name: </span>
                      Nicha Test
                    </div>
                    <div className="small-12 medium-4 columns">
                      <span className="bold_txt1">MRN: </span> 4324234
                    </div>
                    <div className="small-12 medium-4 columns">
                      <span className="bold_txt1">Registered: </span> 01/01/2020, 11:10 am
                    </div>
                  </div>
                  <div className="row">
                    <div className="small-12 medium-4 columns bottom-space1">
                      <span className="bold_txt1">Date of Birth: </span>
                      01/01/1970
                    </div>
                    <div className="small-12 medium-4 columns">
                      <span className="bold_txt1">Age: </span> 50 years
                    </div>
                    <div className="small-12 medium-4 columns">
                      <span className="bold_txt1">Status: </span> active
                    </div>
                  </div>
                </div>
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns bottom-space1">
                        <div className="small-12 medium-2 columns title2">Clinician: </div>
                        <div className="small-12 medium-10 columns txt1">
                          Test Clinician
                        </div>
                      </div>
                      <div className="small-12 columns bottom-space1">
                        <div className="small-12 medium-2 columns title2">Type: </div>
                        <div className="small-12 medium-10 columns txt1">
                          Initial
                        </div>
                      </div>
                      <div className="small-12 columns bottom-space1">
                        <div className="small-12 medium-2 columns title2">Date/Time: </div>
                        <div className="small-12 medium-10 columns txt1">
                          01/02/2020, 11:10 am
                        </div>
                      </div>
                      <div className="small-12 columns">
                        <div className="small-12 medium-2 columns title2">Note: </div>
                        <div className="small-12 medium-10 columns txt1">
                          The event appeared to be a slow burn to those watching NASA's livestream, but that's because the spacecraft and the ISS were traveling at roughly the same speed — more than 17,000 miles per hour, the speed necessary to keep objects orbiting the Earth.
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Back
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientViewNote)
