export const q1 = [
  {
    label: 'I do not feel happier or more cheerful than usual.',
    value: 0,
  }, {
    label: 'I occasionally feel happier or more cheerful than usual.',
    value: 1,
  }, {
    label: 'I often feel happier or more cheerful than usual.',
    value: 2,
  }, {
    label: 'I feel happier or more cheerful than usual most of the time.',
    value: 3,
  }, {
    label: 'I feel happier or more cheerful than usual all of the time.',
    value: 4,
  },
]

export const q2 = [
  {
    label: 'I do not feel more self-confident than usual.',
    value: 0,
  }, {
    label: 'I occasionally feel more self-confident than usual.',
    value: 1,
  }, {
    label: 'I often feel more self-confident than usual.',
    value: 2,
  }, {
    label: 'I feel more self-confident than usual.',
    value: 3,
  }, {
    label: 'I feel extremely self-confident all of the time.',
    value: 4,
  },
]

export const q3 = [
  {
    label: 'I do not need less sleep than usual.',
    value: 0,
  }, {
    label: 'I occasionally need less sleep than usual.',
    value: 1,
  }, {
    label: 'I often need less sleep than usual.',
    value: 2,
  }, {
    label: 'I frequently need less sleep than usual.',
    value: 3,
  }, {
    label: 'I can go all day and night without any sleep and still not feel tired.',
    value: 4,
  },
]

export const q4 = [
  {
    label: 'I do not talk more than usual.',
    value: 0,
  }, {
    label: 'I occasionally talk more than usual.',
    value: 1,
  }, {
    label: 'I often talk more than usual.',
    value: 2,
  }, {
    label: 'I frequently talk more than usual.',
    value: 3,
  }, {
    label: 'I talk constantly and cannot be interrupted.',
    value: 4,
  },
]

export const q5 = [
  {
    label: 'I have not been more active (either socially, sexually, at work, home or school) than usual.',
    value: 0,
  }, {
    label: 'I have occasionally been more active than usual.',
    value: 1,
  }, {
    label: 'I have often been more active than usual.',
    value: 2,
  }, {
    label: 'I have frequently been more active than usual.',
    value: 3,
  }, {
    label: 'I am constantly active or on the go all the time.',
    value: 4,
  },
]
