export const gad7_questions = [
  {
    label: 'Feeling nervous, anxious, or on edge',
    value: 1,
  }, {
    label: 'Not being able to stop or control worrying',
    value: 2,
  }, {
    label: 'Worrying too much about different things',
    value: 3,
  }, {
    label: 'Trouble relaxing',
    value: 4,
  }, {
    label: 'Being so restless that it is hard to sit still',
    value: 5,
  }, {
    label: 'Becoming easily annoyed or irritable',
    value: 6,
  }, {
    label: 'Feeling afraid, as if something awful might happen',
    value: 7,
  },
]

export const gad7_question_8 = [
  {
    label: 'If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?',
    value: 8,
  },
]

export const gad7_1 = [
  {
    label: 'Not at all',
    value: 0,
  }, {
    label: 'Several days',
    value: 1,
  }, {
    label: 'More than half the days',
    value: 2,
  }, {
    label: 'Nearly every day',
    value: 3,
  },
]

export const gad7_2 = [
  {
    label: 'Not difficult at all',
    value: 1,
  }, {
    label: 'Somewhat difficult',
    value: 2,
  }, {
    label: 'Very difficult',
    value: 3,
  }, {
    label: 'Extremely difficult',
    value: 4,
  },
]
