import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import PatientHead3 from "../../../patient/patient_head3"
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { clinicians } from '../../../variables/patient.js'
import { change_reasons } from '../../variables/to_do_list'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";

class ToDoChange extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          todo: '',
          td_status: 2,
          primary_reason: 0,
          primary_reason_other: '',
          secondary_reason: 0,
          secondary_reason_other: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/to_do_list/get_to_do',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        td_id: this.props.match.params.td_id,
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          this.setState({
            todo: result['data'][0].to_do,
            td_status: result['data'][0].status,
            primary_reason: result['data'][0].reason_changed_1,
            primary_reason_other: this.checkNull(result['data'][0].reason_changed_1_other),
            secondary_reason: result['data'][0].reason_changed_2,
            secondary_reason_other: this.checkNull(result['data'][0].reason_changed_2_other),
          })
        }
      }
    )
  }

  checkNull = (txt) => {
    if(txt === 'null') {
      return ''
    }
    return txt
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: this.checkNull(escapeHtml(e.target.value)),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.primary_reason === null) {
      this.setState({
        status: false,
        message: 'Please select primary reason',
      })
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/to_do_list/change_to_do_reason',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          cid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          td_id: this.props.match.params.td_id,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] == this.props.match.params.pid) {
            this.setState({
              message: 'Changed successfully!',
              status: true,
              td_status: 2,
            })
          } else {
            this.setState({
              message: 'Something went wrong. Please try again.',
              status: false,
            })
          }
        }
      )
    }
  }

  render() {
    return (
      <div className="admin_log_to_do box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            To-Do List: Reason for Modification
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
            <form id="make_appointment" onSubmit={this.handleSubmit}>
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  { this.state.message !== '' && this.state.status === false &&
                    <div className="small-12 columns warning_msg6">
                      <div className="float_left1"><WarningIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}
                  { this.state.message !== '' && this.state.status === true &&
                    <div className="small-12 columns warning_msg8">
                      <div className="float_left1"><CheckCircleOutlineIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}

                  <div className="row ">
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns bold_right">To-do: </div>
                      <div className="small-12 medium-8 columns float_left">{this.state.todo}</div>
                    </div>
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns bold_txt3">Primary reason: </div>
                      <div className="small-12 medium-8 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.primary_reason} variant="outlined"
                            label="" defaultValue="" disabled={this.state.td_status > 1 && 'disabled'}
                            onChange={(e) => this.handleChange2(e, 'primary_reason')}>
                              {change_reasons.map( each =>
                                <MenuItem key={'pr'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {this.state.primary_reason === 9 &&
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns bold_txt3">Primary reason: </div>
                      <div className="small-12 medium-8 columns float_left">
                      <TextField label="" onChange={this.handleChange} name="primary_reason_other"
                        disabled={this.state.td_status > 1 && 'disabled'}
                        variant="outlined" type="text" defaultValue={this.state.primary_reason_other}
                        InputProps={{ inputProps: { name: "primary_reason_other" }}} />
                      </div>
                    </div>}

                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns bold_txt3">Secondary reason: </div>
                      <div className="small-12 medium-8 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.secondary_reason} variant="outlined"
                            label="" defaultValue="" disabled={this.state.td_status > 1 && 'disabled'}
                            onChange={(e) => this.handleChange2(e, 'secondary_reason')}>
                            {change_reasons.map( each =>
                              <MenuItem key={'sr'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    {this.state.secondary_reason === 9 &&
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns bold_txt3">Secondary reason: </div>
                      <div className="small-12 medium-8 columns float_left">
                      <TextField label="" onChange={this.handleChange} name="secondary_reason_other"
                        variant="outlined" type="text" defaultValue={this.state.secondary_reason_other}
                        disabled={this.state.td_status > 1 && 'disabled'}
                        InputProps={{ inputProps: { name: "secondary_reason_other" }}} />
                      </div>
                    </div>}
                    {//this.state.td_status === 1 &&
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-3 columns">&nbsp;</div>
                      <div className="small-12 medium-9 columns ">
                        <Button variant="contained" color="primary" disableElevation
                          disabled={this.state.td_status > 1 && 'disabled'}
                         onClick={this.handleSubmit} type="submit">
                          Submit
                        </Button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ToDoChange)
