import axios from "axios"
import Cookies from 'js-cookie'
import { SAVE_USER_AUTH } from '../const/authConst'
import CryptoJS from 'crypto-js'
//import CryptoAES from 'crypto-js/aes'
//import { CALL_API } from 'redux-api-middleware'
import { connectNode } from '../utils/global.js'
/*export const RECEIVE_INTERVENTION = 'RECEIVE_INTERVENTION'*/
import AuthService from '../services/auth/auth.service.js'
const { JWT_CRYPT_KEY } = require("../const/authKey")

export function saveLoginUser(route) {
  return {
    type: SAVE_USER_AUTH,
    route
  }
}

export const logoutUser = () => {
  return ({
    type: 'LOGOUT_USER_AUTH',
    payload: {uid: null},
  })
}

export const loadAuthUser = () => {
  var getToken = Cookies.get('fr')
  if( !getToken ){
    return ({
      type: 'LOAD_USER_AUTH',
      payload: {uid: null},
    })
  }

  var dec = CryptoJS.AES.decrypt(getToken, JWT_CRYPT_KEY).toString(CryptoJS.enc.Utf8)

  return (dispatch) => { // dispatch after the async request ends.
    //return axios.get(connectNode + 'users/getUserByID?uid=2', {
    //    'Content-Type': 'application/x-www-form-urlencoded',
    //  }).then(response => {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      //'Content-Type' : 'application/json',
      //'Content-Type': 'application/x-www-form-urlencoded',
      'Accept' : 'application/json',
      //'Authorization' : 'JWT ' + dec,
      'Authorization' : 'bearer ' + dec,
      //"Access-Control-Allow-Origin": "*",
    }
    var user = {}
    //console.log('Cookies: '+Cookies.get('c_'))
    //console.log(connectNode + 'users/profile')
    return axios.post(connectNode + 'users/profile', {
        uid: Cookies.get('c_'),
        fr: dec
      } , {
      headers: headers,
      withCredentials: true,
      credentials: 'same-origin',
    }).then(response => {
      //console.log('Auth: ')
      //console.log(response)
        if(response.data.uid > 0) {
          //if (typeof response.data.token !== 'undefined') {
            user = {
              uid: response.data.uid,
              role: response.data.role,
              username: response.data.username,
              sub_role: response.data.sub_role,
              p_st: response.data.p_st, // patients_registration->status
              b_step: response.data.baseline_step,
              token: response.data.token,
              loggedIn: true,
              expires: response.data.expires,
              course_id: response.data.course_id,
            }
          //}
          Cookies.set('c_', response.data.uid, {
            expires: new Date(response.data.expires),
          })
          Cookies.set('fr', getToken, {
            expires: new Date(response.data.expires),
          })
          Cookies.set('co_', response.data.course_id, {
            expires: new Date(response.data.expires),
          })

          //return (dispatch) => {
            // ก่อนอื่นเมื่อเรียก loadPages ก็ให้สร้าง action เพื่อบอกว่ากำลังโหลดนะ
            dispatch({
              type: 'LOAD_USER_AUTH',
              payload: user,
            })
          //}
        } else {
          user = {
            loggedIn: false,
          }
          dispatch({
            type: 'LOAD_USER_AUTH',
            payload: user,
          })
        }
      }).catch(error => {
        //console.log(error)
        const {status} = error.response
        if (status === 401) {
          AuthService.logout()
          window.location.reload(false)
        }
      })
  }
}
