import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/pyramid-1-1.png'
import Image2 from '../../../images/olivia1.png'
//import AudioMP3 from '../../../audios/sample1.mp3'

class Intro6 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction7')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction5')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_video2"></div>
            <div className="row">
              <div className="small-12 medium-8 columns content2">
                <img src={Image} alt="pyramid" className="w_90percent"/>
                <div className="margin-top1">
                  <p>When Olivia enrolls in the FITT-BD clinic, she meets
                  with her supervising clinician to come up with a treatment plan.</p>
                  <p>In consultation with the FITT-BD team, Olivia begins on STEP ONE with an online,
                  self-guided Cognitive Behavioral Therapy (CBT) program. </p>
                  <p>She also meets with a team psychopharmacologist to evaluate
                  her current medication.</p>
                </div>
              </div>
              <div className="small-12 medium-4 columns gray_bg">
                <div className="margin-top1"><img src={Image2} alt="pyramid" className="w_80percentc margin-top1" /></div>
                <div className="content_under_picture">
                  <div className="w_100percent"><b>Age:</b> 29</div>
                  <div className="w_80percent"><b>Age diagnosed with bipolar:</b> 19</div>
                  <div className="w_80percent"><b>Education:</b> High school, some college</div>
			            <div className="w_80percent"><b>Work:</b> Retail</div>
                  <div className="w_100percent"><b>Goals:</b> Business degree</div>
                  <div className="w_80percent">Increase energy</div>
				          <div className="w_80percent">Do more things I enjoy</div>
                  <div className="w_100percent"><b>Step:</b> One</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro6)
