import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { calculateTimepoint1 } from '../../../functions/timepoint.js'
import '../../../../styles/assets/css/pages/assessments1.css'
import { loadLocationWithMessage } from '../../../../actions/location'
import moment from 'moment'
import { IoIosSquare } from 'react-icons/io'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ScheduleIcon from '@material-ui/icons/Schedule'
import Spinner from 'react-spinner-material'

class AssessmentList extends Component {
  constructor() {
      super()
      this.state = {
          message: '',
          timeline_start: '',
          now: Math.floor(Date.now() / 1000),
          diff_days: 0,
          mod_days: 0,
          loading: false,
          status_assm: {},
          current_week: 0,
          current_month: 0,
          current_trimonth: 0,
          current_year: 0,
          week_assm: [],
          month_assm: [],
          month_assm_unsc: [],
          trimonth_assm: [],
          trimonth_assm_unsc: [],
          year_assm: [],
      }
  }

  componentDidMount() {
    this.getTimelineStart()
  }

  getTimelineStart = async () => {
    var assm = []
    var assmM = []
    var assmTM = []
    var assmY = []
    var status_assm = {}
    let toState = {}

    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_initial_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.match.params.pid,
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      var getData = []
      //console.log(result)
      if(result['data'][0].status == "fulfilled") {
        status_assm = result['data'][0].value
      }
      if(result['data'][1].status == "fulfilled") {
        getData = result['data'][1].value
        var calTimepoint = calculateTimepoint1(getData)
        for(var i=1; i<=calTimepoint.current_week+1; i++) {
          assm[i] = {phq4: 0, who5: 0}
        }
        for(var i=-1; i<=calTimepoint.current_month+1; i++) {
          assmM[i] = {phq9: 0, asmr: 0, gad7: 0, psqi: 0, mtf: 0}
        }
        for(var i=-1; i<=calTimepoint.current_trimonth+1; i++) {
          assmTM[i] = {covid: 0, pw18: 0}
        }
        for(var i=1; i<=calTimepoint.current_year+1; i++) {
          assmY[i] = {ders: 0}
        }

        toState = {
          timeline_start: getData.timeline_start,
          diff_days: calTimepoint.diff_days,
          mod_days: calTimepoint.mod_days,
          mod_months: calTimepoint.mod_months,
          mod_years: calTimepoint.mod_years,
          current_week: calTimepoint.current_week,
          current_month: calTimepoint.current_month,
          current_trimonth: calTimepoint.current_trimonth,
          current_year: calTimepoint.current_year,
          status_assm: status_assm,
        }
      }
    })

    await axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_overall_assm_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.match.params.pid,
        pid: this.props.match.params.pid,
        current_timepointW: this.state.current_week,
        type_timepointW: 'w',
        current_timepointM: this.state.current_month,
        type_timepointM: 'm',
        current_timepointTM: this.state.current_trimonth,
        type_timepointTM: 'tm',
        current_timepointY: this.state.current_year,
        type_timepointY: 'y',
      }
    })
    .then((result) => {
      //console.log(result['data'])
      var getData = []
      var tp = ''
      // Weekly //
      if(result['data'][0].status == "fulfilled") {
        getData = result['data'][0].value
        getData.map(each => {
          tp = each.timepoint
          if(each.phq4_id > 0) {
            assm[tp].phq4 = each.phq4_id
          }
          if(each.who5_id > 0) {
            assm[tp].who5 = each.who5_id
          }
        })
        toState.week_assm = assm
      }

      // Monthly //
      if(result['data'][1].status == "fulfilled") {
        tp = ''
        getData = result['data'][1].value
        //console.log(getData)
        var obj_assm = {}
        var buff_m_assm = []
        getData.map(each => {
          tp = each.timepoint
          if(each.monthly_status && tp > 0) {
            assmM[tp].monthly_status = each.monthly_status
            //assm[tp] = {phq4: 0, who5: 0}
            if(each.phq9_id > 0) {
              assmM[tp].phq9 = each.phq9_id
            }
            if(each.asmr_id > 0) {
              assmM[tp].asmr = each.asmr_id
            }
            if(each.gad7_id > 0) {
              assmM[tp].gad7 = each.gad7_id
            }
            if(each.psqi_id > 0) {
              assmM[tp].psqi = each.psqi_id
            }
            if(each.mtf_id > 0) {
              assmM[tp].mtf = each.mtf_id
            } else if(each.monthly_status == 2) {
              assmM[tp].mtf = 1
            }
          }

          if(each.timepoint <= -9) {
            obj_assm = {}
            obj_assm.tp = each.timepoint
            if(each.monthly_status) {
              //assmM[tp].monthly_status = each.monthly_status
              obj_assm.monthly_status = each.monthly_status
              obj_assm.end_date_time = each.end_date_time
              obj_assm.start_date_time = each.start_date_time
              if(each.phq9_id > 0) {
                //assmM[tp].phq9 = each.phq9_id
                obj_assm.phq9 = each.phq9_id
              }
              if(each.asmr_id > 0) {
                //assmM[tp].asmr = each.asmr_id
                obj_assm.asmr = each.asmr_id
              }
              if(each.gad7_id > 0) {
                //assmM[tp].gad7 = each.gad7_id
                obj_assm.gad7 = each.gad7_id
              }
              if(each.psqi_id > 0) {
                //assmM[tp].psqi = each.psqi_id
                obj_assm.psqi = each.psqi_id
              }
              if(each.mtf_id > 0) {
                //assmM[tp].mtf = each.mtf_id
                obj_assm.mtf = each.mtf_id
              } else if(each.monthly_status == 2) {
                //assmM[tp].mtf = 1
                obj_assm.mtf = 1
              }
            }
            buff_m_assm.push(obj_assm)
          }
        })
        toState.month_assm = assmM
        toState.month_assm_unsc = buff_m_assm
      }

      // Tri-monthly //
      if(result['data'][2].status == "fulfilled") {
        tp = ''
        getData = result['data'][2].value
        var obj_assm = {}
        var buff_tm_assm = []
        getData.map(each => {
          tp = each.timepoint
          if(tp > 0) {
            if(each.covid_id > 0) {
              assmTM[tp].covid = each.covid_id
            }
            if(each.pw18_id > 0) {
              assmTM[tp].pw18 = each.pw18_id
            }

          }
          if(tp < -9) {
            obj_assm = {}
            obj_assm.tp = each.timepoint
            obj_assm.start_date_time = each.start_date_time
            obj_assm.end_date_time = each.end_date_time
            //assm[tp] = {phq4: 0, who5: 0}
            if(each.covid_id > 0) {
              //assmTM[tp].covid = each.covid_id
              obj_assm.covid = each.covid_id
            }
            if(each.pw18_id > 0) {
              //assmTM[tp].pw18 = each.pw18_id
              obj_assm.pw18 = each.pw18_id
            }
            buff_tm_assm.push(obj_assm)
          }

        })
        toState.trimonth_assm = assmTM
        toState.trimonth_assm_unsc = buff_tm_assm //assmTM
      }

      // Yearly //
      if(result['data'][3].status == "fulfilled") {
        tp = ''
        getData = result['data'][3].value
        getData.map(each => {
          tp = each.timepoint
          if(each.ders_id > 0) {
            assmY[tp].ders = each.ders_id
          }
        })
        toState.year_assm = assmY
        toState.loading = true
      }
    })
    this.setState(toState)
  }

  addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  genColorStatusW = (current_week, mod_days, val, w, enable_status) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(w < current_week) {
        style = 'past_due each_assm1'
      } else {
        if(enable_status == 1) {
          if(mod_days >= 3) {
            style = 'due_soon each_assm1'
          } else if(mod_days <= 4){
            style = 'due_now each_assm1'
          } else {
            style = 'past_due each_assm1'
          }
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusM = (current_month, mod_months, val, m, enable_status) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_month) {
        style = 'past_due each_assm1'
      } else {
        if(enable_status == 1) {
          if(mod_months >= -5 && mod_months <= 0) {
            style = 'due_soon each_assm1'
          } else if(mod_months > 0 && mod_months <= 5){
            style = 'due_now each_assm1'
          } else {
            style = 'past_due each_assm1'
          }
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusTM = (current_month, mod_months, val, m) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_month) {
        style = 'past_due each_assm1'
      } else {
        if(mod_months >= -5 && mod_months <= 0) {
          style = 'due_soon each_assm1'
        } else if(mod_months > 0 && mod_months <= 5){
          style = 'due_now each_assm1'
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  genColorStatusY = (current_year, mod_years, val, m) => {
    var style = ''
    if(val > 0) {
      style = 'completed each_assm1'
    } else {
      if(m < current_year) {
        style = 'past_due each_assm1'
      } else {
        if(mod_years >= -5 && mod_years <= 0) {
          style = 'due_soon each_assm1'
        } else if(mod_years > 0 && mod_years <= 5){
          style = 'due_now each_assm1'
        } else {
          style = 'past_due each_assm1'
        }
      }
    }
    return style
  }

  render() {
    const pid = this.props.match.params.pid
    var w_assm = this.state.week_assm
    var m_assm = this.state.month_assm
    var m_assm_unsc = this.state.month_assm_unsc
    var tm_assm = this.state.trimonth_assm
    var tm_assm_unsc = this.state.trimonth_assm_unsc
    var y_assm = this.state.year_assm

    var dateMoment = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    var dateMomentTM = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    var dateMomentY = moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY")
    //console.log(this.state)
    return (
      <div className="assessment_list box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Questionnaires: Status
          </div>
          { typeof this.props.locationReducer.txt_message !== 'undefined' &&
            this.props.locationReducer.txt_message !== '' &&
            <div className={ (this.props.locationReducer.route === 'assessment_list_1' && "small-12 columns warning_msg8")
              || (this.props.locationReducer.route === 'assessment_list_2' && "small-12 columns warning_msg6")}>
              <div className="float_left1">
              { this.props.locationReducer.route === 'assessment_list_1' && <CheckCircleOutlineIcon />}
              { this.props.locationReducer.route === 'assessment_list_2' && <WarningIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
            </div>
          }
          <div className="small-12 columns mod_2">
            <span className="due_soon_color"><IoIosSquare /> Due soon</span>
            <span className="due_now_color"><IoIosSquare /> Due now</span>
            <span className="due_complete_color"><IoIosSquare /> Complete</span>
            <span className="due_past_color"><IoIosSquare /> Past Due</span>
          </div>

          {!this.state.loading &&
          <div className="small-12 columns loading2">
            <Spinner color={"#bbbbbb"} radius={50}/>
          </div>}

          {this.state.loading &&
          <div>
          <div className="small-12 columns">
            <div className="row mod_1">
              <div className="small-12 columns topic1">
                Weekly Questionnaires
              </div>
              <div className="small-12 columns week_scroll">
              { this.state.timeline_start && [...Array(this.state.current_week)].map((e, i) =>
                <div className="row each_week1" key={'w'+i} >
                  <div className="small-6 medium-6 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Week {i+1}</span>
                    <ScheduleIcon /> {new Date((this.state.timeline_start*1000)+(60*60*24*7*(i+1)*1000)).toLocaleDateString("en-US")}
                  </div>
                  <div className="small-6 medium-6 columns no-padding-right each_assm_link">
                    <div className={w_assm.length > 0 ? this.genColorStatusW(this.state.current_week, this.state.mod_days, w_assm[i+1].who5, (i+1), this.state.status_assm.w_who5) : ''}>
                      {w_assm.length > 0 && (w_assm[i+1].who5 > 0 || (this.state.current_week == (i+1) && this.state.status_assm.w_who5 == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/who5/w/"+(i+1)}>WHO-5</a>}
                      {w_assm.length > 0 && this.state.current_week == (i+1) && this.state.status_assm.w_who5 == 0 && '-'}
                      {w_assm.length > 0 && this.state.current_week > (i+1) && w_assm[i+1].who5 == 0 && 'WHO-5'}
                    </div>
                    { ((this.state.timeline_start*1000)+(60*60*24*7*(i+1)*1000)) < 1642968252000 &&
                    <div className={w_assm.length > 0 ? this.genColorStatusW(this.state.current_week, this.state.mod_days, w_assm[i+1].phq4, (i+1), this.state.status_assm.w_phq4) : ''}>
                    {w_assm.length > 0 && (w_assm[i+1].phq4 > 0 || (this.state.current_week == (i+1) && this.state.status_assm.w_phq4 == 1)) &&
                      <a href={"/admin/assessments/view_assessment/"+pid+"/phq4/w/"+(i+1)}>PHQ-4</a>}
                    {w_assm.length > 0 && this.state.current_week == (i+1) && this.state.status_assm.w_phq4 == 0 && '-'}
                    {w_assm.length > 0 && this.state.current_week > (i+1) && w_assm[i+1].phq4 == 0 && 'PHQ-4'}
                    </div>}
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Monthly Questionnaires
              </div>

              <div className="small-12 columns month_scroll">
              {[...Array(this.state.current_month)].map((e, i) =>
                <div className="row each_month1" key={'m'+i} >
                  <div className="small-12 medium-5 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Month {i+1}</span>
                    <ScheduleIcon /> { dateMoment.add(1, 'M').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-12 medium-7 columns no-padding-right each_assm_link">
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].phq9, (i+1), this.state.status_assm.m_phq9) : ''}>
                    {m_assm.length > 0 && (m_assm[i+1].phq9 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_phq9 == 1)) &&
                      <a href={"/admin/assessments/view_assessment/"+pid+"/phq9/m/"+(i+1)}>PHQ-9</a>}
                    {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_phq9 == 0 && '-'}
                    {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].phq9 == 0 && 'PHQ-9'}
                    </div>
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].asmr, (i+1), this.state.status_assm.m_asrm) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].asmr > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/asrm/m/"+(i+1)}>ASMR</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].asmr == 0 && 'ASMR'}
                    </div>
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].gad7, (i+1), this.state.status_assm.m_gad7) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].gad7 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/gad7/m/"+(i+1)}>GAD-7</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].gad7 == 0 && 'GAD-7'}
                    </div>
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].psqi, (i+1), this.state.status_assm.m_psqi) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].psqi > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/psqi/m/"+(i+1)}>PSQI</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].psqi == 0 && 'PSQI'}
                    </div>
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, m_assm[i+1].mtf, (i+1), this.state.status_assm.m_mtf) : ''}>
                      {m_assm.length > 0 && (m_assm[i+1].mtf > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/mtf/m/"+(i+1)}>MTF</a>}
                      {m_assm.length > 0 && this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 0 && '-'}
                      {m_assm.length > 0 && this.state.current_month > (i+1) && m_assm[i+1].mtf == 0 && 'MTF'}
                    </div>
                  </div>
                </div>
              ).reverse()}
              { this.state.month_assm_unsc.map((each, i) =>
                <div className="row each_month1" key={'m'+i} >
                  <div className="small-12 medium-5 columns each_assm_txt1">
                    {each.tp > 0 && <><CalendarTodayIcon /> <span className="space_right1">Month {each.tp}</span></>}
                    {each.tp <= -9 && <><span className="space_right1">Unscheduled</span></>}
                    {each.tp > 0 && <><ScheduleIcon /> { moment(new Date(this.state.timeline_start*1000), "MM-DD-YYYY").add(each.tp, 'M').format('MM/DD/YYYY')}</>}
                    {each.tp <= -9 && <><ScheduleIcon /> {each.start_date_time}</>}
                  </div>
                  <div className="small-12 medium-7 columns no-padding-right each_assm_link">
                    {((this.state.current_month == (each.tp) && this.state.status_assm.m_phq9 == 1) || each.phq9 > 0 ||
                      (this.state.current_month > (i+1) && each.phq9 == 0)) &&
                    <div className={m_assm_unsc.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.phq9, each.tp, this.state.status_assm.m_phq9, this.state.status_assm.monthly_status) : ''}>
                    {m_assm_unsc.length > 0 && (each.phq9 > 0 || (this.state.current_month == each.tp && this.state.status_assm.m_phq9 == 1)) &&
                      <a href={"/member/assessment/phq9/m/"+each.tp}>PHQ-9</a>}
                    {m_assm_unsc.length > 0 && each.phq9 == 0 && 'PHQ-9'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_asrm == 1) || each.asmr > 0 ||
                      (this.state.current_month > each.tp && each.asmr == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.asmr, each.tp, this.state.status_assm.m_asrm, this.state.status_assm.monthly_status) : ''}>
                      {m_assm_unsc.length > 0 && (each.asmr > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_asrm == 1)) &&
                        <a href={"/member/assessment/asrm/m/"+(i+1)}>ASMR</a>}
                      {m_assm_unsc.length > 0 && each.asmr == 0 && 'ASMR'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_gad7 == 1) || each.gad7 > 0 ||
                      (this.state.current_month > each.tp && each.gad7 == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.gad7, each.tp, this.state.status_assm.m_gad7, this.state.status_assm.monthly_status) : ''}>
                      {m_assm_unsc.length > 0 && (each.gad7 > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_gad7 == 1)) &&
                        <a href={"/member/assessment/gad7/m/"+(i+1)}>GAD-7</a>}
                      {m_assm_unsc.length > 0 && each.gad7 == 0 && 'GAD-7'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_psqi == 1) || each.psqi > 0 ||
                      (this.state.current_month > each.tp && each.psqi == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.psqi, each.tp, this.state.status_assm.m_psqi, this.state.status_assm.monthly_status) : ''}>
                      {m_assm_unsc.length > 0 && (each.psqi > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_psqi == 1)) &&
                        <a href={"/member/assessment/psqi/m/"+(i+1)}>PSQI</a>}
                      {m_assm_unsc.length > 0 && each.psqi == 0 && 'PSQI'}
                    </div>}
                    {((this.state.current_month == each.tp && this.state.status_assm.m_mtf == 1) || each.mtf > 0 ||
                      (this.state.current_month > each.tp && each.mtf == 0)) &&
                    <div className={m_assm.length > 0 ? this.genColorStatusM(this.state.current_month, this.state.mod_months, each.mtf, each.tp, this.state.status_assm.m_mtf, this.state.status_assm.monthly_status) : ''}>
                      {m_assm_unsc.length > 0 && (each.mtf > 0 || (this.state.current_month == (i+1) && this.state.status_assm.m_mtf == 1)) &&
                        <a href={"/member/assessment/mtf/m/"+(i+1)}>MTF</a>}
                      {m_assm_unsc.length > 0  && each.mtf == 0 && 'MTF'}
                    </div>}
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Trimonthly Questionnaires
              </div>
              <div className="small-12 columns month_scroll tri_month">
              {[...Array(this.state.current_trimonth)].map((e, i) =>
                <div className="row each_week1" key={'m'+i} >
                  <div className="small-12 medium-6 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Trimonth {i+1}</span>
                    <ScheduleIcon /> { dateMomentTM.add(3, 'M').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-12 medium-6 columns no-padding-right each_assm_link">
                    <div className={tm_assm.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, tm_assm[i+1].pw18, (i+1)) : ''}>
                      {tm_assm.length > 0 && (tm_assm[i+1].pw18 > 0 || (this.state.current_trimonth == (i+1) && this.state.status_assm.twelve_m_well_being == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/pw18/tm/"+(i+1)}>Well-Being</a>}
                      {tm_assm.length > 0 && this.state.current_trimonth == (i+1) && this.state.status_assm.twelve_m_well_being == 0 && '-'}
                      {tm_assm.length > 0 && this.state.current_trimonth > (i+1) && tm_assm[i+1].pw18 == 0 && 'Well-Being'}
                    </div>
                    <div className={tm_assm.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, tm_assm[i+1].covid, (i+1)) : ''}>
                      {tm_assm.length > 0 && (tm_assm[i+1].covid > 0 || (this.state.current_trimonth == (i+1) && this.state.status_assm.three_m_covid == 1)) &&
                        <a href={"/admin/assessments/view_assessment/"+pid+"/covid/tm/"+(i+1)}>COVID</a>}
                      {tm_assm.length > 0 && this.state.current_trimonth == (i+1) && this.state.status_assm.three_m_covid == 0 && '-'}
                      {tm_assm.length > 0 && this.state.current_trimonth > (i+1) && tm_assm[i+1].covid == 0 && 'COVID'}
                    </div>
                  </div>
                </div>
              ).reverse()}
              { this.state.timeline_start && this.state.trimonth_assm_unsc.map((each, i) =>
                <div className="row each_week1" key={'m'+i} >
                  <div className="small-12 medium-6 columns each_assm_txt1">
                    {each.tp > 0 && <><CalendarTodayIcon /> <span className="space_right1">Trimonth {each.tp}</span></>}
                    {each.tp <= -9 && <> <span className="space_right1">Unscheduled</span></>}
                    {each.tp > 0 && <><ScheduleIcon /> { dateMomentTM.add(each.tp * 3, 'M').format('MM/DD/YYYY')}</>}
                    {each.tp <= -9 && <><ScheduleIcon /> {each.start_date_time}</>}

                  </div>
                  <div className="small-12 medium-6 columns no-padding-right each_assm_link">
                    <div className={tm_assm.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, each.pw18, each.tp) : ''}>
                      {tm_assm_unsc.length > 0 && (each.pw18 > 0 || (this.state.current_trimonth == each.tp && this.state.status_assm.twelve_m_well_being == 1)) &&
                        <a href={"/member/assessment/pw18/tm/"+each.tp}>Well-Being</a>}
                      {tm_assm_unsc.length > 0 && each.pw18 == 0 && 'Well-Being'}
                    </div>
                    <div className={tm_assm_unsc.length > 0 ? this.genColorStatusTM(this.state.current_trimonth, this.state.mod_months, each.covid, each.tp) : ''}>
                      {tm_assm_unsc.length > 0 && (each.covid > 0 || (this.state.current_trimonth == each.tp && this.state.status_assm.three_m_covid == 1)) &&
                        <a href={"/member/assessment/covid/tm/"+each.tp}>COVID</a>}
                      {tm_assm_unsc.length > 0 && each.covid == 0 && 'COVID'}
                    </div>
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>
          <div className="small-12 columns">
            <div className="row mod_1 space_top1">
              <div className="small-12 columns topic1">
                Annual Questionnaires
              </div>

              <div className="small-12 columns month_scroll">
              { this.state.timeline_start && [...Array(this.state.current_year)].map((e, i) =>
                <div className="row each_week1" key={'y'+i} >
                  <div className="small-6 medium-6 columns each_assm_txt1">
                    <CalendarTodayIcon /> <span className="space_right1">Year {i+1}</span>
                    <ScheduleIcon /> { dateMomentY.add(12, 'months').format('MM/DD/YYYY')}
                  </div>
                  <div className="small-6 medium-6 columns no-padding-right each_assm_link">
                    <div className={y_assm.length > 0 ? this.genColorStatusY(this.state.current_year, this.state.mod_years, y_assm[i+1].ders, (i+1)) : ''}>
                      <a href={"/admin/assessments/view_assessment/"+pid+"/ders18/y/"+(i+1)}>DERS</a>
                    </div>
                  </div>
                </div>
              ).reverse()}
              </div>
            </div>
          </div>
          </div>}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AssessmentList)
