export const send_by = [
  {
    label: 'Electronically (secure email)',
    value: 1,
  }, {
    label: 'Fax',
    value: 2,
  },
]

export const send_to_purpose = [
  {
    label: 'Medical Care',
    value: 1,
  }, {
    label: 'Insurance*',
    value: 2,
  }, {
    label: 'Legal Matter*',
    value: 3,
  }, {
    label: 'Personal*',
    value: 4,
  }, {
    label: 'School',
    value: 5,
  }, {
    label: 'Other (please specify)*',
    value: 6,
  },
]

export const information_to_be_release = [
  {
    label: 'Medical Record Abstract/dates (e.g. History & Physical, Operative Report, Consults, Test Reports, Discharge Summary)',
    value: 1,
  }, {
    label: 'Clinic Visit Notes/dates',
    value: 2,
  }, {
    label: 'Discharge Summary/dates',
    value: 3,
  }, {
    label: 'Lab Reports/dates',
    value: 4,
  }, {
    label: 'Operative Reports/dates',
    value: 5,
  }, {
    label: 'Pathology Reports/dates',
    value: 6,
  }, {
    label: 'Radiation Reports/dates',
    value: 7,
  }, {
    label: 'Radiology Reports/dates',
    value: 8,
  }, {
    label: 'Photographs/dates (costs may apply)',
    value: 9,
  }, {
    label: 'Billing Records/dates',
    value: 10,
  }, {
    label: 'Other (please specify below and include dates)',
    value: 11,
  },
]

export const give_permission = [
  {
    label: 'HIV test results (PATIENT AUTHORIZATION REQUIRED FOR EACH RELEASE REQUEST.)',
    value: 1,
  }, {
    label: 'Genetic Screening test results',
    value: 2,
  }, {
    label: 'Alcohol and Drug Abuse Records Protected by Federal Confidentiality Rules 42 CFR Part 2 (FEDERAL RULES PROHIBIT ANY FURTHER DISCLOSURE OF THIS INFORMATION UNLESS FURTHER DISCLOSURE IS EXPRESSLY PERMITTED BY WRITTEN CONSENT OF THE PERSON TO WHOM IT PERTAINS OR AS OTHERWISE PERMITTED BY 42 CFR PART 2.) This consent may be revoked upon oral or written request.',
    value: 3,
  }, {
    label: 'Other(s)',
    value: 4,
  }, {
    label: 'Details of Mental Health Diagnosis and/or Treatment provided by a Psychiatrist, Psychologist, Mental Health Clinical Nurse Specialist, or Licensed Mental Health Clinician (LMHC) (I understand that my permission may not be required to release my mental health records for payment purposes)',
    value: 5,
  }, {
    label: 'Confidential Communications with a Licensed Social Worker',
    value: 6,
  }, {
    label: 'Details of Domestic Violence Victims’ Counseling',
    value: 7,
  }, {
    label: 'Details of Sexual Assault Counseling',
    value: 8,
  },
]
