import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import moment from 'moment'
import { connectNode } from '../../../../../utils/global'
import '../../../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { FormSection } from '../../../../shared/form_section'
import { ReadOnlyLine } from '../../../../shared/read_only_line'
import { SelectLine } from '../../../../shared/select_line'
import { Line } from '../../../../shared/line'
import { TextFieldLine } from '../../../../shared/text_field_line'
import { treatment_modality } from '../../variables/treatment_plan.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

//import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser from 'react-html-parser'

class DischargeForm extends Component {
  constructor() {
      super()
      this.quill = require('react-quill')
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          patient_status: 0,
          patient_name: '',
          supervising_clinician: '',
          patient_navigator: '',
          //assessment_summary: '',
          //goal_completion: '',
          //discharge_plan: '',
          program_referral: '',
          print_resource: '',
          support_group: '',
          other_resource: '',
          //return_cycle: '',
          notes: '',
          date_enroll: '',
          date_discharge: moment().format('YYYY-MM-DD'),
          date_discharge2: moment().format('MM/DD/YYYY'),
          who_start: '',
          who_end: '',
          treatment_goals: [],
          modality_list: [],
          medications_admissions: '',
          medications_discharge: '',
          consultation_neuropsych: '',
          consultation_psychopharm: '',
          consultation_other: '',
          followup_prescriber: '',
          discharge_plan_other: '',
          online_resource: ''
      }
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.state.start_datetime)
  }

  getDataList = async () => {
    // get Rater name //
    await axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_pre_discharge_information',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].status === 6) {
        var registered_date = ''
        if(result['data'].registered != null) {
          registered_date = result['data'].conv_registered.substring(0, 10)
        }

        this.setState({
          patient_name: result['data'].p_name,
          supervising_clinician: result['data'].c_name,
          c_prac_name: result['data'].c_prac_name,
          patient_navigator: result['data'].pn,
          date_enroll: registered_date,
          date_discharge: result['data'].conv_date_discharge,
          date_discharge2: result['data'].conv_date_discharge.substring(0, 10),
          patient_status: result['data'].status,
          //reason_discharge: result['data'].reason,
          //reason_discharge_other: result['data'].reason_other,
          patient_navigator: result['data'].patient_navigator,
          //assessment_summary: result['data'].assessment_summary,
          //goal_completion: result['data'].goal_completion,
          //discharge_plan: result['data'].discharge_plan,
          program_referral: result['data'].program_referral,
          print_resource: result['data'].print_resource,
          support_group: result['data'].support_group,
          other_resource: result['data'].other_resource,
          //return_cycle: result['data'].return_cycle,
          notes: result['data'].notes,
          who_start: result['data'].who_start,
          who_end: result['data'].who_end,
          treatment_goals: result['data'].treatment_goals,
          modality_list: result['data'].modality_list,
          medications_admissions: result['data'].medications_admissions,
          medications_discharge: result['data'].medications_discharge,
          consultation_neuropsych: result['data'].consultation_neuropsych,
          consultation_psychopharm: result['data'].consultation_psychopharm,
          consultation_other: result['data'].other_consult,
          followup_prescriber: result['data'].followup_prescriber,
          discharge_plan_other: result['data'].discharge_plan_other,
          online_resource: result['data'].online_resource,
        })
      } else {
        var date_enroll = ''
        if(result['data'].registered) {
          date_enroll = result['data'].conv_registered.substring(0, 10)
        }
        this.setState({
          patient_name: result['data'].p_name,
          supervising_clinician: result['data'].c_name,
          c_prac_name: result['data'].c_prac_name,
          patient_navigator: result['data'].pn,
          date_enroll: date_enroll,
          patient_status: result['data'].status,
          who_start: result['data'].who_start,
          who_end: result['data'].who_end,
          treatment_goals: result['data'].treatment_goals,
          modality_list: result['data'].modality_list,
          medications_admissions: result['data'].medications_admissions,
          medications_discharge: result['data'].medications_discharge,
          consultation_neuropsych: result['data'].consultation_neuropsych,
          consultation_psychopharm: result['data'].consultation_psychopharm,
          consultation_other: result['data'].other_consult,
        })
      }

    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange4 = (contents) => {
    this.setState({
      reason_discharge_other: contents,
    })
  }

  handleOpenWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'block'
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
    this.setState({
      status_submit: true,
      message: '',
    })
  }

  handleUpdateData = () => {
    var pid = this.props.match.params.pid
    var gen_modality = this.state.modality_list.map(x => x.modality_id !== 21 ? treatment_modality.find(m => m.value == x.modality_id)?.label : x.modality_other).filter(x => x !== "" && x !== undefined).join(", ")
    var gen_tm_goals = ''
    this.state.treatment_goals.map((x, i) => {
      if(i > 0) {
        gen_tm_goals += ', '
      }
      gen_tm_goals += x.treatment_goal
      if(x.treatment_completion_status == 1) {
        gen_tm_goals += ' (completed)'
      }
    })
    //console.log(gen_modality)
    //console.log(gen_tm_goals)

    axios({
      method: 'post',
      url: connectNode + 'admin/patient/set_discharge',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        user: this.state,
        gen_modality: gen_modality,
        gen_tm_goals: gen_tm_goals,
      }
    })
    .then((result) => {
      if(result['data'] === pid) {
        this.getDataList()
        this.setState({
          status_submit: true,
          message: 'Submitted successfully!',
        })
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.consultation_other === '' || this.state.followup_prescriber === ''
       || this.state.followup_prescriber === '' || this.state.program_referral === ''
       || this.state.discharge_plan_other === '' || this.state.online_resource === ''
       || this.state.print_resource === '' || this.state.support_group === ''
       || this.state.other_resource === '' || this.state.notes === '') {
      this.setState({
        status_submit: false,
        message: 'Please fill all answers ',
      })
      window.scrollTo(0, 200)
    } else {
      this.handleUpdateData()
    }
  }

  render() {
    console.log(this.state.patient_status)
    const ReactQuill = this.quill
    const patientDischarged = this.state.patient_status === 6;
    //const reasonForDischargeMap = this.state.reason_discharge_list.map(each => ({id: each.vid, name: each.name}));
    return (
      <div className="discharge">
        <div className="title6">
          FITT-BD Discharge Summary
        </div>
        { this.state.message !== '' &&
        <div className="row left_space1">
          <div className="small-12 columns">
            <div className={(this.state.status_submit && 'warning_msg7') || (!this.state.status_submit && 'warning_msg6')} >
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>
        </div>}


        {(this.state.patient_status === 15 || this.state.patient_status === 6
          || this.state.patient_status === 16 || this.state.patient_status === 14
          || this.state.patient_status === 3) &&
          <div className="row">
            <div className="small-12 columns ">
              <FormSection>
                <ReadOnlyLine label="Patient name" value={this.state.patient_name} />
                <ReadOnlyLine label="Date enrolled" value={this.state.date_enroll} />
                <ReadOnlyLine label="Date of discharge" value={this.state.date_discharge2} />
              </FormSection>

              <FormSection>
              <ReadOnlyLine label="Supervising clinician" value={this.state.supervising_clinician} />
              <ReadOnlyLine label="Practicum Student:" value={this.state.c_prac_name} />
              <ReadOnlyLine label="Patient Navigator" value={this.state.patient_navigator} />
              </FormSection>

              <FormSection>
                <div className="row"><div className="small-12 columns title4">Progress</div></div>
                <div className="row"><div className="small-12 columns bold">Assessment Summary:</div></div>
                <ReadOnlyLine label="WHO-5 baseline" value={this.state.who_start} />
                <ReadOnlyLine label="WHO-5 most recent" value={this.state.who_end} />
                <br/>

                <div className="row top_space1"><div className="small-12 columns bold">Goals:</div></div>
                {this.state.treatment_goals && this.state.treatment_goals.map((x,i) =>
                  (<div key={`treatment_goal_${i}`}>
                    <ReadOnlyLine label="Treatment goal" value={x.treatment_goal} />
                    <ReadOnlyLine label="Completion status" value={x.treatment_completion_status === 1 ? "Yes" : "No"} />
                    <br/>
                  </div>)
                )}

                <div className="row top_space1"><div className="small-12 columns bold">Treatment Types:</div></div>
                <ReadOnlyLine label="List all of treatment modality" value={this.state.modality_list && this.state.modality_list.map(x => x.modality_id !== 21 ? treatment_modality.find(m => m.value == x.modality_id)?.label : x.modality_other).filter(x => x !== "" && x !== undefined).join(", ")} />


                <div className="row top_space1"><div className="small-12 columns bold">Medication:</div></div>
                <ReadOnlyLine label="Admission" value={this.state.medications_admissions} />
                <ReadOnlyLine label="Discharge" value={this.state.medications_discharge} />

                <div className="row top_space1"><div className="small-12 columns bold">Consultations:</div></div>
                <ReadOnlyLine label="Neuropsych consult" value={this.state.consultation_neuropsych} />
                <ReadOnlyLine label="Psychopharm consult" value={this.state.consultation_psychopharm} />
                {!patientDischarged ?
                <TextFieldLine label="Other consult" id="consultation_other"
                  onChange={this.handleChange} defaultValue={this.state.consultation_other}
                  name="consultation_other" maxLength={255} />
                  : <ReadOnlyLine label="Other consult" value={this.state.consultation_other} />
                }
              </FormSection>

              <FormSection>
                <div className="row"><div className="small-12 columns title4">Discharge Plan</div></div>
                {!patientDischarged ?
                  <>
                <TextFieldLine label="Follow-up prescriber/therapist/PCP" id="followup_prescriber"
                  onChange={this.handleChange} defaultValue={this.state.followup_prescriber}
                  name="followup_prescriber" maxLength={255} />
                <TextFieldLine label="Program referral" id="program_referral"
                  onChange={this.handleChange} defaultValue={this.state.program_referral}
                  name="program_referral" maxLength={255} />
                <TextFieldLine label="Other" id="discharge_plan_other"
                  onChange={this.handleChange} defaultValue={this.state.discharge_plan_other}
                  name="discharge_plan_other" maxLength={255} />
                </> :
                  <>
                    <ReadOnlyLine label="Follow-up prescriber/therapist/PCP" value={this.state.followup_prescriber} />
                    <ReadOnlyLine label="Program referral" value={this.state.program_referral} />
                    <ReadOnlyLine label="Other" value={this.state.discharge_plan_other} />
                  </>}
              </FormSection>

              <FormSection>
                <div className="row"><div className="small-12 columns title4">Offboarding Resources</div></div>
                {!patientDischarged ?
                  <>
                    <TextFieldLine label="Online" id="online_resource"
                      onChange={this.handleChange} defaultValue={this.state.online_resource}
                      name="online_resource" maxLength={255} />
                    <TextFieldLine label="Print/reading resource" id="print_resource"
                      onChange={this.handleChange} defaultValue={this.state.print_resource}
                      name="print_resource" maxLength={255} />
                    <TextFieldLine label="Peer/Support group" id="support_group"
                      onChange={this.handleChange} defaultValue={this.state.support_group}
                      name="support_group" maxLength={255} />
                    <TextFieldLine label="Other" id="other_resource"
                      onChange={this.handleChange} defaultValue={this.state.other_resource}
                      name="other_resource" maxLength={255} />
                  </> :
                  <>
                    <ReadOnlyLine label="Online" value={this.state.online_resource} />
                    <ReadOnlyLine label="Print/reading resource" value={this.state.print_resource} />
                    <ReadOnlyLine label="Peer/Support group" value={this.state.support_group} />
                    <ReadOnlyLine label="Other" value={this.state.other_resource} />
                  </>
                }
              </FormSection>
              <FormSection>
                {!patientDischarged ?
                    <TextFieldLine label="Notes" id="notes"
                      onChange={this.handleChange} defaultValue={this.state.notes}
                      name="notes" multiline={true} /> :
                    <ReadOnlyLine label="Notes" value={this.state.notes} />
                }
              </FormSection>
            </div>
            {this.state.patient_status !== 6 &&
              <div className="row top_space1">
                <div className="small-12 columns text_align_right">
                  {/*!this.state.status_submit &&
                    <Button variant="contained" disabled
                      onClick={this.handleSubmit} type="submit">Submit</Button>*/}
                  <Button variant="contained" color="primary" disableElevation
                      onClick={this.handleSubmit} type="submit">Submit</Button>
                </div>
              </div>}
          </div>}
        {this.state.patient_status !== 3 &&
        <div className="row left_space1">
          <div className="small-12 columns top_space2">
          {(this.state.patient_status === 1 || this.state.patient_status === 2 ||
            this.state.patient_status === 4 || this.state.patient_status === 7 ||
            this.state.patient_status === 8 || this.state.patient_status === 9) && "This patient hasn't completed registration."}
          </div>
        </div>}

        <div className="warning_box" id="warning_box">
          <div className="row">
            <div className="small-12 columns">
            <WarningIcon /> Are you sure to discharge this patient?
            </div>
            <div className="small-12 columns margin_top_space1">
            <Button variant="contained" color="primary" className="margin_right_1" disableElevation
             onClick={(e) => this.handleUpdateData(e)} type="button">Yes</Button>
           <Button variant="contained" color="primary" disableElevation
            onClick={(e) => this.closeWarningBox(e)} type="button">No</Button>
            </div>
          </div>
        </div>

      </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DischargeForm)
