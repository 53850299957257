import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/goal1.jpeg'

class Intro12 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction13')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction11')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_2 margin-bottom1">
              <b>F</b>OCUSED, GOAL-ORIENTED
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns">
                <div className="gray_bg5">
                  <div className="content3">
                    <p>At FITT-BD, you’ll focus on specific goals that you choose yourself.
                    These goals could be anything that is important to you.</p>
                    <p>You’ll work with your team to come up with a treatment plan to
                    help you reach your goals. Because everyone has unique goals,
                    every treatment plan will be unique.</p>
                  </div>
                  <div className="w_100percent content3"><b>EXAMPLES OF GOALS:</b></div>
                  <div className="w_100percent content3">
                    <ul>
                      <li>IMPROVE RELATIONSHIP WITH MY PARTNER</li>
                      <li>FIND A NEW JOB</li>
                      <li>SPEND MORE TIME WITH MY KIDS</li>
                      <li>FEEL BETTER ABOUT MYSELF</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="small-12 medium-6 columns">
                <img src={Image} alt="pyramid" className="w_80percentc margin-top2"/>
              </div>
            </div>

            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro12)
