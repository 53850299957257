import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/question1.png'

class Intro2 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction3')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction1')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_1 margin-bottom1">
              WHAT IS FITT-BD?
            </div>
            <div>
              <div className="img_center">
                <img src={Image} alt="team" />
              </div>
            </div>
            <div className="content1 margin-top1">
              <p>
                FITT-BD is a new approach to treating bipolar disorder that gives you
                the skills you need to independently manage your condition.
              </p>
              <p>
                As a patient at FITT-BD, you'll work with the FITT-BD team to identify
                goals that matter to you and learn specific skills and techniques that
                will help you reach these goals.
              </p>
              <p>
                FITT-BD is different from traditional, one-on-one treatment with
                a therapist. Instead of weekly therapy sessions, you may learn skills
                by reading books and articles, taking online courses, attending groups,
                or meeting with a clinician for brief training sessions.
              </p>
              <p>
                You can expect to be actively involved at FITT-BD for six months
                to a year, but you will be able to return to the program for occasional
                booster sessions.
              </p>
            </div>
            <div className="row">
              <div className="small-4 columns back_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro2)
