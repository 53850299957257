import React, { Component } from 'react'
import '../../../styles/assets/css/pages/stepper1.css'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocationWithMessage } from '../../../actions/location'
import { self_stigma_steps } from '../variables/baseline.js'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

class StepperSelfStigma extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          tracker_type: 1,
          steps: [],
          activeStep: 0,
          previous_activeStep: 0,
      }
  }

  componentDidMount() {
    var steps = []
    this.setState({
      steps: self_stigma_steps,
      activeStep: this.props.activeStep,
    })

  }

  render() {
    //console.log(this.state)
    return (
      <div className="small-12 columns stepper">
        <Stepper alternativeLabel nonLinear activeStep={this.state.activeStep}>
          {this.state.steps && this.state.steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StepperSelfStigma)
