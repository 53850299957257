import React, { Component } from 'react'
import { withRouter } from "react-router-dom"

class CompleteAssessments extends Component {
  render() {
    var timepoint_type = ''
    if(this.props.match.params.type == 'w') {
      timepoint_type = 'weekly'
    } else if(this.props.match.params.type == 'm') {
      if(this.props.match.params.timepoint == -2) {
        timepoint_type = 'discharge'
      } else {
        timepoint_type = 'monthly'
      }
    } else if(this.props.match.params.type == 'tm') {
      timepoint_type = 'trimonthly'
    } else if(this.props.match.params.type == 'y') {
      timepoint_type = 'annual'
    }
    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Thank you!
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
              You have completed your {timepoint_type} questionaires.
          </div>
        </div>
      </div>
    );
  }
}

export default (withRouter)(CompleteAssessments)
