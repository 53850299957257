import React from "react";
import { Line } from "./line";

export const ReadOnlyLine = (props) => {
  return (
    <Line label={props.label}>
      <div className="small-12 medium-9 columns">{props.value}</div>
    </Line>
  );
};
