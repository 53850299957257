import React from "react";
import Home from "../components/public/home"
import About from "../components/public/about"
import ScreenQuestionaire from "../components/patients/enroll/screen_questionaire"
import Signup from "../components/patients/enroll/signup"
import CompletePreRegistration from "../components/patients/enroll/complete_pre_registration"

import Login from "../components/auth/login"
import Intro1 from "../components/public/intro/intro1"
import Intro2 from "../components/public/intro/intro2"
import Intro3 from "../components/public/intro/intro3"
import Intro4 from "../components/public/intro/intro4"
import Intro5 from "../components/public/intro/intro5"
import Intro6 from "../components/public/intro/intro6"
import Intro7 from "../components/public/intro/intro7"
import Intro8 from "../components/public/intro/intro8"
import Intro9 from "../components/public/intro/intro9"
import Intro10 from "../components/public/intro/intro10"
import Intro11 from "../components/public/intro/intro11"
import Intro12 from "../components/public/intro/intro12"
import Intro13 from "../components/public/intro/intro13"
import Intro14 from "../components/public/intro/intro14"
import Intro15 from "../components/public/intro/intro15"
import Intro16 from "../components/public/intro/intro16"

const PUBLIC_ROUTES = [
  {   path: "/",
      key: "home",
      title: "Home",
      exact: true,
      requireAuth: false,
      component: () => <Login />,
      showtitle: 'true',
  },
  {   path: "/about",
      key: "about",
      title: "About",
      exact: true,
      requireAuth: false,
      component: () => <About />,
      showtitle: 'true',
  },
  {   path: "/member/complete_pre_screen_questionaire",
      key: "complete_pre_screen_questionaire",
      title: "Complete Patient Screen Questionaire",
      exact: true,
      requireAuth: false,
      component: () => <CompletePreRegistration />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/screen_questionaire/:pid",
      key: "screen_questionaire",
      title: "Patient Screen Questionaire",
      exact: true,
      requireAuth: false,
      component: () => <ScreenQuestionaire />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/screen_questionaire",
      key: "screen_questionaire",
      title: "Patient Screen Questionaire",
      exact: true,
      requireAuth: false,
      component: () => <ScreenQuestionaire />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/signup/:pid",
      key: "signup",
      title: "Signup",
      exact: true,
      requireAuth: false,
      component: () => <Signup />,
      showtitle: 'true',
      //ficon: 'fi-home',
  },
  {   path: "/member/signup",
      key: "signup",
      title: "Signup",
      exact: true,
      requireAuth: false,
      component: () => <Signup />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction1",
      key: "slide1",
      title: "Slide1",
      exact: true,
      requireAuth: false,
      component: () => <Intro1 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction2",
      key: "slide2",
      title: "Slide2",
      exact: true,
      requireAuth: false,
      component: () => <Intro2 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction3",
      key: "slide3",
      title: "Slide3",
      exact: true,
      requireAuth: false,
      component: () => <Intro3 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction4",
      key: "slide4",
      title: "Slide4",
      exact: true,
      requireAuth: false,
      component: () => <Intro4 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction5",
      key: "slide5",
      title: "Slide5",
      exact: true,
      requireAuth: false,
      component: () => <Intro5 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction6",
      key: "slide6",
      title: "Slide6",
      exact: true,
      requireAuth: false,
      component: () => <Intro6 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction7",
      key: "slide7",
      title: "Slide7",
      exact: true,
      requireAuth: false,
      component: () => <Intro7 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction8",
      key: "slide8",
      title: "Slide8",
      exact: true,
      requireAuth: false,
      component: () => <Intro8 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction9",
      key: "slide9",
      title: "Slide9",
      exact: true,
      requireAuth: false,
      component: () => <Intro9 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction10",
      key: "slide10",
      title: "Slide10",
      exact: true,
      requireAuth: false,
      component: () => <Intro10 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction11",
      key: "slide11",
      title: "Slide11",
      exact: true,
      requireAuth: false,
      component: () => <Intro11 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction12",
      key: "slide12",
      title: "Slide12",
      exact: true,
      requireAuth: false,
      component: () => <Intro12 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction13",
      key: "slide13",
      title: "Slide13",
      exact: true,
      requireAuth: false,
      component: () => <Intro13 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction14",
      key: "slide14",
      title: "Slide14",
      exact: true,
      requireAuth: false,
      component: () => <Intro14 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction15",
      key: "slide15",
      title: "Slide15",
      exact: true,
      requireAuth: false,
      component: () => <Intro15 />,
      showtitle: 'true',
  },
  {   path: "/introduction/introduction16",
      key: "slide16",
      title: "Slide16",
      exact: true,
      requireAuth: false,
      component: () => <Intro16 />,
      showtitle: 'true',
  },

];

export default PUBLIC_ROUTES;
