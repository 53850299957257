import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import '../../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import { reason_stopping, mtf_filter } from '../../../../patients/variables/medical_history.js'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../../functions/pagination'
import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

class MtfList extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          dataItems: [], //exampleItems,
          pageOfItems: [],
          update_id: 0,
          mtf_filter: [],
          mtf_filter_selected: 2,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_mtf_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        mtf_filter_selected: this.state.mtf_filter_selected,
      }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          //console.log(result['data'])
          var medList = this.calculateHighlight(result['data'])
          this.setState({
            dataItems: result['data'],
            medList: medList,
            mtf_filter: mtf_filter,
          })
        }
      }
    )
  }

  checkExistArray(medList, med_other, callback) {
    var cnt = -1
    var result = -1
    if(medList.length > 0) {
      medList.find(obj => {
        cnt++
        //console.log(">> " + cnt + ": " + (obj.name == med_other) )
        if(obj.name == med_other) {
          result = cnt
        }
      })
    }
    callback(result)
  }

  calculateHighlight(getData) {
    //console.log(getData)
    var medList = []
    var buff_datetime = ''
    var buff_timestamp = 0

    getData.map(item => {
      //console.log(item.med_other + ': ' + item.date_time + ', ' + item.mrtf_datetime)
      if(item.date_time != null) {
        buff_datetime = item.date_time
        buff_timestamp = item.mtf_timestamp
      } else {
        buff_datetime = item.mrtf_datetime
        buff_timestamp = item.mrtf_timestamp
      }

      this.checkExistArray(medList, item.med_other, result => {
        //console.log('Result: '+result)
        if(result === undefined || result == -1) {
          var buff_med = {}
          buff_med['name'] = item.med_other
          buff_med['datetime'] = buff_datetime
          buff_med['timestamp'] = buff_timestamp
          medList.push(buff_med)
        } else {
          //console.log('>>> ' + buff_timestamp + ' : ' + medList[result]['buff_timestamp'])
          if(buff_timestamp > medList[result]['timestamp']) {
            medList[result]['datetime'] = buff_datetime
            medList[result]['timestamp'] = buff_timestamp
          }
        }
        //console.log(medList)
      })
    })
    return medList
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: this.updateByFilter(this.state.mtf_filter_selected) })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  checkExistMedLatest(medList, medName, mtfDatetime, mrtfDatetime, mtfTimestamp, mrtfTimestamp, row_number) {
    var getResult = -1
    var getTimestamp = 0
    var getDatetime = 0
    if(mtfTimestamp > 0) {
      getTimestamp = mtfTimestamp
      getDatetime = mtfDatetime
    } else if(mrtfTimestamp > 0) {
      getTimestamp = mrtfTimestamp
      getDatetime = mrtfDatetime
    }

    var result = medList.find(obj => {
      //console.log(medName + ': ' + obj.timestamp + ' , ' + getTimestamp)
      //console.log((obj.name == medName) + ' : ' + (obj.timestamp == getTimestamp))
      if(obj.name == medName && obj.timestamp == getTimestamp) {
        //console.log(obj.name + ' >> ' + row_number + '>> ' + obj.timestamp + ' , ' +getTimestamp + ' >> ' + obj.datetime + ' , ' + getDatetime)
        getResult = 1
      }
    })
    return getResult
  }

  handleUpdateStatus = (id) => {
    document.getElementById('warning_box').style.display = 'block'
    this.setState({ update_id: id })
  }

  handleChange2 = (e, name) => {
    var getVal = e.target.value
    this.setState({
      pageOfItems: this.updateByFilter(getVal), //items,
      [name]: getVal,
    })
  }

  updateByFilter(getVal) {
    var items = []
    var genHighlight = -1
    this.state.dataItems.map(item => {
      genHighlight = this.checkExistMedLatest(this.state.medList, item.med_other, item.date_time, item.mrtf_datetime, item.mtf_timestamp, item.mrtf_timestamp, item.row_number)
      if((genHighlight == 1 && getVal == 2) || (getVal == 1)) {
        items.push(item)
      }
    })
    return items
  }

  confirmDelete = (e) => {
    var pid = this.props.match.params.pid
    var med_id =
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/delete_medication',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        med_id: this.state.update_id,
      }
    })
    .then((result) => {
      //console.log(result)
        if(result['data'] === this.state.update_id) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/4/1', 'Medication deleted!')
          this.props.history.push('/admin/patient_chart/'+pid+'/4/1')
          this.closeWarningBox()
          this.getDataList()
        }
      }
    )
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  render() {
    return (
    <div className="admin_log_to_do">
      <div className="row">
        <div className="small-6 medium-9 columns title4">MTF</div>
        <div className="small-6 medium-3 columns title4 bottom_space2">
        <FormControl variant="outlined" className="mtf_filter">
          <Select value={this.state.mtf_filter_selected} variant="outlined"
            label="" defaultValue=""
            onChange={(e) => this.handleChange2(e, 'mtf_filter_selected')}>
              {this.state.mtf_filter && this.state.mtf_filter.map( each =>
                <MenuItem key={'mf'+each.value} value={each.value}>{each.label}</MenuItem>
              )}
          </Select>
        </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns ">
          { this.state.message !== '' && this.state.status === false &&
          <div className="row warning_msg6">
            <div className="small-12 columns ">
              <div className="float_left1"><WarningIcon /></div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          </div>}
          { ((this.state.message !== '' && this.state.status === true) ||
             (typeof this.props.locationReducer.txt_message !== 'undefined' &&
               this.props.locationReducer.txt_message !== "")) &&
          <div className="row warning_msg8">
            <div className="small-12 columns ">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">
              { this.state.message || this.props.locationReducer.txt_message }
              </div>
            </div>
          </div>}
          <div>
            <div className="row">
              <div className="small-12 columns">
                <table className="table_data1">
                  <thead className="table_head">
                    <tr>
                      <th>Month</th>
                      <th>Name</th>
                      <th>Total Daily Dose(mg)</th>
                      <th>Currently Taking</th>
                      <th>Change</th>
                      <th>Patient Update</th>
                      <th>Clinician Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                {!this.state.dataItems &&
                  <tr>
                    <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                  </tr>}
                {this.state && this.state.dataItems && this.state.pageOfItems.map((item) =>
                  <tr key={'d'+item.row_number} className={this.checkExistMedLatest(this.state.medList, item.med_other, item.date_time, item.mrtf_datetime, item.mtf_timestamp, item.mrtf_timestamp, item.row_number) === 1 && 'hightlightRow' } >
                    <td>{ item.timepoint < 0 && 'Initial'}
                      {item.timepoint > 0 && item.timepoint
                    }</td>
                    <td><a href={"/admin/patient_medication/edit_mrtf/"+this.props.match.params.pid+"/"+item.id}>{item.med_other}</a></td>
                    <td>{item.y_dose}</td>
                    <td>
                    {item.currently_taking == 1 && 'Yes'}
                    {item.currently_taking == 2 && 'No'}
                    {item.currently_taking == 2 && item.n_taken_3_months == 1 && ' / Taken at least 3 months'}
                    {item.currently_taking == 2 && item.n_taken_3_months == 2 && <span><br />/ Not taken at least 3 months</span>}
                    {item.currently_taking == 2 && item.n_taken_3_months == 2 && <span><br />/ {this.findLabelByValue(reason_stopping, item.n_reason_stop)}</span>}
                    </td>
                    <td>{item.change_status}</td>
                    <td>{item.date_time2}</td>
                    <td>{item.mrtf_datetime2 || item.mrtf_created_datetime2}</td>
                    <td><a onClick={()=>this.handleUpdateStatus(item.id)} >Delete</a></td>
                  </tr>
                )}
                {this.state && this.state.dataItems &&
                  <tr className="pagination_block disable1">
                    <td colSpan="8">
                      <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                    </td>
                  </tr>
                }
                </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="warning_box more_top_space1" id="warning_box">
        <div className="row">
          <div className="small-12 columns">
          <WarningIcon /> Are you sure you want to delete this medication?
          </div>
          <div className="small-12 columns margin_top_space1">
          <Button variant="contained" color="primary" className="margin_right_1" disableElevation
           onClick={()=>this.confirmDelete(this.state.update_id)} type="button">Yes</Button>
         <Button variant="contained" color="primary" disableElevation
          onClick={this.closeWarningBox} type="button">No</Button>
          </div>
        </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MtfList)
