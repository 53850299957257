export const ismi_questions = [
  {
    label: 'I feel out of place in the world because I have a mental illness.',
    value: 1,
  }, {
    label: 'Mentally ill people tend to be violent.',
    value: 2,
  }, {
    label: 'People discriminate against me because I have a mental illness.',
    value: 3,
  }, {
    label: 'I avoid getting close to people who don’t have a mental illness to avoid rejection.',
    value: 4,
  }, {
    label: 'I am embarrassed or ashamed that I have a mental illness.',
    value: 5,
  }, {
    label: 'Mentally ill people shouldn’t get married.',
    value: 6,
  }, {
    label: 'People with mental illness make important contributions to society.',
    value: 7,
  }, {
    label: 'I feel inferior to others who don’t have a mental illness.',
    value: 8,
  }, {
    label: 'I don\’t socialize as much as I used to because my mental illness might make me look or behave “weird.”',
    value: 9,
  }, {
    label: 'People with mental illness cannot live a good, rewarding life.',
    value: 10,
  }, {
    label: 'I don\’t talk about myself much because I don\’t want to burden others with my mental illness.',
    value: 11,
  }, {
    label: 'Negative stereotypes about mental illness keep me isolated from the “normal” world.',
    value: 12,
  }, {
    label: 'Being around people who don’t have a mental illness makes me feel out of place or inadequate.',
    value: 13,
  }, {
    label: 'I feel comfortable being seen in public with an obviously mentally ill person.',
    value: 14,
  }, {
    label: 'People often patronize me, or treat me like a child, just because I have a mental illness.',
    value: 15,
  }, {
    label: 'I am disappointed in myself for having a mental illness.',
    value: 16,
  }, {
    label: 'Having a mental illness has spoiled my life.',
    value: 17,
  }, {
    label: 'People can tell that I have a mental illness by the way I look.',
    value: 18,
  }, {
    label: 'Because I have a mental illness, I need others to make most decisions for me.',
    value: 19,
  }, {
    label: 'I stay away from social situations in order to protect my family or friends from embarrassment.',
    value: 20,
  }, {
    label: 'People without mental illness could not possibly understand me.',
    value: 21,
  }, {
    label: 'People ignore me or take me less seriously just because I have a mental illness.',
    value: 22,
  }, {
    label: 'I can’t contribute anything to society because I have a mental illness.',
    value: 23,
  }, {
    label: 'Living with mental illness has made me a tough survivor.',
    value: 24,
  }, {
    label: 'Nobody would be interested in getting close to me because I have a mental illness.',
    value: 25,
  }, {
    label: 'In general, I am able to live my life the way I want to.',
    value: 26,
  }, {
    label: 'I can have a good, fulfilling life, despite my mental illness.',
    value: 27,
  }, {
    label: 'Others think that I can’t achieve much in life because I have a mental illness.',
    value: 28,
  }, {
    label: 'Stereotypes about the mentally ill apply to me.',
    value: 29,
  },
]

export const ismi_scale = [
  {
    label: 'Strongly disagree',
    value: 1,
  }, {
    label: 'Disagree',
    value: 2,
  }, {
    label: 'Agree',
    value: 3,
  }, {
    label: 'Strongly disagree',
    value: 4,
  },
]
