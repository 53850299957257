import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/patient_contract.css'

import Button from '@material-ui/core/Button'
//import '../../../styles/assets/css/admin/patient_contract.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../functions/pagination'
import { progress } from '../../admin/variables/patient_contract'

class PatientContractList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          baseline: '',
          miniStatus: '',
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  getDataList = () => {
    var pid = this.props.authReducer.uid
    if(this.props.match.params.pid > 0) {
      pid = this.props.match.params.pid
    }
    // Check completed mini assessments //
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_completed_mini_status', //'patient/assessment/get_baseline_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: pid }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        miniStatus: result['data']
        //baseline: result['data']
      })
    })
    // List //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_contract_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }
  /*'/admin/patient_contract/new_contract/'+this.props.authReducer.uid*/
  render() {
    //console.log(this.state)
    //console.log('Progress: '+this.state.pageOfItems[this.state.pageOfItems.length-1].progress)
    return (
    <div className="patient_contract_list box_border4">
      <div className="row">
        <div className="small-12 medium-7 columns headerPage1">
          Patient Contracts
        </div>
        <div className="small-12 medium-5 columns button_header1">
          { this.state.miniStatus == 'complete' && this.state.pageOfItems.length > 0 && this.state.pageOfItems[this.state.pageOfItems.length-1].progress == 2 &&
          <NavLink to={'/member/update_patient_contract'} className="menu_list_active">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="button" className="button_1">
                Create New Patient Contract
            </Button>
          </NavLink>}
          { this.state.miniStatus == 'complete' && this.state.pageOfItems.length === 0 &&
          <NavLink to={'/member/new_contract/'+this.props.authReducer.uid} className="menu_list_active">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="button" className="button_1">
                Create Patient Contract
            </Button>
          </NavLink>}
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns ">
          { typeof this.props.locationReducer.txt_message !== 'undefined' &&
            this.props.locationReducer.txt_message !== "" &&
            <div className="row warning_msg7">
              <div className="small-12 columns ">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            </div>
          }
          <div>
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>Version</th>
                  <th>Status</th>
                  <th>Review/Update</th>
                  <th>PDF</th>
                  <th>Created Date/Time</th>
                </tr>
              </thead>
              <tbody>
            {this.state && this.state.dataItems && this.state.pageOfItems.map((item) =>
              <tr key={'d'+item.contract_version}>
                <td>{item.contract_version}</td>
                <td>{item.progress == 2 && this.findLabelByValue(progress, item.progress)}
                {item.progress == 1 && this.props.authReducer.role == 1 &&
                  <span><a href={"/admin/patient_contract/update_contract/"+this.props.match.params.pid+"/"+item.contract_version} target="_blank">
                  {this.findLabelByValue(progress, item.progress)}</a>
                  </span>}
                {item.progress == 1 && this.props.authReducer.role == 5 &&
                  <span>{this.findLabelByValue(progress, item.progress)}</span>}
                </td>
                <td>
                  {item.progress == 2 && 'Completed'}
                  {item.progress == 1 && item.patient_submit_status == 2 && 'Submitted'}
                  {item.progress == 1 && item.patient_sign_status == 1 && item.patient_submit_status != 2 && <a href={"/member/edit_patient_contract/"+item.contract_version}>Review/Update</a>}
                </td>
                <td>
                  {item.progress == 1 && '-'}
                  {item.progress == 2 && <a href={"/member/patient_contract_pdf/"+item.contract_version}
                target="_blank">PDF</a>}
                </td>
                <td>{item.conv_date}</td>
              </tr>
            )}
            {this.state && this.state.dataItems &&
              <tr className="pagination_block">
                <td colSpan="6">
                  <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                </td>
              </tr>
            }
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientContractList)
