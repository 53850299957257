import React, { Component } from 'react'
import { withRouter } from "react-router-dom"

class CompleteSelfStigma extends Component {
  render() {
    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Thank you!
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
              You have completed your self stigma questionaires.
          </div>
        </div>
      </div>
    );
  }
}

export default (withRouter)(CompleteSelfStigma)
