import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import { loadLocationWithMessage } from '../../../../actions/location'
import '../../../../styles/assets/css/admin/form2.css'

import WarningIcon from '@material-ui/icons/Warning'
import PatientHead2 from "../../patient/patient_head2"

import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { clinicians } from '../../variables/patient.js'

class PatientEditPracStudent extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          clinician_id: 0,
          clinician_name: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_prac_student',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
        if(result['data'].uid > 0) {
          this.setState({
            clinician_id: result['data'].uid,
            clinician_name: result['data'].fullname,
          })
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        if(result['data'] > 0) {
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {

    return (
      <div className="patient_edit_primary_clinician box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient: Edit Practicum Student
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className="warning_msg2">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
            <div className="box_border5">
              <form id="patient_detail" onSubmit={this.handleSubmit}>
                <PatientHead2 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top-space1">
                    <div className="small-12 medium-2 columns bold_txt3">Clinician: </div>
                    <div className="small-12 medium-5 columns float_left">
                      <FormControl variant="outlined">
                        <Select value={this.state.clinician_id} variant="outlined"
                          label=""
                          onChange={(e) => this.handleChange(e, 'clinician_id')}>
                          {clinicians.map(each =>
                            each.sub_role === 2 &&
                            <MenuItem key={'it'+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="small-12 medium-2 columns">&nbsp;</div>
                    <div className="small-12 medium-10 columns top-space1">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>

                </div>
                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Link to={"/admin/patient_chart/"+this.props.match.params.pid} className="go_back">Go Back</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientEditPracStudent)
