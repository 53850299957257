import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/stepper1.css'
import '../../../styles/assets/css/pages/form6.css'
import { loadLocation } from '../../../actions/location'
import { registration_steps } from '../variables/registration.js'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

//import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

class RoiInstruction extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'roi_instruction') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/update_roi_instruction',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid
      }
    })
    .then((result) => {
        if(result['data'] === 'roi') {
          this.props.onLoadLocation('/member/roi')
          this.props.history.push('/member/roi')
        }

      }
    )
  }

  render() {

    return (
      <div className="roi box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Authorization for Release of Protected or Privileged Health Information
          </div>
          <div className="small-12 columns stepper">
          <Stepper alternativeLabel nonLinear activeStep={5}>
            {registration_steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg5">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box2">
              <div className="title_topic1">Instructions:</div>
              <p>Please fill out Part A and Part B. Note that portions of this form are pre-filled.</p>
              <p>In Part B, please provide the contact information for all providers you have seen for therapy and medication management/prescription in the PAST YEAR. Therapy providers can include psychiatrists, psychologists, clinical social workers or counselors, and psychiatric nurse practitioners. Prescribing providers can include primary care physicians, psychiatrists, psychopharmacologists, and nurse practitioners.</p>
              <p>To add more providers, click on the ADD PROVIDER button in the lower right corner.</p>
              <p>Don't forget to sign and date the document at the end.</p>
          </div>
          <div className="small-12 columns align_right">
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Next
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RoiInstruction)
