export const to_do_status = [
  {
    label: 'Ongoing',
    value: 1,
  }, {
    label: 'Completed',
    value: 2,
  }, {
    label: 'Modified',
    value: 3,
  },
]

export const to_do_status_show = [ '',
  'Ongoing', // 1
  'Modified', // 2
  'Completed', // 3
]

export const change_reasons = [
  {
    label: 'Completed task',
    value: 1,
  }, {
    label: 'Symptoms abated',
    value: 2,
  }, {
    label: 'Symptoms worsened',
    value: 3,
  }, {
    label: 'New symptoms',
    value: 4,
  }, {
    label: 'Task ineffective',
    value: 5,
  }, {
    label: 'New adverse event or life stressor',
    value: 6,
  }, {
    label: 'Patient reports unwilling to complete',
    value: 7,
  }, {
    label: 'Patient reports unable to complete',
    value: 8,
  }, {
    label: 'Other',
    value: 9,
  },
]
