import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../../actions/location'
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { filter_patient_status } from '../variables/patient_1.js'

class SelfStigmaTeamList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          show_filter_status: 11,
          team_list: [],
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/self_stigma/get_self_stigma_team_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { assm_id: this.props.match.params.assm_id }
    })
    .then((result) => {
      this.setState({
        team_list: result['data'],
      })
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  convertDateFormat = (date) => {
    var buff = date.split('-')
    return buff[1]+'/'+buff[2]+'/'+buff[0]
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Self Stigma: {this.state.team_list.length > 0 && this.convertDateFormat(this.state.team_list[0].due_date) }
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Name</th>
                    <th>SCCS</th>
                    <th>ISMI</th>
                  </tr>
                </thead>
                <tbody>
              {this.state && this.state.team_list && this.state.team_list.map(item =>
                <tr key={item.id}>
                  <td>{item.fullname}</td>
                  <td>{item.sccs_status == 1 && <a href={"/admin/assessments/view_assessment_self_stigma/sccs/"+item.uid+"/"+item.id}>Detail</a>}</td>
                  <td>{item.ismi_status == 1 && <a href={"/admin/assessments/view_assessment_self_stigma/ismi/"+item.uid+"/"+item.id}>Detail</a>}</td>
                </tr>
              )}
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SelfStigmaTeamList)
