import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import ReactTooltip from 'react-tooltip'
import { AiOutlineQuestionCircle, AiFillPushpin } from 'react-icons/ai'
import { pn_checkin_frequency } from '../../admin/variables/patient.js'

class ToDoList extends Component {
  constructor() {
    super()
    this.state = {
      getDataList: [],
      checkin: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var get_tracker_day = []
    var get_tracker_week = []

    // to do list //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_current_to_do_intervention_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid, }
    })
    .then((result) => {
        //console.log(result['data'])
        this.setState({ getDataList: result['data'] })
      }
    )

    // PN Checkin //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_checkin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      if(result['data'].checkin > 0) {
        this.setState({
          checkin: result['data'].checkin,
        })
      }
    })
  }

  handleChange = (e, intervention_id) => {
    if (e.target.checked) {
      var box = window.confirm("Are you sure you completed this item?")
      if (box == true) {
        axios({
          method: 'post',
          url: connectNode + 'patient/general/update_completed_todo_list',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            pid: this.props.authReducer.uid,
            intervention_id: intervention_id
          }
        })
        .then((result) => { this.getDataList() })
      } else { e.target.checked = false }
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="box_border box_space_top1 to_do_list">
        <div className="row">
            <div className="small-12 columns head2">
                Your Treatment To Do List
            </div>
            <div className="small-12 columns content2 space_bottom1 txt_question2">
            {this.state.checkin > 0 && this.state.checkin < 4 &&
              <div className="todo_each">
                <AiFillPushpin /> {this.findLabelByValue(pn_checkin_frequency, this.state.checkin)} check in with patient navigator
              </div>}
            {this.state.getDataList.map((each, index) =>
                each.target_completion != 1 && each.change_target != 1 && each.assign_new_intervention != 1
                && each.modality_completion_status != 5 && each.todo_status!=1 && each.todo_status!=3 &&
                <div key={'td_'+index} className="todo_each">
                  <input type="checkbox" name={'td'+index} value='1' className="todo_cb"
                    //disabled={each.complete_status == 1 && 'disabled'}
                    //checked={each.complete_status == 1 && 'checked'}
                    onChange={(e) => this.handleChange(e, each.intervention_id)}/>
                    {each.intervention}
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for={'intervention_'+each.intervention_id} >
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id={'intervention_'+each.intervention_id} type='warning' effect='solid'>
                    <span>
                      { each.treatment_goal }{each.treatment_goal_other}
                    </span>
                  </ReactTooltip>
                </div>
              )}
              {this.state.getDataList.length == 0 && <div className="align_center">-</div>}
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList)
