import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import Cookies from 'js-cookie'
import moment from 'moment'
import validator from 'validator'
import { connectNode } from '../../../utils/global'
import { escapeHtml, validateEmail } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import { referral_source, ans_yesno, ans_yesno_think, ans_yes_think } from '../variables/patient.js'

import InputAdornment from '@material-ui/core/InputAdornment'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EventIcon from '@material-ui/icons/Event'
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

class AddPatient extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          uid: 0,
          name: '',
          date_contact: new Date().toISOString().substring(0, 10),
          referral_source: 0,
          screening: 2,
          email: '',
          address: '',
          phone: '',
          go_to_enroll: 0,
          unencrypted_email: 0,
          access_smartphone: 0,
          best_phone: '',
          message: '',
      }
  }

  handleChange = (e) => {
    if(e.target.name === 'email') {
      this.setCookie('email_pt', e.target.value, 60)
    }
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  setCookie = (cname, cvalue, minutes) => {
    var d = new Date();
    d.setTime(d.getTime() + (minutes*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }

  openInNewTabwCookie = (url) => {
    if(this.state.email == '') {
      this.setState({
        message: "Please provide patient\'s email",
      })
      window.scrollTo(0, 0)
    } else {
      if(validateEmail(this.state.email)) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      } else {
        this.setState({
          message: "Please provide correct email",
        })
        window.scrollTo(0, 0)
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(!validator.isEmail(this.state.email)) {
      this.setState({
        message: 'Please fill email in the right format.'
      })
      window.scrollTo(0, 0)
    } else if (this.state.address.trim().length < 21) {
      this.setState({
        message: 'Please fill patient street address.'
      })
      window.scrollTo(0, 0)
    } else {
      if(this.state.email.trim() !== '' && this.state.date_contact.trim() !== ''
        && this.state.name.trim() !== '' && this.state.phone.trim() !== ''
        && this.state.address.trim() !== '' && this.state.referral_source != 0
        && ((this.state.go_to_enroll == 1 && this.state.access_smartphone == 1 && this.state.unencrypted_email != 0)
         || (this.state.go_to_enroll == 1 && this.state.access_smartphone == 2 && this.state.best_phone != '' && this.state.unencrypted_email != 0)
         || (this.state.go_to_enroll == 2) || (this.state.go_to_enroll == 3))) {
          axios({
            method: 'post',
            url: connectNode + 'admin/register_patient/add_new_patient',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
              uid: this.props.authReducer.uid,
              user: this.state,
            }
          })
          .then((result) => {
            var message = ''
            if(result['data'] > 0) {
              this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
              this.props.history.push('/admin/patient_list')
            } else if(result['data'] === -1) {
              message = 'This email already exists.'
            } else if(result['data'] === -2) {
              message = 'Adding new patient completed but system couldn\'t send email reminder!'
            } else {
              message = 'Something went wrong. Please try again.'
            }
            if(message !== '') {
              this.setState({
                message: message
              })
              window.scrollTo(0, 0)
            }
          })
      } else {
        var message = ''
        if(this.state.name === '') {
          message = 'Please fill patient name.'
        } else if(this.state.phone === '') {
          message = 'Please fill patient phone number.'
        } else if(this.state.address === '') {
          message = 'Please fill patient street address.'
        } else if(this.state.referral_source == 0) {
          message = 'Please select how did you hear about us?'
        } else if(this.state.go_to_enroll == 0) {
          message = 'Please select enrolling clinic.'
        } else if(this.state.go_to_enroll == 1) {
          if(this.state.access_smartphone == 0) {
            message = 'Please select answer about access to a smartphone.'
          } else if(this.state.access_smartphone == 2 && this.state.best_phone == '') {
            message = 'Please fill the best phone number.'
          } else if(this.state.unencrypted_email == 0) {
            message = 'Please select answer about unencrypted emails.'
          }
        }
        this.setState({
          message: message,
        })
        window.scrollTo(0, 0)
      }
    }
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {
    return (
      <div className="admin_add_patient box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Initial Patient Contact Tracking Form
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <form id="add_new_patient" onSubmit={this.handleSubmit}>
              <div className="row">
                { this.state.message !== '' &&
                  <div className="small-12 columns warning_msg6">
                    <div className="float_left1"><WarningIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>
                }
                <div className="small-12 columns">
                  Please complete the following form during initial phone call with potential FITT-BD patients:
                </div>
              </div>
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient Name</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.name}
                        InputProps={{ inputProps: { name: "name" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Date of contact</div>
                      <div className="small-12 medium-8 columns float_left">{/*+(10*60*60*24*1000)*/}
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="date" defaultValue={this.state.date_contact}
                        InputProps={{ inputProps: { name: "date_contact", min: this.formatDate(new Date(Date.now()-(30*60*60*24*1000))), max: this.formatDate(new Date(Date.now())) }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row referral_source">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">How did you hear about us?</div>
                      <div className="small-12 medium-8 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.referral_source} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange2(e, 'referral_source')}>
                            { referral_source.map( each =>
                              <MenuItem key={'rs'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient email address</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="email" defaultValue={this.state.email}
                        InputProps={{ inputProps: { name: "email" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient phone number</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.phone}
                        InputProps={{ inputProps: { name: "phone" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-4 columns bold_txt3">Patient street address</div>
                      <div className="small-12 medium-8 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.address}
                        InputProps={{ inputProps: { name: "address" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row"><div className="sep_line_sub2">&nbsp;</div></div>

                  <div className="row top_space2">
                    <div className="small-12 columns bold_txt1">
                      Thank you for your interest in the FITT-BD clinic. Are you calling to learn more about our clinic, or are you already familiar with FITT-BD and would like to enroll?
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <span className="bold_txt1">• If “Calling to learn more”:</span><br />
                      We can give you some basic information on the phone now, and we can also mail you a brochure that will describe our program in more detail. Would you like to hear a little about our program now, or would you rather wait to see the brochure?
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">{/* indent1 */}
                      <span className="bold_txt1">• If “Would like to hear a little now”:</span><br />
                      The FITT-BD clinic is a team-based, goal-oriented program designed to help people with bipolar disorder learn the skills and develop the insight they need to be able to independently manage their condition. Instead of traditional, open-ended, one-on-one therapy, our program puts you at the center of a team of clinicians and staff who are dedicated to working with you to achieve your goals within a specified time frame. With your team, you will create a personal treatment plan that is appropriate to the level of care you need. You might start with a self-guided, skills-based training with regular check-ins with our staff, or you might jump to a more intensive program that includes group therapy and/or brief, individual meetings with a staff psychologist. We strive to treat every patient holistically, considering such things as nutrition and exercise, as well as actively engaging the support of family and friends. All of our providers have expertise in treating people with bipolar disorder and are involved in research to identify more effective treatments for this condition.
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      <span className="bold_txt1">• If “Would rather wait to see the brochure”:</span><br />
                      We’ll put the brochure in the mail to you as soon as possible.
                      If you’re interested in enrolling, just call us back at this number.
                      Thank you!
                    </div>
                  </div>

                  <div className="row"><div className="sep_line_sub2">&nbsp;</div></div>

                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <span className="bold_txt1">1. Would you like to enroll in the clinic now, or would you like to think about it?</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      {ans_yes_think.map((each, index) =>
                        <div className="small-6 medium-3 columns" key={'gte'+each.label}>
                          <input type="radio" name="go_to_enroll" value={each.value}
                          id={'q1'+index}
                          onChange={(e) => this.handleChange2(e, 'go_to_enroll')}/>
                          <label for={'q1'+index}>{each.label}</label>
                        </div>
                      )}
                      <div className="medium-8 columns">&nbsp;</div>
                    </div>
                  </div>

                  {this.state.go_to_enroll >=2 &&
                  <div className="row">
                    <div className="small-12 columns">
                    <div className="box_inner4">
                    We’ll put the brochure in the mail to you as soon as possible.
                    If you’re interested in enrolling, just call us back at this
                    number. Thank you!
                    </div></div>
                  </div>}

                  {this.state.go_to_enroll == 1 &&
                  <div>

                  <div className="row">
                    <div className="small-12 columns bold_txt1">
                      2. Do you have access to a smartphone, tablet, or computer?
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      {ans_yesno.map((each, index) =>
                        <div className="small-6 medium-2 columns" key={'as'+each.label}>
                          <input type="radio" name="access_smartphone" value={each.value}
                          id={'q2'+index} onChange={(e) => this.handleChange2(e, 'access_smartphone')}/>
                          <label for={'q2'+index}>{each.label}</label>
                        </div>
                      )}
                      <div className="medium-8 columns">&nbsp;</div>
                    </div>
                  </div>

                  {this.state.access_smartphone == 2 &&
                  <div>
                    <div className="row">
                      <div className="small-12 columns top_space2">
                        I'm sorry. You will need to have access to a smartphone, tablet, or computer to participate in the program.
                        {/*We may be able to loan you a tablet. If you’re interested, one of our staff 	can call you within the next five business days to discuss this. After that, we can resume 	the registration process.*/}
                      </div>
                      {/*<div className="small-12 columns bold_txt1 top_space1">
                        What is the best phone number to reach you?
                      </div>
                      <div className="small-12 columns">
                        <TextField onChange={this.handleChange} variant="outlined"
                        type="text" defaultValue={this.state.best_phone}
                        InputProps={{ inputProps: { name: "best_phone" }}} />
                      </div>*/}
                    </div>
                  </div>}

                  {this.state.access_smartphone > 0 &&
                  <div>
                  <div className="row top_space2">
                    <div className="small-12 columns bold_txt1">
                      3. Do you agree to receive unencrypted emails containing information about the FITT-BD clinic and registration process?
                    </div>
                  </div>

                  <div className="row ">
                    <div className="small-12 columns">
                      <p className="bold_txt1">Please read the following unencrypted email disclosure:</p>
                      <p className="small-12 columns">
                        The Partners standard is to send email securely through a Patient Gateway account, which you would access using a password. These “encrypted” emails require the recipient to go through a cumbersome, multi-step verification process to open and read each and every email. Because the FITT-BD program involves regular emails to patients, this would make our communication with you very burdensome. Instead, as a patient at the FITT-BD clinic, you will receive "unencrypted" email that is sent directly to your email address.
                      </p>
                      <p className="small-12 columns">
                        To protect your privacy, the emails we send you will not include any clinical information or mention of a diagnosis. However, please note that these emails are not secure and could potentially result in the unauthorized use or disclosure of your information. If you agree to receive communications by unencrypted email despite these risks, Partners HealthCare will not be held responsible.
                      </p>
                      <p className="small-12 columns">
                        Your preference to receive unencrypted email will apply only to emails sent from the FITT-BD Clinic.
                      </p>
                      <p className="small-12 columns">
                        Do you agree that you want to communicate over unencrypted email?"
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-6 medium-2 columns" key={'ue'+each.label}>
                          <input type="radio" name="unencrypted_email" value={each.value}
                          onChange={(e) => this.handleChange2(e, 'unencrypted_email')}/>
                          <span>{each.label}</span>
                        </div>
                      )}
                      <div className="medium-8 columns">&nbsp;</div>
                    </div>
                  </div>

                  {this.state.unencrypted_email == 1 && this.state.access_smartphone == 1 &&
                  <div className="row">
                    <div className="small-12 columns">
                    <div className="box_inner4">
                    Great! Check your email for instructions for enrolling. Before you enroll, you will be 	asked a few questions to make sure the clinic is a good fit for you.
                    </div></div>
                  </div>}

                  {this.state.unencrypted_email == 1 && this.state.access_smartphone == 2 &&
                  <div className="row">
                    <div className="small-12 columns">
                    <div className="box_inner4">
                    You will hear from us within 5 business days about the loan of a tablet.
                    </div></div>
                  </div>}

                  {this.state.unencrypted_email == 2 &&
                  <div className="row">
                    <div className="small-12 columns">
                    <div className="box_inner4">
                    I’m sorry, but at present we are only able to enroll patients who agree to accept unencrypted emails.
                    <br />Thank you for your interest in FITT-BD.
                    </div></div>
                  </div>}
                  </div>}

                  </div>}

                  <div className="row top_space2">
                    <div className="small-12 columns float_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddPatient)
