import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/pyramid1.png'
//import AudioMP3 from '../../../audios/sample1.mp3'

class Intro3 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction4')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction2')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_1 margin-bottom1">STEPPED CARE</div>
            <div className="img_center">
              <img src={Image} alt="pyramid" />
            </div>
            <div className="content1 margin-top1">
              <p>At FITT-BD, your treatment plan is based on the level of care you need.</p>
              <p>We call this approach "stepped care." Since it's quite different
                from most other mental health treatment, let's take a closer look at
                how it works.
              </p>
            </div>
            <div className="row">
              <div className="small-4 columns back_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro3)
