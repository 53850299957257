import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import validator from 'validator'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../patient/patient_head3"

class EditPatientStatus extends Component {
  constructor() {
      super();
      this.state = {
          msg_status: false,
          message: '',
          statusList: [],
          patient_status: 0,
      }
  }

  componentDidMount() {
    this.getPatientStatusList()
  }

  getPatientStatusList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_status_list2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      this.setState({
        statusList: result['data']
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    if(this.state.patient_status === 0 ) {
      this.setState({
        msg_status: false,
        message: "Please select patient's status",
      })
      window.scrollTo(0, 0)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/edit_patient_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          patient_status: this.state.patient_status,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/1', 'Updated status successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
          /*this.setState({
            msg_status: true,
            message: "Updated patient's status successfully!",
          })*/
        }
      })
    }
  }

  render() {
    return (
      <div className="admin_make_appointment box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Status
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className={(!this.state.msg_status && "row warning_msg6") || this.state.msg_status && "row warning_msg7"}>
              <div className="small-12 columns ">
                <div className="float_left1">
                {!this.state.msg_status && <WarningIcon />}
                {this.state.msg_status && <CheckCircleOutlineIcon />}
                </div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Patient Status: </div>
                        <div className="small-12 medium-6 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.patient_status} variant="outlined" name="patient_status"
                              label="" onChange={(e) => this.handleChange(e, 'patient_status')}>
                              {this.state.statusList.map(each =>
                                <MenuItem key={'p'+each.vid} value={each.vid}>{each.name}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="small-12 medium-4 columns">
                          <Button variant="contained" color="primary" disableElevation
                           onClick={this.handleSubmit} type="submit">
                            Submit
                          </Button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditPatientStatus)
