import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { cgi_type, cgi_mood, mood_state_long, mood_state_long_type,
  severity_illness, improvement } from '../variables/assessments/cgi_bd.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import PatientHead3 from "../patient/patient_head3"

class CgiBD extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          date: '',
          rater: 0,
          rater_name: '',
          type: 0,
          mood_initial: 0,
          mood_state_long: 0,
          mood_state_long_type: 0,
          severity_a: 0,
          severity_b: 0,
          severity_c: 0,
          improvement_a: 0,
          improvement_b: 0,
          improvement_c: 0,
          anxiety_disorder: 0,
          behavioral_substance: 0,
          start_datetime: Math.floor(new Date().getTime()/1000),

      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    // get Rater name //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_clinician_name',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      this.setState({
        rater: this.props.authReducer.uid,
        rater_name: result['data'].fullname,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleDateChange = (e, name) => {
    this.setState({ [name]: e })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    if(this.state.type === 0 || 
      this.state.date === '' || this.state.severity_a === 0 ||
      this.state.severity_b === 0 || this.state.severity_c === 0 ||
      this.state.anxiety_disorder === 0 || this.state.behavioral_substance === 0) {
      this.setState({
        message: 'Please select all answers ',
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/add_cgi_bd',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          cid: this.props.authReducer.uid,
          user: this.state,
          pid: pid,
        }
      })
      .then((result) => {
          if(result['data'] === pid) {
            this.props.onLoadLocation('/admin/patient_chart/'+pid+'/5/4', 'Add new CGI-BD successfully!')
            this.props.history.push('/admin/patient_chart/'+pid+'/5/4')
          }
        }
      )
    }
  }

  render() {

    return (
      <div className="assm_mini box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Clinical Global Impressions – Bipolar Disorder (CGI-BD)
          </div>
        </div>
        <PatientHead3 />
        { this.state.message !== '' &&
        <div className="row warning_msg6 top_space2">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        { this.props.authReducer.role == 3 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row top_space2">
            <div className="small-12 columns box1">
              <div className="row">
                <div className="small-12 medium-1 columns top_space2">
                  <span className="bold">Rater:</span>
                </div>
                <div className="small-12 medium-11 columns top_space2">
                   {this.state.rater_name}
                </div>
                <div className="small-12 medium-1 columns top_space2">
                  <span className="bold">Date:</span>
                </div>
                <div className="small-12 medium-11 columns top_space2">
                  <DatePicker selected={this.state.date}
                  onChange={(e) => this.handleDateChange(e, 'date')}
                  className="date_picker short2" />
                </div>
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                {cgi_type.map(each =>
                  <div className="each_choice1" key={'type'+each.label}>
                    <input type="radio" name="type" value={each.value} onChange={(e) => this.handleChange(e)}/>
                    <span>{each.label}</span>
                  </div>
                )}
                </div>
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                  <div className="bold">1. Severity of Illness: Bipolar Disorder</div>
                  <div>Considering your total clinical experience with bipolar patients, how severely ill has the patient been during the past week?</div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns top_space2">a. Mania</div>
                    <div className="small-12 medium-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.severity_a} variant="outlined" name="severity_a"
                        label="" onChange={(e) => this.handleChange(e)}>
                        {severity_illness.map( each =>
                          <MenuItem key={'sa'+each.value} value={each.value}>{each.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns top_space2">b. Depression</div>
                    <div className="small-12 medium-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.severity_b} variant="outlined" name="severity_b"
                        label="" onChange={(e) => this.handleChange(e)}>
                        {severity_illness.map( each =>
                          <MenuItem key={'sb'+each.value} value={each.value}>{each.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-12 medium-3 columns top_space2">c. Overall Bipolar Illness</div>
                    <div className="small-12 medium-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.severity_c} variant="outlined" name="severity_c"
                        label="" onChange={(e) => this.handleChange(e)}>
                        {severity_illness.map( each =>
                          <MenuItem key={'sc'+each.value} value={each.value}>{each.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                </div>

                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                  <div className="bold">2. Severity of Illness: Anxiety Disorder</div>
                  <div>Considering your total clinical experience with anxiety disorder patients, how severely ill has the patient been during the past week?</div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.anxiety_disorder} variant="outlined" name="anxiety_disorder"
                        label="" onChange={(e) => this.handleChange(e)}>
                        {severity_illness.map( each =>
                          <MenuItem key={'ia'+each.value} value={each.value}>{each.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                </div>

                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                  <div className="bold">3. Severity of Illness: Behavioral and Substance Use Disorder</div>
                  <div>Considering your total clinical experience with behavioral and substance use disorder patients, how severely ill has the patient been during the past week?</div>
                </div>
                <div className="small-12 columns top_space2">
                  <div className="row">
                    <div className="small-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.behavioral_substance} variant="outlined" name="behavioral_substance"
                        label="" onChange={(e) => this.handleChange(e)}>
                        {severity_illness.map( each =>
                          <MenuItem key={'ia'+each.value} value={each.value}>{each.label}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                </div>

                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>
                <div className="small-12 columns">
                  <div className="bold">Severity scale for all items: </div>
                  <div className="top_space2">1 = Normal—not at all ill, symptoms of disorder not present past seven days</div>
                  <div>2 = Borderline mentally ill—subtle or suspected pathology</div>
                  <div>3 = Mildly ill—clearly established symptoms with minimal, if any, distress or difficulty in social and occupational function</div>
                  <div>4 = Moderately ill—overt symptoms causing noticeable, but modest, functional impairment or distress; symptom level may warrant medication</div>
                  <div>5 = Markedly ill—intrusive symptoms that distinctly impair social/occupational function or cause intrusive levels of distress</div>
                  <div>6 = Severely ill—disruptive pathology, behavior and function are frequently influenced by symptoms, may require assistance from others</div>
                  <div>7 = Among the most extremely ill patients—pathology drastically interferes in many life functions; may be hospitalized</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-6 columns">
              <Link to={"/admin/patient_chart/"+this.props.match.params.pid+"/3/3"}
              className="go_back">Go Back</Link>
            </div>
            <div className="small-12 medium-6 columns align_right">
            {!this.state.status_submit &&
              <Button variant="contained" color="primary" disabled
                 onClick={this.handleSubmit} type="submit" className="submit_1">
                  Submit
              </Button>}
            {this.state.status_submit &&
              <Button variant="contained" color="primary"
                 onClick={this.handleSubmit} type="submit" className="submit_1">
                  Submit
              </Button>}
            </div>
          </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CgiBD)
