import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, NavLink } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
//import Select from '@material-ui/core/Select'
//import { ListSubheader } from '@material-ui/core'
//import MenuItem from '@material-ui/core/MenuItem'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../../patient/patient_head3"
import { contact, type, reason, outcome, yes_no } from '../variables/contact_logs.js'
import { completion, adherence_reason } from '../variables/treatment_plan.js'

import ReactHtmlParser from 'react-html-parser'
import Select from 'react-select'

class EditSelfStigma extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          due_date: '',
          start_date: '',
          end_date: '',
          reminder_date: '',
          patient_list: [],
          patient_group: [],
          get_patient: 0,
          dataList: [],
          editMode: false
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/self_stigma/get_self_stigma',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { assm_id: this.props.match.params.assm_id }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        var buff = []
        var pt_group = []
        if(result['data'].length >= 1) {
          result['data'].map(each => {
            buff = each.fullname.trim().split(" ")
            pt_group.push(
              { pid: each.uid,
                name: buff[buff.length-1]+', '+buff[0],
              }
            )
          })
        }

        this.setState({
          due_date: result['data'][0].due_date,
          start_date: result['data'][0].available_date_begin,
          end_date: result['data'][0].available_date_end,
          reminder_date: result['data'][0].reminder_date,
          patient_group: pt_group,
        })
      }
    })

    axios({
      method: 'post',
      url: connectNode + 'admin/self_stigma/get_active_patient_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          patient_list: result['data'],
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSelectPatient = (e) => {
    if(e.value > 0) {
      this.setState({
        get_patient: e.value,
      })
    }
  }

  add_patient = () => {
    //console.log(this.textInput.current[0])
    var getVal = this.state.get_patient
    var getPatientGroup = this.state.patient_group
    var getName = this.findLabelByValue(this.state.patient_list, getVal)
    const found = getPatientGroup.some(el => el.pid === getVal)
    if(found) {
      this.setState({
        message: 'This patient has already added to the list.',
      })
      window.scrollTo(0, 0)
    } else {
      getPatientGroup.push(
        { pid: this.state.get_patient,
          name: getName,
        }
      )
      this.setState({
        patient_group: getPatientGroup,
        //get_patient: 0,
      })
    }
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.pid === id)
    //console.log(getIndex)
    fields.splice(getIndex, 1)
    this.setState({ [name]: fields })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if(this.state.due_date == '' || this.state.start_date == '' ||
      this.state.end_date == '' || this.state.reminder_date == '') {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/self_stigma/edit_self_stigma',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          assm_id: this.props.match.params.assm_id,
          data: this.state,
        }
      })
      .then((result) => {
        //console.log(result['data'])
        if(result['data'] == this.props.authReducer.uid) {
          this.props.onLoadLocation('self_stigma_list', 'Edit self stigma assessments successfully!')
          this.props.history.push('/admin/self_stigma/self_stigma_list')
        }
      })
    }
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    //console.log(this.state)
    return (
      <div className="admin_log_outcome box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Edit Self Stigma Assessments
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>
            }
            <div className="box_border5">
              <form id="new_self_stigma" onSubmit={this.handleSubmit}>
                <div className="row">
                {(this.state.dataList.length == 0 || this.state.editMode) &&
                <div>
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Assessments due date:</div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <TextField id="due_date" label="" onChange={this.handleChange}
                        variant="outlined" type="date" value={this.state.due_date}
                        InputProps={{ inputProps: { name: "due_date" }}} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Available date on patient portal:</div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <TextField id="start_date" label="" onChange={this.handleChange}
                        variant="outlined" type="date" value={this.state.start_date}
                        InputProps={{ inputProps: { name: "start_date" }}} />
                      </div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <span className="float_left1">to &nbsp; &nbsp; </span>
                      <TextField id="end_date" label="" onChange={this.handleChange}
                        variant="outlined" type="date" value={this.state.end_date}
                        InputProps={{ inputProps: { name: "end_date" }}} className="w90p" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Date of email reminder:</div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <TextField id="reminder_date" label="" onChange={this.handleChange}
                        variant="outlined" type="date" value={this.state.reminder_date}
                        InputProps={{ inputProps: { name: "reminder_date" }}} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Patients:</div>
                      <div className="small-12 medium-4 columns no_padding_left_float_left">
                      <Select options={this.state.patient_list} placeholder="Type or select..."
                       onChange={this.handleSelectPatient} id="get_patient" />
                      <div className="group_name_list">
                        <ul>
                        {this.state.patient_group.map(each =>
                            <li key={'k'+each.pid}>{each.name}
                            <CancelIcon onClick={ () => this.removeInputSet1('patient_group', each.pid, this.state.patient_group) } /></li>
                        )}</ul>
                      </div>
                      </div>
                      <div className="small-12 medium-1 columns no_padding_left_float_left">
                      <button type="button" className="addInput1" id="patient_name"
                       onClick={() => this.add_patient()} >
                        Add
                      </button>
                      </div>
                    </div>
                  </div>
                </div>}
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                  {this.state.dataList.length > 0 && !this.state.editMode &&
                    <a onClick={() => this.props.history.goBack()} className="go_back">Go Back</a>
                  }
                  {(this.state.dataList.length == 0 || this.state.editMode) &&
                    <span>
                    {this.state.status_submit &&
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    {!this.state.status_submit &&
                    <Button variant="contained" disabled
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    </span>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditSelfStigma)
