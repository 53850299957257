import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import PatientApptList from "./patient_appt_list"

class TabAppointment extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    //console.log('=> '+this.props.match.params.secondtab)
    if(this.props.match.params.secondtab > 0) {
      this.setState({
        tab_status: this.props.match.params.secondtab,
      })
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  render() {

    return (
      <div className="">
        <div className="row">
          <div className="small-12 columns tab_data1 tab_patient">
            <div className="tabbox1">
              <PatientApptList />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabAppointment)
