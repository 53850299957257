import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink} from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../functions/pagination'
import { patient_status } from './variables/patient_1.js'
import { escapeHtml } from '../../../utils/textFunctions'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search';

class PatientSearch extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          patient_name: '',
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    //this.getDataList()
  }

  getDataList = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/get_patient_list_search',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        str_search: this.state.patient_name,
      }
    })
    .then((result) => {
        this.setState({
          dataItems: result['data'],
        })
      }
    )
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  render() {
    return (
      <div className="patient_dashboard box_border4 patient_search">
        <div className="row search_box">
          <div className="small-12 medium-6 columns headerPage1 no_padding_right">
            <span className="aufo_fit">Search</span>
            <TextField onChange={this.handleChange} variant="outlined"
            type="text" defaultValue={this.state.patient_name}
            InputProps={{ inputProps: { name: "patient_name", placeholder: 'Patient name' }}} />
          </div>
          <div className="small-12 medium-6 columns search_submit">
            <Button variant="contained" color="primary" disableElevation
             onClick={this.getDataList} type="submit">
              <SearchIcon />
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>ID</th>
                    <th>MRN</th>
                    <th>Name</th>
                    <th>Primary Clinician</th>
                    <th>Appt Status</th>
                    <th>Intake Appt</th>
                    <th>Days Enrolled</th>
                  </tr>
                </thead>
                <tbody>
              {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                <tr key={item.uid}>
                  <td>{item.uid}</td>
                  <td>{item.mrn}</td>
                  <td className="align-left"><a href={"/admin/patient_chart/"+item.uid+"/1/1"}>{item.fullname}</a></td>
                  <td>{item.c_name !== null && item.c_name}{item.c_name === null && '-'}</td>
                  <td>
                    {item.status == 1 && item.diff_days > 28 && 'Incomplete'}
                    {((item.status == 1 && item.diff_days <= 28) || item.status != 1) && item.st_name }
                  </td>
                  <td>
                    {item.intake_clinician}
                    {item.intake_clinician && <br/>}
                    {item.intake_clinician && item.mini_start_time.substring(0, 10)}
                    {item.intake_clinician == null && '-'}
                  </td>
                  <td>{item.diff_days}</td>
                </tr>
              )}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearch)
