import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import { connectNode } from '../../../utils/global'
import { escapeHtml, validateAge } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import '../../../styles/assets/css/pages/form4.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { dep_exp_past, pt_lastyear_spent_dep, yes_no_unknown,
         yes_no, kinds_child_abuse } from '../variables/psychiatric_history.js'

class PsychiatricHistory extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          invalid: false,
          message: '',
          uid: 0,
          age_first_dep: '',
          age_first_manic: '',
          total_prev_dep: "",
          num_dep_exp_last_year: 0,
          pt_lastyear_spent_dep: 0,
          total_prev_manic: "",
          num_manic_exp_last_year: 0,
          pt_lastyear_spent_manic: 0,
          prev_hosp_psyc: 0,
          number_hospitalize: 0,
          hurt_yourself_other_reason: 0,
          prev_suicide: 0,
          number_suicide: 0,
          childhood_abuse: 0,
          kind_childhood_abuse: 0,
          kind_childhood_abuse_emotional: 0,
          kind_childhood_abuse_physical: 0,
          kind_childhood_abuse_sexual: 0,
          kind_childhood_abuse_other: 0,
          kind_childhood_abuse_other_txt: '',
          hear_voice: 0,
          diff_violent: 0,
          fam_his_suicide: 0,
          family_member_suicide: '',
          fam_his_violent: 0,
          family_member_violent: '',
          fam_his_hospitalize: 0,
          family_member_hospitalize: '',
          fam_his_abuse: 0,
          family_member_abuse: '',
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'psychiatric_history') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2_1 = (e) => {
    var get_val = validateAge(e.target.value)
    if(get_val != null || get_val != undefined) {
      this.setState({
        [e.target.name]: get_val,
      })
    }
  }

  handleChange3 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var cnt_msg = 0
    var message = ''
    if(this.state.age_first_dep == "") {
      cnt_msg++
      message = '<li>Approximate age of first depressive symptoms that interfered with functioning</li>'
    }
    if(this.state.age_first_manic == "") {
      cnt_msg++
      message = '<li>Approximate age of first manic symptoms that interfered with functioning</li>'
    }

    if(this.state.total_prev_dep == "") {
      cnt_msg++
      message += '<li>Total number of previous depressive episodes in lifetime</li>'
    }
    if(this.state.num_dep_exp_last_year == 0) {
      cnt_msg++
      message += '<li>Number of depressive episodes experienced within the past year</li>'
    }
    if(this.state.pt_lastyear_spent_dep == 0) {
      cnt_msg++
      message += '<li>Percentage of last year spent depressed</li>'
    }

    if(this.state.total_prev_manic == "") {
      cnt_msg++
      message += '<li>Total number of previous manic or hypomanic episodes over lifetime</li>'
    }
    if(this.state.num_manic_exp_last_year == 0) {
      cnt_msg++
      message += '<li>Number of previous manic or hypomanic episodes in the past year</li>'
    }
    if(this.state.pt_lastyear_spent_manic == 0) {
      cnt_msg++
      message += '<li>Percentage of last year spent manic or hypomanic</li>'
    }

    if(this.state.prev_hosp_psyc == 0) {
      cnt_msg++
      message += '<li>Previously hospitalized for psychiatric reasons</li>'
    }
    if(this.state.prev_hosp_psyc == 1 && this.state.number_hospitalize == "") {
      cnt_msg++
      message += '<li>Number of hospitalizations</li>'
    }
    if(this.state.hurt_yourself_other_reason == 0) {
      cnt_msg++
      message += '<li>Have you ever hurt yourself, not to end your life, but for another reason, like to make yourself feel better or get something to happen?</li>'
    }
    if(this.state.prev_suicide == 0) {
      cnt_msg++
      message += '<li>Previous suicide attempts</li>'
    }
    if(this.state.prev_suicide == 1 && this.state.number_suicide == "") {
      cnt_msg++
      message += '<li>How many times have you attempted suicide?</li>'
    }

    if(this.state.childhood_abuse == 0) {
      cnt_msg++
      message += '<li>Childhood abuse</li>'
    }

    if(this.state.hear_voice == 0) {
      cnt_msg++
      message += '<li>When you are not feeling depressed, manic, or high, do you hear voices?</li>'
    }
    if(this.state.diff_violent == 0) {
      cnt_msg++
      message += '<li>Have you ever had difficulties with violent, disruptive, or aggressive behavior?</li>'
    }
    if(this.state.fam_his_suicide == 0) {
      cnt_msg++
      message += '<li>Family history of suicidal behavior</li>'
    } else {
      if(this.state.fam_his_suicide == 1 && this.state.family_member_suicide == "") {
        cnt_msg++
        message += '<li>Which family member/s who is related to suicidal behavior?</li>'
      }
    }
    if(this.state.fam_his_violent == 0) {
      cnt_msg++
      message += '<li>Family history of violent behavior</li>'
    } else {
      if(this.state.fam_his_violent == 1 && this.state.family_member_violent == "") {
        cnt_msg++
        message += '<li>Which family member/s who is related to violent behavior?</li>'
      }
    }
    if(this.state.fam_his_hospitalize == 0) {
      cnt_msg++
      message += '<li>Family history of psychiatric illness/ hospitalization</li>'
    } else {
      if(this.state.fam_his_hospitalize == 1 && this.state.family_member_hospitalize == "") {
        cnt_msg++
        message += '<li>Which family member/s who is related to psychiatric illness/ hospitalization?</li>'
      }
    }
    if(this.state.fam_his_abuse == 0) {
      cnt_msg++
      message += '<li>Family history of substance abuse</li>'
    } else {
      if(this.state.fam_his_abuse == 1 && this.state.family_member_abuse == "") {
        cnt_msg++
        message += '<li>Which family member/s who is related to substance abuse?</li>'
      }
    }
    if(cnt_msg > 0) {
      this.setState({
        message: 'Please answer all questions.', //'<br /><ul>'+message+'</ul>',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_psychiatric_history',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] === 'complete_registration') {
            this.props.onLoadLocation('/member/complete_registration')
            this.props.history.push('/member/complete_registration')
          }
        }
      )
    }
  }

  render() {
    return (
      <div className="psychiatric_history box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Psychiatric History
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={9}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ ReactHtmlParser(this.state.message) }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box2">
            <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns box1">
            <div className="box_border5">
              <form onSubmit={this.handleSubmit}>

                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Approximate age of first depressive symptoms that interfered with functioning:
                  </div>
                  <div className="small-6 medium-2 columns">
                    <TextField label="" type="number" name="age_first_dep"
                    error={this.state.invalid && this.state.age_first_dep == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.age_first_dep}
                    InputProps={{ maxLength: 3 }}  />
                  </div>
                  <div className="small-6 medium-2 columns">years old</div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Approximate age of first manic symptoms that interfered with functioning:
                  </div>
                  <div className="small-6 medium-2 columns">
                    <TextField label="" type="number" name="age_first_manic"
                    error={this.state.invalid && this.state.age_first_manic == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.age_first_manic}
                    InputProps={{ maxLength: 3 }}  />
                  </div>
                  <div className="small-6 medium-2 columns">years old</div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Total number of previous depressive episodes in lifetime:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" type="number" name="total_prev_dep"
                    error={this.state.invalid && this.state.total_prev_dep == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.total_prev_dep}
                    InputProps={{ maxLength: 3 }}  />
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Number of depressive episodes experienced within the past year:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.num_dep_exp_last_year == ''} >
                      <Select value={this.state.num_dep_exp_last_year} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'num_dep_exp_last_year')}>
                          {dep_exp_past.map(each =>
                            <MenuItem key={"num_dep_exp_last_year"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Percentage of last year spent depressed:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.pt_lastyear_spent_dep == ''} >
                      <Select value={this.state.pt_lastyear_spent_dep} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'pt_lastyear_spent_dep')}>
                          {pt_lastyear_spent_dep.map(each =>
                            <MenuItem key={"pt_lastyear_spent_dep"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Total number of previous manic or hypomanic episodes over lifetime:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" type="number" name="total_prev_manic"
                    error={this.state.invalid && this.state.total_prev_manic == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.total_prev_manic}
                    InputProps={{ maxLength: 3 }}  />
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Number of previous manic or hypomanic episodes in the past year:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.num_manic_exp_last_year == ''} >
                      <Select value={this.state.num_manic_exp_last_year} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'num_manic_exp_last_year')}>
                          {dep_exp_past.map(each =>
                            <MenuItem key={"num_manic_exp_last_year"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Percentage of last year spent manic or hypomanic:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.pt_lastyear_spent_manic == ''} >
                      <Select value={this.state.pt_lastyear_spent_manic} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'pt_lastyear_spent_manic')}>
                          {pt_lastyear_spent_dep.map(each =>
                            <MenuItem key={"pt_lastyear_spent_manic"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Previously hospitalized for psychiatric reasons:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.prev_hosp_psyc == ''} >
                      <Select value={this.state.prev_hosp_psyc} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'prev_hosp_psyc')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"prev_hosp_psyc"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.prev_hosp_psyc == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Number of hospitalizations:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" type="number" name="number_hospitalize"
                    error={this.state.invalid && this.state.prev_hosp_psyc == 1 && this.state.number_hospitalize == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.number_hospitalize}
                    InputProps={{ maxLength: 4 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Have you ever hurt yourself, not to end your life, but for another reason, like to make yourself feel better or get something to happen?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.hurt_yourself_other_reason == 0} >
                      <Select value={this.state.hurt_yourself_other_reason} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'hurt_yourself_other_reason')}>
                          {yes_no.map(each =>
                            <MenuItem key={"hurt_yourself_other_reason"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Previous suicide attempts:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.prev_suicide == 0} >
                      <Select value={this.state.prev_suicide} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'prev_suicide')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"prev_suicide"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.prev_suicide == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> How many times have you attempted suicide?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" type="number" name="number_suicide"
                    error={this.state.invalid && this.state.prev_suicide == 1 && this.state.number_suicide == ''}
                    onChange={(e) => this.handleChange2_1(e)}
                    variant="outlined" value={this.state.number_suicide}
                    InputProps={{ maxLength: 4 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Childhood abuse:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.childhood_abuse == 0} >
                      <Select value={this.state.childhood_abuse} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'childhood_abuse')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"childhood_abuse"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.childhood_abuse == 1 &&
                <div className="row top_space1">
                  <div className="small-12 columns bold">
                    <ArrowRightIcon /> Kinds of childhood abuse (check all that apply):
                  </div>
                  <div className="small-12 columns">
                    <div className="row">
                      <div className="medium-1 columns show-for-medium">&nbsp;</div>
                      <div className="small-12 medium-2 columns">
                        <input type="checkbox" value={kinds_child_abuse[0].value} name="kind_childhood_abuse_emotional"
                          onChange={(e) => this.handleChange7(e)} />
                          {kinds_child_abuse[0].label}
                      </div>
                      <div className="small-12 medium-2 columns">
                        <input type="checkbox" value={kinds_child_abuse[1].value} name="kind_childhood_abuse_physical"
                          onChange={(e) => this.handleChange7(e)} />
                          {kinds_child_abuse[1].label}
                      </div>
                      <div className="small-12 medium-2 columns">
                        <input type="checkbox" value={kinds_child_abuse[2].value} name="kind_childhood_abuse_sexual"
                          onChange={(e) => this.handleChange7(e)} />
                          {kinds_child_abuse[2].label}
                      </div>
                      <div className="small-12 medium-2 columns">
                        <input type="checkbox" value={kinds_child_abuse[3].value} name="kind_childhood_abuse_other"
                          onChange={(e) => this.handleChange7(e)} />
                          {kinds_child_abuse[3].label}
                      </div>
                      <div className="medium-3 columns show-for-medium">&nbsp;</div>
                    </div>
                  </div>
                </div>}
                { this.state.childhood_abuse == 1 && this.state.kind_childhood_abuse_other == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Type of abuse "other" (specify):
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" name="kind_childhood_abuse_other_txt" onChange={(e) => this.handleChange2(e)}
                    variant="outlined" InputProps={{ maxLength: 200 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    When you are not feeling depressed, manic, or high, do you hear voices?
                    Do you think people can read your mind, or put thoughts or messages in your head?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.hear_voice == 0} >
                      <Select value={this.state.hear_voice} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'hear_voice')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"hear_voice"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Have you ever had difficulties with violent, disruptive, or aggressive behavior?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.diff_violent == 0} >
                      <Select value={this.state.diff_violent} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'diff_violent')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"diff_violent"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Family history of suicidal behavior:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.fam_his_suicide == 0} >
                      <Select value={this.state.fam_his_suicide} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'fam_his_suicide')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"fam_his_suicide"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.fam_his_suicide == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Which family member/s?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" name="family_member_suicide"
                    error={this.state.invalid && this.state.fam_his_suicide == 1 &&this.state.family_member_suicide == ''}
                    onChange={(e) => this.handleChange2(e)}
                    variant="outlined" InputProps={{ maxLength: 200 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Family history of violent behavior:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.fam_his_violent == 0} >
                      <Select value={this.state.fam_his_violent} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'fam_his_violent')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"fam_his_violent"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.fam_his_violent == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Which family member/s?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" name="family_member_violent"
                    error={this.state.invalid && this.state.fam_his_violent == 1 && this.state.family_member_violent == ''}
                    onChange={(e) => this.handleChange2(e)}
                    variant="outlined" InputProps={{ maxLength: 200 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Family history of psychiatric illness/ hospitalization:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.fam_his_hospitalize == 0} >
                      <Select value={this.state.fam_his_hospitalize} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'fam_his_hospitalize')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"fam_his_hospitalize"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.fam_his_hospitalize == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Which family member/s?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" name="family_member_hospitalize"
                    error={this.state.invalid && this.state.fam_his_hospitalize == 1 && this.state.family_member_hospitalize == 0}
                    onChange={(e) => this.handleChange2(e)}
                    variant="outlined" InputProps={{ maxLength: 200 }}  />
                  </div>
                </div>}

                <div className="sep_line1">&nbsp;</div>
                <div className="row">
                  <div className="small-12 medium-8 columns bold">
                    Family history of substance abuse:
                  </div>
                  <div className="small-12 medium-4 columns">
                    <FormControl variant="outlined"
                    error={this.state.invalid && this.state.fam_his_abuse == 0} >
                      <Select value={this.state.fam_his_abuse} variant="outlined"
                        onChange={(e) => this.handleChange3(e, 'fam_his_abuse')}>
                          {yes_no_unknown.map(each =>
                            <MenuItem key={"fam_his_abuse"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                { this.state.fam_his_abuse == 1 &&
                <div className="row top_space1">
                  <div className="small-12 medium-8 columns bold">
                    <ArrowRightIcon /> Which family member/s?
                  </div>
                  <div className="small-12 medium-4 columns">
                    <TextField label="" name="family_member_abuse"
                    error={this.state.invalid && this.state.fam_his_abuse == 1 && this.state.fam_his_abuse == 0}
                    onChange={(e) => this.handleChange2(e)}
                    variant="outlined" InputProps={{ maxLength: 200 }}  />
                  </div>
                </div>}


            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
        {this.state.status_submit &&
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        {!this.state.status_submit &&
          <Button variant="contained" disabled
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PsychiatricHistory)
