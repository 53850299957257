import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import '../../../../styles/assets/css/pages/graph.css'
import { VictoryChart, VictoryLine, VictoryTheme, VictoryAxis,
        VictoryLabel, VictoryScatter, VictoryGroup } from 'victory'

class StepTrackingGraph extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      num_month: 0,
      tracker_month: [],
      max_y: 0,
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  componentWillReceiveProps(nextProps) {
    this.getDataList()
  }

  getDataList = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_step_tracking_history2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.pid,
        ip: ip,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.calculateEachMonth(result['data'])
      }
    })
  }

  calculateEachMonth(arr) {
    var max_y = 0
    var last_timepoint = ((arr[arr.length-1].st - 1) / 15) + 1 //arr[arr.length-1].timepoint
    var buff = []
    for(var i=0; i<last_timepoint; i++) {
      buff[i] = 0
    }
    var gen = []
    var tp = 0
    console.log(arr)

    arr.map(each => {
      //console.log(each)
      if(each.cal_score > max_y) {
        max_y = each.cal_score
      }
      tp = ((each.st - 1)/15)
      buff[tp] += each.cal_score
      last_timepoint = tp
    })
    //console.log(buff)
    buff.map((each, index) => {
      gen.push({
        x: last_timepoint-index+1, //last_timepoint-index, // index+1,
        y: each
      })
    })
    this.setState({
      num_month: arr.length,
      tracker_month: gen,
      max_y: max_y,
    })
  }

  genArr1(start, num) {
    var a = []
    var d1 = new Date()
    var d2 = new Date()
    for(var i=start; i<num; i++) {
      //a[i] = (((num-i)*15)-14)+"-"+(((num-i)*15)+15)
      d1 = new Date()
      d1.setDate(d1.getDate() - (((num-i)*15)-14))
      d2 = new Date()
      d2.setDate(d2.getDate() - (((num-i)*15)+15))
      //console.log(i+': '+(((num-i)*15)-14)+ ' , '+(((num-i)*15)+15))
      a[i] = (d1.getMonth()+1)+"/"+d1.getDate()+"/"+d1.getFullYear().toString().substr(-2)
    }
    d1 = new Date()
    a[num-1] = (d1.getMonth()+1)+"/"+d1.getDate()+"/"+d1.getFullYear().toString().substr(-2)
    //console.log(a)
    return a
  }

  genArr2(max_y) {
    //console.log('max y: '+this.state.max_y)
    max_y = this.state.max_y
    var a = [0, 10, 20, 30, 40, 50, 60]
    if(max_y > 60) {
      var buff = Math.ceil(max_y/10)
      for(var i=0; i<=buff; i=i+1) { a[i] = i*10 }
    }
    return a
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    //console.log(this.state.tracker_month)
    return (
      <div className="small-12 columns box_space_top1 pt_graph">
        <div className="row">
          <div className="small-12 columns">
          <VictoryChart theme={VictoryTheme.material}
            padding={{ top: 5, bottom: 25, left: 30, right: 25 }}
            domainPadding={5} height={90} >
            <VictoryAxis crossAxis
              tickValues={this.genArr1(0, this.state.num_month)}
              style={{
                axis: {stroke: "#cccccc", fontSize: 5},
                axisLabel: {fontSize: 5, padding: 5},
                ticks: {stroke: "#cccccc", size: 1},
                tickLabels: {fontSize: 5, padding: 10, angle: 80},
              }} />
            <VictoryAxis dependentAxis crossAxis
              tickValues={this.genArr2(0, this.state.num_month)}
                style={{
                  axis: {stroke: "#cccccc", fontSize: 5},
                  axisLabel: {fontSize: 5, padding: 5},
                  ticks: {stroke: "#cccccc", size: 1},
                  tickLabels: {fontSize: 5, padding: 5}
                }}
              />
          <VictoryGroup data={this.state.tracker_month} color="#ffac29" >
            <VictoryLine
              style={{
                data: { stroke: "#ffac29", strokeWidth: 0.5 },
                parent: { border: "1px solid #cccccc"},
                labels: { fontSize: 5}
              }}
              data={this.state.tracker_month}
              labels={({ datum }) => datum.y}
              labelComponent={
                <VictoryLabel dy={-2} textAnchor="end"/>
              }
            />
            <VictoryScatter size={1.15} symbol="dot" />
          </VictoryGroup>
          </VictoryChart>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StepTrackingGraph)
