import React, { Component } from 'react'
import { withRouter } from "react-router-dom"

class Sorry extends Component {
  render() {
    var txt = ''
    var txt2 = ''
    if(this.props.match.params.type == 1) {
      txt = 'Sorry'
      txt2 = 'You are ineligible for FITT-BD.'
    } else if(this.props.match.params.type == 2) {
      txt = 'Sorry'
      txt2 = 'Please complete initial intake appointment.'
    }
    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            {txt}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
            {txt2}
          </div>
        </div>
      </div>
    );
  }
}

export default (withRouter)(Sorry)
