import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'
import Cookies from 'js-cookie'

import WarningIcon from '@material-ui/icons/Warning'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no } from '../../variables/treatment_plan.js'

class NewTreatmentPlan extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_open: false,
          contract_id: 0,
          treatment_goal_list: [],
          treatment_goal_id: 0,
          treatment_goal_other: '',
          treatment_target: [{
            id: 1,
            target: 0,
            notes: '',
            target_other: '',
            modality: 0,
            modality_other: '',
            intervention: '',
            intervention_format: 0,
            intervention_format_other: '',
            modailty_date: new Date().toISOString().substring(0, 10),
          }],
          cookieTimeout: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    var getData = {
      treatment_goal_id: 0,
      treatment_target: [{
        id: 1,
        target: 0,
        notes: '',
        target_other: '',
        modality: 0,
        modality_other: '',
        intervention: '',
        intervention_format: 0,
        intervention_format_other: '',
        modailty_date: new Date().toISOString().substring(0, 10),
      }],
    }
    // Check cookies //
    if(Cookies.get('treatment_goal_id')) {
      getData.treatment_goal_id = JSON.parse(Cookies.get('treatment_goal_id'))
    }
    if(Cookies.get('treatment_target')) {
      getData.treatment_target = JSON.parse(Cookies.get('treatment_target'))
    }
    // Close check cookies //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_goal',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      this.setState({
        treatment_goal_list: this.genTreatmentGoal(result['data']),
        contract_id: result['data'][0].contract_id,
        treatment_goal_id: getData.treatment_goal_id,
        treatment_target: getData.treatment_target,
      })
    })
  }

  genTreatmentGoal = (treatment_goal_list) => {
    var cnt = 0
    var new_arr = []
    treatment_goal_list.map( each => {
      if(each.completion == undefined) {
        cnt++
        new_arr.push(each)
      }
    })
    return new_arr
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })

    Cookies.set(name, JSON.stringify(e.target.value), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })

    Cookies.set(name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }

  handleChange4 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    Cookies.set(name, JSON.stringify(getStateVal), {
      expires: this.state.cookieTimeout,
    })
  }

  appendInputSet2 = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID,
          target: 0,
          notes: '',
          target_other: '',
          modality: 0,
          modality_other: '',
          intervention: '',
          intervention_format: 0,
          intervention_format_other: '',
          modailty_date: new Date().toISOString().substring(0, 10),
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  removeTpCk = () => {
    Cookies.remove('treatment_goal_id')
    Cookies.remove('treatment_target')
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var chk = true
    if(this.state.treatment_goal_id == 0 ||
      (this.state.treatment_goal_id == -1 && this.state.treatment_goal_other === '')) {
      chk = false
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    } else {
      this.state.treatment_target.map(val => {
        console.log(val)
        if(val.modality == 0 || val.intervention === '' ||
           val.intervention_format == 0 ||
          (val.modality == 21 && val.modality_other === '') ||
          (val.intervention_format == 10 && val.intervention_format_other === '')) {
            chk = false
            this.setState({
              message: 'Please fill all information',
            })
            window.scrollTo(0, 0)
        }
      })
    }
    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/new_treatment_plan',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.pid) {
          this.removeTpCk()
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/3/2', 'Treatment plan added successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/3/2')
        }
      })
    }
  }

  render() {
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Treatment Plan
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns box2">
                        <div className="small-12 columns bold">Long-Term Treatment Goals (from Patient Contract)</div>
                        <div className="small-12 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.treatment_goal_id} variant="outlined" label=""
                              onChange={(e) => this.handleChange2(e, 'treatment_goal_id')}>
                              {this.state.treatment_goal_list.length > 0 && this.state.treatment_goal_list.map( each =>
                                <MenuItem key={'tg'+each.id} value={each.id}>{each.val}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    {this.state.treatment_target.map((val, index) =>
                      <div className="row no_margin_bottom" key={'ttm'+index}>
                        <div className="small-12 columns ">
                        <div className="box1">
                        <div className="small-12 columns bold space_top_1">To Do List item</div>
                        <div className="small-12 columns">
                        <TextField variant="outlined" type="text" className="space_top_1"
                          onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'intervention')}
                          value={this.state.treatment_target[index].intervention}
                          InputProps={{ inputProps: { maxLength: 240, placeholder: 'Write in ex. 8 weeks mindfulness course' }}} />
                        </div>
                          <div className="small-12 columns bold space_top_1">Treatment Modality</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.treatment_target[index].modality}
                                onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'modality')}
                                variant="outlined" label="" className="width_auto">
                                {treatment_modality.map( each =>
                                  <MenuItem key={'tm'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.treatment_target[index].modality == 21 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'modality_other')}
                              value={this.state.treatment_target[index].modality_other}
                              InputProps={{ inputProps: { maxLength: 95, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">To Do List item Format</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.treatment_target[index].intervention_format}
                                onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'intervention_format')}
                                variant="outlined" label="" className="width_auto">
                                {intervention_format.map( each =>
                                  <MenuItem key={'if'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.treatment_target[index].intervention_format == 10 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange3(e, 'treatment_target', index, 'intervention_format_other')}
                              value={this.state.treatment_target[index].intervention_format_other}
                              InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">Date To Do List item assigned</div>
                          <div className="small-12 columns float_left width_auto">
                            <TextField variant="outlined" type="date" format={'MM/DD/YYYY'}
                            onChange={(e) => this.handleChange4(e, 'treatment_target', index, 'modailty_date')}
                            value={this.transformDate(this.state.treatment_target[index].modailty_date)} />
                          </div>
                        </div>
                        </div>
                      </div>
                    )}


                    <div className="row">
                      <div className="small-12 columns align_right">
                      <button type="button" className="addInput1 width_auto_right"
                        onClick={ () => this.appendInputSet2('treatment_target', this.state.treatment_target) }>
                         Add To Do List item ?
                      </button>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewTreatmentPlan)
