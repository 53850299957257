import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { covid_questions, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10,
         q11 } from '../variables/assessments/covid.js'

import { calculateTimepoint1 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

class Covid extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1,
          message: '',
          uid: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'pw18',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
          q6: -1,
          q7: -1,
          q8: -1,
          q9: -1,
          q10: -1,
          q10_num: '',
          q11: -1,
          q11_num: '',
          q12: '',
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    if(type === 'tm') {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/chk_each_assm',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: type,
          timepoint: timepoint,
          assm: 'patients_assessment_covid',
         }
      }).then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(timepoint > calTimepoint.current_trimonth) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(timepoint == calTimepoint.current_trimonth) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].assm_status == 1) {
              // Completed //
              this.getCompletedData(2)
            } else {
              if(result['data'].status == 1 && result['data'].step !== 'covid') {
                this.props.history.push('/member/assessment/'+result['data'].step+'/'+type+'/'+timepoint)
              }
            }
          } else {
            // Insert new row //
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/add_new_assm_process_wo_overview',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
                next_step: 'covid',
               }
            })
            .then((result) => { })
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3)
        }
      })
    } else {
      this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
      this.props.history.push('/member/assessment_list')
    }
  }

  getCompletedData = (status) => {
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: type,
        timepoint: timepoint,
        frm: 'covid',
       }
    }).then((result) => {
      this.setState({
        status: status,
        q1: result['data'].q1,
        q2: result['data'].q2,
        q3: result['data'].q3,
        q4: result['data'].q4,
        q5: result['data'].q5,
        q6: result['data'].q6,
        q7: result['data'].q7,
        q8: result['data'].q8,
        q9: result['data'].q9,
        q10: result['data'].q10,
        q10_num: result['data'].q10_num,
        q11: result['data'].q11,
        q11_num: result['data'].q11_num,
        q12: result['data'].q12,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    if(this.state.status === 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 || this.state.q6 === -1 ||
        this.state.q7 === -1 || this.state.q8 === -1 || this.state.q9 === -1 ||
        this.state.q10_num === '' || this.state.q11_num === '' || this.state.q12 === '') {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_covid',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            timepoint: timepoint,
            type: type,
          }
        })
        .then((result) => {
          if(result['data'] === this.props.authReducer.uid) {
            this.props.onLoadLocation('/member/assessment/complete_assessments/'+type+'/'+timepoint)
            this.props.history.push('/member/assessment/complete_assessments/'+type+'/'+timepoint)
          }
        })
      }
    } else {
      this.props.onLoadLocation('/member/assessment/pw18/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/pw18/'+type+'/'+timepoint)
    }
  }

  render() {
    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Coronavirus Impact Scale
          </div>
          <StepperAssm activeStep={0} assm={'COVID'} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box1">
            <div className="row">
              <div className="small-12 columns bold">
                Rate how much the Coronavirus pandemic has changed your life in each of the following ways.
              </div>
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[0].value}. {covid_questions[0].label}
                </div>
                {q1.map(each =>
                  <div className="small-12 columns" key={'q1'+each.value}>
                    <input type="radio" name="q1" value={each.value}
                    checked={this.state["q1"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[1].value}. {covid_questions[1].label}
                </div>
                {q2.map(each =>
                  <div className="small-12 columns" key={'q2'+each.value}>
                    <input type="radio" name="q2" value={each.value}
                    checked={this.state["q2"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[2].value}. {covid_questions[2].label}
                </div>
                {q3.map(each =>
                  <div className="small-12 columns" key={'q3'+each.value}>
                    <input type="radio" name="q3" value={each.value}
                    checked={this.state["q3"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[3].value}. {covid_questions[3].label}
                </div>
                {q4.map(each =>
                  <div className="small-12 columns" key={'q4'+each.value}>
                    <input type="radio" name="q4" value={each.value}
                    checked={this.state["q4"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[4].value}. {covid_questions[4].label}
                </div>
                {q5.map(each =>
                  <div className="small-12 columns" key={'q5'+each.value}>
                    <input type="radio" name="q5" value={each.value}
                    checked={this.state["q5"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[5].value}. {covid_questions[5].label}
                </div>
                {q6.map(each =>
                  <div className="small-12 columns" key={'q6'+each.value}>
                    <input type="radio" name="q6" value={each.value}
                    checked={this.state["q6"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[6].value}. {covid_questions[6].label}
                </div>
                {q7.map(each =>
                  <div className="small-12 columns" key={'q7'+each.value}>
                    <input type="radio" name="q7" value={each.value}
                    checked={this.state["q7"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[7].value}. {covid_questions[7].label}
                </div>
                {q8.map(each =>
                  <div className="small-12 columns" key={'q8'+each.value}>
                    <input type="radio" name="q8" value={each.value}
                    checked={this.state["q8"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[8].value}. {covid_questions[8].label}
                </div>
                {q9.map(each =>
                  <div className="small-12 columns" key={'q9'+each.value}>
                    <input type="radio" name="q9" value={each.value}
                    checked={this.state["q9"] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/> {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[9].value}. {covid_questions[9].label}
                  <TextField label="" className="num_members" type="number" name="q10_num"
                  value={this.state.q10_num}
                  disabled={this.state.status > 1 && 'disabled'}
                  onChange={(e) => this.handleChange(e)} variant="outlined"
                  InputProps={{ maxLength: 2 }}  />
                </div>
                { this.state.q10_num > 0 &&
                <div>
                  <div className="small-12 columns">
                    <ArrowRightIcon /> Rate the symptoms of the person who was most sick:
                  </div>
                  {q10.map(each =>
                    <div className="small-12 columns indent_left" key={'q10'+each.value}>
                      <input type="radio" name="q10" value={each.value}
                      checked={this.state["q10"] == each.value && 'checked'}
                      disabled={this.state.status > 1 && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/> {each.label}
                    </div>
                  )}
                </div>}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[10].value}. {covid_questions[10].label}
                  <TextField label="" className="num_members" type="number" name="q11_num"
                  value={this.state.q11_num}
                  disabled={this.state.status > 1 && 'disabled'}
                  onChange={(e) => this.handleChange(e)} variant="outlined"
                  InputProps={{ maxLength: 2 }}  />
                </div>
                { this.state.q11_num > 0 &&
                <div>
                  <div className="small-12 columns">
                    <ArrowRightIcon /> Rate the symptoms of the person who was most sick:
                  </div>
                  {q11.map(each =>
                    <div className="small-12 columns indent_left" key={'q11'+each.value}>
                      <input type="radio" name="q11" value={each.value}
                      checked={this.state["q11"] == each.value && 'checked'}
                      disabled={this.state.status > 1 && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/> {each.label}
                    </div>
                  )}
                </div>}
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>

                <div className="small-12 columns bold3 margin-bottom1">
                  {covid_questions[11].value}. {covid_questions[11].label}
                </div>
                <div className="small-12 columns covid_q12">
                  <TextField label="" name="q12"
                  value={this.state.q12}
                  disabled={this.state.status > 1 && 'disabled'}
                  onChange={(e) => this.handleChange(e)} variant="outlined"
                  InputProps={{ maxLength: 250 }}  />
                </div>

              </div>

        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Covid)
