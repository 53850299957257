import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, NavLink } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ListSubheader } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../../patient/patient_head3"
import { contact, type, reason, outcome, yes_no } from '../variables/contact_logs.js'
import { completion, adherence_reason } from '../variables/treatment_plan.js'

//import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser from 'react-html-parser'

class PatientLogsOutcome extends Component {
  constructor() {
      super();
      this.quill = require('react-quill')
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          date_log: new Date().toISOString().substring(0, 10),
          contact: 0,
          type: 0,
          reason: 0,
          reason_other: '',
          reason_list: [],
          outcome: [{ id:1, outcome: 0, outcome_txt: '' }],
          outcome_list: [],
          comments: '',
          dataList: [],
          update_treatment_plan: 0,
          getTreatmentPlan: [],
          update_mrtf: 0,
          getMRTF: [],
          editMode: false
      }
  }

  componentDidMount() {
    this.getData()
  }

  get_outcome_and_reasons = (editMode = false) => {
    axios({
      method: 'post',
      url: connectNode + 'functions/patient/get_dd_variable2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { v_type: 8, }
    })
    .then((result) => {
      var get_reasons = result['data']
      var get_outcome = []
      axios({
        method: 'post',
        url: connectNode + 'functions/patient/get_dd_variable',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { v_type: 9, }
      })
      .then((result) => {
        get_outcome = result['data']
        //console.log(result['data'])
        this.setState({
          reason_list: get_reasons,
          outcome_list: get_outcome,
        });
        if (editMode === true) this.setEditMode();
      }
    )})
  }

  getData = () => {
    if(this.props.match.params.ct_id > 0) {
      axios({
        method: 'post',
        url: connectNode + 'admin/contact_log/get_contact_log',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.match.params.pid,
          appt_id: this.props.match.params.appt_id,
          ct_id: this.props.match.params.ct_id,
        }
      })
      .then((result) => {
        if(result['data'].length > 0) {
          this.setState({ dataList: result['data'], })
        }
      })
    } else this.get_outcome_and_reasons(false);
  }

  setEditMode = () => {
    const data = this.state.dataList[0];
    const outcomeArray = data.outcome.split(",");
    const outcomeList = this.state.outcome_list;
    this.setState({
      ...data, editMode: true, date_log: data.date_contact, outcome: outcomeArray.map((x,i) => {
        const listValue = outcomeList.find(y => y.name == x);
        return {id: i + 1, outcome: listValue.vid, outcome_txt: ''}
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: escapeHtml(e.target.value),
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange4 = (contents) => {
    this.setState({
      comments: contents,
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }

  appendOutcome = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, outcome: 0, outcome_txt: '',
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if(this.state.contact == 0 || this.state.type == 0 ||
      this.state.reason == 0 || this.state.outcome[0].outcome == 0) {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      var chk_outcome = true
      this.state.outcome.map(each => {
        if(each.outcome == 0) {
          chk_outcome = false
        }
      })
      if(!chk_outcome) {
        this.setState({
          message: 'Please select outcome',
        })
        window.scrollTo(0, 0)
      } else {
        this.setState({
          status_submit: false,
          message: '',
        })
        var pid = this.props.match.params.pid
        var appt_id = 0
        if(this.props.match.params.appt_id > 0) {
          appt_id = this.props.match.params.appt_id
        }
        axios({
          method: 'post',
          url: connectNode + 'admin/contact_log/add_contact_log',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
            appt_id: appt_id,
            ct_id: this.props.match.params.ct_id
          }
        })
        .then((result) => {
          //console.log(result['data'])
          if(result['data'] > 0) {
            setTimeout(() => {
              this.props.onLoadLocation('/admin/patient_chart/'+pid, 'Saved successfully!')
              //window.location.href = '/admin/patient_chart/'+pid+'/1/2'
              this.props.history.push('/admin/patient_chart/'+pid+'/2/1')
            }, 900)
          }
        })
      }
    }
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const ReactQuill = this.quill
    return (
      <div className="admin_log_outcome box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Contact Logs
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>
            }
            <div className="box_border5">
              <form id="log_outcome" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                {this.state.dataList.length > 0 && !this.state.editMode &&
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row" style={{display: 'flex'}}>
                      <div className="small-12 columns header1">Contact</div>
                      <div className="small-12 medium-9 columns bold button_header2">
                        {this.props.authReducer.uid == this.state.dataList[0].cid &&
                        <button type="button" onClick={() => this.get_outcome_and_reasons(true)}
                        className="edit_btn" >
                          Edit
                        </button>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Date:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {this.state.dataList[0].conv_date}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Initiated by:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {this.findLabelByValue(contact, this.state.dataList[0].contact)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Format:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {this.findLabelByValue(type, this.state.dataList[0].type)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Reason:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {this.state.dataList[0].reason_name}
                      {this.state.dataList[0].reason==301 && '['+this.state.dataList[0].reason_other_txt+']'}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Outcome:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {this.state.dataList[0].outcome}
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_text1">Comments:</div>
                      <div className="small-12 medium-8 columns no_padding_left_float_left">
                      {ReactHtmlParser(this.state.dataList[0].comments)}
                      </div>
                    </div>
                  </div>}
                {(this.state.dataList.length == 0 || this.state.editMode) &&
                <div>
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns header1">Contact Detail</div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Date:</div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <TextField id="date_log" label="" onChange={this.handleChange}
                        variant="outlined" type="date" defaultValue={this.state.date_log}
                        InputProps={{ inputProps: { name: "date_log" }}} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Initiated by:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.contact} variant="outlined" name="contact"
                            label="" onChange={(e) => this.handleChange(e, 'contact')}>
                            {contact.map(each =>
                              each.value != 2 &&
                              <MenuItem key={'ct'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Format:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                      <FormControl variant="outlined">
                        <Select value={this.state.type} variant="outlined" name="type"
                          label="" onChange={(e) => this.handleChange(e, 'type')}>
                          {type.map(each =>
                            <MenuItem key={'tp'+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Reason:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                      <FormControl variant="outlined">
                        <Select value={this.state.reason} variant="outlined" name="reason"
                          label="" onChange={(e) => this.handleChange(e, 'reason')}>
                          {this.state.reason_list.map((each, index) => {
                            if (each.vid == 100 || each.vid == 200)
                              return(<ListSubheader key={'sh'+index}>{each.name}</ListSubheader>)
                            if (each.vid != 100 && each.vid != 200)
                              return(<MenuItem key={'rs'+each.vid} value={each.vid}>{each.name}</MenuItem>)
                          })}
                        </Select>
                      </FormControl>
                      {/* <MenuGroup label="optgroup label"><option aria-label="None" value="" /><option key={'rs'+each.vid} value={each.vid}>{each.name}</option> */}
                      </div>
                    </div>
                    {(this.state.reason == 301 || this.state.reason == 121) &&
                      <div className="row reason_list">
                        <div className="medium-4 columns">&nbsp;</div>
                        <div className="small-12 medium-6 columns no_padding_left_float_left">
                          <TextField label="Please specify" onChange={this.handleChange} name="reason_other"
                            variant="outlined" type="text" defaultValue={this.state.reason_other}
                            InputProps={{ inputProps: { name: "reason_other", maxLength: 250 }}} />
                        </div>
                      </div>}
                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Outcome:</div>
                      <div className="small-12 medium-7 columns no_padding_left_float_left">
                      {this.state.outcome.map((val, index) =>
                      <div className="txt_todo" key={'oc'+index}>
                        <div className="small-12 columns no_padding_left_float_left">
                        <FormControl variant="outlined" key={"oc"+index} className="bottom_space_outcome_1">
                          <Select value={this.state.outcome[index].outcome} variant="outlined"
                            name={"outcome["+index+"]"} label=""
                            onChange={(e) => this.handleChange5(e, 'outcome', index, 'outcome')} >
                            {this.state.outcome_list.map((each, index) => {
                              if (each.vid == 100 || each.vid == 200)
                                return(<ListSubheader key={'sh2'+index}>{each.name}</ListSubheader>)
                              if (each.vid != 100 && each.vid != 200)
                                return(<MenuItem key={'oc'+index+each.vid} value={each.vid}>{each.name}</MenuItem>)
                            })}
                          </Select>
                        </FormControl>
                        {val.id === 1 &&
                          <AddBoxIcon className="add_icon"
                            onClick={ () => this.appendOutcome('outcome', this.state.outcome) }/>
                        }
                        {val.id > 1 && <CancelIcon className="remove_icon" onClick={ () => this.removeInputSet1('outcome', val.id, this.state.outcome) } />}
                        </div>
                        {this.state.outcome[index].outcome == 301 &&
                          <div className="small-12 columns no_padding_left_float_left reason_list">
                            <TextField label="Please specify" onChange={(e) => this.handleChange3(e, 'outcome', index, 'outcome_txt')}
                              variant="outlined" value={this.state.outcome[index].outcome_txt}
                              InputProps={{ inputProps: { name: "outcome_txt", maxLength: 250 }}} />
                          </div>}
                      </div>
                      )}
                      </div>
                      <div className="small-12 medium-1 columns no_padding_left_float_left">

                      </div>
                    </div>

                    <div className="row">
                      <div className="small-12 medium-4 columns bold_txt3">Comments:</div>
                      <div className="small-12 medium-6 columns no_padding_left_float_left">
                      <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.comments}
                        onChange={this.handleChange4}
                        style={{height: '200px'}} />
                      </div>
                    </div>
                  </div>
                </div>}
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                  {this.state.dataList.length > 0 && !this.state.editMode &&
                    <a onClick={() => this.props.history.goBack()} className="go_back">Go Back</a>
                  }
                  {(this.state.dataList.length == 0 || this.state.editMode) &&
                    <span>
                    {this.state.status_submit &&
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    {!this.state.status_submit &&
                    <Button variant="contained" disabled
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    </span>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientLogsOutcome)
