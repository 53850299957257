import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/stepper1.css'
import '../../../styles/assets/css/pages/form7.css'
import { loadLocation } from '../../../actions/location'
import { registration_steps } from '../variables/registration.js'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

//import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

class OverviewRegistration extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'] !== 'overview_registration') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      status_submit: false,
      message: '',
    })
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/update_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        end_step: 'overview_end',
        start_step: 'clinic_policies_start',
        next_step: 'clinic_policies',
      }
    })
    .then((result) => {
        if(result['data'] === 'clinic_policies') {
          this.props.onLoadLocation('/member/clinic_policies')
          this.props.history.push('/member/clinic_policies')
        }

      }
    )
  }

  render() {

    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            New Patient Registration Overview
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={0}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg5">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
              <p className="txt1">Welcome to the FITT-BD Clinic!</p>
              <p>Now that you've created an online account, we'd like you to go over information about the clinic, sign some documents that will allow us to treat you, and give us information about yourself and your medical  history. These should take you about an hour to complete. You can work on these documents over the next seven days.</p>
              <p>If you finish several of the registration documents and want to take a break before doing the rest, you may save your information by clicking "submit" before you log out. However, you must complete each document or questionnaire in its entirety at one sitting. If you do only part of one, your information will not be saved and you will have to start from the beginning of that document the next time you log in. </p>
              <p>Before you start, make sure you have the names, addresses and phone numbers of any therapist and/or prescribing physician you've seen in the past year.
                Examples of therapists are psychiatrists, psychologists, clinical social workers or counselors, and psychiatric nurse practitioners. Prescribers could be primary care physicians, psychiatrists, psychopharmacologists, or nurse practitioners.</p>
              <p>We can't set up your first appointment until you've completed these registration documents. So please do your best to finish them within the next seven days. </p>
              <p>Please note: if you stop working on a section, you will be automatically logged out after 30 minutes of inactivity.</p>
              <p>Thank you!</p>
          </div>
          <div className="small-12 columns align_right">
          {this.state.status_submit &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Next
            </Button>}
          {!this.state.status_submit &&
            <Button variant="contained" disabled
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Next
            </Button>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('overview STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(OverviewRegistration)
