import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml, validateMRN } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form6.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import SignatureCanvas from 'react-signature-canvas'
import validator from 'validator'
import { send_by, send_to_purpose, information_to_be_release, give_permission } from '../variables/roi.js'

class Roi extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          invalid: false,
          message: '',
          uid: 0,
          patient_name: '',
          patient_mrn: '',
          address_street: '',
          address_apt: '',
          address_city: '',
          address_state: 'MA',
          address_zip: '',
          contact_day: '',
          contact_evening: '',
          from_name: '',
          from_address: '',
          from_tel: '',
          from_fax: '',
          send_by: 0,
          send_by_email: '',
          send_from: [{ id:1, name: '', address: '', tel: '', fax: '', send_email: 1, send_fax: 1, send_by_email: '' }],
          send_to: [{ id:1, name: 'FITT-BD Clinic', address: '50 Staniford Street, Suite 580, Boston, MA 02114', tel: '617-726-6928', fax: '617-726-6768', purpose: 1, purpose_other: '', email: 'FITT-BD@mgh.harvard.edu' }],
          //information_to_be_release: [],
          itbr_1: 1,
          itbr_2: 1,
          itbr_3: 1,
          itbr_4: 1,
          itbr_5: 0,
          itbr_6: 0,
          itbr_7: 0,
          itbr_8: 0,
          itbr_9: 0,
          itbr_10: 0,
          itbr_11: 1,
          itbr_1_txt: 'Past 12 months',
          itbr_2_txt: 'Past 12 months',
          itbr_3_txt: 'Past 12 months',
          itbr_4_txt: 'Past 12 months',
          itbr_5_txt: '',
          itbr_6_txt: '',
          itbr_7_txt: '',
          itbr_8_txt: '',
          itbr_9_txt: '',
          itbr_10_txt: '',
          itbr_11_txt: 'Two-way communuication',
          permission_1: 0,
          permission_hiv_date: '',
          permission_2: 0,
          permission_genetic_result: '',
          permission_3: 1,
          permission_4: 0,
          permission_others_list: '',
          permission_5: 1,
          permission_6: 1,
          permission_7: 0,
          permission_8: 0,
          patient_signature: '',
          patient_print_name: '',
          patient_date: new Date().toISOString().substring(0, 10),
      }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'roi') {
          this.props.history.push('/member/'+result['data'].reg_step)
        } else {
          this.setState({
            patient_print_name: result['data'].fullname,
            patient_name: result['data'].fullname,
          })
        }
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange5_checkbox = (e, name, id, field) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    var getStateVal = this.state[name]
    getStateVal[id][field] = val
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange6 = (e, name, field) => {
    var index = 0
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      field.push(+e.target.value)

    } else {
      // or remove the value from the unchecked checkbox from the array
      index = field.indexOf(+e.target.value)
      field.splice(index, 1)
    }
    this.setState({
      [name]: field
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    // signature //
    var signature = this.refs.mySignature //.getCanvas()
    var chk = 1
    var txt_message = ''
    if(this.state.patient_name === '' || this.state.address_street === ''
      || this.state.address_city === '' || this.state.address_state === ''
      || this.state.address_zip === '' || this.state.contact_day === '') {
      chk = 2
      txt_message = 'Please fill in all required information.'
    } else if(!validator.isNumeric(this.state.address_zip)) {
      chk = 2
      txt_message = 'Please correct your zipcode.'
    } else if(!validator.isMobilePhone(this.state.contact_day)) {
      chk = 2
      txt_message = 'Please correct your contact number.'
    } else if(this.state.contact_evening !== '' &&
              !validator.isMobilePhone(this.state.contact_evening)) {
      chk = 2
      txt_message = 'Please correct your contact number.'
    } else if(this.state.itbr_1 == 0 && this.state.itbr_2 == 0 && this.state.itbr_3 == 0
      && this.state.itbr_4 == 0 && this.state.itbr_5 == 0 && this.state.itbr_6 == 0
      && this.state.itbr_7 == 0 && this.state.itbr_8 == 0 && this.state.itbr_9 == 0
      && this.state.itbr_10 == 0 && this.state.itbr_11 == 0) {
      chk = 2
      txt_message = 'Please select information to be released.'
    } else if((this.state.itbr_1 == 1 && this.state.itbr_1_txt === '') ||
      (this.state.itbr_2 == 1 && this.state.itbr_2_txt === '') ||
      (this.state.itbr_3 == 1 && this.state.itbr_3_txt === '') ||
      (this.state.itbr_4 == 1 && this.state.itbr_4_txt === '') ||
      (this.state.itbr_5 == 1 && this.state.itbr_5_txt === '') ||
      (this.state.itbr_6 == 1 && this.state.itbr_6_txt === '') ||
      (this.state.itbr_7 == 1 && this.state.itbr_7_txt === '') ||
      (this.state.itbr_8 == 1 && this.state.itbr_8_txt === '') ||
      (this.state.itbr_9 == 1 && this.state.itbr_9_txt === '') ||
      (this.state.itbr_10 == 1 && this.state.itbr_10_txt === '') ||
      (this.state.itbr_11 == 1 && this.state.itbr_11_txt === '')) {
      chk = 2
      txt_message = 'Please fill information to be released.'
    } else if((this.state.permission_1 == 1 && this.state.permission_hiv_date === '') ||
      (this.state.permission_2 === 1 && this.state.permission_genetic_result === '') ||
      (this.state.permission_4 && this.state.permission_others_list === '') ) {
      chk = 2
      txt_message = 'Please specify information about giving permission.'
    } else if(this.state.patient_date === '') {
      chk = 2
      txt_message = 'Please fill the date.'
    }

    if(this.state.patient_print_name === '') {
      chk = 2
      txt_message = 'Please fill your print name.'
    }
    if(signature.isEmpty()) {
      chk = 2
      txt_message = 'Please add your signature.'
    }

    if(chk == 2) {
      this.setState({
        message: txt_message,
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else if(chk == 1) {
      this.setState({
        status_submit: false,
        message: '',
      })
      var base64img = signature.toDataURL("image/png")
      const formData = new FormData()
      formData.append('file', base64img)
      formData.append('uid', this.props.authReducer.uid)
      formData.append('patient_date', this.state.patient_date)
      formData.append('udata', JSON.stringify(this.state))

      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_roi',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify(this.state),
        data: formData
      })
      .then((result) => {
          if(result['data'] === 'general_information') {
            this.props.onLoadLocation('/member/general_information')
            this.props.history.push('/member/general_information')
          }

        }
      )
    }
  }

  appendSendTo = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
        id:newInputID, name: '', address: '', tel: '', fax: '', purpose: 0, purpose_other: '', email: '',
      }])
      }
    ))
  }

  appendSendFrom = (name, fields) => {
    var newInput = fields[fields.length-1]
    var newInputID = newInput.id + 1
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
        id:newInputID, name: '', address: '', tel: '', fax: '', send_email: 1, send_fax: 1, send_by_email: ''
      }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {
    var txt_required = 'This field is required.'
    var today = new Date()
    return (
      <div className="roi box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Authorization for Release of Protected or Privileged Health Information
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={6}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="general_information" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns box2">
                      <div className="title_topic1">Instructions:</div>
                      <p>Please fill out Part A and Part B. Note that parts C and D are already filled in. These sections specify the information to be released. Although some of this information is sensitive, it is essential to your treatment at FITT-BD.</p>
                      <p>In Part B, please provide the contact information for all providers you have seen for
                      therapy and medication management/prescription in the PAST YEAR. Therapy providers can
                      include psychiatrists, psychologists, clinical social workers or counselors, and
                      psychiatric nurse practitioners. Prescribing providers can include primary care physicians,
                      psychiatrists, psychopharmacologists, and nurse practitioners.</p>
                      <p>To add more providers, click on the ADD PROVIDER button in the lower right corner.</p>
                      <p>Don't forget to sign and date the document at the end.</p>
                      <p>If you do not wish to release your medical history information, just leave the provider information section blank. Sign the document and click "Submit."</p>
                      <p><hr/></p>
                      <p><b>IMPORTANT:</b> Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.</p>
                  </div>
                  <div className="small-12 columns box1">
                    <div className="title_topic1">A. Patient Information</div>
                    <div className="row">
                      <div className="small-12 medium-3 columns title1">
                        Patient name:
                      </div>
                      <div className="small-12 medium-5 columns no-leftright-padding float_left1">
                      <TextField label="" name="patient_name" value={this.state.patient_name}
                      error={this.state.invalid && this.state.patient_name.length == 0}
                      disabled='disabled' InputProps={{ maxLength: 50 }}
                      onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                    </div>
                    <div className="row top_space_1">
                      <div className="small-12 medium-3 columns title1">
                        Patient address:
                      </div>
                      <div className="small-12 medium-1 columns title1">
                        Street:
                      </div>
                      <div className="small-12 medium-5 columns no-leftright-padding">
                        <TextField label="" name="address_street" InputProps={{ maxLength: 50 }}
                        error={this.state.invalid && this.state.address_street.length == 0}
                        //helperText={this.state.invalid && this.state.address_street.length == 0 && txt_required}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                      <div className="small-12 medium-1 columns title1">
                        Apt #:
                      </div>
                      <div className="small-12 medium-2 columns no-left-padding">
                        <TextField label="" name="address_apt" InputProps={{ maxLength: 10 }}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                    </div>
                    <div className="row top_space_1">
                      <div className="medium-3 columns show-for-medium">&nbsp;</div>
                      <div className="small-12 medium-1 columns title1">
                        City:
                      </div>
                      <div className="small-12 medium-2 columns no-leftright-padding">
                        <TextField label="" name="address_city" InputProps={{ maxLength: 25 }}
                        error={this.state.invalid && this.state.address_city.length == 0}
                        //helperText={this.state.invalid && this.state.address_city.length == 0 && txt_required}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                      <div className="small-12 medium-1 columns title1">
                        State:
                      </div>
                      <div className="small-12 medium-2 columns no-left-padding">
                        <TextField label="" name="address_state" InputProps={{ maxLength: 15 }}
                        disabled='disabled' value={this.state.address_state}
                        error={this.state.invalid && this.state.address_state.length == 0}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                      <div className="small-12 medium-1 columns title1">
                        Zipcode:
                      </div>
                      <div className="small-12 medium-2 columns no-left-padding">
                        <TextField label="" name="address_zip" InputProps={{ maxLength: 10 }}
                        error={this.state.invalid && this.state.address_zip.length == 0}
                        //helperText={this.state.invalid && this.state.address_zip.length == 0 && txt_required}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                    </div>
                    <div className="row top_space_1">
                      <div className="small-12 medium-3 columns title1">
                        Telephone contact:
                      </div>
                      <div className="small-12 medium-1 columns title1">
                        Day:
                      </div>
                      <div className="small-12 medium-3 columns no-leftright-padding">
                        <TextField label="" name="contact_day" InputProps={{ maxLength: 50 }}
                        error={this.state.invalid && this.state.contact_day.length == 0}
                        //helperText={this.state.invalid && this.state.contact_day.length == 0 && txt_required}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                      <div className="small-12 medium-2 columns title1">
                        Evening:
                      </div>
                      <div className="small-12 medium-3 columns no-left-padding">
                        <TextField label="" name="contact_evening" InputProps={{ maxLength: 10 }}
                        onChange={(e) => this.handleChange(e)} variant="outlined" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">B. Permission to share:
                      <span className="no_bold">I give my permission to share my protected health information</span>
                    </div>
                    <div className="row">
                      <div className="small-12 columns"><b>From:</b></div>
                    </div>
                    {this.state.send_from.map((val, index) =>
                    <div className="send_to" key={"sendfrom_"+index}>
                      {index > 0 &&
                        <div className="row">
                          <div className="small-12 columns">
                            <div className="line_2">&nbsp;</div>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="small-12 medium-3 columns title1">
                          Provider Name:
                        </div>
                        <div className="small-11 medium-8 columns no-leftright-padding send_to_name">
                          <TextField label="" InputProps={{ maxLength: 50 }} value={this.state.send_from[index].name}
                          onChange={(e) => this.handleChange5(e, 'send_from', index, 'name')} variant="outlined" />
                        </div>
                        <div className="small-1 medium-1 columns no-left-padding">
                          {index > 0 &&
                            <CancelIcon onClick={ () => this.removeInputSet1('send_from', val.id, this.state.send_from) } />
                          }
                        </div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Provider Address:
                        </div>
                        <div className="small-12 medium-8 columns no-leftright-padding send_to_name">
                          <TextField label="" InputProps={{ maxLength: 80 }} value={this.state.send_from[index].address}
                          onChange={(e) => this.handleChange5(e, 'send_from', index, 'address')} variant="outlined" />
                        </div>
                        <div className="medium-1 columns show-for-medium">&nbsp;</div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Telephone number:
                        </div>
                        <div className="small-12 medium-3 columns no-left-padding">
                          <TextField label="" InputProps={{ maxLength: 20 }} value={this.state.send_from[index].tel}
                          onChange={(e) => this.handleChange5(e, 'send_from', index, 'tel')} variant="outlined" />
                        </div>
                        <div className="small-12 medium-2 columns title1">
                          Fax number:
                        </div>
                        <div className="small-12 medium-3 columns no-left-padding">
                          <TextField label="" InputProps={{ maxLength: 20 }} value={this.state.send_from[index].fax}
                          onChange={(e) => this.handleChange5(e, 'send_from', index, 'fax')} variant="outlined" />
                        </div>
                        <div className="medium-1 columns">&nbsp;</div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title2">
                          Send by:
                        </div>
                        <div className="small-12 medium-9 columns no-left-padding">
                          <div className="each_radio1">
                            <input type="checkbox"  value={send_by[0].value} disabled="disabled"
                                checked={this.state.send_from[index].send_email == '1'}
                                onChange={(e) => this.handleChange5_checkbox(e, 'send_from', index, 'send_email')} />
                                {send_by[0].label}
                          </div>
                          <div className="each_radio1">
                            <input type="checkbox"  value={send_by[1].value} disabled="disabled"
                              checked={this.state.send_from[index].send_fax == '1'}
                              onChange={(e) => this.handleChange5_checkbox(e, 'send_from', index, 'send_fax')} />
                              {send_by[1].label}
                          </div>
                        </div>
                      </div>

                    </div>)}
                    <div className="row">
                      <div className="small-12 columns">
                        <div className="line_2">&nbsp;</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 columns align_right">
                        <button type="button" className="addInput1"
                           onClick={ () => this.appendSendFrom('send_from', this.state.send_from) }>
                            Add Provider
                        </button>
                      </div>
                    </div>
                    {/*---*/}

                    <div className="row">
                      <div className="small-12 columns">
                        <div className="line_1">&nbsp;</div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="small-12 columns"><b>To:</b></div>
                    </div>

                    {this.state.send_to.map((val, index) =>
                    <div className="send_to" key={"sendto_"+index}>
                      {index > 0 &&
                        <div className="row">
                          <div className="small-12 columns">
                            <div className="line_2">&nbsp;</div>
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="small-12 medium-3 columns title1">
                          Name:
                        </div>
                        <div className="small-11 medium-8 columns no-leftright-padding send_to_name">
                          <TextField label="" InputProps={{ maxLength: 50 }} value={this.state.send_to[index].name}
                          onChange={(e) => this.handleChange5(e, 'send_to', index, 'name')} variant="outlined" disabled  />
                        </div>
                        <div className="small-1 medium-1 columns no-left-padding">
                          {index > 0 &&
                            <CancelIcon onClick={ () => this.removeInputSet1('send_to', val.id, this.state.send_to) } />
                          }
                        </div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Address:
                        </div>
                        <div className="small-12 medium-8 columns no-leftright-padding send_to_addr">
                          <TextField label="" InputProps={{ maxLength: 80 }}  value={this.state.send_to[index].address}
                          onChange={(e) => this.handleChange5(e, 'send_to', index, 'address')} variant="outlined" disabled />
                        </div>
                        <div className="small-12 medium-1 columns">&nbsp;</div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Telephone number:
                        </div>
                        <div className="small-12 medium-3 columns no-left-padding send_to_tel">
                          <TextField label="" InputProps={{ maxLength: 20 }}  value={this.state.send_to[index].tel}
                          onChange={(e) => this.handleChange5(e, 'send_to', index, 'tel')} variant="outlined" disabled />
                        </div>
                        <div className="small-12 medium-2 columns title1">
                          Fax number:
                        </div>
                        <div className="small-12 medium-3 columns no-leftright-padding send_to_fax">
                          <TextField label="" InputProps={{ maxLength: 20 }}  value={this.state.send_to[index].fax}
                          onChange={(e) => this.handleChange5(e, 'send_to', index, 'fax')} variant="outlined" disabled />
                        </div>
                        <div className="small-12 medium-1 columns">&nbsp;</div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Email:
                        </div>
                        <div className="small-12 medium-8 columns no-leftright-padding send_to_email">
                          <TextField label="" InputProps={{ maxLength: 80 }}  value={this.state.send_to[index].email}
                          onChange={(e) => this.handleChange5(e, 'send_to', index, 'email')} variant="outlined" disabled />
                        </div>
                        <div className="small-12 medium-1 columns">&nbsp;</div>
                      </div>
                      <div className="row top_space_1">
                        <div className="small-12 medium-3 columns title1">
                          Purpose (check the appropriate box):
                        </div>
                        <div className="small-12 medium-8 columns no-leftright-padding send_to_addr">
                        {send_to_purpose.map(each =>
                          <div className="each_radio2" key={"purpose"+index+"_"+each.value}>
                            <input type="radio" value={each.value} disabled
                                checked={this.state.send_to[index].purpose == each.value}
                                onChange={(e) => this.handleChange5(e, 'send_to', index, 'purpose')}/>
                                {each.label}
                          </div>
                        )}
                        { this.state.send_to[index].purpose === '6' &&
                        <div className="each_radio2">
                          <TextField label="" InputProps={{ maxLength: 20 }}  value={this.state.send_to[index].purpose_other}
                            onChange={(e) => this.handleChange5(e, 'send_to', index, 'purpose_other')} variant="outlined" />
                        </div>
                        }
                        </div>
                        <div className="small-12 medium-1 columns">&nbsp;</div>
                      </div>
                    </div>)}
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">C. Information to be released
                    <span className="no_bold">(This section is pre-filled. Please note that we only request information that is necessary fo you to receive appropriate treatment.):</span></div>
                    {information_to_be_release.map(each =>
                      <div className="row" key={"itbr"+each.value}>
                        <div className="small-12 columns each_cb1">
                            <input type="checkbox" value={each.value} name={"itbr_"+each.value}
                            checked={this.state["itbr_"+each.value]} onChange={(e) => this.handleChange7(e)}
                            disabled={(each.value<5 || each.value==11) && 'disabled'} />
                                {each.label}
                        </div>
                        {this.state["itbr_"+each.value] == 1 &&
                        <div className="small-12 columns each_tb1">
                            <TextField label="" InputProps={{ maxLength: 50 }}
                              name={'itbr_'+each.value+'_txt'}
                              value={this.state['itbr_'+each.value+'_txt']}
                              onChange={(e) => this.handleChange(e)} variant="outlined"
                              disabled={(each.value==1 || each.value==2 || each.value==3 || each.value==4 || each.value==11) && 'disabled'} />
                        </div>
                        }
                      </div>
                      )}
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">D. Please check YES to indicate if you give permission to release the following information if present in your record
                    <span className="no_bold">(This section is pre-filled. Please note that we only request information that is necessary fo you to receive appropriate treatment.):</span></div>
                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_1"
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[0].label}
                      </div>
                      {this.state.permission_1 === 1 &&
                      <div className="small-12 columns">
                        <div className="row">
                          <div className="small-12 medium-3 columns title1">
                              SPECIFY DATES
                          </div>
                          <div className="small-12 medium-9 columns no-left-padding tb_auto_width">
                              <TextField label="" type="date"
                                name="permission_hiv_date"
                                onChange={(e) => this.handleChange(e)} variant="outlined" />
                          </div>
                        </div>
                      </div>}
                    </div>

                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_2"
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[1].label}
                      </div>
                      {this.state.permission_2 === 1 &&
                      <div className="small-12 columns">
                        <div className="row">
                          <div className="small-12 medium-3 columns title1">
                              SPECIFY TYPE OF TEST
                          </div>
                          <div className="small-12 medium-9 columns no-left-padding">
                              <TextField label="" name="permission_genetic_result" InputProps={{ maxLength: 50 }}
                                onChange={(e) => this.handleChange(e)} variant="outlined" />
                          </div>
                        </div>
                      </div>}
                    </div>

                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_3"
                            checked={this.state.permission_3} disabled
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[2].label}
                      </div>
                    </div>

                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_4"
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[3].label}
                      </div>
                      {this.state.permission_4 === 1 &&
                      <div className="small-12 columns">
                        <div className="row">
                          <div className="small-12 medium-2 columns title1">
                              Please List
                          </div>
                          <div className="small-12 medium-10 columns no-left-padding">
                              <TextField label="" name="permission_others_list" InputProps={{ maxLength: 80 }}
                                onChange={(e) => this.handleChange(e)} variant="outlined" />
                          </div>
                        </div>
                      </div>}
                    </div>

                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_5"
                            checked={this.state.permission_5} disabled='disabled'
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[4].label}
                      </div>
                    </div>

                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                          <input type="checkbox" value="1" name="permission_6"
                            checked={this.state.permission_6} disabled='disabled'
                            onChange={(e) => this.handleChange7(e)} />
                            Yes {give_permission[5].label}
                      </div>
                    </div>

                    {give_permission.map(each =>
                      each.value > 6 &&
                      <div className="row" key={"gp_"+each.value}>
                        <div className="small-12 medium-12 columns each_cb1">
                            <input type="checkbox" value="1" name={"permission_"+each.value}
                              onChange={(e) => this.handleChange7(e)} />
                              Yes {each.label}
                        </div>
                      </div>)}

                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">E. I understand and agree that:</div>
                    <div className="row">
                      <div className="small-12 medium-12 columns each_cb1">
                        <ul>
                          <li>Partners HealthCare System (PHS) cannot control how the recipient uses or shares the information, and that laws protecting its confidentiality at PHS may or may not protect this information once it has been released to the recipient</li>
                          <li>This authorization is voluntary</li>
                          <li>My treatment, payment, health plan enrollment, or eligibility for benefits will not be affected if I do not sign this form</li>
                          <li>I may cancel this authorization at any time by submitting a written request to the Department or Office where I originally submitted it, except:
                            <ul>
                              <li>if PHS has already relied upon it (for example, once information is released, it will not be retrieved)</li>
                              <li>if I signed this authorization as a condition of obtaining insurance, other laws may provide the insurer with a right to contest a claim under the policy or the policy itself</li>
                            </ul>
                          </li>
                          <li>This authorization will automatically expire 6 months from the date signed unless otherwise specified:</li>
                          <li>My questions about this authorization form have been answered</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row signature">
                  <div className="small-12 medium-6 columns show-for-medium">
                    &nbsp;
                  </div>
                  <div className="small-12 medium-6 columns">
                    <div className="row title_signature">
                      <div className="small-6 columns">
                        Patient Signature
                      </div>
                      <div className="small-6 columns align-right">
                        <button type="button" className="clear_button"
                           onClick={ () => this.refs.mySignature.clear() }>
                            Clear
                        </button>
                      </div>
                    </div>
                    <div className="relative_float_left">
                        <SignatureCanvas penColor='#555555' canvasProps={{className: 'signatureCanvas'}}
                         ref="mySignature" />
                    </div>
                    <div className="relative_float_left p_name">
                      <span className="bold">Name:</span> {this.state.patient_print_name}
                      {/*<TextField id="patient_print_name" label="Print Name"
                      onChange={this.handleChange} variant="outlined"
                      error={this.state.invalid && this.state.patient_print_name.length == 0}
                      InputProps={{
                          maxLength: 60,
                          inputProps: { name: "patient_print_name" }
                        }}  />*/}
                    </div>
                    <div className="relative_float_left ">
                      <span className="bold">Date:</span> {this.state.patient_date}
                      {/*<TextField id="patient_date" label="Date" onChange={this.handleChange2} variant="outlined" type="date" defaultValue={this.state.patient_date}
                      InputProps={{ inputProps: { name: "patient_date" }}} />*/}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="small-12 columns align_right">
                    {this.state.status_submit &&
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit" className="submit_1">
                      Submit
                    </Button>}
                    {!this.state.status_submit &&
                    <Button variant="contained" disabled
                     onClick={this.handleSubmit} type="submit" className="submit_1">
                      Submit
                    </Button>}
                  </div>
                </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Roi)
