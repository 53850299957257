import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import PatientHead3 from "../../../patient/patient_head3"
import Spinner from 'react-spinner-material'
import '../../../../../styles/assets/css/admin/form3.css'
import ReactHtmlParser from 'react-html-parser'

class ClinicalNotesDetail extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: '',
          reason: {reason: 0, reason_other: '', name: ''}
      }
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  getDataList = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip

    await axios({
      method: 'post',
      url: connectNode + 'admin/integrations/get_clinical_note_detail',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        ip: ip,
        param: this.props.match.params.param,
        type: this.props.match.params.type,
        cid: this.props.match.params.cid,
        appt_date: this.props.match.params.appt_date,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'][1][0],
          reason: result['data'][1][1][0],
        })
      }
    })
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Clinical Note Details
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns title_epic1">
                  {this.state.reason &&
                  <div>
                  Reason: {this.state.reason.name && this.state.reason.name}
                  {this.state.reason.reason_other &&
                  ' ['+this.state.reason.reason_other+']'}
                  </div>}
                  </div>
                  <div className="small-12 columns">
                  {ReactHtmlParser(this.state.dataItems['DisplayHTML'])}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClinicalNotesDetail)
