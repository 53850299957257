import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import Button from '@material-ui/core/Button'
import Spinner from 'react-spinner-material'
import { FiSettings } from 'react-icons/fi'
import '../../../../styles/assets/css/admin/patient_contract.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../functions/pagination'
import { progress } from '../../variables/patient_contract'

class PatientContractList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          baseline: '',
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_contract_overall_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'][1].status == "fulfilled") {
        this.setState({
          baseline: result['data'][0].value,
          dataItems: result['data'][1].value,
        })
      }
    })
    // Check completed baseline assessments //
    /*axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_baseline_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      this.setState({ baseline: result['data'] })
    })
    // List //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_contract_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid
      }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
        })
      }
    })*/
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="patient_contract">
        <div className="row">
          <div className="small-6 columns title4">
            Patient Contracts
          </div>
          <div className="small-6 columns bold button_header2">
          {this.state.pageOfItems && this.state.pageOfItems.length > 0 &&
           this.state.pageOfItems[this.state.pageOfItems.length-1].progress == 2 &&
          <NavLink to={"/admin/patient_contract/edit_patient_contract/"+this.props.match.params.pid+"/"+this.state.pageOfItems[this.state.pageOfItems.length-1].contract_version+"/new"}
            className="menu_list_active">Create Patient Contract
          </NavLink>}
          {this.state.pageOfItems.length == 0 &&
          <NavLink to={"/admin/patient_contract/new_contract/"+this.props.match.params.pid}
            className="menu_list_active">Create Patient Contract
          </NavLink>}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
                </div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Review/Update</th>
                    <th>PDF</th>
                    <th>Created By</th>
                    <th>Created Date/Time</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems && this.state.dataItems!=0 &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems!=0 && this.state.dataItems
                && this.state.pageOfItems.map((item) =>
                <tr key={'d'+item.contract_version}>
                  <td>{item.contract_version}</td>
                  <td>{item.progress == 2 && this.findLabelByValue(progress, item.progress)}
                  {item.progress == 1 && this.findLabelByValue(progress, item.progress)}
                  </td>
                  <td>{item.progress == 2 && this.findLabelByValue(progress, item.progress)}
                  {item.progress == 1 &&
                    <span><a href={"/admin/patient_contract/edit_patient_contract/"+this.props.match.params.pid+"/"+item.contract_version} >
                    Review/Update</a>
                    </span>}
                  </td>
                  <td>{item.progress == 2 && <a href={"/admin/patient_contract/patient_contract_pdf/"+this.props.match.params.pid+"/"+item.contract_version}
                  target="_blank">
                    Link</a>}{item.progress == 1 && '-'}</td>
                  <td>{item.created_by_c || item.created_by_a || item.created_by_p}</td>
                  <td>{item.conv_date}</td>
                </tr>
              )}
              {this.state && this.state.dataItems!=0 && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="8">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientContractList)
