import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'
import '../../../../../styles/assets/css/admin/form3.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../../functions/pagination'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { medication_filter } from '../../variables/integration'

class MedicationList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          med_filter: [],
          med_filter_selected: 2,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  getDataList = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip

    await axios({
      method: 'post',
      url: connectNode + 'admin/integrations/get_medication_order',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        ip: ip,
        med_filter_selected: this.state.med_filter_selected,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'],
          med_filter: medication_filter,
        })
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value,
    })
    this.getDataList()
  }

  convertDateFormat(getDate) {
    var buff = getDate.split("T")
    var genTime = buff[1].substr(0, 5)
    var buff = buff[0]
    var genDate = buff.split("-")
    genDate = genDate[1]+'/'+genDate[2]+'/'+genDate[0]
    return genDate+', '+genTime
  }

  render() {
    return (
      <div className="contact_logs">
        <div className="row">
          <div className="small-6 medium-9 columns title4">
            Medication Order
          </div>
          <div className="small-6 medium-3 columns title4 bottom_space2">
          <FormControl variant="outlined" className="mtf_filter">
            <Select value={this.state.med_filter_selected} variant="outlined"
              label="" defaultValue=""
              onChange={(e) => this.handleChange2(e, 'med_filter_selected')}>
                {this.state.med_filter && this.state.med_filter.map( each =>
                  <MenuItem key={'mf'+each.value} value={each.value}>{each.label}</MenuItem>
                )}
            </Select>
          </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Date</th>
                    <th>Medication</th>
                    <th>Dose</th>
                    <th>Currently Taking</th>
                    <th>Prescriber</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map((item, index) =>
                <tr key={'d'+index}>
                  <td>{item.resource.authoredOn}</td>
                  <td>{item.resource.medicationReference.display}</td>
                  <td>{item.resource.dosageInstruction[0].text}</td>
                  <td>{item.resource.status}</td>
                  <td>{item.resource.recorder.display}</td>
                </tr>
              )}
              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="6">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MedicationList)
