import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import '../../../styles/assets/css/pages/form2.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import validator from 'validator'
//import { makeStyles } from "@material-ui/core/styles";
import { gender, hispanic, race, marital, growup_with, preferred_pronouns, relationship_long,
    yes_no, highest_education, live_where, live_with, exercise, duration_longest_job } from '../variables/general_information.js'

class GeneralInformationAdditional extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          invalid: false,
          message: '',
          uid: 0,
          gender: 0,
          gender_other: '',
          preferred_pronouns: 0,
          preferred_pronouns_other: '',
          hispanic: 0,
          race: 0,
          marital: 0,
          growup_with: 0,
          growup_with_family: '',
          growup_with_other: '',
          childhood_environment_safe: 0,
          childhood_environment_safe_text: '',
          childhood_environment_supportive: 0,
          childhood_environment_supportive_text: '',
          current_relationship: 0,
          current_relationship_text: 0,
          highest_education: 0,
          learning_disability: 0,
          learning_disability_what: '',
          learning_disability_age: '',
          exercise: 0,
          current_employed: 0,
          current_employed_job: '',
          occupation_significant_position: '',
          occupation_longest_held: 0,
          stressors: 0,
          live_where: 0,
          live_where_text: '',
          live_with: 0,
          live_with_text: '',
          child_with: 0,
          child_with_number: '',
          child_with_age: '',
          own_firearms: 0,
          safe_living: 0,
          safe_living_text: '',
          current_legal_stress: 0,
          current_legal_stress_text: '',
          current_legal_need: 0,
          current_legal_need_text: '',
          military_service: 0,
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_additional_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        g_type: 1,
        g_page: 1,
      }
    })
    .then((result) => {
        if(result['data'].status !== 1) {
          this.props.history.push('/member/complete_registration')
        }
    })
  }

  checNoEmptyData1 = (fields) => {
    var result = [ true, '' ]
    fields.map(each => {
      this.state[each.field].map(val => {
        each.valSet.map(chkVal => {
          var trimStr = val[chkVal.name]
          if(chkVal.empVal === '') {
            trimStr.trim()
          }
          if(trimStr === chkVal.empVal) {
            result[0] = false
            result[1] = 'Please fill all information'
          } else {
            if(chkVal.name === 'email') {
              var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
              if (!pattern.test(trimStr)) {
                result[0] = false
                result[1] = "Please enter valid email address."
              }
            }
          }
        })
      })
    })
    return result
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange4 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.gender == 0 || this.state.preferred_pronouns == 0
      || this.state.hispanic == 0 || this.state.race == 0 || this.state.merital == 0
      || this.state.growup_with == 0 || this.state.childhood_environment_safe == 0
      || this.state.childhood_environment_supportive == 0 || this.state.current_relationship == 0
      || this.state.highest_education == 0 || this.state.learning_disability == 0
      || this.state.exercise == 0 || this.state.current_employed == 0
      || this.state.occupation_significant_position === ''
      || this.state.occupation_longest_held == 0 || this.state.stressors == 0
      || this.state.live_where == 0 || this.state.live_with == 0
      || this.state.child_with == 0 || this.state.own_firearms == 0
      || this.state.safe_living == 0 || this.state.current_legal_stress == 0
      || this.state.current_legal_need == 0 || this.state.military_service == 0) {
        this.setState({
          message: 'Please fill in all information.',
          invalid: true,
        })
        window.scrollTo(0, 0)
    } else if(this.state.child_with == 1 && !validator.isNumeric(this.state.child_with_number)) {
      this.setState({
        message: 'Please give a numerical answer for how many children are living with you.',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_additional_general_information',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] === 'complete_registration') {
            this.props.onLoadLocation('/member/complete_registration')
            this.props.history.push('/member/complete_registration')
          }

        }
      )
    }
  }

  render() {
    var today = new Date()
    return (
      <div className="general_patient_information box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            General Information
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box2_1">
              <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="general_information" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns box1">
                    <div className="title_topic1">Identifying Information</div>
                    <div className="row top_space">
                      <div className="small-12 medium-6 columns ">
                        <div className="title_space1">Gender</div>
                        <div className="frm_space1">
                          <FormControl variant="outlined"
                          error={this.state.invalid && this.state.gender == 0} >
                            <Select value={this.state.gender} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'gender', 'val')}>
                                {gender.map(each =>
                                  <MenuItem key={"gender"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {this.state.gender == 9 &&
                      <div className="small-12 medium-6 columns ">
                        <div className="title_space1">Explain:</div>
                        <div className="frm_space1">
                          <TextField name="gender_other"
                            onChange={this.handleChange} inputProps={{ maxLength: 50 }}
                            label=" " InputLabelProps={{ shrink: true }}
                            variant="outlined" />
                        </div>
                      </div>}
                    </div>
                    <div className="row top_space">
                      <div className="small-12 medium-6 columns ">
                        <div className="title_space1">Preferred Pronouns</div>
                        <div className="frm_space1">
                          <FormControl variant="outlined"
                          error={this.state.invalid && this.state.preferred_pronouns == 0} >
                            <Select value={this.state.preferred_pronouns} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'preferred_pronouns', 'val')}>
                                {preferred_pronouns.map(each =>
                                  <MenuItem key={"preferred_pronouns"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {this.state.preferred_pronouns == 6 &&
                      <div className="small-12 medium-6 columns ">
                        <div className="title_space1">Explain:</div>
                        <div className="frm_space1">
                          <TextField name="preferred_pronouns_other"
                            onChange={this.handleChange} inputProps={{ maxLength: 50 }}
                            label=" " InputLabelProps={{ shrink: true }}
                            variant="outlined" />
                        </div>
                      </div>}
                    </div>
                    <div className="row top_space">
                      <div className="small-12 medium-12 columns float_left2">
                        <div className="title_space1">Are you Hispanic (a person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin, regardless of race)? </div>
                        <div className="frm_space2">
                          <FormControl variant="outlined"
                          error={this.state.invalid && this.state.hispanic == 0} >
                            <Select value={this.state.hispanic} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'hispanic', 'val')}>
                                {hispanic.map(each =>
                                  <MenuItem key={"hispanic"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                    <div className="row top_space">
                      <div className="small-12 medium-6 columns float_left2">
                        <div className="title_space1">What is your race?</div>
                        <div className="frm_space1">
                          <FormControl variant="outlined"
                          error={this.state.invalid && this.state.race == 0} >
                            <Select value={this.state.race} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'race', 'val')}>
                                {race.map(each =>
                                  <MenuItem key={"race"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="small-12 medium-6 columns float_left2">
                        <div className="title_space1">Marital Status</div>
                        <div className="frm_space1">
                          <FormControl variant="outlined"
                          error={this.state.invalid && this.state.marital == 0} >
                            <Select value={this.state.marital} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange4(e, 'marital', 'val')}>
                                {marital.map(each =>
                                  <MenuItem key={"marital"+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="row ">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Developmental History and Family of Origin</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1"><b>Family History and Relationships</b></div>
                      <div className="title_space2">Who did you grow up with? </div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.growup_with == 0} >
                          <Select value={this.state.growup_with} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'growup_with', 'val')}>
                              {growup_with.map(each =>
                                <MenuItem key={"growup_with"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.growup_with == 2 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1 show-for-medium">&nbsp;</div>
                      <div className="title_space2">What family members?</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="growup_with_family"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                    {this.state.growup_with == 4 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1 show-for-medium">&nbsp;</div>
                      <div className="title_space2">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="growup_with_other"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Was your childhood home environment safe?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.childhood_environment_safe == 0} >
                          <Select value={this.state.childhood_environment_safe} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'childhood_environment_safe', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"childhood_environment_safe"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.childhood_environment_safe == 2 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="childhood_environment_safe_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Was your childhood home environment supportive?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.childhood_environment_supportive == 0} >
                          <Select value={this.state.childhood_environment_supportive} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'childhood_environment_supportive', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"childhood_environment_supportive"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.childhood_environment_supportive == 2 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="childhood_environment_supportive_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Are you currently in a relationship?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.current_relationship == 0} >
                          <Select value={this.state.current_relationship} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'current_relationship', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"current_relationship"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.current_relationship == 1 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">How long:</div>
                      <div className="frm_space1 txt_border2">
                        <FormControl variant="outlined">
                          <Select value={this.state.current_relationship_text} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'current_relationship_text', 'val')}>
                              {relationship_long.map(each =>
                                <MenuItem key={"current_relationship_text"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Education and Learning Disabilities</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1"><b>Education Level</b></div>
                      <div className="title_space2">What is your highest level of education?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.highest_education == 0} >
                          <Select value={this.state.highest_education} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'highest_education', 'val')}>
                              {highest_education.map(each =>
                                <MenuItem key={"highest_education"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Have you ever been diagnosed with a learning disability?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.learning_disability == 0} >
                          <Select value={this.state.learning_disability} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'learning_disability', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"learning_disability"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                  {this.state.learning_disability == 1 &&
                  <div className="row top_space">
                  <div className="small-12 medium-6 columns ">
                    <div className="title_space1">What learning disability was diagnosed?</div>
                    <div className="frm_space1 txt_border1">
                      <TextField name="learning_disability_what"
                        onChange={this.handleChange}
                        label=" " InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 50 }}
                        variant="outlined" />
                    </div>
                  </div>
                  <div className="small-12 medium-6 columns ">
                    <div className="title_space1">Approximate age when diagnosed?</div>
                    <div className="frm_space1 txt_border1">
                      <TextField name="learning_disability_age"
                        onChange={this.handleChange}
                        label=" " InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 50 }}
                        variant="outlined" />
                    </div>
                  </div>
                </div>}
                </div>
              </div>

              <div className="row ">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Exercise</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space2">On average, how much do you exercise?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.exercise == 0} >
                          <Select value={this.state.exercise} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'exercise', 'val')}>
                              {exercise.map(each =>
                                <MenuItem key={"exercise"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Employment</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Are you currently employed?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.current_employed == 0} >
                          <Select value={this.state.current_employed} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'current_employed', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"employed"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.current_employed == 1 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Name of current job:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="current_employed_job"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>

                  <div className="row top_space">
                    <div className="small-12 columns float_left2">
                      <div className="title_space1"><b>Occupation/Work History</b></div>
                    </div>
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">What was the most significant job position you held?</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="occupation_significant_position"
                          error={this.state.invalid && this.state.occupation_significant_position == ''}
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">What was the duration of the longest job position you held? </div>
                      <div className="frm_space1 txt_border1">
                      <FormControl variant="outlined"
                      error={this.state.invalid && this.state.occupation_longest_held == 0} >
                        <Select value={this.state.occupation_longest_held} variant="outlined"
                          label="" defaultValue="" name="occupation_longest_held"
                          onChange={(e) => this.handleChange2(e)}>
                            {duration_longest_job.map(each =>
                              <MenuItem key={"lh"+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Do you have financial stressors?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.stressors == 0} >
                          <Select value={this.state.stressors} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'stressors', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"stressors"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Living Situation</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1"><b>Current Living Situation:</b></div>
                      <div className="title_space2">Where do you live?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.live_where == 0} >
                          <Select value={this.state.live_where} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'live_where', 'val')}>
                              {live_where.map(each =>
                                <MenuItem key={"live_where"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.live_where == 5 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1 show-for-medium">&nbsp;</div>
                      <div className="title_space2">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="live_where_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>

                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1"> Who do you live with?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.live_with == 0} >
                          <Select value={this.state.live_with} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'live_with', 'val')}>
                              {live_with.map(each =>
                                <MenuItem key={"live_with"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.live_with == 5 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="live_with_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>

                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Are children living with you? </div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.child_with == 0} >
                          <Select value={this.state.child_with} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'child_with', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"child_with"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.child_with == 1 &&
                      <div className="small-12 medium-6 columns ">
                        <div className="title_space1">How many children are living with you?</div>
                        <div className="frm_space1 txt_border1">
                          <TextField name="child_with_number"
                            onChange={this.handleChange}
                            label=" " InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 50 }}
                            variant="outlined" />
                        </div>
                      </div>}
                  </div>

                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Do you own firearms?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.own_firearms == 0} >
                          <Select value={this.state.own_firearms} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'own_firearms', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"own_firearms"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Do you feel safe in your current living situation?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.safe_living == 0} >
                          <Select value={this.state.safe_living} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'safe_living', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"safe_living"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.safe_living == 2 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="safe_living_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>

                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Legal History</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Current legal stresses? </div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.current_legal_stress == 0} >
                          <Select value={this.state.current_legal_stress} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'current_legal_stress', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"current_legal_stress"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.current_legal_stress == 1 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="current_legal_stress_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">Current legal needs?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.current_legal_need == 0} >
                          <Select value={this.state.current_legal_need} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'current_legal_need', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"current_legal_need"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.current_legal_need == 1 &&
                    <div className="small-12 medium-6 columns ">
                      <div className="title_space1">Explain:</div>
                      <div className="frm_space1 txt_border1">
                        <TextField name="current_legal_need_text"
                          onChange={this.handleChange}
                          label=" " InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                          variant="outlined" />
                      </div>
                    </div>}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1">Military Service</p>
                  <div className="row top_space">
                    <div className="small-12 medium-6 columns float_left2">
                      <div className="title_space1">History of military service?</div>
                      <div className="frm_space1">
                        <FormControl variant="outlined"
                        error={this.state.invalid && this.state.military_service == 0} >
                          <Select value={this.state.military_service} variant="outlined"
                            label="" defaultValue=""
                            onChange={(e) => this.handleChange4(e, 'military_service', 'val')}>
                              {yes_no.map(each =>
                                <MenuItem key={"military_service"+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="small-12 columns align_right">
                {this.state.status_submit &&
                  <Button variant="contained" color="primary" disableElevation
                   onClick={this.handleSubmit} type="submit" className="submit_1">
                    Submit
                  </Button>}
                {!this.state.status_submit &&
                  <Button variant="contained" disabled
                   onClick={this.handleSubmit} type="submit" className="submit_1">
                    Submit
                  </Button>}
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GeneralInformationAdditional)
