import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocation } from '../../../actions/location'
import Button from '@material-ui/core/Button'

class Complete extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_mini_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          if(result['data'].mini_step !== 'complete') {
            this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
  }

  render() {
    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Thank you
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
              You have completed MINI summary sheet for intake appointment.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Complete)
