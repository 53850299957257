import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
import { Link } from "react-router-dom"

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      route: '',
      message: '',
    }
  }

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div className="footer">
        <div className="wrapper row">
          {/*<div className="small-12 columns align-center">
            <Link to="/" className="main_menu_active">HOME</Link>
            <Link to="/about" className="main_menu_active">ABOUT</Link>
            <Link to="/blog" className="main_menu_active">BLOG</Link>
            <Link to="/contact" className="main_menu_active">CONTACT</Link>
          </div>*/}
          <div className="small-12 columns align-center copyright">
            Copyright © 2021, Mass General Brigham, Inc. All rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { uid: state.authReducer.uid }
}

export default connect(mapStateToProps)(Footer)
