import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { calculateTimepoint1 } from '../../functions/timepoint.js'
import moment from 'moment'
import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import { IoIosSquare } from 'react-icons/io'
import { RiPencilFill } from 'react-icons/ri'
import CheckIcon from '@material-ui/icons/Check'
import TerrainIcon from '@material-ui/icons/Terrain'
import ReactTooltip from 'react-tooltip'
//import TerrainIcon from '@mui/icons-material/Terrain'

class ScheduledAssessments extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      self_stigma: 0,
      self_stigma_id: 0,
      self_stigma_date: '',
      self_stigma_start: '',
      self_stigma_end: '',
      self_stigma_status: 0,
    }
  }

  componentDidMount() {
    this.getTimelineStart()
  }

  calAssmMonth = (calTimepoint, timeline_start) => {
    const d = new Date();
    const currMonth = d.getMonth() + 1
    const currDay = d.getDate()
    //console.log(currDay + ', ' + currMonth)

    var tp_week = calTimepoint.current_week
    var date_tp_week = new Date((timeline_start*1000)+(60*60*24*7*(tp_week)*1000)).toLocaleDateString("en-US")
    var week_date = new Date((timeline_start*1000)+(60*60*24*7*(tp_week)*1000)).getDate()
    var cnt_week = Math.ceil(week_date/7)
    //console.log('W => '+tp_week + ': ' + date_tp_week + ': ' + cnt_week)

    var tp_month = calTimepoint.current_month
    var date_tp_month = new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((tp_month), 'M').format('MM-DD-YYYY'))
    //this.state.dateMomentM.add(tp_month, 'M').format('MM/DD/YYYY')
    var month_date = date_tp_month.getDate()
    var cnt_month = 0
    if(currDay > (month_date - 5)) {
      cnt_month = 1
    } else {
      tp_month = 0
    }
    //console.log('M => '+date_tp_month+': '+month_date+': '+cnt_month)

    var tp_trimonth = calTimepoint.current_trimonth
    //console.log('tp_trimonth: '+tp_trimonth)
    var date_tp_trimonth = new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((tp_trimonth*3), 'M').format('MM-DD-YYYY'))
    //this.state.dateMomentM.add(tp_month, 'M').format('MM/DD/YYYY')
    var trimonth_date = date_tp_trimonth.getDate()
    var trimonth_month = date_tp_trimonth.getMonth() + 1
    var cnt_trimonth = 0
    if(currMonth == trimonth_month) {
      if(currDay > (trimonth_date - 5)) {
        cnt_trimonth = 1
      } else {
        tp_trimonth = 0
      }
    } else {
      tp_trimonth = 0
    }
    //console.log('TM => '+date_tp_trimonth+': '+trimonth_date+': '+trimonth_month+': '+cnt_trimonth)

    var tp_year = calTimepoint.current_year
    var date_tp_year = new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((tp_year), 'Y').format('MM-DD-YYYY'))
    //this.state.dateMomentM.add(tp_month, 'M').format('MM/DD/YYYY')
    var year_date = date_tp_year.getDate()
    var year_month = date_tp_year.getMonth() + 1
    var cnt_year = 0
    if(currMonth == year_month) {
      if(currDay > (year_date - 5)) {
        cnt_year = 1
      } else {
        tp_year = 0
      }
    } else {
      tp_year = 0
    }
    //console.log('Y => '+date_tp_year+': '+year_date+': '+year_month+': '+cnt_year)
    const total = cnt_week + cnt_month + cnt_trimonth + cnt_year
    //console.log('Total: ' + total)

    return [total, tp_week, cnt_week, tp_month, cnt_month, tp_trimonth, cnt_trimonth, tp_year, cnt_year]
  }

  getTimelineStart = () => {
    // Timeline //
    var getTimePoint = {}
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_timeline_start2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      var timeline_start = result['data'].timeline_start
      var end_baseline = result['data'].end_baseline
      var p_status = result['data'].status
      var calTimepoint = calculateTimepoint1(timeline_start)
      //console.log(calTimepoint)
      const calTP = this.calAssmMonth(calTimepoint, timeline_start)
      //console.log(calTP)

      var st_w = ''
      var num_w = 0
      var num_p_w = 0
      var st_m = ''
      var num_m = 0
      var num_p_m = 0
      var st_tm = ''
      var num_tm = 0
      var num_p_tm = 0
      var st_y = ''
      var num_y = 0
      var num_p_y = 0

      if(calTimepoint.mod_days >= 5) {
        st_w = 'due_soon_color'
        num_w = calTimepoint.current_week
      } else if (calTimepoint.mod_days <= 2) {
        st_w = 'due_now_color'
        num_w = calTimepoint.current_week
      } else {
        num_p_w = calTimepoint.current_week
      }

      if(calTimepoint.mod_months >= -5 && calTimepoint.mod_months <= 0) {
        st_m = 'due_soon_color'
        num_m = calTimepoint.current_month
      } else if (calTimepoint.mod_months >=0 && calTimepoint.mod_months <= 5) {
        st_m = 'due_now_color'
        num_m = calTimepoint.current_month
      } else {
        num_p_m = calTimepoint.current_month
      }

      if(calTimepoint.current_month%3 == 0) {
        if(calTimepoint.mod_months >= -5 && calTimepoint.mod_months <= 0) {
          st_tm = 'due_soon_color'
          num_tm = calTimepoint.current_trimonth
        } else if (calTimepoint.mod_months >=0 && calTimepoint.mod_months <= 5) {
          st_tm = 'due_now_color'
          num_tm = calTimepoint.current_trimonth
        } else {
          num_p_tm = calTimepoint.current_trimonth
        }
      }

      if(calTimepoint.mod_years >= -5 && calTimepoint.mod_years <= 0) {
        st_y = 'due_soon_color'
        num_y = calTimepoint.current_year
      } else if (calTimepoint.mod_years >=0 && calTimepoint.mod_years <= 5) {
        st_y = 'due_now_color'
        num_y = calTimepoint.current_year
      } else {
        num_p_y = calTimepoint.current_year
      }

      // --- Process --- //
      var assm_w = num_w
      var assm_m = num_m
      var assm_tm = num_tm
      var assm_y = num_y
      var status_w = 0
      var status_m = 0
      var status_tm = 0
      var status_y = 0
      var total = 0
      var completed = 0
      var calStars = 0
      var cntStar = []
      var discharge_status = 0
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_recent_available',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          week: num_w,
          month: num_m,
          trimonth: num_tm,
          year: num_y,
         }
      })
      .then((result) => {
        //console.log(result['data'])
        result['data'].map(each => {
          if(each.timepoint_type == 'w' && each.status == 2 && st_w != '') {
            assm_w = 0
          }
          if(each.timepoint_type == 'm' && each.status == 2 && st_m != '') {
            assm_m = 0
          }
          if(each.timepoint_type == 'tm' && each.status == 2 && st_tm != '') {
            assm_tm = 0
          }
          if(each.timepoint_type == 'y' && each.status == 2 && st_y != '') {
            assm_y = 0
          }
        })
      })
      // --- Count number completed --- //
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/count_assm_completed',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          week: calTP[1], //num_p_w,
          cnt_week: calTP[2],
          month: calTP[3],//num_p_m,
          cnt_month: calTP[4],
          trimonth: calTP[5],//num_p_tm,
          cnt_trimonth: calTP[6],
          year: calTP[7],//num_p_y,
          cnt_year: calTP[8],
         }
      })
      .then((result) => {
        //console.log(num_w +' , '+ num_m +' , '+ num_tm +' , '+ num_y)
        //console.log(num_p_w +' , '+ num_p_m +' , '+ num_p_tm +' , '+ num_p_y)
        total = calTP[0] //num_w + num_m + num_tm + num_y + num_p_w + num_p_m + num_p_tm + num_p_y
        completed = result['data'].cnt
        if(completed == undefined) {
          completed = 0
        }
        cntStar = completed
      })

      // Check Discharge Assm Status
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/check_assm_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          type: 'm',
          timepoint: '-2',
         }
      })
      .then((result) => {
        //console.log(result['data'])
        discharge_status = result['data'].status
      })

      // --- Previous Status --- //
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_recent_available',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
          week: num_p_w,
          month: num_p_m,
          trimonth: num_p_tm,
          year: num_p_y,
         }
      })
      .then((result) => {
        //console.log(result['data'])
        result['data'].map(each => {
          if(each.timepoint_type == 'w' && each.status == 2 && assm_w == 0) {
            status_w = each.status
          }
          if(each.timepoint_type == 'm' && each.status == 2 && assm_m == 0) {
            status_m = each.status
          }
          if(each.timepoint_type == 'tm' && each.status == 2 && assm_tm == 0) {
            status_tm = each.status
          }
          if(each.timepoint_type == 'y' && each.status == 2 && assm_y == 0) {
            status_y = each.status
          }
        })
        this.setState({
          timeline_start: timeline_start,
          end_baseline: end_baseline,
          p_status: p_status,
          discharge_status: discharge_status,
          assm_w: assm_w,
          assm_p_w: num_p_w,
          st_w: st_w,
          status_w: status_w,
          assm_m: assm_m,
          assm_p_m: num_p_m,
          st_m: st_m,
          status_m: status_m,
          assm_tm: assm_tm,
          assm_p_tm: num_p_tm,
          st_tm: st_tm,
          status_tm: status_tm,
          assm_y: assm_y,
          assm_p_y: num_p_y,
          st_y: st_y,
          status_y: status_y,
          total: total,
          completed: completed,
          cntStar: cntStar,
          dateMomentM: moment(new Date(timeline_start*1000), "MM-DD-YYYY"),
          dateMomentTM: moment(new Date(timeline_start*1000), "MM-DD-YYYY"),
          dateMomentY: moment(new Date(timeline_start*1000), "MM-DD-YYYY"),
          //dateMomentWchk: new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((num_w+3), 'w').format('MM-DD-YYYY')).getTime(),
          dateMomentMchk: new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((num_p_m+1), 'M').format('MM-DD-YYYY')).getTime(),
          dateMomentTMchk: new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add(((num_p_tm*3)+1), 'M').format('MM-DD-YYYY')).getTime(),
          dateMomentYchk: new Date(moment(new Date(timeline_start*1000), "MM-DD-YYYY").add((num_p_y*13), 'M').format('MM-DD-YYYY')).getTime(),
          //new Date(this.state.dateMomentYchk.add((this.state.assm_p_y*13), 'M').format('MM-DD-YYYY')).getTime(), //moment(new Date(timeline_start*1000), "MM-DD-YYYY"),
        })
      })

      // Check Self Stigma Available //
      axios({
        method: 'post',
        url: connectNode + 'admin/self_stigma/check_avilibility_completed',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.authReducer.uid,
         }
      })
      .then((result) => {
        if(result['data'].id > 0) {
          this.setState({
            self_stigma: 1,
            self_stigma_id: result['data'].id,
            self_stigma_date: result['data'].conv_date,
            self_stigma_start: result['data'].available_date_begin,
            self_stigma_end: result['data'].available_date_end,
            self_stigma_status: result['data'].ss_status,
          })
        }
      })
    })
  }

  getPreviousTimepoint = (num) => {
    var newnum = num - 1
    if(newnum < 0) {
      newnum = 0
    }
    return newnum
  }

  render() {
    const today = Date.now()
    var cnt_mtn = []
    for(var i=0; i<this.state.completed; i++){
      cnt_mtn.push(1)
    }
    return (
      <>
      {/*<div className="box_border box_space_top2 badges_box2">
        <div className="row">
            <div className="small-8 columns head2">
              Badges
            </div>
            <div className="small-12 columns">
            <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges1'>
              <img src="./badges/daily_tracker_badge.png" className="badges1" />
            </NavLink>
            <ReactTooltip id='badges1' type='warning' effect='solid'>
              <span>5 daily trackers this week</span>
            </ReactTooltip>
            <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges2'>
              <img src="./badges/who5_badge.png" className="badges1" />
            </NavLink>
            <ReactTooltip id='badges2' type='warning' effect='solid'>
              <span>4 Who-5 this month</span>
            </ReactTooltip>
            <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges3'>
              <img src="./badges/monthly_assessment_badge.png" className="badges1" />
            </NavLink>
            <ReactTooltip id='badges3' type='warning' effect='solid'>
              <span>Completed monthly assessment</span>
            </ReactTooltip>
            </div>
            <div className="small-12 columns">
            <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges6'>
              <img src="./badges/bookworm_badge.png" className="badges1" />
            </NavLink>
            <ReactTooltip id='badges6' type='warning' effect='solid'>
              <span>5 daily trackers this week</span>
            </ReactTooltip>
            <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges7'>
              <img src="./badges/fitness_active1.png" className="badges1 new_badge" />
            </NavLink>
            <ReactTooltip id='badges7' type='warning' effect='solid'>
              <span>4 Who-5 this month</span>
            </ReactTooltip>
            </div>
            <div className="small-12 columns">
            Congratulations! You've earned a new badge.
            </div>
        </div>
      </div>*/}
      <div className="box_border box_space_top2 schedule_assm">
        <div className="row">
            <div className="small-8 columns head2">
              Scheduled Assessments
            </div>
            {/*<div className="small-4 columns head2 align_right">
              {this.state.cntStar && this.state.cntStar.map(star =>
                <img src="/star.png" />
              )}
            </div>*/}
            <div className="small-12 columns">
            {!this.state.end_baseline &&
              <div className="due_now_color">
              <a href={"/member/assessment/overview/m/-1"}><RiPencilFill />
              First Set of Questionnaires
              </a></div>}
            {/*this.state.completed > 0 &&
            <div className='large_terrain completed'>
            {cnt_mtn.map((each) => <TerrainIcon /> )}<br />
             You've done {this.state.completed} assessment{this.state.completed > 0 && 's'} this month!
            </div>*/}
            {this.state.p_status == 16 && this.state.discharge_status != 2 &&
              <div className="due_now_color">
              <a href={"/member/assessment/overview/m/-2"}><RiPencilFill />
                Discharge assessments
              </a></div>
            }
            {this.state.assm_w > 0 &&
              <div className={this.state.st_w}>
              <a href={"/member/assessment/who5/w/"+this.state.assm_w}><RiPencilFill />
              Week {this.state.assm_w}: {new Date((this.state.timeline_start*1000)+(60*60*24*7*(this.state.assm_w)*1000)).toLocaleDateString("en-US")}
              </a></div>}
            {/*this.state.status_w == 2 &&
              <div className="completed">
              <CheckIcon />&nbsp;Week {this.state.assm_p_w}: {new Date((this.state.timeline_start*1000)+(60*60*24*7*(this.state.assm_p_w)*1000)).toLocaleDateString("en-US")}
              </div>*/}
            {this.state.assm_m > 0 &&
              <div className={this.state.st_m}>
              <a href={"/member/assessment/phq9/m/"+this.state.assm_m}><RiPencilFill />
              Month {this.state.assm_m}: {this.state.dateMomentM.add(this.state.assm_m, 'M').format('MM/DD/YYYY')}
              </a></div>}
            {/*this.state.status_m == 2 && today < this.state.dateMomentMchk &&
              <div className="completed">
              <CheckIcon />&nbsp;Month {this.state.assm_p_m}: {this.state.dateMomentM.add(this.state.assm_p_m, 'M').format('MM/DD/YYYY')}
              </div>*/}
            {this.state.assm_tm > 0 &&
              <div className={this.state.st_tm}>
              <a href={"/member/assessment/covid/tm/"+this.state.assm_tm}><RiPencilFill />
              Trimonth {this.state.assm_tm}: {this.state.dateMomentTM.add((this.state.assm_tm*3), 'M').format('MM/DD/YYYY')}
              </a></div>}
            {/*this.state.status_tm == 2 && today < this.state.dateMomentTMchk &&
              <div className="completed">
              <CheckIcon />&nbsp;Trimonth {this.state.assm_p_tm}: {this.state.dateMomentTM.add((this.state.assm_p_tm*3), 'M').format('MM/DD/YYYY')}
              </div>*/}
            {this.state.assm_y > 0 &&
              <div className={this.state.st_y}>
              <a href={"/member/assessment/ders18/y/"+this.state.assm_y}><RiPencilFill />
              Year {this.state.assm_y}: {this.state.dateMomentY.add((this.state.assm_y*12), 'M').format('MM/DD/YYYY')}
              </a></div>}
            {/*this.state.status_y == 2 && today < this.state.dateMomentYchk &&
              <div className="completed">
              <CheckIcon />&nbsp;Year {this.state.assm_p_y}: {this.state.dateMomentY.add((this.state.assm_p_y*12), 'M').format('MM/DD/YYYY')}
              </div>*/}
              {this.state.self_stigma_id > 0 && this.state.self_stigma_status != 2 &&
                <div className="due_now_color" >
                <a href={"/member/assessment/self_stigma/sccs/"+this.state.self_stigma_id}><RiPencilFill />
                Self Stigma: {this.state.self_stigma_date}
                </a></div>}
            </div>

            {//(this.state.assm_w > 0 || this.state.assm_m > 0 || this.state.assm_tm > 0 || this.state.assm_y > 0)&&
            <div className="small-12 columns color_box">
              <p><span className="due_soon_color"><IoIosSquare /> Due soon</span>
              <span className="due_now_color"><IoIosSquare /> Due now</span></p>
              {/*<span className="completed margin_left1"><CheckIcon />&nbsp;Completed</span></p>
              <p className="starsTxt"><img src="/star.png" /> 25% &nbsp;  .......... &nbsp;&nbsp;
                <img src="/star.png" /><img src="/star.png" /><img src="/star.png" /><img src="/star.png" /> 100%
              </p>*/}
            </div>}

            {/*this.state.assm_w == 0 && this.state.assm_m == 0 && this.state.assm_tm == 0
              && this.state.assm_y == 0 && this.state.end_baseline &&
            <div className="small-12 columns align_center">-</div>*/}
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledAssessments)
