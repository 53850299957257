import React from "react"
import OverviewRegistration from "../components/patients/enroll/overview_registration"
import GeneralInformation from "../components/patients/enroll/general_information"
import MedicalHistory from "../components/patients/enroll/medical_history"
import PsychiatricHistory from "../components/patients/enroll/psychiatric_history"
import TreatmentConsent from "../components/patients/enroll/treatment_consent"
import CommunicationConsent from "../components/patients/enroll/communication_consent"
import ConsentFormList from "../components/patients/consent/consent_form_list"
import ClinicPolicies from "../components/patients/enroll/clinic_policies"
import PatientWaiver from "../components/patients/enroll/patient_waiver"
import InformationSheet from "../components/patients/enroll/information_sheet"
import Roi from "../components/patients/enroll/roi"
import RoiInstruction from "../components/patients/enroll/roi_instruction"
import CompleteRegistration from "../components/patients/enroll/complete_registration"

import PatientContractList from "../components/patients/patient_contract/patient_contract_list"
import PatientContract from "../components/patients/patient_contract/patient_contract"
import PatientContractUpdate from "../components/patients/patient_contract/patient_contract_update"
import PatientContractPDF from "../components/patients/patient_contract/patient_contract_pdf"
import ConsentPDF from "../components/patients/consent/consent_pdf"

import Overview from "../components/patients/assessments/overview"
import Phq9 from "../components/patients/assessments/phq9"
import Asrm from "../components/patients/assessments/asrm"
import Gad7 from "../components/patients/assessments/gad7"
import Cobra from "../components/patients/assessments/cobra"
import Pw18 from "../components/patients/assessments/pw18"
import Der18 from "../components/patients/assessments/ders18"
import Psqi from "../components/patients/assessments/psqi"
import CompleteBaseline from "../components/patients/assessments/complete_baseline"
import Sorry from "../components/patients/assessments/sorry"

import Dashboard from "../components/patients/dashboard/dashboard"
import TrackerPreviousDay from "../components/patients/dashboard/tracker_previous_day"

import GeneralInformationAdditional from "../components/patients/enroll/general_information_additional"

const PATIENTS_PRE_ROUTES = [

  {   path: "/member/overview_registration",
      key: "overview_registration",
      title: "Overview Registration",
      exact: true,
      requireAuth: true,
      component: () => <OverviewRegistration />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/general_information",
      key: "generalinformation",
      title: "General Information",
      exact: true,
      requireAuth: true,
      component: () => <GeneralInformation />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/medical_history",
      key: "medicalhistory",
      title: "Medical History",
      exact: true,
      requireAuth: true,
      component: () => <MedicalHistory />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/psychiatric_history",
      key: "psychiatrichistory",
      title: "Psychiatric History",
      exact: true,
      requireAuth: true,
      component: () => <PsychiatricHistory />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/treatment_consent",
      key: "treatmentconsent",
      title: "Treatment Consent Form",
      exact: true,
      requireAuth: true,
      component: () => <TreatmentConsent />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/information_sheet",
      key: "information_sheet",
      title: "Information Sheet",
      exact: true,
      requireAuth: true,
      component: () => <InformationSheet />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/communication_consent",
      key: "communicationconsent",
      title: "Communication Consent Form",
      exact: true,
      requireAuth: true,
      component: () => <CommunicationConsent />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/clinic_policies",
      key: "clinicpolicies",
      title: "Clinic Policies",
      exact: true,
      requireAuth: true,
      component: () => <ClinicPolicies />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/patient_waiver",
      key: "patientwaiver",
      title: "Patient Waiver",
      exact: true,
      requireAuth: true,
      component: () => <PatientWaiver />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/roi_instruction",
      key: "roi instruction",
      title: "ROI Instruction",
      exact: true,
      requireAuth: true,
      component: () => <RoiInstruction />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/roi",
      key: "roi",
      title: "ROI",
      exact: true,
      requireAuth: true,
      component: () => <Roi />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/complete_registration",
      key: "complete_registration",
      title: "Complete Registration",
      exact: true,
      requireAuth: true,
      component: () => <CompleteRegistration />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/overview/:type/:timepoint",
      key: "overview",
      title: "Overview",
      exact: true,
      requireAuth: true,
      component: () => <Overview />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/phq9/:type/:timepoint",
      key: "phq9",
      title: "PHQ9",
      exact: true,
      requireAuth: true,
      component: () => <Phq9 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/asrm/:type/:timepoint",
      key: "asrm",
      title: "ASRM",
      exact: true,
      requireAuth: true,
      component: () => <Asrm />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/gad7/:type/:timepoint",
      key: "gad7",
      title: "GAD-7",
      exact: true,
      requireAuth: true,
      component: () => <Gad7 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/cobra/:type/:timepoint",
      key: "cobra",
      title: "COBRA",
      exact: true,
      requireAuth: true,
      component: () => <Cobra />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/pw18/:type/:timepoint",
      key: "pw18",
      title: "PW18",
      exact: true,
      requireAuth: true,
      component: () => <Pw18 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/ders18/:type/:timepoint",
      key: "der18",
      title: "DER-18",
      exact: true,
      requireAuth: true,
      component: () => <Der18 />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/psqi/:type/:timepoint",
      key: "psqi",
      title: "PSQI",
      exact: true,
      requireAuth: true,
      component: () => <Psqi />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/complete_baseline/:type/:timepoint",
      key: "complete_baseline",
      title: "Complete Baseline",
      exact: true,
      requireAuth: true,
      component: () => <CompleteBaseline />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/assessment/sorry/:type",
      key: "sorry",
      title: "Sorry",
      exact: true,
      requireAuth: true,
      component: () => <Sorry />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/dashboard",
      key: "dashboard",
      title: "Dashboard",
      exact: true,
      requireAuth: true,
      component: () => <Dashboard />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/tracker/previous_day",
      key: "previous_day",
      title: "Dashboard Previous Day",
      exact: true,
      requireAuth: true,
      component: () => <TrackerPreviousDay />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  // Patient Contract //
  {   path: "/member/new_contract/:pid",
      key: "new patient contract",
      title: "New Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/member/consent_forms",
      key: "consent_forms",
      title: "Consent Forms",
      exact: true,
      requireAuth: true,
      component: () => <ConsentFormList />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/consent/:consent",
      key: "consent",
      title: "Patient Consent",
      exact: true,
      requireAuth: true,
      component: () => <ConsentPDF />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/patient_contract_list",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractList />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/patient_contract",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/update_patient_contract",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/edit_patient_contract/:contract_id",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContract />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/sign_contract/:contract_id",
      key: "patientcontract",
      title: "Patient Contract",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractUpdate />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/patient_contract_update/:contract_id",
      key: "patientcontractupdate",
      title: "Patient Contract Update",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractUpdate />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  {   path: "/member/patient_contract_pdf/:contract_id",
      key: "patientcontractpdf",
      title: "Patient Contract PDF",
      exact: true,
      requireAuth: true,
      component: () => <PatientContractPDF />,
      showtitle: 'true',
      ficon: 'fi-home',
  },
  // Additional //
  {   path: "/member/additional/general_information",
      key: "generalinformation",
      title: "General Information",
      exact: true,
      requireAuth: true,
      component: () => <GeneralInformationAdditional />,
      showtitle: 'true',
      ficon: 'fi-home',
  },

];

export default PATIENTS_PRE_ROUTES;
