import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import PostAddIcon from '@material-ui/icons/PostAdd'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AddBoxIcon from '@material-ui/icons/AddBox';
import { loadLocationWithMessage } from '../../../../../actions/location'
import '../../../../../styles/assets/css/admin/list2.css'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WarningIcon from '@material-ui/icons/Warning'
import Pagination from '../../../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { completion, treatment_targets, yes_no } from '../../variables/treatment_plan.js'

class TeamNotesList extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          dataItems: [],
          pageOfItems: [],
          cnt_active: 0,
          treatmentGoal: [],
          todoList: [],
          p_status: 0,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getData()
    //console.log(treatment_targets)
  }

  getData = () => {
    // get current treatment goal //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_current_treatment_goal',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'] != 0) {
        this.setState({
          treatmentGoal: result['data'],
          p_status: result['data'][0].status
        })
      }
    })
    // get treatment plan list //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result['data'])
      var cnt = 0
      if(result['data'].length > 0) {
        result['data'].map(each => {
          if(each.completion == 2) {
            cnt++
          }
        })
        this.setState({
          dataItems: result['data'],
          cnt_active: cnt,
        })
      }
    })
    // get treatment to do list //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_active_todo_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
      this.setState({
        todoList: result['data'],
      })
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  getTodoListValue(arr, plan_id) {
    var txt = ''
    var buff = ''
    var sep = []
    var cnt_line = 0
    if(arr.length > 0) {
      arr.map(obj => {
        //console.log(obj)
        if(obj.interventions && obj.plan_id == plan_id) {
          buff = obj.interventions
          sep = buff.split('|||')
          sep.map((each, index) => {
            buff = each.split('###')
            if(buff[1] != 1 && buff[1] != 3) {
              if(cnt_line > 0) {
                txt += "<br />"
              }
              txt += '- '+buff[0]
              cnt_line++
            }
          })
        }
      })
    }
    return txt
  }

  genTreatmentTarget2(arr, pid, tid) {
    var txt = ''
    if(arr) {
      var sep = arr.split(',')
      var sep2 = []
      var cnt_line = 0
      sep.map((each, index) => {
        sep2 = each.split('-')
        //console.log(sep[0] + ': ' + sep2[0] + ', ' + sep2[1])
        if(sep2[2] != 1 && sep2[1] != 1 && sep2[3] != 1 && sep2[3] != 3) {
          if(cnt_line > 0) {
            txt += "<br />"
          }
          txt += '- '+this.findLabelByValue(treatment_targets, sep2[0])
          if(sep2[1] == 1) {
            txt += ' [Full]'
          } else if(sep2[1] == 2) {
            txt += ' [Partial]'
          } else if(sep2[1] == 3) {
            txt += ' [None]'
          }
          cnt_line++
        }
      })
    }
    return txt
  }

  genTreatmentTarget(arr, pid, tid) {
    var txt = ''
    var target_list = []
    if(arr) {
      var sep = arr.split('|||')
      var sep2 = []
      var cnt_line = 0
      sep.map((each, index) => {
        sep2 = each.split('-')
        //console.log(sep[0] + ': ' + sep2[0] + ', ' + sep2[1])
        if(sep2[2] != 1 && sep2[1] != 1 && sep2[3] != 1 && sep2[3] != 3) {
          if(!target_list.includes(sep2[0])){
            target_list.push(sep2[0])
            if(cnt_line > 0) {
              txt += "<br />"
            }
            txt += '- '+this.findLabelByValue(treatment_targets, sep2[0])
            if(sep2[1] == 1) {
              txt += ' [Full]'
            } else if(sep2[1] == 2) {
              txt += ' [Partial]'
            } else if(sep2[1] == 3) {
              txt += ' [None]'
            }
            cnt_line++
          }
        }
      })
    }
    return txt
  }

  genNewTreatmentTarget(arr, pid, tid, completion) {
    var txt = ''
    if(arr) {
      var sep = arr.split(',')
      var sep2 = []
      var cnt_line = 0
      sep.map((each, index) => {
        sep2 = each.split('-')
        //console.log(sep[0] + ': ' + sep2[0] + ', ' + sep2[1])
        if(sep2[2] != 1 && sep2[1] != 1) {
          cnt_line++
        } else if(completion == 2) {
          cnt_line++
        }
      })
    }
    return cnt_line
  }

  render() {
    //console.log(this.state)
    return (
    <div className="admin_treatment_plan">
      <div className="row">
        <div className="small-8 columns title4">
          Team Meeting Notes
        </div>
        <div className="small-4 columns bold button_header2">
          <NavLink to={'/admin/treatment_plan/new_team_notes/'+this.props.match.params.pid} className="menu_list_active">
            New Team Meeting Note
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns ">
          { typeof this.props.locationReducer.txt_message !== 'undefined' &&
            this.props.locationReducer.txt_message !== "" &&
            <div className="row warning_msg7">
              <div className="small-12 columns ">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            </div>}
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
        <table className="table_data1">
          <thead className="table_head">
            <tr>
              <th className="w_num">#</th>
              <th className="w_st">Status</th>
              <th className="w_st">Created by</th>
              <th className="w_tt">Submit Date/Time</th>
            </tr>
          </thead>
          <tbody>
        {this.state && this.state.dataItems && this.state.pageOfItems.map((item, index) =>
          <tr key={'d'+index}>
            <td>{index+1}</td>
            <td>
              <a href={"/admin/treatment_plan/treatment_plan/"+item.pid+"/"+item.id}>
                {item.treatment_goal_id > 0 && item.tm_goal}
                {item.treatment_goal_id < 0 && item.treatment_goal_other}
              </a></td>
            <td><a href={"/admin/treatment_plan/treatment_plan_history_log/"+item.pid+"/"+item.id}>History</a></td>
            <td className="align_left tm_target_icon">
              {ReactHtmlParser(this.genTreatmentTarget(item.tm_target, item.pid, item.id))}
              {this.genNewTreatmentTarget(item.tm_target, item.pid, item.id, item.completion) > 0 &&
                <span><br />
                  <a href={"/admin/treatment_plan/new_treatment_target/"+item.pid+"/"+item.id}><AddBoxIcon />Intermediate Goal</a>
                </span>
              }
            </td>
            <td className="align_left">
            {ReactHtmlParser(this.getTodoListValue(this.state.todoList, item.id))}
            {item.completion == 2 &&
            <span><br />
              <a href={"/admin/treatment_plan/new_to_do_list/"+item.pid+"/"+item.id}><AddBoxIcon />To Do List</a>
            </span>
            }
            </td>
            <td>{this.findLabelByValue(yes_no, item.completion)}</td>
            <td>{item.created_datetime} / <br />{item.updated_datetime}</td>
          </tr>
        )}
        
        </tbody>
        </table>
        </div>
      </div>
    </div>)
  }
}

const mapStateToProps = (state) => {
  //console.log('Treatment Plan List STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TeamNotesList)
