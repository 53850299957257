export const covid_questions = [
  {
    label: 'Routines:',
    choices: 'q1',
    value: 1,
  }, {
    label: 'Family Income/Employment:',
    value: 2,
  }, {
    label: 'Food Access:',
    value: 3,
  }, {
    label: 'Medical health care access:',
    value: 4,
  }, {
    label: 'Mental health treatment access:',
    value: 5,
  }, {
    label: 'Access to extended family and non-family social supports:',
    value: 6,
  }, {
    label: 'Experiences of stress related to coronavirus pandemic:',
    value: 7,
  }, {
    label: 'Stress and discord in the family:',
    value: 8,
  }, {
    label: 'Personal diagnosis of coronavirus.',
    value: 9,
  }, {
    label: 'Number of immediate family members diagnosed with coronavirus:',
    value: 10,
  }, {
    label: 'Number of extended family member(s) and/or close friends diagnosed with coronavirus:',
    value: 11,
  }, {
    label: 'Other. Please tell us about any other ways the coronavirus pandemic has impacted your life:',
    value: 12,
  },
]

export const q1 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Change in only one area (e.g. work, education, social life, hobbies, religious activities).',
    value: 1,
  }, {
    label: 'Moderate. Change in two areas (e.g. work, education, social life, hobbies, religious activities).',
    value: 2,
  }, {
    label: 'Severe. Change in three or more areas (e.g. work, education, social life, hobbies, religious activities).',
    value: 3,
  },
]

export const q2 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Small change; able to meet all needs and pay bills.',
    value: 1,
  }, {
    label: 'Moderate. Having to make cuts but able to meet basic needs and pay bills.',
    value: 2,
  }, {
    label: 'Severe. Unable to meet basic needs and/or pay bills.',
    value: 3,
  },
]

export const q3 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Enough food but difficulty getting to stores and/or finding needed items.',
    value: 1,
  }, {
    label: 'Moderate. Occasionally without enough food and/or good quality (i.e., healthy) foods.',
    value: 2,
  }, {
    label: 'Severe. Frequently without enough food and/or good quality (i.e., healthy) foods.',
    value: 3,
  },
]

export const q4 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Appointments moved to telehealth.',
    value: 1,
  }, {
    label: 'Moderate. Delays or cancellations in appointments and/or delays in getting prescriptions; changes have minimal impact on health.',
    value: 2,
  }, {
    label: 'Severe. Unable to access needed care resulting in moderate to severe impact on health.',
    value: 3,
  },
]

export const q5 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Appointments moved to telehealth.',
    value: 1,
  }, {
    label: 'Moderate. Delays or cancellations in appointments and/or delays in getting prescriptions; changes have minimal impact.',
    value: 2,
  }, {
    label: 'Severe. Unable to access needed care resulting in severe risk and/or significant impact.',
    value: 3,
  },
]

export const q6 = [
  {
    label: 'No change.',
    value: 0,
  }, {
    label: 'Mild. Continued visits with social distancing and/or regular phone calls and/or televideo or social media contacts.',
    value: 1,
  }, {
    label: 'Moderate. Loss of in person and remote contact with a few people, but not all supports.',
    value: 2,
  }, {
    label: 'Severe. Loss of in person and remote contact with all supports.',
    value: 3,
  },
]

export const q7 = [
  {
    label: 'None.',
    value: 0,
  }, {
    label: 'Mild. Occasional worries and/or minor stress-related symptoms (e.g., feel a little anxious, sad, and/or angry; mild/rare trouble sleeping).',
    value: 1,
  }, {
    label: 'Moderate. Frequent worries and/or moderate stress-related symptoms (e.g., feel moderately anxious, sad, and/or angry; moderate/occasional trouble sleeping).',
    value: 2,
  }, {
    label: 'Severe. Persistent worries and/or severe stress-related symptoms (e.g., feel extremely anxious, sad, and/or angry; severe/frequent trouble sleeping).',
    value: 3,
  },
]

export const q8 = [
  {
    label: 'None.',
    value: 0,
  }, {
    label: 'Mild. Family members occasionally short-tempered with one another; no physical violence.',
    value: 1,
  }, {
    label: 'Moderate. Family members frequently short-tempered with one another; and/or children in the home getting in physical fights with one another.',
    value: 2,
  }, {
    label: 'Severe. Family members frequently short-tempered with one another and adults in the home throwing things at one another, and/or knocking over furniture, and/or hitting and/or harming one another.',
    value: 3,
  },
]

export const q9 = [
  {
    label: 'None.',
    value: 0,
  }, {
    label: 'Mild. Symptoms effectively managed at home.',
    value: 1,
  }, {
    label: 'Moderate. Symptoms severe and required brief hospitalization.',
    value: 2,
  }, {
    label: 'Severe. Symptoms severe and required ventilation.',
    value: 3,
  },
]

export const q10 = [
  {
    label: 'Mild. Symptoms effectively managed at home.',
    value: 1,
  }, {
    label: 'Moderate. Symptoms severe and required brief hospitalization.',
    value: 2,
  }, {
    label: 'Severe. Symptoms severe and required ventilation.',
    value: 3,
  }, {
    label: 'Immediate family member died from coronavirus.',
    value: 4,
  },
]

export const q11 = [
  {
    label: 'Mild. Symptoms effectively managed at home.',
    value: 1,
  }, {
    label: 'Moderate. Symptoms severe and required brief hospitalization.',
    value: 2,
  }, {
    label: 'Severe. Symptoms severe and required ventilation.',
    value: 3,
  }, {
    label: 'Extended family member and/or close friend died of coronavirus.',
    value: 4,
  },
]
