import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no, todolist_status } from '../../variables/treatment_plan.js'

class NewToDoList extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_open: false,
          long_term_goal: '',
          treatment_target: 0,
          modality: 0,
          modality_other: '',
          intervention: '',
          intervention_format: 0,
          intervention_date: '',
          intervention_format_other: '',
          todo_list: [],
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_todo_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        tpid: this.props.match.params.tpid
     }
    })
    .then((result) => {
      console.log(result['data'][0].value)
      this.setState({
        todo_list: result['data'][0].value,
        long_term_goal: result['data'][1].value.val,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  handleSubmit = (e) => {
    //console.log(' --- handleSubmit ---')
    //console.log(this.state)
    e.preventDefault()
    var chk = true
    if(this.state.intervention === ''
      || this.state.modality == 0 ||
      (this.state.modality == 21 && this.state.modality_other === '')
      || this.state.intervention_format == 0 || this.state.intervention_date === '' ||
      (this.state.intervention_format == 10 && this.state.intervention_format_other === '')) {
        //console.log('-- case 4 --')
        chk = false
    }

    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/add_intervention',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          plan_id: this.props.match.params.tpid,
          user: this.state,
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/3/2', 'Added To Do List item successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/3/2')
        }
      })
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  transformDate2 = (getDate) => {
    var buff = getDate.split("-")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[1]+'/'+buff[2]+'/'+buff[0]
    }
    return toDate
  }

  render() {
    console.log(this.state.todo_list)
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            To Do List
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                      <div className="row no_margin_bottom" >
                        <div className="small-12 columns ">
                        <div className="">
                          <div className="small-12 columns ">
                            <span className="bold">Long Term Treatment Goal:</span> &nbsp;
                            {this.state.long_term_goal}
                          </div>

                          <div className="small-12 columns bold space_top_1">To Do List item</div>
                          <div className="small-12 columns">
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange2(e, 'intervention')}
                            value={this.state.intervention}
                            InputProps={{ inputProps: { maxLength: 240 }}} />
                          </div>

                          <div className="small-12 columns bold space_top_1">Treatment Modality</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.modality}
                                onChange={(e) => this.handleChange2(e, 'modality')}
                                variant="outlined" label="" className="width_auto">
                                {treatment_modality.map( each =>
                                  <MenuItem key={'tm'+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.modality == 21 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange2(e, 'modality_other')}
                              value={this.state.modality_other}
                              InputProps={{ inputProps: { maxLength: 95, placeholder: 'Please specify' }}} />
                            </div>}

                          <div className="small-12 columns bold space_top_1">To Do List item Format</div>
                          <div className="small-12 columns float_left">
                            <FormControl variant="outlined">
                              <Select value={this.state.intervention_format}
                                onChange={(e) => this.handleChange2(e, 'intervention_format')}
                                variant="outlined" label="" className="width_auto">
                                {intervention_format.map( each =>
                                  <MenuItem key={'if'+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          {this.state.intervention_format == 10 &&
                            <div className="small-12 columns">
                            <TextField variant="outlined" type="text" className="space_top_1"
                              onChange={(e) => this.handleChange2(e, 'intervention_format_other')}
                              value={this.state.intervention_format_other}
                              InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                            </div>}
                          <div className="small-12 columns bold space_top_1">Date To Do List item assigned</div>
                          <div className="small-12 columns float_left width_auto">
                            <TextField variant="outlined" type="date" format={'MM/DD/YYYY'}
                            onChange={(e) => this.handleChange2(e, 'intervention_date')}
                            value={this.transformDate(this.state.intervention_date)} />
                          </div>
                        </div>
                        </div>
                      </div>
                  </div>
                  {this.state.todo_list != 0 && this.state.todo_list.map(item =>
                  <div className="small-12 columns box_inner4 top_space1">
                      <div className="row no_margin_bottom" >
                        <div className="small-12 columns ">
                        <div className="">
                          <div className="small-12 columns space_top_1">
                            <span className="bold">To Do List item:&nbsp;</span>
                            {item.intervention}&nbsp;
                            {(item.todo_status != null || item.target_completion != 0) && "["}
                            {item.todo_status != null && this.findLabelByValue(todolist_status, item.todo_status)}
                            {item.todo_status == null && item.target_completion != 0 && this.findLabelByValue(completion, item.target_completion)+" target completion"}
                            {(item.todo_status != null || item.target_completion != 0) && "]"}</div>

                          <div className="small-12 columns space_top_1">
                            <span className="bold">Treatment Modality:&nbsp;</span>
                            {this.findLabelByValue(treatment_modality, item.modality_id)}
                            {item.modality_id == 21 && " ["+item.modality_other+"]"}
                            </div>

                          <div className="small-12 columns space_top_1">
                            <span className="bold">To Do List item Format:&nbsp;</span>
                            {this.findLabelByValue(intervention_format, item.intervention_format)}
                            {item.intervention_format == 10 && " ["+item.intervention_format_other+"]"}
                            </div>

                          <div className="small-12 columns space_top_1">
                            <span className="bold">Date To Do List item assigned: &nbsp;</span>
                            {this.transformDate2(item.modality_date)}</div>

                        </div>
                        </div>
                      </div>
                  </div>)}
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(NewToDoList)
