import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocation } from '../../../actions/location'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Spinner from 'react-spinner-material'

class CgiBdList extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          getData: [],
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = async () => {
    await axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_cgi_bd_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      this.setState({
        getData: result['data'],
      })
    })
  }

  render() {
    return (
      <div className="row">
        <div className="small-12 medium-3 columns title4">
          CGI-BD
        </div>
        <div className="small-12 medium-9 columns bold button_header2">
        {this.props.authReducer.role == 3 &&
          <Link to={'/admin/assessments/cgi_bd/'+this.props.match.params.pid}
          className="menu_list_active new_appt">
            New CGI-BD</Link>}
        </div>
        <div className="small-12 columns ">
          { typeof this.props.locationReducer.txt_message !== 'undefined' &&
            this.props.locationReducer.txt_message !== "" &&
            <div className="row warning_msg7">
              <div className="small-12 columns ">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            </div>}
          <div>
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th className="align_left">Date</th>
                  <th className="align_left">Rater</th>
                  <th className="align_left">Link</th>
                </tr>
              </thead>
              <tbody>
              {!this.state.getData &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state.getData.map(each =>
                <tr>
                  <td>{each.date_visit}</td>
                  <td>{each.c_name}</td>
                  <td><Link to={'/admin/assessments/cgi_bd/view/'+this.props.match.params.pid+'/'+each.id}>Link</Link></td>
                </tr>)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CgiBdList)
