import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { who5_questions, who5_choices } from '../variables/assessments/who5.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

class Who5 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'complete_assessments',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    if(this.props.match.params.type === 'w') {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      }).then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_week) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_week) {
          // Current timepoint => Fill the form //
          if(result['data'].w_who5 == 0) {
            this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
            this.props.history.push('/member/assessment_list')
          }
          if(result['data'].status !== null) {
            if(result['data'].status == 2) {
              // Completed //
              this.getCompletedData(2)
            }
          } else {
            // Insert new row //
            axios({
              method: 'post',
              url: connectNode + 'patient/assessment/add_new_assm_process_wo_overview',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
              },
              data: {
                uid: this.props.authReducer.uid,
                type: this.props.match.params.type,
                timepoint: this.props.match.params.timepoint,
                next_step: 'who5',
               }
            })
            .then((result) => { })
            this.setState({
              enable_phq4: result['data'].w_phq4,
              enable_who5: result['data'].w_who5
            })
          }
        } else {
          // Past timepoint //
          this.getCompletedData(3)
        }
      })
    } else {
      this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
      this.props.history.push('/member/assessment_list')
    }
  }

  getCompletedData = (status) => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_assm_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: this.props.match.params.type,
        timepoint: this.props.match.params.timepoint,
        frm: 'who5',
       }
    }).then((result) => {
      this.setState({
        status: status,
        q1: result['data'].q1,
        q2: result['data'].q2,
        q3: result['data'].q3,
        q4: result['data'].q4,
        q5: result['data'].q5,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    var next_step = 'complete_assessments'
    if(this.state.status === 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_who5',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            timepoint: timepoint,
            type: type,
          }
        })
        .then((result) => {
          if(result['data'] === this.props.authReducer.uid) {
            var next_step = 'complete_assessments'
            this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
            this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
          }
        })
      }
    }else{
      this.props.onLoadLocation('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
      this.props.history.push('/member/assessment/'+next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {
    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            { this.props.match.params.type !== 'w' &&
            <span>WHO (Five) Well-Being Index (1998 version)</span>}
            { this.props.match.params.type === 'w' &&
            <span>Weekly Questionnaire 2: WHO-5 Well-Being Index</span> }
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-6 columns">
                Over the last two weeks:
              </div>
              {who5_choices.map(each =>
                <div className="medium-1 columns headerResize1" key={'w'+each.value}>
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>

            {who5_questions.map(eachQ =>
              <div className="row" key={'w5'+eachQ.value}>
                <div className="small-12 medium-6 columns">
                  {eachQ.value}. {eachQ.label}
                </div>
                {who5_choices.map(each =>
                  <div className="small-12 medium-1 columns" key={'wc'+eachQ.value+each.value}>
                    <input type="radio" name={"q"+eachQ.value} value={each.value}
                    checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
                { eachQ.value < 5 &&
                <div className="small-12 columns">
                  <div className="sep_line2">&nbsp;</div>
                </div>}
              </div>
            )}

        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Who5)
