import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/thankyou1.png'
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';

class Intro15 extends Component {
  handleSubmit = (e) => {
    window.location.href = "https://dautenbipolarcenter.org"
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction15')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_1 margin-bottom1">
              THANK YOU!
            </div>
            <div>
              <div className="img_center w_100percent">
                <img src={Image} alt="team" />
              </div>
            </div>
            <div className="content1 margin-top1">
              <p><ul>
                <li>Thank you for watching this introduction to FITT-BD.</li>
                <li>If you have any questions, or you’d like to enroll, please feel free to contact us at the number on the bottom of this screen.</li>
              </ul></p>
              <p className="margin-top1"><PhoneEnabledIcon /> 617-726-6928</p>
            </div>
            <div className="row">
              <div className="small-12 medium-4 columns back_button3">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-12 medium-4 columns next_button3">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Go Back to Dauten Family Center
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro15)
