export const ders18_questions = [
  {
    label: 'I pay attention to how I feel.',
    value: 1,
  }, {
    label: 'I have no idea how I am feeling.',
    value: 2,
  }, {
    label: 'I have difficulty making sense out of my feelings.',
    value: 3,
  }, {
    label: 'I am attentive to my feelings.',
    value: 4,
  }, {
    label: 'I am confused about how I feel.',
    value: 5,
  }, {
    label: 'When I’m upset, I acknowledge my emotions.',
    value: 6,
  }, {
    label: 'When I’m upset, I become embarrassed for feeling that way.',
    value: 7,
  }, {
    label: 'When I’m upset, I have difficulty getting work done.',
    value: 8,
  }, {
    label: 'When I’m upset, I become out of control.',
    value: 9,
  },  {
    label: "When I'm upset, I believe that I will remain that way for a long time.",
    value: 10,
  }, {
    label: 'When I’m upset, I believe that I’ll end up feeling very depressed.',
    value: 11,
  }, {
    label: 'When I’m upset, I have difficulty focusing on other things.',
    value: 12,
  },{
    label: "When I'm upset, I feel ashamed of myself for feeling that way.",
    value: 13,
  }, {
    label: 'When I’m upset, I feel guilty for feeling that way.',
    value: 14,
  }, {
    label: 'When I’m upset, I have difficulty concentrating.',
    value: 15,
  }, {
    label: 'When I’m upset, I have difficulty controlling my behaviors.',
    value: 16,
  }, {
    label: 'When I’m upset, I believe that wallowing in it is all I can do.',
    value: 17,
  }, {
    label: 'When I’m upset, I lose control over my behaviors.',
    value: 18,
  },
]

export const ders18_choices = [
  {
    label: 'Almost never',
    value: 1,
  }, {
    label: 'Sometimes',
    value: 2,
  }, {
    label: 'About half the time',
    value: 3,
  }, {
    label: 'Most of the time',
    value: 4,
  }, {
    label: 'Almost always',
    value: 5,
  },
]

export const ders18_choices2 = [
  {
    label: '(0-10%)',
    value: 1,
  }, {
    label: '(11-35%)',
    value: 2,
  }, {
    label: '(36-65%)',
    value: 3,
  }, {
    label: '(66-90%)',
    value: 4,
  }, {
    label: '(91-100%)',
    value: 5,
  },
]
