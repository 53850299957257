import React, { Component } from 'react'
import '../../styles/assets/css/pages/changelog.css'

class ChangeLog extends Component {
  render() {
    return (
      <div className="changelog">
        <div className="wrapper row">
          <div className="small-12 columns main_box">
            <div className="header1">Change Log</div>
            <div className="margin_bottom_1">
              All notable changes to this project will be documented in this file.
            </div>

            <div className="header2">[1.1.47] - 2022-06-29</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add the feature for disable user status [Ticket 508]</li>
              </ul>
            </div>
            <div className="header2">[1.1.46] - 2022-06-15</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Treatment plan glitch on admin/clinician portal [Ticket 505]</li>
              </ul>
            </div>
            <div className="header2">[1.1.45] - 2022-05-10</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Treatment plan history doesn't show correctly [Ticket 478]</li>
              </ul>
            </div>
            <div className="header3">Edited</div>
            <div>
              <ul>
                <li>Change MRTF filter [Ticket 477]</li>
              </ul>
            </div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Zero drink on AUDIT-C [Ticket 474]</li>
              </ul>
            </div>

            <div className="header2">[1.1.44] - 2022-05-06</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Non-psychiatric medication shouldn't show in MRTF [Ticket 385]</li>
                <li>Add filter to MRTF for each type of medication [Ticket 468]</li>
              </ul>
            </div>

            <div className="header2">[1.1.43] - 2022-05-05</div>
            <div className="header3">Edited</div>
            <div>
              <ul>
                <li>Change language of email 2B  [Ticket ]</li>
              </ul>
            </div>

            <div className="header2">[1.1.42] - 2022-05-03</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add filter to not see inactives/withdrawn—make this the default setting [Ticket 431]</li>
                <li>Add Registered days on patient list on admin/clinician portal [Ticket 473]</li>
              </ul>
            </div>

            <div className="header2">[1.1.41] - 2022-05-02</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add update patient status in admin/clinician portal [Ticket 406]</li>
              </ul>
            </div>

            <div className="header2">[1.1.40] - 2022-04-29</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add Save button on update the treatment plan [Ticket 462]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Daily/weekly trackers show wrong order [Ticket 470]</li>
              </ul>
            </div>

            <div className="header2">[1.1.39] - 2022-04-21</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>ASRM baseline assessment score show zero [Ticket 395]</li>
                <li>Forgot password show "null" username [Ticket 464]</li>
              </ul>
            </div>

            <div className="header2">[1.1.38] - 2022-04-20</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add To Do List on clinician/admin portal [Ticket 459]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Check assessments on mobile devices [Ticket 421]</li>
              </ul>
            </div>

            <div className="header2">[1.1.37] - 2022-04-19</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add correct medication to MRTF [Ticket 458]</li>
              </ul>
            </div>

            <div className="header2">[1.1.36] - 2022-04-15</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Treatment Plan shows the completed one [Ticket 460]</li>
              </ul>
            </div>

            <div className="header2">[1.1.35] - 2022-04-11</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Tracker chart without data point to -1 [Ticket 451]</li>
              </ul>
            </div>

            <div className="header2">[1.1.34] - 2022-03-21</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Add screen questions in BD-Screen [Ticket 387]</li>
              </ul>
            </div>

            <div className="header2">[1.1.33] - 2022-03-17</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>No 'Add Treatment Plan' link for incomplete treatment plan [Ticket 448]</li>
              </ul>
            </div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add substance use screening questions plus tobacco use to intake appointment  [Ticket 415]</li>
              </ul>
            </div>

            <div className="header2">[1.1.32] - 2022-03-16</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Patient completed registration without system saved psychiatric history [Ticket 445]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Treatment target that was changed should go away [Ticket 439]</li>
              </ul>
            </div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add "Self-guided program" to Intervention Format [Ticket 440]</li>
                <li>Add new treatment target to the treatment plan should not need to require the update all the data [Ticket 442]</li>
              </ul>
            </div>

            <div className="header2">[1.1.31] - 2022-03-15</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add Note in treatment target [Ticket 423]</li>
              </ul>
            </div>

            <div className="header2">[1.1.30] - 2022-03-14</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Error when submit treatment plan [Ticket 436]</li>
                <li>Treatment Plan show "Please fill all information" even though filled all information [Ticket 437]</li>
                <li>Cannot past the treatment consent form page [Ticket 438]</li>
              </ul>
            </div>

            <div className="header2">[1.1.29] - 2022-03-09</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Duplicated medications [Ticket 413]</li>
              </ul>
            </div>

            <div className="header2">[1.1.28] - 2022-03-08</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Able to reschedule past appointment [Ticket 414]</li>
                <li>Change "New Contact" to "Contact Detail" [Ticket 419]</li>
                <li>Change showing default status from "Upcoming" to "All Status" [Ticket 420]</li>
              </ul>
            </div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add new options in dropdown list of contact log [Ticket 412]</li>
              </ul>
            </div>

            <div className="header2">[1.1.27] - 2022-03-04</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change patient contract work flow [Ticket 408]</li>
              </ul>
            </div>

            <div className="header2">[1.1.26] - 2022-03-02</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add PDF original form as link on each assessments [Ticket 402]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Who-5 doesn't show questions-answers in detail page [Ticket 396]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Take out automatically inactive status in admin/clinician portal [Ticket 407]</li>
                <li>Take out PHQ-4 column from patient chart for the new patient [Ticket 403]</li>
              </ul>
            </div>

            <div className="header2">[1.1.25] - 2022-03-01</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add textfield for treatment targets Notes [Ticket 389]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change to no assign Fellow/Student after patients finished registration [Ticket 405]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Wrong start and end baseline assessments [Ticket 404]</li>
              </ul>
            </div>

            <div className="header2">[1.1.24] - 2022-02-28</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Add ? to be mouse over to show treatment goal [Ticket 390]</li>
                <li>Change Initiated by in contact log [Ticket 386]</li>
                <li>Change Daily/weekly tracker on admin/clinician portal to show descendant [Ticket 393]</li>
                <li>Move date/time of assessments in admin/clinician portal to be the same alignment [Ticket 398]</li>
                <li>Show - instead of 0 for incomplete assessments [Ticket 394]</li>
              </ul>
            </div>

            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add start/end date time for baseline assessment [Ticket 397]</li>
              </ul>
            </div>

            <div className="header2">[1.1.23] - 2022-02-23</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>ROI wasn't generated [Ticket 400]</li>
              </ul>
            </div>

            <div className="header2">[1.1.22] - 2022-02-22</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Linking patient navigators to specific supervising clinicians [Ticket 378]</li>
                <li>Change logic of assigning primary clinician [Ticket 391]</li>
              </ul>
            </div>

            <div className="header2">[1.1.21] - 2022-02-18</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change status word on clinician/admin portal [Ticket 379]</li>
              </ul>
            </div>

            <div className="header2">[1.1.20] - 2022-02-17</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change adherence dropdown value [Ticket 384]</li>
              </ul>
            </div>

            <div className="header2">[1.1.20] - 2022-02-16</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Graph on patient dashboard doesn't update right away after submitting tracker [Ticket 368]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Mood tracker graph show incorrectly [Ticket 381]</li>
                <li>MRTF show medication dose incorrectly [Ticket 382]</li>
              </ul>
            </div>

            <div className="header2">[1.1.19] - 2022-02-09</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change status word on clinician/admin portal [Ticket 379]</li>
              </ul>
            </div>

            <div className="header2">[1.1.18] - 2022-02-03</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Tracker chart show last 30 days instead of last 30 times [Ticket 369]</li>
              </ul>
            </div>

            <div className="header2">[1.1.17] - 2022-02-02</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Split daily/weekly tracker scales [Ticket 367]</li>
              </ul>
            </div>

            <div className="header2">[1.1.16] - 2022-01-26</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Remove PHQ4 from weekly assessments [Ticket 361]</li>
                <li>Make dashboard to do list box bigger [Ticket 362]</li>
              </ul>
            </div>

            <div className="header2">[1.1.15] - 2022-01-24</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Cannot update Fellow/Student [Ticket 357]</li>
                <li>Patient Contract instruction shows in clinician/admin portal [Ticket 358]</li>
                <li>Dashboard tracker shows Submit button when all trackers are disabled [Ticket 359]</li>
              </ul>
            </div>

            <div className="header2">[1.1.14] - 2022-01-21</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add change log page [Ticket 321]</li>
                <li>Add buttons to introduction pages [Ticket 352]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Fix layout on mobile of introduction pages [Ticket 356]</li>
              </ul>
            </div>

            <div className="header2">[1.1.13] - 2022-01-20</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Remove Exercise in Medical history tab in clinician and admin portal [Ticket 351]</li>
              </ul>
            </div>

            <div className="header2">[1.1.12] - 2022-01-18</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add (Next 7 days) after Upcoming appointment status [Ticket 183]</li>
              </ul>
            </div>

            <div className="header2">[1.1.11] - 2022-01-15</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add searching patient and replace patient list page after clinician sign in [Ticket 328]</li>
              </ul>
            </div>

            <div className="header2">[1.1.10] - 2022-01-14</div>
            <div className="header3">Added / Changed</div>
            <div>
              <ul>
                <li>Update copyright of introduction pictures and put the link in DCBI website [Ticket 348]</li>
              </ul>
            </div>

            <div className="header2">[1.1.0] - 2022-01-13</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Export patient information as txt file [Ticket 317]</li>
                <li>Add ROI in Information tab in clinician and admin portal [Ticket 347]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Able to make past appointment [Ticket 341]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Date of birth in General Information doesn't show on Safari [Ticket 336]</li>
              </ul>
            </div>

            <div className="header2">[1.1.8] - 2022-01-06</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Highlight currently most updated medication on MTF [Ticket 316]</li>
              </ul>
            </div>

            <div className="header2">[1.1.7] - 2022-01-05</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change patient status on patient list of admin and clinician portal [Ticket 324]</li>
              </ul>
            </div>

            <div className="header2">[1.1.6] - 2022-01-04</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Update patient contract flow [Ticket 340]</li>
                <li>Update introduction pages</li>
              </ul>
            </div>

            <div className="header2">[1.1.5] - 2022-01-03</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add screen questionaire in admin/clinician portal [Ticket 334]</li>
              </ul>
            </div>

            <div className="header2">[1.1.4] - 2021-12-30</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Assign practicum student and patient navigator automatically [Ticket 326]</li>
              </ul>
            </div>

            <div className="header2">[1.1.3] - 2021-12-24</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add Save button for the draft Patient Contract before submitting [Ticket 332]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Add other description of Signs of Depression and Signs of (Hypo)mania to PDF file [Ticket 337]</li>
              </ul>
            </div>

            <div className="header2">[1.1.2] - 2021-12-21</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add patient’s phone number and email in the header of patient chart [Ticket 327]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Admin be able to edit patient contract, but cannot sign final signature [Ticket 320]</li>
              </ul>
            </div>

            <div className="header2">[1.1.1] - 2021-12-20</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Patient contract work flow [Ticket 308]</li>
              </ul>
            </div>

            <div className="header2">[1.0.16] - 2021-12-16</div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>General Information not save the data when textfield was filled in more than the certain number of characters [Ticket 330]</li>
              </ul>
            </div>

            <div className="header2">[1.0.15] - 2021-12-07</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add patient navigator [Ticket 323]</li>
              </ul>
            </div>

            <div className="header2">[1.0.14] - 2021-12-06</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add current dose on MRTF list on patient chart [Ticket 314]</li>
              </ul>
            </div>

            <div className="header2">[1.0.13] - 2021-11-25</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>FITT-BD Introduction pages [Ticket 322]</li>
              </ul>
            </div>

            <div className="header2">[1.0.12] - 2021-11-23</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change scale of tracker from number to word [Ticket 312]</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>MRTF log doesn't show in history [Ticket 318]</li>
              </ul>
            </div>

            <div className="header2">[1.0.11] - 2021-11-22</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Dashboard tracker start from zero [Ticket 311]</li>
              </ul>
            </div>

            <div className="header2">[1.0.10] - 2021-11-19</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Be able to add the new treatment plan without connecting to patient contract [Ticket 310]</li>
              </ul>
            </div>

            <div className="header2">[1.0.9] - 2021-11-18</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add "Edit" link behind patient's name on the header of patient chart to be able to change the patient's name [Ticket 292]</li>
              </ul>
            </div>

            <div className="header2">[1.0.8] - 2021-11-17</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Language of buttons and links [Ticket 295, 298]</li>
              </ul>
            </div>

            <div className="header2">[1.0.7] - 2021-11-16</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add email notification for the no-show appointment [Ticket 304]</li>
                <li>Add billable to appointment's email</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Email reminder and notification language</li>
              </ul>
            </div>
            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>PHQ-4 and PHQ-9 email notification conditions [Ticket 309]</li>
              </ul>
            </div>


            <div className="header2">[1.0.6] - 2021-11-15</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add Patient Navigator to the header of patient chart [Ticket 296]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Admin can see patient contract before it be generated to PDF [Ticket 299]</li>
              </ul>
            </div>

            <div className="header2">[1.0.5] - 2021-11-12</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add session for the patient contract form in patient portal [Ticket 297]</li>
                <li>Add other to appointment provider with textbox to fill provider name [Ticket 307]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Change the word from Week to Frequency on Appointment [Ticket 303]</li>
              </ul>
            </div>

            <div className="header2">[1.0.4] - 2021-11-10</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add specify box to all of sign of Depression/Mania in Patient Contract [Ticket 301]</li>
              </ul>
            </div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Edit email 3C</li>
              </ul>
            </div>

            <div className="header3">Fixed</div>
            <div>
              <ul>
                <li>Email log</li>
              </ul>
            </div>

            <div className="header2">[1.0.3] - 2021-11-09</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add Patient Navigator to Clinician/Student tab in patient chart [Ticket 285]</li>
                <li>Add the language to the top of registration and baseline assessments page [Ticket 294]</li>
              </ul>
            </div>

            <div className="header2">[1.0.2] - 2021-11-05</div>
            <div className="header3">Changed</div>
            <div>
              <ul>
                <li>Patient contract be able to start after completed MINI</li>
                <li>Patient contract is not require to fill all information to click submit</li>
              </ul>
            </div>

            <div className="header2">[1.0.1] - 2021-11-03</div>
            <div className="header3">Added</div>
            <div>
              <ul>
                <li>Add intake appointment to sub-type of regular appointment</li>
                <li>Add Intake Appt colum to the Patient page in clinician and admin portal</li>
                <li>Add email 2B, 3C and 3D</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default ChangeLog;
