import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {Link} from "react-router-dom"

class ConsentList extends Component {
  constructor() {
    super()
    this.state = {
        msg_status: false,
        status_submit: true,
        completed_status: false,
        message: '',
        selectedFile: null,
        reg_step: '',
        information_sheet: 0,
        name: '',
        uid: 0, //this.props.authReducer.uid,
        pid: 0, //this.props.match.params.pid,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    // Get Files //
    axios({
      method: 'post',
      url: connectNode + '/patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      //console.log(result['data'])
      //if(result['data'].length > 0) {
        this.setState({
          reg_step: result['data'].reg_step,
          information_sheet: result['data'].data_repository,
        })
        //console.log(this.state)
      //}
    })
  }


  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const pid = this.props.match.params.pid
    return (
    <div className="general_information">
      <div className="row">
        <div className="small-12 columns">
          <div className="title1">Consent</div>
        </div>

        <div className="small-12 columns">
          <div className="small-12 columns top_space1">
          <table className="table_data1 top_space1">
            <tr>
              <th>#</th>
              <th>Form</th>
              <th>Consented</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Clinic Policies</td>
              <td><a href={"/admin/consent/clinicpolicy/"+pid}>PDF</a></td>
            </tr>
            <tr>
              <td>2</td>
              <td>Patient Waiver</td>
              <td><a href={"/admin/consent/patientwaiver/"+pid}>PDF</a></td>
            </tr>
            <tr>
              <td>3</td>
              <td>Communication Preferences</td>
              <td><a href={"/admin/consent/communication/"+pid}>PDF</a></td>
            </tr>
            <tr>
              <td>4</td>
              <td>Treatment Consent Form</td>
              <td><a href={"/admin/consent/treatment/"+pid}>PDF</a></td>
            </tr>
            <tr>
              <td>5</td>
              <td>FITT-BD Data Repository Information Sheet</td>
              <td>
                {(this.state.information_sheet == 1 || this.state.information_sheet == 2) &&
                <a href={"/admin/consent/information_sheet/"+pid}>PDF</a>}
                {this.state.information_sheet == 2 && '-'}
                {this.state.information_sheet == null && ''}
              </td>
            </tr>
          </table>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ConsentList)
