export const role = [
  { label: 'My psychiatrist/prescriber',
    value: 1,
  },
  { label: 'My primary care physician (PCP)',
    value: 2,
  },
  { label: 'My psychologist/therapist',
    value: 3,
  },
  { label: 'My peer support specialist',
    value: 4,
  },
  { label: 'My religious/spiritual advisor',
    value: 5,
  },
  { label: 'Other FITT-BD staff ',
    value: 6,
  },
  { label: 'Family members /friends',
    value: 7,
  },
  { label: 'Other',
    value: 8,
  },
]

export const signs_of_depression = [
  {
    label: 'Depressive thoughts, e.g. “I have failed, I should give up”',
    value: 1,
  },
  {
    label: 'Depressive symptoms, e.g. fatigue, insomnia, pessimism, hopelessness, irritability',
    value: 2,
  },
  {
    label: 'Depressive behaviors, e.g. self-harm, lethargy, change in diet and sleep patterns',
    value: 3,
  },
  {
    label: 'Other',
    value: 4,
  }
]

export const signs_of_mania = [
  {
    label: '(Hypo)manic thoughts',
    value: 1,
  },
  {
    label: '(Hypo)manic symptoms, e.g. restlessness, racing thoughts, being easily distracted',
    value: 2,
  },
  {
    label: '(Hypo)manic behaviors, e.g. hyperactivity, Insomnia, obsessing over things',
    value: 3,
  },
  {
    label: 'Other',
    value: 4,
  },
]

export const action = [
  {
    label: 'Contact members of my support team',
    value: 1,
  },
  {
    label: 'Monitor my depressive symptoms',
    value: 2,
  },
  {
    label: 'Monitor my manic symptoms',
    value: 3,
  },
  {
    label: 'Increase my daily activities',
    value: 4,
  },
  {
    label: 'Decrease my daily activities',
    value: 5,
  },
  {
    label: 'Stick to a regular sleep schedule',
    value: 6,
  },
  {
    label: 'Stick to a regular schedule of activities',
    value: 7,
  },
  {
    label: 'Evaluate my thoughts for negative or hyperpositive thinking',
    value: 8,
  },
  {
    label: 'Limit alcohol use and avoid non-medication drugs',
    value: 9,
  },
  {
    label: 'Ask support person to take care of kids/pets/other',
    value: 10,
  },
  {
    label: 'Contact my doctor or have support person do it if I cannot',
    value: 11,
  },
  {
    label: 'Reduce impulsive behaviors by giving my credit cards or keys to a support person',
    value: 12,
  },
  {
    label: 'Go to a hospital [preferred hospital] or have support person take me',
    value: 13,
  },
  {
    label: 'Other',
    value: 14,
  },
]

export const maintain_action = [
  {
    label: 'Take my medications as prescribed',
    value: 1,
  },
  {
    label: 'Adhere to my treatment plan',
    value: 2,
  },
  {
    label: 'Complete scheduled assessments',
    value: 3,
  },
  {
    label: 'Keep regular appointments with treatment team',
    value: 4,
  },
  {
    label: 'Refrain from use of illicit drugs and excessive alcohol',
    value: 5,
  },
  {
    label: 'Stick to a regular sleep schedule',
    value: 6,
  },
  {
    label: 'Stick to a schedule of activities',
    value: 7,
  },
  {
    label: 'Evaluate thoughts and redirect negative thoughts with support person',
    value: 8,
  },
  {
    label: 'Other',
    value: 9,
  },
]
