import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no, todolist_status, completion2,
  todolist_status2, todolist_status3 } from '../../variables/treatment_plan.js'

class TreatmentPlan extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_open: false,
          contract_id: 0,
          completion_goal: 0,
          completion_status: 0,
          getData: [],
          getDataGoal: [],
          treatment_target: [],
          new_todolist: 1,
          new_todolist_sum: {},
          new_todolist_item: '',
          new_todolist_modality: 0,
          new_todolist_modality_other: '',
          new_todolist_format: 0,
          new_todolist_format_other: '',
          new_todolist_date: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid, tid: this.props.match.params.tid }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        getDataGoal: result['data'],
        completion_status: result['data'][0].completion,
      })
    })
    // Target List //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan_target_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        plan_id: this.props.match.params.tid
      }
    })
    .then((result) => {
      var get_data = result['data']
      var g_goal_completion = 0
      get_data && get_data.map(each => {
        /*if(each.target_list[0].g_adherence > 0) {
          buff_data.adherence = each.target_list[0].g_adherence
        }
        if(each.target_list[0].g_reason > 0) {
          buff_data.reason = each.target_list[0].g_reason
        }
        if(each.target_list[0].g_reason_other !== null) {
          buff_data.reason_other = each.target_list[0].g_reason_other
        }
        if(each.target_list[0].g_target_completion > 0) {
          buff_data.completion = each.target_list[0].g_target_completion
        }
        if(each.target_list[0].g_change_target > 0) {
          buff_data.change = each.target_list[0].g_change_target
        }
        if(each.target_list[0].g_reason_change > 0) {
          buff_data.reason_change = each.target_list[0].g_reason_change
        }
        if(each.target_list[0].g_reason_change_other) {
          buff_data.reason_change_other = each.target_list[0].g_reason_change_other
        }
        if(each.target_list[0].g_reason_change_notes) {
          buff_data.reason_change_notes = each.target_list[0].g_reason_change_notes
        }*/
        if(each.target_list[0]?.g_goal_completion > 0) {
          g_goal_completion = each.target_list[0].g_goal_completion
        }
      })
      this.setState({
        getData: get_data,
        completion_goal: g_goal_completion,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handlechange_completion = (e) => {
    if(e.target.value == 1) {
      var getStateVal = this.state.getData
      this.state.getData.map((val, index) => {
        if(getStateVal[index].target_completion != 1){
          getStateVal[index].change = 0
          getStateVal[index].reason_change = 0
          getStateVal[index].reason_change_other = ''
          getStateVal[index].reason_change_notes = ''
          //getStateVal[index].assign_new_intervention = 2
        }
      })
      this.setState({
        completion_goal: e.target.value,
        getData: getStateVal,
      })
    } else {
      this.setState({
        completion_goal: e.target.value,
        treatment_target: [],
      })
    }
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange4 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    if(field == 'change' && e.target.value == 2) {
      getStateVal[id].reason_change = 0
      getStateVal[id].reason_change_other = ''
      getStateVal[id].reason_change_notes = ''
    } else if(field == 'completion' && e.target.value == 1) {
      getStateVal[id].change = 0
      getStateVal[id].reason_change = 0
      getStateVal[id].reason_change_other = ''
      getStateVal[id].reason_change_notes = ''
    }
    //console.log(name+' : '+field)
    //console.log(getStateVal)
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state)
  }

  handleChange5 = (e, goal_index, target_index, field) => {
    var getD = this.state.getData
    getD[goal_index].target_list[target_index][field] = e.target.value
    this.setState({
      getData: getD
    })
    //console.log(this.state.getData)
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  handleChangeTDL = (e, name) => {
    var val = 1
    if(this.state.new_todolist == 1) {
      val = 2
    }
    this.setState({
      [name]: val
    })
  }

  handleSave = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var tid = this.props.match.params.tid
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/save_treatment_plan',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        tid: tid,
        completion_goal: this.state.completion_goal,
        updated: this.state.getData,
      }
    })
    .then((result) => {
      if(result['data'] === this.props.match.params.pid) {
        this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/4', 'Treatment plan saved successfully!')
        this.props.history.push('/admin/patient_chart/'+pid+'/1/4')
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var chk = true
    if(this.state.completion_goal == 0) {
      chk = false
    } else {
      // existed data //
      this.state.getData.map(val => {
        if(val.target_list.length > 0) {
          val.target_list.map(tg => {
            if(tg.discussed == 1) {
              if((tg.adherence == 1 || tg.adherence == 2) && tg.reason == null) {
                chk = false
              }
              if(tg.target_status == null || tg.target_status == 0) {
                chk = false
              }
              if(tg.target_status == 4 && tg.terminated_reason == '') {
                chk = false
              }
            }
          })
        }
      })
      if(this.state.completion_goal == 2) {
        if(this.state.new_todolist_item != "") {
          if(this.state.new_todolist_modality == 0 || this.state.new_todolist_format == 0
            || this.state.new_todolist_date == ''
            || (this.state.new_todolist_modality == 21 && this.state.new_todolist_modality_other == '')
            || (this.state.new_todolist_format == 10 && this.state.new_todolist_format_other == '')) {
            chk = false
          }
        }
      }
    }

    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/update_treatment_plan',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          plan_id: this.props.match.params.tid,
          completion_goal: this.state.completion_goal,
          updated: this.state.getData,
          newInput: this.state.treatment_target,
          new_todolist: {
            intervention: this.state.new_todolist_item,
            modality: this.state.new_todolist_modality,
            modality_other: this.state.new_todolist_modality_other,
            intervention_format: this.state.new_todolist_format,
            intervention_format_other: this.state.new_todolist_format_other,
            intervention_date: this.state.new_todolist_date,
          }
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/3/2', 'Treatment plan updated successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/3/2')
        }
      })
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const options_adherence = ['N/A']
    for (let i=0; i <= 100; i += 10) { options_adherence.push(i) }
    var chk_tid = 0
    console.log(this.state.getData)
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Treatment Plan
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                {this.state.getData == 0 &&
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns">
                        There is no To Do List item. Please <a href={"/admin/treatment_plan/new_to_do_list/"+this.props.match.params.pid+"/"+this.props.match.params.tid}>click here</a> to add at least one in order to update this long-term treatment goal.
                      </div>
                    </div>
                  </div>
                </div>}
                {this.state.getData.length > 0 &&
                  <>
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns">
                        <div className="small-12 columns"><span className="bold">Long-Term Treatment Goal: </span>
                        {this.state.getData.length > 0 && this.state.getData[0].tm_goal}
                        {this.state.getData.length > 0 && this.state.getData[0].treatment_goal_other}
                        </div>
                      </div>
                    </div>

                    {this.state.getData.length > 0 && this.state.completion_status != 1 && this.state.getData.map((val, index) =>
                      val.target_list.length > 0 &&
                      <div className="row no_margin_bottom" key={'ttm'+index}>
                        <div className="small-12 columns ">
                        <div className="box1">
                          {val.target_list.map((tg, tg_index) =>
                            //console.log(tg) ||
                            <div className="small-12 columns mod_interventions">
                              <div className="small-12 columns space_top_1">
                                <span className="bold">To Do List item: </span>
                                {tg.intervention}
                                &nbsp; <a href={"/admin/treatment_plan/to_do_list_edit/"+this.props.match.params.pid+"/"+this.props.match.params.tid+"/"+tg.modality_main_id}
                                className="a_underline">Edit</a>
                              </div>
                              <div className="small-12 columns space_top_1">
                                <span className="bold">Treatment Modality: </span>
                                {this.findLabelByValue(treatment_modality, tg.modality_id)}
                                {tg.modality_id == 21 && ' ['+tg.modality_other+']'}
                              </div>
                              <div className="small-12 columns space_top_1">
                                <span className="bold">To Do List item Format: </span>
                                {this.findLabelByValue(intervention_format, tg.intervention_format)}
                                {tg.intervention_format == 6 && ' ['+tg.intervention_format_other+']'}
                              </div>
                              <div className="small-12 columns space_top_1">
                                <span className="bold">Date To Do List item assigned: </span>{tg.modality_date}
                              </div>
                              <div className="small-12 columns box_inner2 top_space1">
                                <div className="row">
                                  <div className="small-12 medium-6 columns bold align_right">
                                    <span className="space_top_3">Discussed?</span>
                                  </div>
                                  <div className="small-12 medium-6 columns no_padding_left">
                                    <span className="float_left">
                                    <FormControl variant="outlined" className="w_adherence">
                                      <Select variant="outlined" label="" className="width_auto"
                                        onChange={(e) => this.handleChange5(e, index, tg_index, 'discussed')} >
                                        {yes_no.map( each =>
                                          <MenuItem key={'yn'+each.value} value={each.value}>{each.label}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl></span>
                                  </div>
                                </div>
                                { this.state.getData[index].target_list[tg_index].discussed == 1 &&
                                <div className="row">
                                  <div className="small-12 medium-6 columns bold space_top_1 align_right">
                                    <span className="space_top_3">Adherence to To Do List item:</span>
                                  </div>
                                  <div className="small-12 medium-6 columns space_top_1 no_padding_left">
                                    <span className="float_left">
                                    <FormControl variant="outlined" className="w_adherence">
                                      <Select onChange={(e) => this.handleChange5(e, index, tg_index, 'adherence')}
                                        defaultValue={tg.g_adherence > 0 && tg.g_adherence}
                                        variant="outlined" label="" className="width_auto">
                                        {completion2.map( each =>
                                          <MenuItem key={'adh'+each.value} value={each.value}>{each.label}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl></span>
                                  </div>
                                </div>}
                              { (this.state.getData[index].target_list[tg_index].adherence != null
                                && this.state.getData[index].target_list[tg_index].adherence < 3) &&
                                <div className="row">
                                  <div className="small-12 medium-6 columns bold space_top_1 align_right">
                                    <span className="space_top_3">Select reason:</span>
                                  </div>
                                  <div className="small-12 medium-6 columns bold space_top_1 no_padding_left">
                                    <FormControl variant="outlined">
                                      <Select onChange={(e) => this.handleChange5(e, index, tg_index, 'reason')}
                                        defaultValue={tg.g_reason > 0 && tg.g_reason}
                                        variant="outlined" label="" className="width_auto">
                                        {adherence_reason.map( each =>
                                          <MenuItem key={'adhr'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                        )}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  { (this.state.getData[index].target_list[tg_index].reason == 10) &&
                                  <div className="row">
                                  <div className="small-12 medium-6 columns bold space_top_1 align_right">
                                    <span className="space_top_3">Please specify other reason:</span>
                                  </div>
                                  <div className="small-12 medium-6 columns bold space_top_1 no_padding_left">
                                    <TextField variant="outlined" type="text"
                                      onChange={(e) => this.handleChange5(e, index, tg_index, 'reason_other')}
                                      value={(this.state.getData[index].target_list[tg_index].g_reason_other !== 'undefined'
                                      && this.state.getData[index].target_list[tg_index].g_reason_other) }
                                      InputProps={{ inputProps: { maxLength: 95 }}} />
                                  </div>
                                </div>}
                              </div>}

                              { this.state.getData[index].target_list[tg_index].discussed == 1 &&
                              <div className="row">
                                <div className="small-12 medium-6 columns bold space_top_1 align_right">
                                  <span className="space_top_3">To Do List item status:</span>
                                </div>
                                <div className="small-12 medium-6 columns bold space_top_1 no_padding_left">
                                  <FormControl variant="outlined" className="w_todolist_status">
                                    <Select value={this.state.getData[index].target_status}
                                      onChange={(e) => this.handleChange5(e, index, tg_index, 'target_status')}
                                      defaultValue={tg.g_target_completion}
                                      variant="outlined" label="" className="width_auto">
                                      {todolist_status3.map( each =>
                                        <MenuItem key={'ts'+index+each.value} value={each.value}>{each.label}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                                { (this.state.getData[index].target_list[tg_index].target_status == 4
                                || this.state.getData[index].target_list[tg_index].g_target_completion == 3
                                  || this.state.getData[index].target_status == 3) &&
                                <div className="row">
                                  <div className="small-12 medium-6 columns bold space_top_1 align_right">
                                    <span className="space_top_3">Please specify modified to do list item:</span>
                                  </div>
                                  <div className="small-12 medium-6 columns bold space_top_1 no_padding_left">
                                    <TextField variant="outlined" type="text"
                                      onChange={(e) => this.handleChange5(e, index, tg_index, 'terminated_reason')}
                                      value={(this.state.getData[index].target_list[tg_index].terminated_reason !== 'undefined'
                                      && this.state.getData[index].target_list[tg_index].terminated_reason) ||
                                      this.state.getData[index].target_list[tg_index].g_terminated_reason}
                                      InputProps={{ inputProps: { maxLength: 95 }}} />
                                  </div>
                                </div>}
                              </div>}
                              </div>

                            </div>
                          )}
                        </div>
                        </div>
                      </div>
                    )}
                    <div className="row no_margin_bottom space_top_1">
                      <div className="small-12 columns">
                        <div className="small-6 columns bold space_top_1">You have reviewed all to do list items.</div>
                        <div className="small-6 columns text_align_right">
                        {this.state.completion_goal != 1 && this.state.completion_goal != 3 &&
                        <Button variant="contained" color="primary" type="button"
                          onClick={(e) => this.handleChangeTDL(e, 'new_todolist')} >
                          New To Do List Item
                        </Button>}
                        </div>
                      </div>
                    </div>
                    {this.state.new_todolist == 2 && this.state.completion_goal != 1 &&
                      this.state.completion_goal != 3 &&
                    <>
                    <div className="small-12 columns">
                    <div className="row no_margin_bottom space_top_1">
                      <div className="small-12 columns sep_line1">
                        &nbsp;
                      </div>
                    </div>

                    <div className="row">
                      <div className="small-12 columns bold space_top_1">To Do List item</div>
                      <div className="small-12 columns">
                      <TextField variant="outlined" type="text" className="space_top_1"
                        onChange={(e) => this.handleChange2(e, 'new_todolist_item')}
                        value={this.state.new_todolist_item}
                        InputProps={{ inputProps: { maxLength: 240 }}} />
                      </div>

                      <div className="small-12 columns bold space_top_1">Treatment Modality</div>
                      <div className="small-12 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.new_todolist_modality}
                            onChange={(e) => this.handleChange2(e, 'new_todolist_modality')}
                            variant="outlined" label="" className="width_auto">
                            {treatment_modality.map( each =>
                              <MenuItem key={'tm'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      {this.state.new_todolist_modality == 21 &&
                        <div className="small-12 columns">
                        <TextField variant="outlined" type="text" className="space_top_1"
                          onChange={(e) => this.handleChange2(e, 'new_todolist_modality_other')}
                          value={this.state.new_todolist_modality_other}
                          InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                        </div>}
                      <div className="small-12 columns bold space_top_1">To Do List item Format</div>
                      <div className="small-12 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.new_todolist_format}
                            onChange={(e) => this.handleChange2(e, 'new_todolist_format')}
                            variant="outlined" label="" className="width_auto">
                            {intervention_format.map( each =>
                              <MenuItem key={'if'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                      {this.state.new_todolist_format == 10 &&
                        <div className="small-12 columns">
                        <TextField variant="outlined" type="text" className="space_top_1"
                          onChange={(e) => this.handleChange2(e, 'new_todolist_format_other')}
                          value={this.state.new_todolist_format_other}
                          InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                        </div>}
                      <div className="small-12 columns bold space_top_1">Date To Do List item assigned</div>
                      <div className="small-12 columns float_left width_auto">
                        <TextField variant="outlined" type="date" format={'MM/DD/YYYY'}
                        onChange={(e) => this.handleChange2(e, 'new_todolist_date')}  />
                      </div>
                    </div></div>
                    </>}

                    <div className="row no_margin_bottom space_top_1">
                      <div className="small-12 columns space_top_1">
                    <div className="small-12 columns sep_line1">
                        &nbsp;
                      </div>
                    </div>
                  </div>

                    <div className="row no_margin_bottom">
                      <div className="small-12 columns">
                        <div className="small-12 columns bold space_top_1">
                        <span className="float_left right-space1 space_top_1">Long-Term goal status: &nbsp;</span>
                        <span className="float_left">
                        <FormControl variant="outlined" className="w_adherence">
                          <Select variant="outlined" label="" className="width_auto"
                           onChange={(e) => this.handleChange2(e, 'completion_goal')} >
                            {todolist_status2.map( each =>
                              <MenuItem key={'yn'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.completion_status != 1 &&
                  <div className="row top_space1 button_left_space_medium1">
                    <div className="small-12 columns text_align_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSave} type="button">
                        Save
                      </Button>
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>}
                </>}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TreatmentPlan)
