import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import ScheduleIcon from '@material-ui/icons/Schedule'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import GroupIcon from '@material-ui/icons/Group'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

class UpcomingAppointment extends Component {
  constructor() {
    super()
    this.state = {
      checked: false,
      appt: [],
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var get_tracker_day = []
    var get_tracker_week = []

    // tracker day //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_upcoming_appt',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid, }
    })
    .then((result) => {
        this.setState({ appt: result['data'] })
      }
    )
  }

  render() {
    //console.log(this.state.appt)
    return (
      <div className="box_border box_space_top1 upcoming_appt">
        <div className="row">
            <div className="small-9 columns head2">
                Next Appointment
            </div>
            <div className="small-3 columns see_all_link">
                <a href="member/appointment_list">See all</a>
            </div>
            {this.state.appt.fullname != null &&
            <div className="small-12 columns coming_apt space_bottom1">
              <div className="row">
                <div className="medium-1 columns show-for-medium">&nbsp;</div>
                <div className="small-2 medium-2 columns calendar_bg">
                  <div className="apt_month">{this.state.appt.month}</div>
                  <div className="apt_day">{this.state.appt.day}</div>
                  <div className="apt_year">{this.state.appt.year}</div>
                </div>
                <div className="small-8 medium-8 columns float_left">
                  <div className="apt_provider"><AccountCircleIcon /> {this.state.appt.fullname}</div>
                  <div className="apt_time"><ScheduleIcon /> {this.state.appt.appt_time}</div>
                  <div className="apt_by"><HourglassEmptyIcon /> {this.state.appt.duration} mins</div>
                </div>
              </div>
            </div>}
            {this.state.appt.fullname == null &&
              <div className="small-12 columns coming_apt space_bottom1">
                <div className="row"><div className="small-12 columns align_center">
                -
                </div></div></div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingAppointment)
