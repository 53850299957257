import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/team2.jpg'

class Intro14 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction15')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction13')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_2 margin-bottom1">
              <b>T</b>EAM-BASED
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns">
                <img src={Image} alt="team" className="w_100percent" />
              </div>
              <div className="small-12 medium-6 columns content2">
                <p>At the heart of FITT-BD is that it is collaborative. You will work with a team of people with different areas of expertise and different approaches to mental health.</p>
                <p>Your team will include:<br />
                  <ul>
                    <li>your supervising clinician</li>
                    <li>a graduate fellow or practicum student</li>
                    <li>a patient navigator who will check in with you on a regular basis</li>
                  </ul>
                </p>
                <p>As appropriate, it might also include:<br />
                  <ul>
                    <li>a psychopharmacologist</li>
                    <li>a peer specialist, or</li>
                    <li>a neuropsychologist.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro14)
