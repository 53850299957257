export const db_type = [
  {
    label: 'Daily',
    value: 1,
  }, {
    label: 'Weekly',
    value: 2,
  }
]

export const db_data_show = [
  {
    label: 'Mood/Depressed',
    value: 1,
  }, {
    label: 'Mood/Manic',
    value: 2,
  }, {
    label: 'Sleep',
    value: 3,
  }, {
    label: 'Medication',
    value: 4,
  }, {
    label: 'Exercise',
    value: 5,
  }, {
    label: 'Anxiety',
    value: 6,
  }
]

export const db_scale_day = [
  {
    label: 5,
    value: 1,
  }, {
    label: 5,
    value: 2,
  }, {
    label: 7,
    value: 3,
  }, {
    label: 5,
    value: 4,
  }, {
    label: 9,
    value: 5,
  }, {
    label: 5,
    value: 6,
  }
]

export const db_scale_week = [
  {
    label: 'Mood/Depressed',
    value: 5,
  }, {
    label: 'Mood/Manic',
    value: 5,
  }, {
    label: 'Sleep',
    value: 7,
  }, {
    label: 'Medication',
    value: 5,
  }, {
    label: 'Exercise',
    value: 9,
  }, {
    label: 'Anxiety',
    value: 5,
  }
]
