import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
//import Button from '@material-ui/core/Button'

import AssignTeam from "../patient_chart/assign_team"
import TreatmentPlanList from "../patient_chart/treatment_plan/treatment_plan_list"
import PatientAssmEdit from "../patient_chart/patient_assessments_edit"
import PatientHead3 from "../patient_head3"

class PatientEdit extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '3',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
      }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/add_new_patient',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: this.state
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] > 0) {
          //this.props.onLoadPages(response)
          this.props.onLoadLocation('patient_list', 'Adding new patient completed!')
          this.props.history.push('/admin/patient_list')
          /*this.setState({
            message: 'Adding new patient completed!'
          })*/
        } else if(result['data'] === -1) {
          this.setState({
            message: 'This email already exist.'
          })
        } else if(result['data'] === -2) {
          this.setState({
            message: 'Adding new patient completed but system couldn\'t send email reminder!'
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.'
          })
        }
      }
    )
  }

  render() {

    return (
      <div className="admin_patient_detail box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className="warning_msg2">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
            <div className="box_border5">
              <form id="patient_detail" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <PatientHead3 />
                  </div>
                  <div className="small-12 columns tab_data1">
                    <TabContext value={this.state.tab_status}>
                      <AppBar position="static">
                        <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                          <Tab label="Clinician/Student" value="1" />
                          <Tab label="Treatment Plan" wrapped value="2" />
                          <Tab label="Modify Assessments" value="3" />
                        </TabList>
                      </AppBar>
                      <TabPanel value="1" index={1} ><AssignTeam /></TabPanel>
                      <TabPanel value="2" index={2} ><TreatmentPlanList /></TabPanel>
                      <TabPanel value="3" index={3} ><PatientAssmEdit /></TabPanel>
                    </TabContext>
                  </div>
                </div>
                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <a href="" className="go_back">Go Back</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientEdit)
