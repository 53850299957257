import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead2 from "../../patient/patient_head2"
import { ans_onoff } from '../../variables/patient.js'

class DailySelfMonitoring extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          daily_mood: -1,
          daily_sleep: -1,
          daily_medication: -1,
          daily_exercise: -1,
          daily_anxiety: -1,
      }
  }

  componentDidMount() {
    this.getAssmStatus()
  }

  getAssmStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_assessments_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
        //console.log(result['data'])
        this.setState({
          id: result['data'].id,
          daily_mood: result['data'].d_mood,
          daily_sleep: result['data'].d_sleep,
          daily_medication: result['data'].d_medication,
          daily_exercise: result['data'].d_exercise,
          daily_anxiety: result['data'].d_anxiety,
        })
      }
    )
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.daily_mood > -1 &&
      this.state.daily_sleep > -1 && this.state.daily_medication > -1 &&
      this.state.daily_exercise > -1 && this.state.daily_anxiety > -1 ) {
        var pid = this.props.match.params.pid
        var timepoint = this.props.match.params.timepoint
        axios({
          method: 'post',
          url: connectNode + 'admin/patient/set_daily_monitor',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            timepoint: timepoint,
            user: this.state,
          }
        })
        .then((result) => {
            if(result['data'] === this.props.match.params.pid) {
              this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/2/', 'Completed assign daily tracker!')
              this.props.history.push('/admin/patient_chart/'+pid+'/1/2/')
            }
          }
        )
    } else {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <div className="admin_patient_daily_self_monitor box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Daily/Weekly Self Monitoring
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead2 />
                { this.state.message !== '' &&
                <div className="row warning_msg6 ">
                  <div className="small-12 columns ">
                    <div className="float_left1"><WarningIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>
                </div>}
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns">
                        <div className="small-12 medium-4 columns bold_txt4">Mood: </div>
                        {ans_onoff.map(each =>
                          <div className="small-6 medium-2 columns float_left" key={'dmd'+each.label}>
                            <input type="radio" name="daily_mood" value={each.value}
                            onChange={(e) => this.handleChange(e)}
                            checked={this.state.daily_mood == each.value} />
                            <span>{each.label}</span>
                          </div>
                        )}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="small-12 medium-4 columns bold_txt4">Sleep: </div>
                        {ans_onoff.map(each =>
                          <div className="small-6 medium-2 columns float_left" key={'ds'+each.label}>
                            <input type="radio" name="daily_sleep" value={each.value}
                            onChange={(e) => this.handleChange(e)}
                            checked={this.state.daily_sleep == each.value} />
                            <span>{each.label}</span>
                          </div>
                        )}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="small-12 medium-4 columns bold_txt4">Medication: </div>
                        {ans_onoff.map(each =>
                          <div className="small-6 medium-2 columns float_left" key={'dm'+each.label}>
                            <input type="radio" name="daily_medication" value={each.value}
                            onChange={(e) => this.handleChange(e)}
                            checked={this.state.daily_medication == each.value}  />
                            <span>{each.label}</span>
                          </div>
                        )}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="small-12 medium-4 columns bold_txt4">Exercise: </div>
                        {ans_onoff.map(each =>
                          <div className="small-6 medium-2 columns float_left" key={'de'+each.label}>
                            <input type="radio" name="daily_exercise" value={each.value}
                            onChange={(e) => this.handleChange(e)}
                            checked={this.state.daily_exercise == each.value} />
                            <span>{each.label}</span>
                          </div>
                        )}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="small-12 medium-4 columns bold_txt4">Anxiety: </div>
                        {ans_onoff.map(each =>
                          <div className="small-6 medium-2 columns float_left" key={'da'+each.label}>
                            <input type="radio" name="daily_anxiety" value={each.value}
                            onChange={(e) => this.handleChange(e)}
                            checked={this.state.daily_anxiety == each.value} />
                            <span>{each.label}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    {this.state.id == undefined &&
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>}
                    {this.state.id > 0 &&
                    <Link to={"/admin/patient_chart/"+this.props.match.params.pid+"/1/2"} className="go_back">Go Back</Link>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DailySelfMonitoring)
