export const phq9_questions = [
  {
    label: 'Little interest or pleasure in doing things',
    value: 1,
  }, {
    label: 'Feeling down, depressed or hopeless',
    value: 2,
  }, {
    label: 'Trouble falling asleep, staying asleep, or sleeping too much',
    value: 3,
  }, {
    label: 'Feeling tired or having little energy',
    value: 4,
  }, {
    label: 'Poor appetite or overeating',
    value: 5,
  }, {
    label: 'Feeling bad about yourself - or that you’re a failure or have let yourself or your family down',
    value: 6,
  }, {
    label: 'Trouble concentrating on things, such as reading the newspaper or watching television',
    value: 7,
  }, {
    label: 'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
    value: 8,
  }, {
    label: 'Thoughts that you would be better off dead or of hurting yourself in some way',
    value: 9,
  },
]

export const phq9_question_10 = [
  {
    label: 'If you checked off any problems, how difficult have those problems made it for you to do your work, take care of things at home, or get along with other people?',
    value: 10,
  },
]

export const phq9_1 = [
  {
    label: 'Not at all',
    value: 0,
  }, {
    label: 'Several days',
    value: 1,
  }, {
    label: 'More than half the days',
    value: 2,
  }, {
    label: 'Nearly every day',
    value: 3,
  },
]

export const phq9_2 = [
  {
    label: 'Not difficult at all',
    value: 1,
  }, {
    label: 'Somewhat difficult',
    value: 2,
  }, {
    label: 'Very difficult',
    value: 3,
  }, {
    label: 'Extremely difficult',
    value: 4,
  },
]


export const sth2 = [
  {
    label: '',
    value: 1,
  }, {
    label: '',
    value: 2,
  }, {
    label: '',
    value: 3,
  }, {
    label: '',
    value: 4,
  }, {
    label: '',
    value: 5,
  }, {
    label: '',
    value: 6,
  },
]
