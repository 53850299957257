export const baseline_steps = [
  'Overview',
  'PHQ-9',
  'ASRM',
  'GAD-7',
  'COBRA',
  'PWB',
  'DERS-18',
  'PSQI',
]

export const discharge_steps = [
  'Overview',
  'PHQ-9',
  'ASRM',
  'GAD-7',
  'COBRA',
  'PWB',
  'DERS-18',
  'PSQI',
]

export const self_stigma_steps = [
  'SCCS',
  'ISMI',
]
