import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

//mport InputAdornment from '@material-ui/core/InputAdornment'
//import MailOutlineIcon from '@material-ui/icons/MailOutline'
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CancelIcon from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
//import { makeStyles } from "@material-ui/core/styles";
import { role, signs_of_depression, signs_of_mania, action,
         maintain_action } from '../variables/patient_contract.js'
import SignatureCanvas from 'react-signature-canvas'
import GetImage from '../../functions/get_image.jsx'

class PatientContractUpdate extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          fullname: '',
          treatment_goal: [{
            id: 1,
            text: ''
          }],
          support_team: [{
            id: 1,
            role: 0,
            name: '',
            email: '',
          }],
          signs_of_depression: [{
            id: 1,
            val: 0,
            text: '',
          }],
          signs_of_mania: [{
            id: 1,
            val: 0,
            text: '',
          }],
          support_dep_mania: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          support_maintain: [{
            id: 1,
            action: 0,
            support: 0,
            text: '',
          }],
          suicide_contact: [{
            id: 1,
            support: 0,
          }],
          patient_name: '',
          patient_date: '',
          clinician_name: '',
          clinician_date: new Date().toISOString().substring(0, 10),
          treatment_team_date: new Date().toISOString().substring(0, 10),
          progress: 0,
      }
  }

  componentDidMount() {
    this.getData()
  }

  componentWillMount() {
    this.getData()
  }

  getData = () => {
    var pid = 0
    var role_user = this.props.authReducer.role
    var role_name = ''
    if(this.props.authReducer.role == 5) {
      pid = this.props.authReducer.uid
      role_name = 'Patient'
    } else {
      pid = this.props.match.params.pid
      role_name = 'Clinician'
    }
    // get patient information //
    axios({
      method: 'post',
      url: connectNode + 'patient/enroll/get_patient_contract_by_id',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid,
        contract_id: this.props.match.params.contract_id
      },
    })
    .then((result) => {
      var buff = { treatment_team_date: new Date().toISOString().substring(0, 10) }
      buff = {
        ...result['data'],
        treatment_team_date: new Date().toISOString().substring(0, 10),
        role_user: role_user,
        role_name: role_name,
      }
      this.setState(buff)
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.treatment_team_name === '') {
      this.setState({
        message: 'Please fill in all information',
      })
      window.scrollTo(0, 0)
    } else {
      // signature //
      var signature = this.refs.mySignature //.getCanvas()
      if(signature.isEmpty()) {
        this.setState({
          message: 'Please sign your signature',
        })
        window.scrollTo(0, 0)
      } else {
        var base64img = signature.toDataURL("image/png")

        const formData = new FormData()
        formData.append('file', base64img)
        formData.append('pid', this.state.pid)
        formData.append('uid', this.props.authReducer.uid)
        formData.append('contract_id', this.props.match.params.contract_id)

        formData.append('clinician_name', this.state.treatment_team_name)
        formData.append('clinician_date', this.state.treatment_team_date)
        formData.append('role_user', this.state.role_user)
        //formData.append('udata', JSON.stringify(this.state))
        axios({
          method: 'post',
          url: connectNode + 'patient/enroll/update_patient_contract_c',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data' //'application/json;charset=UTF-8'
          },
          body: JSON.stringify(this.state), //this.state,
          data: formData //sendData, //this.state // formData
          //file: formData
        })
        .then((result) => {
            if(result['data'] == this.props.authReducer.uid && this.state.role_user != 5) {
              this.setState({
                message: 'Thank you for completing patient contract. System will generate PDF file and send it out to patient.',
                progress: 2,
              })
              window.scrollTo(0, 0)
            } else if(result['data'] == this.props.authReducer.uid && this.state.role_user == 5) {
              this.props.onLoadLocation('patient_contract_list', 'Signed patient contract successfully!')
              this.props.history.push('/member/patient_contract_list')
            }
          }
        )
      }
    }

  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  findSupportByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.id === searchValue })
    if(typeof result !== "undefined") {
      label = result.name
    }
    return label
  }

  render() {
    return (
      <div className="patient_contract patient_contract_update box_border4">
        <div className="row">
          <div className="small-10 columns headerPage1">
            FITT-BD Patient Contract
          </div>
          { this.props.authReducer.role == 5 && this.state.progress == 2 &&
            <div className="small-1 columns">
            <a href={"../../../../member/patient_contract_pdf/"+this.props.match.params.contract_id}>
            <PictureAsPdfIcon /></a></div>
          } { this.props.authReducer.role !== 5 && this.state.progress == 2 &&
            <div className="small-1 columns">
            <a href={'../../patient_contract_pdf/'+this.props.match.params.pid+'/'+this.props.match.params.contract_id}><
            PictureAsPdfIcon /></a></div>
          }
        </div>
        { this.state.message !== '' && this.state.progress < 2 &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        { this.state.message !== '' && this.state.progress == 2 &&
        <div className="row warning_msg8">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border3">
              <form id="patient_contract" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <p><b>AS A PATIENT</b> at the FITT-BD clinic,
                    I, {this.state.fullname}, voluntarily accept the following
                    Patient Contract to guide my care with the support of
                    my treatment team. I understand that this contract can be
                    added to or otherwise modified as my treatment evolves
                    over time.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="small-12 columns box1 treatment_goal">
                    <p className="title_topic1"><span className="bullet_dot">•</span> Treatment Goals</p>
                    <p className="p_bottom_025">
                    During the time I am a patient at the FITT-BD Clinic, I will work towards:</p>
                    <div id="dynamicInput">
                       {this.state.treatment_goal.map((val, index) =>
                         <div key={"treatment_goal"+val.id} >
                         {index+1}. {val.text}
                        </div>)}
                   </div>
                </div>
              </div>
              <div className="row">
                <div className="small-12 columns support_team box1">
                  <p className="title_topic1"><span className="bullet_dot">•</span> Support Team</p>
                  <p>
                  My support team can include any of my providers, as well as family members,
                  friends, and others who I trust with my care.</p>
                  <p>I have chosen the following as members of my support team:</p>
                  <p>Choose "ROLE" from drop-down menu. You may choose as many as you wish.
                  Write in names and phone numbers or email addresses.</p>
                  <div id="dynamicInput">
                    <div className="row">
                      <div className="small-12 medium-4 columns bold">Role
                      </div>
                      <div className="small-12 medium-4 columns bold">Name</div>
                      <div className="small-12 medium-4 columns bold">Phone number/email address</div>
                    </div>
                    {this.state.support_team.map((val, index) =>
                    <div className="row" key={"sp"+val.id} >
                      <div className="small-12 medium-4 columns">
                        {index+1}. {this.findLabelByValue(role, val.role)}
                      </div>
                      <div className="small-12 medium-4 columns">{val.name}</div>
                      <div className="small-12 medium-4 columns">{val.email}</div>
                    </div>)}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns monitor_my_mood box1">
                  <p className="title_topic1"><span className="bullet_dot">
                  •</span> In order to monitor my moods, I will watch out for the following signs:</p>
                  <p>Choose as many as you wish from the drop-down menus and/or write in your own.</p>
                  <p className="p_bottom_025">
                    <b><ArrowRightIcon className="icon_1" /> <span className="title_name1">Signs of Depression</span></b>
                  </p>
                  <div id="dynamicInput" className="signs_of_depression">
                    {this.state.signs_of_depression.map((val, index) =>
                    <div className="row" key={"sod"+val.id} >
                      <div className="small-12 columns">
                        {index+1}. {this.findLabelByValue(signs_of_depression, val.val)}
                        { (val.val === 4 || (val.val < 4 && val.text.length > 0)) && ': '+val.text }
                        </div>
                      </div>)}
                    </div>
                    <div className="line1">&nbsp;</div>
                    <p className="p_bottom_025">
                      <b><ArrowRightIcon className="icon_1" /> <span className="title_name1">Signs of (Hypo)mania</span></b>
                    </p>
                    <div id="dynamicInput" className="signs_of_depression">
                      {this.state.signs_of_mania.map((val, index) =>
                      <div className="row" key={"som"+val.id} >
                      <div className="small-12 columns">
                        {index+1}. {this.findLabelByValue(signs_of_mania, val.val)}
                        { (val.val === 4 || (val.val < 4 && val.text.length > 0)) && ': '+val.text }
                        </div>
                      </div>)}
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team_member box1">
                  <p className="title_topic1"><span className="bullet_dot">
                  •</span> If I notice signs of depression or mania, I will:</p>
                  <p className="p_bottom_025">
                  Choose as many as you wish from the drop-down menu and/or write in your own.</p>
                  <p>If a person on your support team can help, please choose that person
                  from the drop-down menu of support-team members.</p>
                  <div id="dynamicInput" className="signs_dep_mania">
                    <div className="row">
                      <div className="small-12 medium-6 columns bold">Action</div>
                      <div className="small-12 medium-6 columns bold">Support Person</div>
                    </div>
                    {this.state.support_dep_mania.map((val, index) =>
                    <div className="row" key={"adm"+val.id} >
                      <div className="small-12 medium-6 columns">
                        {index+1}. {this.findLabelByValue(action, val.action)}
                        { val.action === 13 && ': '+val.text }
                      </div>
                      <div className="small-12 medium-6 columns support_person">
                        {this.findSupportByValue(this.state.support_team, val.support)}
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns support_team_member box1">
                  <p className="title_topic1">
                  <span className="bullet_dot">•</span>
                  To maintain my mood in a desired range throughout my treatment program, I will:</p>
                  <p className="p_bottom_025">
                  Choose as many as you wish from the drop-down menu and/or write in your own.</p>
                  <p>If a person on your support team can help, please choose that person from the drop-down menu of support-team members.</p>
                  <div id="dynamicInput" className="signs_maintain">
                    {this.state.support_maintain.map((val, index) =>
                    <div className="row" key={"am"+val.id} >
                      <div className="small-12 medium-6 columns">
                        {index+1}. {this.findLabelByValue(action, val.action)}
                        { val.action === 9 && ': '+val.text }
                      </div>
                      <div className="small-12 medium-6 columns support_person">
                        {this.findSupportByValue(this.state.support_team, val.support)}
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns suicide_contact box1">
                  <p className="title_topic1_1">
                  <span className="bullet_dot">•</span>
                  If I have strong suicidal thoughts, I will contact my team members and support person</p>
                  <div id="dynamicInput" className="suicide_contact">
                    {this.state.suicide_contact.map((val, index) =>
                    <div className="row" key={"scc"+val.id} >
                      <div className="small-12 columns">
                      {this.findSupportByValue(this.state.support_team, val.support)}
                      </div>
                    </div>)}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns box1">
                  <p className="title_topic1_1"><span className="bullet_dot">•</span>
                  If I fear I will act on suicidal thoughts, I will report to the emergency room.</p>
                </div>
              </div>

              <div className="row">
                <div className="small-12 columns">
                  <p><b>AS TREATMENT TEAM MEMBERS</b> for {this.state.fullname}, we agree to:</p>
                  <div>
                    <ul>
                      <li>Commit to partner with and support {this.state.fullname} in reaching [her/his/their] chosen goals.</li>
                      <li>Commit to working with {this.state.fullname} as an "expert by experience" and equal partner in making decisions about treatment. </li>
                      <li>Make every effort to arrive on time for all appointments.</li>
                      <li>Reply to any contact or correspondence from {this.state.fullname} within two business days.</li>
                    </ul>
                  </div>
                  <p className="margin_top_2rem">
                    We agree to the terms of this contract, which we have read and understand as our responsibilities as a patient/support person/treatment team member at the FITT-BD.
                  </p>
                </div>
              </div>

              <div className="row signature">
                <div className="small-12 medium-6 columns">
                  { /*this.state.role_user != 5 &&
                  <div className="signed_part">
                    <div><GetImage path="patient_contract_p_signature" /></div>
                    <div className="top_space_signature bold">{this.state.patient_name}</div>
                    <div>Date: {this.state.patient_date}</div>
                  </div>*/}
                </div>
                {this.state.progress < 2 &&
                <div className="small-12 medium-6 columns">
                  <div className="relative_float_left">
                  <TextField id="clinician_name" label={this.state.role_name + ' Name'} onChange={this.handleChange} variant="outlined"
                  InputProps={{
                      maxLength: 60,
                      inputProps: { name: "treatment_team_name" }
                    }}  />
                  </div>
                  <div className="row title_signature">
                    <div className="small-6 columns">
                      {this.state.role_name} Signature
                    </div>
                    <div className="small-6 columns align-right">
                      <button type="button" className="clear_button"
                         onClick={ () => this.refs.mySignature.clear() }>
                          Clear
                      </button>
                    </div>
                  </div>
                  <div className="relative_float_left">
                      <SignatureCanvas penColor='#555555' canvasProps={{className: 'signatureCanvas'}}
                       ref="mySignature" />
                  </div>
                  <div className="relative_float_left">
                  <TextField id="clinician_date" label="Date" onChange={this.handleChange2} variant="outlined"
                  type="date" defaultValue={this.state.treatment_team_date}
                  InputProps={{ inputProps: { name: "treatment_team_date" }}} /></div>
                </div>}
                {this.state.progress == 2 &&
                <div className="small-12 medium-6 columns">
                  <div className="signed_part">
                    <div><GetImage path="patient_contract_c_signature" /></div>
                    <div className="top_space_signature bold">{this.state.treatment_team_name}</div>
                    <div>Date: {this.state.treatment_team_date}</div>
                  </div>
                </div>}
              </div>
              {this.state.progress < 2 &&
              <div className="row top_space">
                <div className="small-12 columns align_right">
                  <Button variant="contained" color="primary" disableElevation
                   onClick={this.handleSubmit} type="submit" className="submit_1">
                    Submit
                  </Button>
                </div>
              </div>}
            </form>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientContractUpdate)
