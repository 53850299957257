import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import validator from 'validator'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import PatientHead3 from "../../../patient/patient_head3"
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { mrtf_type_change, yes_no, reason_change } from '../../variables/medication'
import { calculateTimepoint1 } from '../../../../functions/timepoint.js'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectLine } from '../../../../shared/select_line'
import DatePicker from "react-datepicker"

//import { makeStyles } from "@material-ui/core/styles";

class AddMrtf extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          med_name: '',
          med_other: '',
          med_category: '',
          med_dose: '',
          frequency: '',
          frequency_other: '',
          sig: '',
          curr_month: 0,
          medication_names: [],
          frequency_list: [],
          date: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_timeline_start',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      var calTimepoint = calculateTimepoint1(result['data'])
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_medication_name',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { }
      })
      .then((result) => {
        const medicationNames = result['data'];
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/get_frequency_name',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: { }
        })
        .then((result) => {
          this.setState({
            curr_month: calTimepoint.current_month,
            medication_names: medicationNames,
            frequency_list: result['data']
          })
        })
      })
    })
  }

  checkNull = (txt) => {
    if(txt === 'null') {
      return ''
    }
    return txt
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: this.checkNull(escapeHtml(e.target.value)),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleDateChange = (e, name) => {
    this.setState({ [name]: e })
  }

  handleNumber = (e, name) => {
    if(!validator.isFloat(e.target.value)) {
      this.setState({
        [name]: '',
      })
    } else {
      this.setState({
        [name]: e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.med_name === '' || this.state.med_dose === '' || this.state.med_dose == 0
       || this.state.date === '') {
      this.setState({
        status: false,
        message: 'Please fill all answers',
      })
      window.scrollTo(0, 0)
    } else {
        const med = this.state.medication_names.find(x => x.id === this.state.med_name);
        const medCategory = med.med_category;
        axios({
          method: 'post',
          url: connectNode + 'admin/patient/add_medication_mrtf',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            cid: this.props.authReducer.uid,
            pid: this.props.match.params.pid,
            med_id: med.med_id,
            med_category: medCategory,
            med_name: medCategory === 9 || medCategory === 10 ? this.state.med_other : med.med_name,
            med_dose: this.state.med_dose,
            frequency: this.state.frequency,
            frequency_other: this.state.frequency_other,
            sig: this.state.sig,
            date: this.state.date,
            timepoint: this.state.curr_month,
          }
        })
        .then((result) => {
            if(result['data'] == this.props.match.params.pid) {
              this.props.onLoadLocation('patient_chart', 'Add new medication successfully!')
              this.props.history.push('/admin/patient_chart/'+this.props.match.params.pid+'/4/2')
            } else {
              this.setState({
                message: 'Something went wrong. Please try again.',
                status: false,
              })
            }
          }
        )


    }
  }

  addZero = (number) => {
    var txt = number
    if(number < 10)  {
      txt = '0'+number
    }
    return txt
  }

  render() {
    var today = new Date()
    const medicationNamesMap = this.state.medication_names.map(each => ({id: each.id, med_id: each.med_id, med_category: each.med_category, name: each.med_name}));
    const frequencyMap = this.state.frequency_list.map(each => ({id: each.vid, name: each.name}));
    const medCategory = this.state.medication_names.find(x => x.id === this.state.med_name)?.med_category;
    return (
      <div className="admin_log_to_do box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            MRTF
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
            <form id="make_appointment" onSubmit={this.handleSubmit}>
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  { this.state.message !== '' && this.state.status === false &&
                  <div className="small-12 columns warning_msg6">
                      <div className="float_left1"><WarningIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}
                  { this.state.message !== '' && this.state.status === true &&
                    <div className="small-12 columns warning_msg8">
                      <div className="float_left1"><CheckCircleOutlineIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}

                  <div className="row ">
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Date: </div>
                    <div className="small-12 medium-8 columns float_left">
                    <DatePicker selected={this.state.date}
                    onChange={(e) => this.handleDateChange(e, 'date')}
                    className="date_picker" />
                    </div>
                  </div>

                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_right top_space1" style={{paddingTop: "0.8rem"}}>Medication Name:</div>
                    <SelectLine value={this.state.med_name} padding="0.9rem" width="15rem"
                      name="med_name" onChange={(e) => {this.setState({ med_name: e.target.value })}} menuItems={medicationNamesMap} noClass  />
                    {(medCategory === 9 || medCategory === 10) &&
                    <>
                      <div className="small-12 medium-4 columns bold_right top_space1"></div>
                      <div className="small-12 medium-8 columns float_left">
                      <TextField label="" onChange={this.handleChange} name="med_other"
                        className="half1" variant="outlined" type="text"
                        value={this.state.med_other}
                        InputProps={{ inputProps: { name: "med_other", placeholder: 'Medication name' }}}
                        style={{paddingBottom: '0.7rem'}} />
                      </div>
                    </>
                   }
                  </div>
                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_right top_space1">Total Daily Dose: </div>
                    <div className="small-12 medium-8 columns float_left">
                    <TextField label="" onChange={(e) => this.handleNumber(e, 'med_dose')}
                      name="med_dose" className="short2" variant="outlined"
                      value={this.state.med_dose}
                      InputProps={{ inputProps: { name: "med_dose" }}} />
                      <span className="top_space3">mg</span>
                    </div>
                  </div>

                  <div className="small-12 columns">
                    <div className="small-12 medium-4 columns bold_right top_space1" style={{paddingTop: "0.8rem"}}>Frequency:</div>
                    <SelectLine value={this.state.frequency} padding="0.9rem" width="15rem"
                      name="med_name" onChange={(e) => {this.setState({ frequency: e.target.value })}} menuItems={frequencyMap} noClass  />
                  </div>

                  {(this.state.frequency === 12) &&
                    <>
                      <div className="small-12 medium-4 columns bold_right top_space1"></div>
                      <div className="small-12 medium-8 columns float_left" style={{paddingLeft: "1.1rem", marginBottom: "1.5rem"}}>
                      <TextField label="" onChange={this.handleChange} name="frequency_other"
                        className="half1" variant="outlined" type="text"
                        value={this.state.frequency_other}
                        InputProps={{ inputProps: { name: "frequency_other", maxLength: 200 }}} />
                      </div>
                    </>
                   }

                  <div className="small-12 medium-4 columns bold_right top_space1">Sig:</div>
                  <div className="small-12 medium-8 columns float_left" style={{paddingLeft: "1.1rem"}}>
                      <TextField onChange={this.handleChange} name="sig"
                        className="half1" variant="outlined" type="text"
                        value={this.state.sig}
                        InputProps={{ inputProps: { name: "sig", maxLength: 200 }}} />
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns">&nbsp;</div>
                    <div className="small-12 medium-8 columns">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>)}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AddMrtf)
