import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SignatureCanvas from 'react-signature-canvas'

class InformationSheet extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          reg_step: '',
          fullname: '',
          status: false,
          status_submit: true,
          message: '',
          uid: 0,
          patient_date: new Date().toISOString().substring(0, 10),
          accept: 0,
      }
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      //console.log(result['data'])
      var type = 'registration'
      if(result['data'].reg_step == 'complete_registration') {
        type = 'update'
      }
      if(result['data'].data_repository_end != null) {
        this.props.history.push('/member/'+result['data'].reg_step)
      }
      this.setState({
        reg_step: result['data'].reg_step,
        fullname: result['data'].fullname,
        type: type,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if(this.state.accept == 0) {
      this.setState({
        message: 'Please indicate your data use preference',
      })
      window.scrollTo(0, 0)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_information_sheet',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          accept: this.state.accept,
        }
      })
      .then((result) => {
        if(result['data'] === 'roi') {
          this.props.onLoadLocation('/member/roi')
          this.props.history.push('/member/roi')
        }
      })
    }
  }

  render() {
    //console.log(this.state)
    return (
      <div className="patient_waiver box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Data Repository Information Sheet
          </div>
          {this.state.reg_step != 'complete_registration' &&
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={5}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>}
        </div>
        {this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>}
        <div className="row">
          <div className="small-12 columns box1">
            <div className="">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <div className="bold">Data Repository Title: Focused Integrative Team-based Treatment for Bipolar Disorder (FITT-BD)</div>
                    <div className="bold">Principal Investigator: Louisa G. Sylvia, PhD</div>
                    <div className="white_bg1 top_space1">
                      <p>As part of your clinical care at the MGH Dauten Family Center for Bipolar Treatment Innovation’s FITT-BD clinic, you’ll be asked to complete questionnaires and answer questions about your mental health. With your permission, we would like to use your responses to these questions for research as described below.</p>
                      <p>Participation in the data repository is voluntary and can stop at any time. Deciding not to participate won't affect the medical care you receive at Mass General Brigham now or in the future.</p>

                      <p className="bold">Purpose of FITT-BD Data Repository</p>
                      <p>Upon enrollment in the FITT-BD clinic, you will be asked to provide a valid email address and password, which will then be used to log in to the FITT-BD secure web portal. You will complete assessments regularly via this FITT-BD portal.  Your responses will be securely stored on this platform. The database and web portal will be housed on the Mass General Brigham (MGB) Research Computing Cloud Infrastructure (DIPR and RFA). The web server and database server are hosted within the MGB Firewall. The web server and makes use of standard 128-bit Secure Socket Layer (SSL) technology encryption to protect data in transit. The participant data reside on servers located in the MGB Healthcare Systems (MGB) server farm. Physical and software access to the servers and security is provided by the MGB IT department. Members of the data management team will do everything, within reason, to keep a participant’s identity protected.</p>
                      <p>Only study staff members from FITT-BD will have access to identifiable health information stored in the repository, but we may share de-identified data (i.e., data that cannot be connected back to you) with other researchers. The study staff who will have access to these identifiable data are staff responsible for ensuring data integrity (i.e., data manager, website and database developer), our statistical team (i.e., statistical consultant and Director of Biostatistics Department), and all clinical and project management staff (e.g., the director of communications, the principal investigator, research coordinators, patient navigators, peer specialists). These study staff members help to ensure that the data is complete and accurate, mostly by reviewing aggregated data reports, but occasionally by reviewing individual data outliers, etc.</p>

                      <p className="bold">By reviewing this information sheet and choosing to opt into the data repository, you will be allowing us to use this information for research, analysis, and publications. If you choose to opt out of the data repository, your information will not be used for these purposes.</p>

                      <p>If you have questions about the FITT-BD clinic or would like your information to be removed from the system, please call us at <b>617-643-2076</b> to speak either with study staff or directly with the Principal Investigator, Dr. Louisa Sylvia.</p>

                      <p>If you’d like to speak to someone not involved in this research about your rights as a research subject, or any concerns or complaints you may have about the research, contact the Mass General Brigham IRB at (857) 282-1900.</p>

                      <p><i>We are required by the Health Insurance Portability and Accountability Act (HIPAA) to protect the privacy of health information obtained for research. This is an abbreviated notice, and does not describe all details of this requirement. During this study, identifiable information about you or your health will be collected and shared with the researchers conducting the research. In general, under federal law, identifiable health information is private. However, there are exceptions to this rule. In some cases, others may see your identifiable health information for purposes of research oversight, quality control, public health and safety, or law enforcement. We share your health information only when we must, and we ask anyone who receives it from us to protect your privacy.</i></p>

                      <hr className="sep_line5" />
                      <p className="bold">Please indicate your data use preference below:</p>
                      <p className="bold">
                      <input type="radio" value="1" name="accept" className="cb_1"
                        onChange={(e) => this.handleChange(e)} />
                        I have read and reviewed the FITT-BD Repository Information Sheet and choose to opt into the FITT-BD repository. I agree to have my de-identified clinical data to be used for research, analysis, and publications.
                      </p>
                      <p className="bold">
                      <input type="radio" value="2" name="accept" className="cb_1"
                        onChange={(e) => this.handleChange(e)} />
                        I have read and reviewed the FITT-BD Repository Information Sheet and choose to opt out of the FITT-BD repository. I do not agree to have my de-identified clinical data to be used for research, analysis, and publications.
                      </p>
                    </div>

                  </div>
                </div>

            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
          {this.state.status_submit &&
            <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        {!this.state.status_submit &&
          <Button variant="contained" disabled
           onClick={this.handleSubmit} type="submit" className="submit_1">
            Submit
        </Button>}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(InformationSheet)
