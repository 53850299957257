export const who5_questions = [
  {
    label: 'I have felt cheerful and in good spirits',
    value: 1,
  }, {
    label: 'I have felt calm and relaxed',
    value: 2,
  }, {
    label: 'I have felt active and vigorous',
    value: 3,
  }, {
    label: 'I wake up feeling fresh and rested',
    value: 4,
  }, {
    label: 'My daily life has been filled with things that interest me',
    value: 5,
  },
]

export const who5_choices = [
  {
    label: 'All the time',
    value: 5,
  }, {
    label: 'Most of the time',
    value: 4,
  }, {
    label: 'More than half of the time',
    value: 3,
  }, {
    label: 'Less than half of the time',
    value: 2,
  }, {
    label: 'Some of the time',
    value: 1,
  }, {
    label: 'At no time',
    value: 0,
  },
]
