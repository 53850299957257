import React from "react";

export const Line = (props) => {
  return (
    <div className="row" style={{ display: "flex", padding: props.padding ?? "0.5rem" }}>
      {props.label !== undefined && <div className="small-12 medium-3 columns bold">{props.label}:</div>}
      {props.children}
    </div>
  );
};
