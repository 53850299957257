import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/header1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";

class PatientHead2 extends Component {
  constructor() {
      super();
      this.state = {
          name: '',
          mrn: '',
          registered: '',
          dob: '',
          age: '',
          status: '',
      }
  }

  componentDidMount() {
    this.getHeader()
  }

  getHeader = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_head1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
      // this.props.authReducer.uid
    })
    .then((result) => {
        var patient = result['data']
        this.setState({
          name: patient.fullname,
          mrn: patient.mrn,
          registered: patient.registered,
          dob: patient.dob,
          age: patient.age,
          status: patient.status,
        })
      }
    )
  }

  render() {

    return (
      <div className="row">
        <div className="small-12 columns box_inner3">
          <div className="row">
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Patient Name: </span>
              {this.state.name}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">MRN: </span> {this.state.mrn}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Registered: </span> {this.state.registered}
            </div>
          </div>
          <div className="row">
            <div className="small-12 medium-4 columns bottom-space1">
              <span className="bold_txt1">Date of Birth: </span>
              {this.state.dob}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Age: </span> {this.state.age}
            </div>
            <div className="small-12 medium-4 columns">
              <span className="bold_txt1">Status: </span> {this.state.status}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({ })

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientHead2)
