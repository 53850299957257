import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"

import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { loadLocation } from '../../../actions/location'
import Button from '@material-ui/core/Button'

class CompleteRegistration extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          uid: 0,
      }
  }

  componentDidMount() { }

  handleSubmit = (e) => {
    e.preventDefault()
  }

  render() {

    return (
      <div className="welcome_overview box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Thank you!
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns box1">
              You have completed your registration for the FITT-BD Clinic.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CompleteRegistration)
