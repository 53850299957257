import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'
import '../../../../../styles/assets/css/admin/form3.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../../functions/pagination'
import { appt_status, appt_type_show } from '../../../variables/patient'

class ClinicalNotesList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: []
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  getDataList = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json")
    const ip = res.data.ip

    await axios({
      method: 'post',
      url: connectNode + 'admin/integrations/get_clinical_note_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        ip: ip,
      }
    })
    .then((result) => {
      console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          dataItems: result['data'][1],
        })
      }
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  convertDateFormat(getDate) {
    var buff = getDate.split("T")
    var genTime = buff[1].substr(0, 5)
    var buff = buff[0]
    var genDate = buff.split("-")
    genDate = genDate[1]+'/'+genDate[2]+'/'+genDate[0]
    return genDate+', '+genTime
  }

  genNumberToText(m) {
    return m.substr(2,2)+'o'+m.substr(4)+'o'+m.substr(0,2);
  }

  genType(m) {
    if(m == 'internal' || m == 'Internal') {
      return 1
    } else {
      return 2
    }
  }

  render() {
    return (
      <div className="contact_logs">
        <div className="row">
          <div className="small-12 columns title4">
            Clinical Note List
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Title</th>
                    <th>Author</th>
                    <th>Department</th>
                    <th>Create Date/Time</th>
                    <th>Detail</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map((item, index) =>
                <tr key={'d'+index}>
                  <td>{item.Type.Title}</td>
                  <td>{item.Author.DisplayName}</td>
                  <td>{item.Department.Name}</td>
                  <td>{this.convertDateFormat(item.CreationTime)}</td>
                  <td><Link to={"/admin/clinical_note/"+this.props.match.params.pid+"/"+this.genNumberToText(item.NoteID.ID)+"/"+this.genType(item.NoteID.Type)}>
                        Details</Link>
                  </td>
                </tr>
              )}
              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="6">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClinicalNotesList)
