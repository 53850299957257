import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import EmailIcon from '@material-ui/icons/Email'
import Spinner from 'react-spinner-material'
import { gender, hispanic, race, marital, growup_with, preferred_pronouns, relationship_long,
    yes_no, highest_education, live_where, live_with, exercise } from '../../../patients/variables/general_information.js'


class GeneralInformation extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          loading: false,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_general_information',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        this.setState({
          dataItems: result['data'],
          loading: true,
        })
      }
    )
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  formatDate =  (date) => {
    if(date) {
      var [ year, month, day ] = date.split('-')

      if (month < 10)
          month = '0' + month;
      if (day < 10)
          day = '0' + day;

      return [month, day, year].join('/')
    }
  }

  render() {
    return (
    <div className="general_information">
    {!this.state.loading &&
      <div className="row">
        <div className="small-12 columns loading2">
          <Spinner color={"#bbbbbb"} radius={50}/>
        </div>
      </div>}
    {this.state.loading &&
      <div className="row">
        <div className="small-12 columns">
          <div className="title1">
            Contact
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <div className="row">
              <div className="small-12 columns contact">
                <div><EmailIcon /> {this.state.dataItems.email}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns">
          <div className="w50m_w100s title1">General Information</div>
          <div className="w50m_w100s title1 right_date">Submitted: {this.state.dataItems.submitted_time}</div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Identifying Information</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Date of Birth:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.formatDate(this.state.dataItems.dob)}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Gender:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(gender, this.state.dataItems.gender)}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Preferred Pronouns:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(preferred_pronouns, this.state.dataItems.prefered_pronouns)}
              </div>
              </div>
              <div className="row">
              <div className="small-12 medium-6 columns title2">
                Hispanic:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(hispanic, this.state.dataItems.hispanic)}
              </div>
              </div>
              <div className="row">
              <div className="small-12 medium-6 columns title2">
                Race:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(race, this.state.dataItems.race)}
              </div>
              </div>
              <div className="row">
              <div className="small-12 medium-6 columns title2">
                Marital Status:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(marital, this.state.dataItems.marital)}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Developmental History and Family of Origin</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Who did you grow up with?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(growup_with, this.state.dataItems.who_did_grow_with)}
              </div>
            </div>
            {this.state.dataItems.who_did_grow_with == 2 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What family members?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.who_did_grow_with_fam}
              </div>
            </div>}
            {this.state.dataItems.who_did_grow_with == 4 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.who_did_grow_with_other}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Was your childhood home environment safe?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.childhood_environment_safe)}
              </div>
            </div>
            {this.state.dataItems.childhood_environment_safe == 2 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.childhood_environment_safe_no}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Was your childhood home environment supportive?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.childhood_environment_supportive)}
              </div>
            </div>
            {this.state.dataItems.childhood_environment_supportive == 2 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.childhood_environment_supportive_no}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Are you currently in a relationship?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.currently_relationship)}
              </div>
            </div>
            {this.state.dataItems.currently_relationship == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                How long:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(relationship_long, this.state.dataItems.currently_relationship_long)}
              </div>
            </div>}
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Education and Learning Disabilities</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What is your highest level of education?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(highest_education, this.state.dataItems.education)}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Have you ever been diagnosed with a learning disability?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.learning_disability)}
              </div>
            </div>
            {this.state.dataItems.learning_disability == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What learning disability was diagnosed?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.learning_disability_diagnosed}
              </div>
            </div>}
            {this.state.dataItems.learning_disability == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Approximate age when diagnosed?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.learning_disability_when}
              </div>
            </div>}
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Exercise</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                On average, how much do you exercise?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(exercise, this.state.dataItems.often_exercise)}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Employment</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Are you currently employed?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.currently_employed)}
              </div>
            </div>
            {this.state.dataItems.currently_employed == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
              Name of current job:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.currently_employed_job}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What was the most significant job position you held?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.most_significant_job}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What was the duration of the longest job position you held?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.duration_longest_job_held}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Do you have financial stressors?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.financial_stressors)}
              </div>
            </div>
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Living Situation</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Where do you live?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(live_where, this.state.dataItems.where_you_live)}
              </div>
            </div>
            {this.state.dataItems.where_you_live == 5 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
              Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.where_you_live_other}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Who do you live with?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(live_with, this.state.dataItems.who_live_with)}
              </div>
            </div>
            {this.state.dataItems.who_live_with == 5 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
              Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.who_live_withe_other}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Are children living with you?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.child_live_with)}
              </div>
            </div>
            {this.state.dataItems.child_live_with == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                How many children are living with you?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.child_live_with_number}
              </div>
            </div>}
            {this.state.dataItems.child_live_with == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                What is the age of each child?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.child_live_with_age}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Do you own firearms?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.firearms)}
              </div>
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Do you feel safe in your current living situation?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.feel_safe)}
              </div>
            </div>
            {this.state.dataItems.feel_safe == 2 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.feel_safe_no}
              </div>
            </div>}
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Legal History</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Current legal stresses?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.current_legal_stresses)}
              </div>
            </div>
            {this.state.dataItems.current_legal_stresses == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.current_legal_stresses_txt}
              </div>
            </div>}
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Current legal needs?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.current_legal_needs)}
              </div>
            </div>
            {this.state.dataItems.current_legal_needs == 1 &&
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                Explain:
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.state.dataItems.current_legal_needs_txt}
              </div>
            </div>}
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <p className="title_topic1">Military Service</p>
            <div className="row">
              <div className="small-12 medium-6 columns title2">
                History of military service?
              </div>
              <div className="small-12 medium-6 columns txt1">
                {this.findLabelByValue(yes_no, this.state.dataItems.military_service)}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(GeneralInformation)
