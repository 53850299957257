import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import PostAddIcon from '@material-ui/icons/PostAdd'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { loadLocationWithMessage } from '../../../../../actions/location'
import '../../../../../styles/assets/css/admin/list2.css'

import TreeChart from "./tree_chart"
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import WarningIcon from '@material-ui/icons/Warning'
import Pagination from '../../../../functions/pagination'
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no, todolist_status, completion2 } from '../../variables/treatment_plan.js'

class TreatmentPlanHistoryLog extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          message: '',
          dataItems: [],
          pageOfItems: [],
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan_history_log',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid, tid: this.props.match.params.tpid, }
    })
    .then((result) => {
      var getData = []
      //console.log(result['data'])
      //console.log(result['data'][0].value.length + ' : ' + result['data'][1].value.length)
      if(result['data'][0].value.length > 0 && result['data'][1].value.length > 0) {
        getData = [...result['data'][0].value, ...result['data'][1].value]
      } else if(result['data'][0].value.length > 0 && (result['data'][1].value.length == 0 || result['data'][1].value.length == undefined)) {
        getData = result['data'][0].value
      } else if((result['data'][0].value.length == 0 || result['data'][0].value.length == undefined) && result['data'][1].value.length > 0) {
        getData = result['data'][1].value
      }

      getData.sort((a, b) => a.mod_datetime.localeCompare(b.mod_datetime)).reverse()
      this.setState({
        dataItems: getData, //result['data'],
      })
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
    <div className="admin_treatment_plan_history_log box_border4">
      <div className="row">
        <div className="small-12 columns headerPage1">
          Long-Term Treatment Goal: {this.state.dataItems.length > 0 && this.state.dataItems[0].tm_goal}
          {this.state.dataItems.length > 0 && this.state.dataItems[0].treatment_goal_other}
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns top_space1" >
          <TreeChart pid={this.props.match.params.pid} tid={this.props.match.params.tpid} />
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns tb1_log">
          {this.state && this.state.dataItems && this.state.pageOfItems.map((val, index) =>
            <div key={'dt'+index} className="each_row_log" >
            {val.plan_completed && val.completion == 1 &&
            <div className="row">
              <div className="small-12 columns">
                <span className="bold">Goal Completion: </span>Yes
              </div>
              <div className="small-12 columns">
                <span className="bold">Updated Date: </span>
                {val.plan_completed}
              </div>
            </div>}
            {//val.target > 0 &&
            <div className="row">
              <div className="small-12 columns">
                <span className="bold">Updated Date: </span>
                {val.mod_datetime}{!val.mod_datetime && val.mod_comp_time}
              </div>
              {val.mod_status == 5 &&
              <div className="small-12 columns">
                <span className="bold">Updated by: </span> Patient
              </div>}
              <div className="small-12 columns">
                <span className="bold">To Do List item: </span>
                {val.intervention}
              </div>
              <div className="small-12 columns">
                <span className="bold">Treatment Modality: </span>
                {this.findLabelByValue(treatment_modality, val.modality_id)}
                {val.modality_id == 21 && ' ['+val.modality_other+']'}
              </div>
              <div className="small-12 columns">
                <span className="bold">To Do List item Format: </span>
                {this.findLabelByValue(intervention_format, val.intervention_format)}
                {val.intervention_format == 6 && ' ['+val.intervention_format_other+']'}
              </div>
              <div className="small-12 columns">
                <span className="bold">Date To Do List item assigned: </span>{val.modality_date}
              </div>
              {val.discussed != null &&
              <div className="small-12 columns">
                <span className="bold">Discussed: </span>{this.findLabelByValue(yes_no, val.discussed)}
              </div>}
              {val.discussed == 1 &&
              <>
                <div className="small-12 columns">
                  <span className="bold">Adherence to To Do List item: </span>
                  {val.adherence2 != null && this.findLabelByValue(completion2, val.adherence2)}
                  {val.adherence != null && val.adherence + ' %'}
                </div>
                {val.adherence < 90 &&
                <div className="small-12 columns">
                  <span className="bold">Adherence Reason: </span>
                  {this.findLabelByValue(adherence_reason, val.adherence2_reason)}
                  {val.reason == 10 && ' ['+val.adherence2_reason_other+']'}
                </div>}
                <div className="small-12 columns">
                  <span className="bold">To Do List item status: </span>{this.findLabelByValue(todolist_status, val.todo_status2)}
                </div>
              </>}
              {val.discussed == null &&
              <>
                <div className="small-12 columns">
                  <span className="bold">Adherence to To Do List item: </span>
                  {val.adherence2 != null && this.findLabelByValue(completion2, val.adherence)}
                  {val.adherence != null && val.adherence + ' %'}
                </div>
                {val.adherence < 90 &&
                <div className="small-12 columns">
                  <span className="bold">Adherence Reason: </span>
                  {this.findLabelByValue(adherence_reason, val.reason)}
                  {val.reason == 10 && ' ['+val.reason_other+']'}
                </div>}
                <div className="small-12 columns">
                  <span className="bold">To Do List item status: </span>{this.findLabelByValue(todolist_status, val.todo_status)}
                </div>
              </>}

            </div>}
            {val.target == 0 &&
            <div className="row">
              <div className="small-12 columns">
                <span className="bold">Goal Completion: </span>No
              </div>
              <div className="small-12 columns">
                <span className="bold">Updated Date: </span>
                {val.mod_comp_time}
              </div>
            </div>}
            {val.target == 0 &&
            <div className="row">
              <div className="small-12 columns">
                <span className="bold">Goal Completion: </span>Yes
              </div>
              <div className="small-12 columns">
                <span className="bold">Updated Date: </span>
                {val.created_timestamp}
              </div>
            </div>}
          </div>)}
          {this.state && this.state.dataItems &&
          <div className="pagination_block">
            <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
          </div>}
        </div>
      </div>
    </div>)}
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TreatmentPlanHistoryLog)
