import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import { FiSettings } from 'react-icons/fi'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import Switch from "react-switch"
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import ReactTooltip from 'react-tooltip'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'

class TrackerPreviousDay extends Component {
  constructor() {
    super()
    this.state = {
      message: '',
      status: 0,
      message_tracker: '',
      message_daily: '',
      message_weekly: '',
      status_tracker: 0,
      checked: false,
      mood_depressed: 3,
      mood_manic: 3,
      sleep: 4,
      medication: 0,
      exercise: 0,
      anxiety: 0,
      exercise_scale: 8,
      tracker_status: {},
      username: '',
    }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    // tracker day //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_status_daily_tracker_previous_day',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.authReducer.uid, }
    })
    .then((result) => {
        //console.log(result['data'])
        var message_tracker = ''
        var message_daily = ''
        var message_weekly = ''
        var status_tracker = 0
        if(result['data'].get_date == result['data'].previous_day) {
          message_tracker = "You have completed previous day trackers."
          message_daily = "You have completed previous day trackers."
          status_tracker = 1
        }
        if(result['data'].get_date == result['data'].previous_day ) {
          status_tracker = 12
        }
        this.setState({
          tracker_status: result['data'],
          message_tracker: message_tracker,
          message_daily: message_daily,
          message_weekly: message_weekly,
          status_tracker: status_tracker,
          username: result['data'].username,
        })
      }
    )
  }

  valueLabel2Format(val) {
    var txt = ''
    if(val === 2) {
        txt = '3 - 4'
      } else if(val === 3) {
        txt = '5 - 6'
      } else if(val === 4) {
        txt = '7 - 8'
      } else if(val === 5) {
        txt = '9 - 10'
      } else if(val === 6) {
        txt = '11 - 12'
      }
    return txt;
  }

  valueLabel3Format(val) {
    var txt = ''
    if(val === 2) {
        txt = 'Some'
      } else if(val === 3) {
        txt = 'Half'
      } else if(val === 4) {
        txt = 'Most'
      }
    return txt;
  }

  handleChange = (e) => {
    this.setState({
      //[e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  valueLabel4Format = (val) => {
    var txt = ''
    if(this.state.checked == false) {
      if(val === 2) {
        txt = '5 - 10'
      } else if(val === 3) {
        txt = '15 - 20'
      } else if(val === 4) {
        txt = '25 - 30'
      } else if(val === 5) {
        txt = '35 - 40'
      } else if(val === 6) {
        txt = '45 - 50'
      } else if(val === 7) {
        txt = '55 - 60'
      }
    } else {
      if(val === 2) {
        txt = '1 - 2'
      } else if(val === 3) {
        txt = '3 - 4'
      } else if(val === 4) {
        txt = '5 - 6'
      } else if(val === 5) {
        txt = '7 - 8'
      } else if(val === 6) {
        txt = '9 - 10'
      } else if(val === 7) {
        txt = '11 - 12'
      } else if(val === 8) {
        txt = '13 - 14'
      }
    }

    return txt;
  }


  handleChangeTrack1 = (e, name, val) => {
    //console.log(val)
    this.setState({ [name]: val });
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state.checked)
    var type = 1
    if(this.state.checked === true) {
      type = 2
    }
    // checked => false: Daily, true: weekly //
    var mood_depressed = -1
    var mood_manic = -1
    var sleep = -1
    var medication = -1
    var exercise = -1
    var anxiety = -1
    if(this.state.tracker_status.d_mood == 1) {
      mood_depressed = e.target.mood_depressed.value
      mood_manic = e.target.mood_manic.value
    }
    if(this.state.tracker_status.d_sleep == 1) {
      sleep = e.target.sleep.value
    }
    if(this.state.tracker_status.d_sleep == 1) {
      sleep = e.target.sleep.value
    }
    if(this.state.tracker_status.d_medication == 1) {
      medication = e.target.medication.value
    }
    if(this.state.tracker_status.d_exercise == 1) {
      exercise = e.target.exercise.value
    }
    if(this.state.tracker_status.d_anxiety == 1) {
      anxiety = e.target.anxiety.value
    }

    axios({
      method: 'post',
      url: connectNode + 'patient/tracker/add_tracker_previous_day',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        type: type,
        mood_depressed: mood_depressed,
        mood_manic: mood_manic,
        sleep: sleep,
        medication: medication,
        exercise: exercise,
        anxiety: anxiety,
      }
    })
    .then((result) => {
        console.log('Callback: '+result['data'])
        if(result['data'] === 1) {
          var status_tracker = 0
          var message_daily = ''
          var message_weekly = ''
          if((this.state.checked == false && this.state.status_tracker == 2) ||
             (this.state.checked == true && this.state.status_tracker == 1)) {
            status_tracker = 12
            message_daily = "You have completed previous day trackers."
          } else if(this.state.checked == false) {
            status_tracker = 1
            message_daily = "You have completed previous day trackers."
          }
          this.setState({
            //message: 'Thank you!',
            status: 1,
            status_tracker: status_tracker,
            message_daily: message_daily,
            message_weekly: message_weekly,
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.',
            status: 2,
          })
        }
      }
    )
  }

  render() {
    const PrettoSlider = withStyles({
      root: {
        color: '#b7ccd1',
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% - 5px)',
        top: -22,
        '& *': {
          background: 'transparent',
          color: '#6a8992',
        },
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    })(Slider)
    //console.log(this.state.tracker_status.d_mood)
    return (
      <div className="assessment_list box_border4 patient_dashboard" >
        <form onSubmit={this.handleSubmit}>
        <div className="dashboard_tracker">
          <div className="row">
            <div className="small-12 columns headerPage1">
            Daily Tracker: Previous Day
            </div>
            {(this.state.message === '' && (
            (this.state.status_tracker != 1 && this.state.status_tracker != 12 && this.state.checked == false) ||
            (this.state.status_tracker != 2 && this.state.status_tracker != 12 && this.state.checked == true))) &&
            <div>
              {/*--- Mood ---*/}
              {this.state.tracker_status.d_mood == 1 &&
              <div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
                <div className="small-12 columns txt_question">
                  Mood
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='mood_tracker'>
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id='mood_tracker' type='warning' effect='solid'>
                    <span>
                    { this.state.checked == false &&
                      <div>How is your mood today? Please use both trackers.</div>}
                    { this.state.checked == true &&
                      <div>How has your mood been over the past week?<br />Please use both trackers.</div>}
                    </span>
                  </ReactTooltip>
                </div>
                <div className="small-12 columns slider_no_scale">
                  <PrettoSlider valueLabelDisplay="auto" name="mood_depressed"
                   aria-labelledby="track-false-slider"
                   defaultValue={1} min={1} max={5} step={0.5} />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-6 columns align-left">
                      Not at all Depressed
                    </div>
                    <div className="small-6 columns align-right">
                      Extremely Depressed
                    </div>
                  </div>
                </div>
                <div className="small-12 columns slider_no_scale box_space_top3">
                <PrettoSlider valueLabelDisplay="auto" name="mood_manic"
                 aria-labelledby="track-false-slider"
                 defaultValue={1} min={1} max={5} step={0.5} />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-6 columns align-left">
                      Not at all Manic
                    </div>
                    <div className="small-6 columns align-right">
                      Extremely Manic
                    </div>
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
              </div>}
              {/*--- Sleep ---*/}
              {this.state.tracker_status.d_sleep == 1 &&
              <div>
                <div className="small-12 columns txt_question">
                  Sleep
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='sleep_tracker'>
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id='sleep_tracker' type='warning' effect='solid'>
                    <span>
                    { this.state.checked == false &&
                      <div>How many hours did you sleep last night?</div>}
                    { this.state.checked == true &&
                      <div>On average, how much have you been sleeping<br />a day (including naps) over the past week?</div>}
                    </span>
                  </ReactTooltip>
                </div>
                <div className="small-12 columns">
                  <PrettoSlider valueLabelDisplay="on"
                   aria-labelledby="continuous-slider"
                   name="sleep" min={1} max={7}
                   step={0.5} defaultValue={1}
                   getAriaValueText={this.valueLabel2Format}
                   valueLabelFormat={this.valueLabel2Format}
                   />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-4 columns align-left">
                      0-2 hours
                    </div>
                    <div className="small-4 columns align-left">
                      &nbsp;
                    </div>
                    <div className="small-4 columns align-right">
                      12+ hours
                    </div>
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
              </div>}
              {/*--- Medication ---*/}
              {this.state.tracker_status.d_medication == 1 &&
              <div>
                <div className="small-12 columns txt_question">
                  Medication
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='medication_tracker'>
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id='medication_tracker' type='warning' effect='solid'>
                    <span>
                    { this.state.checked == false &&
                      <div>How much of your medication did you take today?<br />
                        If answering in the morning, how much of your medication<br />
                        did you take yesterday? If you don't take medication,<br />
                        skip this tracker.</div>}
                    { this.state.checked == true &&
                      <div>How much of your medication have you taken in the<br />
                        past week? If you don't take medication, skip this tracker.</div>}
                    </span>
                  </ReactTooltip>
                </div>
                <div className="small-12 columns">
                  <PrettoSlider
                   valueLabelDisplay="on"
                   //aria-labelledby="track-false-slider"
                   defaultValue={1}
                   min={1} max={5}
                   step={0.5}
                   name="medication"
                   getAriaValueText={this.valueLabel3Format}
                   valueLabelFormat={this.valueLabel3Format}
                   />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-6 columns align-left">
                      None
                    </div>
                    <div className="small-6 columns align-right">
                      All
                    </div>
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
              </div>}
              {/*--- Exercise ---*/}
              {this.state.tracker_status.d_exercise == 1 &&
              <div>
                <div className="small-12 columns txt_question">
                  Exercise ({ this.state.checked == false && 'minutes'}{ this.state.checked == true && 'hours'})
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='exercise_tracker'>
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id='exercise_tracker' type='warning' effect='solid'>
                    <span>
                    { this.state.checked == false &&
                      <div>How much did you exercise today? If answering in<br />
                      the morning, how much did you exercise yesterday?</div>}
                    { this.state.checked == true &&
                      <div>How much exercise did you get in the past week?</div>}
                    </span>
                  </ReactTooltip>
                </div>
                <div className="small-12 columns">
                  <PrettoSlider
                   valueLabelDisplay="on"
                   //aria-labelledby="track-false-slider"
                   defaultValue={1}
                   min={1} max={this.state.exercise_scale}
                   step={0.5}
                   name="exercise"
                   getAriaValueText={this.valueLabel4Format}
                   valueLabelFormat={this.valueLabel4Format}
                   />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-6 columns align-left">
                    { this.state.checked == false && '0 min'}
                    { this.state.checked == true && '0 - 1 hours'}
                    </div>
                    <div className="small-6 columns align-right">
                    { this.state.checked == false && '60+'}
                    { this.state.checked == true && '14+'}
                    </div>
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
              </div>}
              {/*--- Coping with Stress ---*/}
              {this.state.tracker_status.d_anxiety == 1 &&
              <div>
                <div className="small-12 columns txt_question">
                  Anxiety
                  <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='stress_tracker'>
                    <AiOutlineQuestionCircle />
                  </NavLink>
                  <ReactTooltip id='stress_tracker' type='warning' effect='solid'>
                    <span>
                    { this.state.checked == false &&
                      <div>How anxious are you today?</div>}
                    { this.state.checked == true &&
                      <div>On average, how anxious have you been in the past week?</div>}
                    </span>
                  </ReactTooltip>
                </div>
                <div className="small-12 columns stress_slider">
                  <PrettoSlider valueLabelDisplay="on"
                   aria-labelledby="track-false-slider"
                   defaultValue={1} min={1} max={5} step={0.5}
                   name="anxiety"
                   />
                </div>
                <div className="small-12 columns">
                  <div className="row txt_scales">
                    <div className="small-4 columns align-left">
                      Not at all Anxious
                    </div>
                    <div className="small-4 columns align-left">
                      &nbsp;
                    </div>
                    <div className="small-4 columns align-right">
                      Extremely Anxious
                    </div>
                  </div>
                </div>
                <div className="small-12 columns">
                  <div className="line_1">&nbsp;</div>
                </div>
              </div>}
              <div className="row">
              { (this.state.tracker_status.d_mood == 1 || this.state.tracker_status.d_sleep == 1
                || this.state.tracker_status.d_medication == 1 || this.state.tracker_status.d_exercise == 1
                || this.state.tracker_status.d_anxiety == 1) &&
              <div className="small-12 columns align-right submit1 tracker_submit">
                <Button variant="contained" color="primary" disableElevation
                 //onClick={this.handleSubmit}
                 type="submit">
                  Submit
                </Button>
              </div>}
              </div>
            </div>}
            { ((this.state.message_daily.length > 0 && this.state.checked === false)
              || (this.state.message_weekly.length > 0 && this.state.checked === true)
              || this.state.status == 2) &&
            <div className="small-12 columns warning_tracker_txt1">
              <span className="warning_msg1">
              {this.state.checked === false && this.state.message_daily}
              {this.state.checked === true && this.state.message_weekly}
              {this.state.status == 2 && this.state.message}
              </span>
            </div>}
          </div>
        </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Dashboard STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('Dashboard mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackerPreviousDay)
