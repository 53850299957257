import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/olivia1.png'
//import AudioMP3 from '../../../audios/sample1.mp3'

class Intro5 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction6')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction4')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_2 margin-bottom1">
              MEET OLIVIA
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns content2">
                <p>Let’s look at an imaginary patient to see how this might work.</p>
                <p>Olivia is 29 years old and was diagnosed with bipolar disorder
                when she was 19. She graduated high school and started college,
                but she couldn’t keep up with her schoolwork and dropped out.</p>
                <p>Now Olivia works in retail, which she dislikes. She has not had a manic episode in recent years, but she has periods of depression that get in the way of making the changes she wants in her life.</p>
                <p>Olivia wants to go back to school and earn a degree in business. She also wants to have the energy to do more things she enjoys with her partner and her friends.</p>
              </div>
              <div className="small-12 medium-6 columns">
                <img src={Image} alt="team" />
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro5)
