import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { Link } from "react-router-dom"
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import PatientHead3 from "../../patient/patient_head3"
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import FormPHQ4 from '../../../../pdf/PHQ-4.pdf'
import FormWHO5 from '../../../../pdf/WHO-5.pdf'
import FormPHQ9 from '../../../../pdf/PHQ-9.pdf'
import FormGAD7 from '../../../../pdf/GAD7.pdf'
import FormCOBRA from '../../../../pdf/COBRA.pdf'
import FormASRM from '../../../../pdf/ASRM.pdf'
import FormPW18 from '../../../../pdf/PW18.pdf'
import FormPSQI from '../../../../pdf/PSQI.pdf'
import FormDERS18 from '../../../../pdf/DERS-18.pdf'

import { phq4_choices, phq4_questions } from '../../../patients/variables/assessments/phq4.js'
import { who5_choices, who5_questions } from '../../../patients/variables/assessments/who5.js'
import { phq9_1, phq9_2, phq9_questions, phq9_question_10 } from '../../../patients/variables/assessments/phq9.js'
import { q1 as asrm_q1, q2 as asrm_q2, q3 as asrm_q3, q4 as asrm_q4, q5 as asrm_q5 } from '../../../patients/variables/assessments/asrm.js'
import { gad7_1, gad7_2, gad7_questions, gad7_question_8 } from '../../../patients/variables/assessments/gad7.js'
import { cobra_1, cobra_questions } from '../../../patients/variables/assessments/cobra.js'
import { pw18_questions, pw18_scale } from '../../../patients/variables/assessments/pw18.js'
import { ders18_questions, ders18_choices } from '../../../patients/variables/assessments/ders18.js'
import { q5_questions, q5_choices, q6_choices, q7_choices, q9_choices, q10_choices,
         q10_add_questions, q10_add_choices } from '../../../patients/variables/assessments/psqi.js'
import { covid_questions, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11 } from '../../../patients/variables/assessments/covid.js'
import { sccs_scale, sccs_questions } from '../../../patients/variables/assessments/sccs.js'
import { ismi_scale, ismi_questions } from '../../../patients/variables/assessments/ismi.js'

class ViewAssessment extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: {},
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/view_assessment',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        assm: this.props.match.params.assm,
        time_type: this.props.match.params.time_type,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
      console.log(result['data'])
        this.setState({
          dataItems: result['data'],
        })
      }
    )

  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  convert_date_time = (datetime) => {
    var getdate = datetime.substr(5, 2)+'/'+datetime.substr(8, 2)+'/'+datetime.substr(0, 4)
    getdate += ', '+datetime.substr(11, 8)
    return getdate
  }

  render() {
    console.log(this.state)
    return (
      <div className="admin_patient_detail box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            {this.props.match.params.assm == 'phq4' && 'PHQ-4'}
            {this.props.match.params.assm == 'who5' && 'Well-Being Index (WHO-5)'}
            {this.props.match.params.assm == 'phq9' && 'The Patient Health Questionnaire (PHQ-9)'}
            {this.props.match.params.assm == 'asrm' && 'Altman Self-Rating Mania Scale (ASRM)'}
            {this.props.match.params.assm == 'gad7' && 'Generalized Anxiety Disorder Scale (GAD-7)'}
            {this.props.match.params.assm == 'cobra' && 'Cognitive Complaints in Bipolar Disorder Rating Assessment (COBRA)'}
            {this.props.match.params.assm == 'pw18' && 'Psychological Well-Being Scale (PWB)'}
            {this.props.match.params.assm == 'ders18' && 'Difficulties in Emotion Regulation Scale (DERS-18)'}
            {this.props.match.params.assm == 'psqi' && 'Pittsburgh Sleep Quality Index (PSQI)'}
            {this.props.match.params.assm == 'mtf' && 'Medication Tracking Form'}
            {this.props.match.params.assm == 'covid' && 'Coronavirus Impact Scale'}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns">
                    <div className="small-11 columns title4">
                      {this.props.match.params.time_type == 'm' && this.props.match.params.timepoint == -1 && 'Baseline assessments'}
                      {this.props.match.params.time_type == 'w' && 'Weekly assessments: '+this.props.match.params.timepoint }
                      {this.props.match.params.time_type == 'm' && this.props.match.params.timepoint != -1 && 'Monthly assessments: '+this.props.match.params.timepoint }
                      {this.props.match.params.time_type == 'tm' && 'Trimonthly assessments: '+this.props.match.params.timepoint }
                      {this.props.match.params.time_type == 'y' && 'Yearly assessments: '+this.props.match.params.timepoint }
                    </div>
                    <div className="small-1 columns">
                      {this.props.match.params.assm == 'phq9' &&
                        <Link to={FormPHQ9} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'asrm' &&
                        <Link to={FormASRM} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'gad7' &&
                        <Link to={FormGAD7} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'cobra' &&
                        <Link to={FormCOBRA} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'pw18' &&
                        <Link to={FormPW18} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'ders18' &&
                        <Link to={FormDERS18} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'psqi' &&
                        <Link to={FormPSQI} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'who5' &&
                        <Link to={FormWHO5} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'phq4' &&
                        <Link to={FormPHQ4} target="_blank"><PictureAsPdfIcon /></Link>}
                    </div>
                    {this.props.match.params.assm != 'mtf' &&
                    <div className="small-12 columns">
                      Start: {this.state.dataItems.start_datetime} | Finish: {this.state.dataItems.end_datetime && this.convert_date_time(this.state.dataItems.end_datetime)}
                    </div>}
                    <div className="small-12 columns sep_line2">&nbsp;</div>
                    {this.props.match.params.assm == 'phq4' &&
                    <div>{phq4_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(phq4_choices, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'who5' &&
                    <div>{who5_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(who5_choices, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'phq9' &&
                    <div>{phq9_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(phq9_1, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                        <div className="small-12 columns">
                          <div className="row">
                            <div className="small-9 columns">10. {this.findLabelByValue(phq9_question_10, 10)}</div>
                            <div className="small-3 columns">{this.findLabelByValue(phq9_2, this.state.dataItems['q10'])}</div>
                          </div>
                        </div>
                    </div>}
                    {this.props.match.params.assm == 'asrm' &&
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-3 columns txt2">Question 1:</div>
                          <div className="small-9 columns">{this.findLabelByValue(asrm_q1, this.state.dataItems['q1'])}</div>
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-3 columns txt2">Question 2:</div>
                          <div className="small-9 columns">{this.findLabelByValue(asrm_q2, this.state.dataItems['q2'])}</div>
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-3 columns txt2">Question 3:</div>
                          <div className="small-9 columns">{this.findLabelByValue(asrm_q3, this.state.dataItems['q3'])}</div>
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-3 columns txt2">Question 4:</div>
                          <div className="small-9 columns">{this.findLabelByValue(asrm_q4, this.state.dataItems['q4'])}</div>
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                      <div className="small-12 columns">
                        <div className="row ">
                          <div className="small-3 columns txt2">Question 5:</div>
                          <div className="small-9 columns">{this.findLabelByValue(asrm_q5, this.state.dataItems['q5'])}</div>
                        </div>
                      </div>
                    </div>}
                    {this.props.match.params.assm == 'gad7' &&
                    <div>{gad7_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(gad7_1, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                        <div className="small-12 columns">
                          <div className="row">
                            <div className="small-9 columns">8. {this.findLabelByValue(gad7_question_8, 8)}</div>
                            <div className="small-3 columns">{this.findLabelByValue(gad7_2, this.state.dataItems['q8'])}</div>
                          </div>
                        </div>
                    </div>}
                    {this.props.match.params.assm == 'cobra' &&
                    <div>{cobra_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(cobra_1, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'pw18' &&
                    <div>{pw18_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(pw18_scale, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'ders18' &&
                    <div>{ders18_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-9 columns">{each.value}. {each.label}</div>
                              <div className="small-3 columns">{this.findLabelByValue(ders18_choices, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'psqi' &&
                    <div>
                      <div className="row">
                        <div className="small-12 columns box1">
                          <div className="row no_margin_bottom">

                            <div className="small-12 medium-10 columns bold">
                                1. During the past month, when have you usually gone to bed at night?
                            </div>
                            <div className="small-12 medium-2 columns ">
                              {this.state.dataItems['q1']}
                            </div>
                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>

                            <div className="small-12 medium-10 columns bold">
                                2. During the past month, how long (in minutes) has it usually take you to fall asleep each night?
                            </div>
                            <div className="small-12 medium-2 columns">
                              {this.state.dataItems['q2']}
                            </div>
                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>

                            <div className="small-12 medium-10 columns bold">
                                3. During the past month, when have you usually gotten up in the morning?
                            </div>
                            <div className="small-12 medium-2 columns">
                              {this.state.dataItems['q3']}
                            </div>
                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>

                            <div className="small-12 medium-10 columns bold">
                                4. During the past month, how many hours of actual sleep did you get a night? (This may be different than the number of hours you spend in bed.)
                            </div>
                            <div className="small-12 medium-2 columns">
                              {this.state.dataItems['q4']}
                            </div>
                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                            <div className="small-12 columns bold">
                                5. During the past month, how often have you had trouble sleeping because you...
                            </div>
                            <div className="small-12 columns">
                            {this.findLabelByValue(q5_choices, this.state.dataItems['q5'])}
                            {q5_questions.map(eachQ =>
                              <div className="top_space1" key={"q5"+eachQ.value} >
                                <div className="small-12 medium-8 columns">
                                  {eachQ.label}
                                  {eachQ.value==10 && this.state.dataItems['q510_other']}
                                </div>
                                <div className="small-12 medium-4 columns">
                                  {this.findLabelByValue(q5_choices, this.state.dataItems['q5'+eachQ.value])}
                                </div>
                              </div>)}
                            </div>
                            <div className="small-12 columns top_space2">
                              <div className="row">
                                <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                                <div className="small-12 medium-9 columns bold">
                                  6. During the past month, how would you rate your sleep quality overall?
                                </div>
                                <div className="small-12 medium-3 columns">
                                  {this.findLabelByValue(q6_choices, this.state.dataItems['q6'])}
                                </div>
                                <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                                <div className="small-12 medium-9 columns bold">
                                  7. During the past month, how often have you taken medicine
                                  (prescribed or "over the counter") to help you sleep?
                                </div>
                                <div className="small-12 medium-3 columns">
                                  {this.findLabelByValue(q7_choices, this.state.dataItems['q7'])}
                                </div>
                                <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                                <div className="small-12 medium-9 columns bold">
                                  8. During the past month, how often have you had trouble staying
                                  awake while driving, eating meals, or engaging in social activity?
                                </div>
                                <div className="small-12 medium-3 columns">
                                  {this.findLabelByValue(q7_choices, this.state.dataItems['q8'])}
                                </div>
                              </div>
                            </div>

                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                            <div className="small-12 medium-9 columns bold">
                              9. During the past month, how much of a problem has it been for you
                              to keep up enough enthusiasm to get things done?
                            </div>
                            <div className="small-12 medium-3 columns">
                              {this.findLabelByValue(q9_choices, this.state.dataItems['q9'])}
                            </div>

                            <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                            <div className="small-12 medium-9 columns bold">
                              10. Do you have a bed partner or room mate?
                            </div>
                            <div className="small-12 medium-3 columns">
                              {this.findLabelByValue(q10_choices, this.state.dataItems['q10'])}
                            </div>

                            { this.state.dataItems['q10'] >2 &&
                            <div>
                              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                              <div className="small-12 columns bold">
                                <ArrowRightIcon />  If you have a roommate or bed partner, ask him/her how often in the past month you have had...
                              </div>
                              <div className="small-12 columns"><div className="sep_line1">&nbsp;</div></div>
                              <div className="small-12 columns top_space2">
                                <div className="row">
                                  {q10_add_questions.map(eachQ =>
                                    <div className="">
                                      <div className="small-12 medium-9 columns">
                                        {eachQ.label}
                                        {eachQ.value==5 && this.state.dataItems['q105_other']}
                                      </div>
                                      <div className="small-12 medium-3 columns">
                                        {this.findLabelByValue(q10_add_choices, this.state.dataItems['q10'+eachQ.value])}
                                      </div>
                                      {eachQ.value < 5 &&
                                      <div className="small-12 columns">
                                        <div className="sep_line1">&nbsp;</div>
                                      </div>}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>}

                          </div>
                        </div>
                      </div>
                    </div>}
                    {this.props.match.params.assm == 'mtf' && this.state.dataItems.length>0 &&
                    <div>
                    <div>{this.state.dataItems.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-12 columns"><span className="bold">Medication name: </span>{each.med_other}</div>
                              <div className="small-12 columns"><span className="bold">Dosage: </span>{each.y_dose} mg</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>
                  </div>}
                  {this.props.match.params.assm == 'mtf' && this.state.dataItems==0 &&
                  <div className="small-12 columns">
                    No medication submitted
                  </div>}
                  {this.props.match.params.assm == 'covid' &&
                  <div>{covid_questions.map(each =>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom">
                            <div className="small-6 columns">{each.value}. {each.label}</div>
                            <div className="small-6 columns">
                            { each.value == 1 && this.findLabelByValue(q1, this.state.dataItems['q'+each.value]) }
                            { each.value == 2 && this.findLabelByValue(q2, this.state.dataItems['q'+each.value]) }
                            { each.value == 3 && this.findLabelByValue(q3, this.state.dataItems['q'+each.value]) }
                            { each.value == 4 && this.findLabelByValue(q4, this.state.dataItems['q'+each.value]) }
                            { each.value == 5 && this.findLabelByValue(q5, this.state.dataItems['q'+each.value]) }
                            { each.value == 6 && this.findLabelByValue(q6, this.state.dataItems['q'+each.value]) }
                            { each.value == 7 && this.findLabelByValue(q7, this.state.dataItems['q'+each.value]) }
                            { each.value == 8 && this.findLabelByValue(q8, this.state.dataItems['q'+each.value]) }
                            { each.value == 9 && this.findLabelByValue(q9, this.state.dataItems['q'+each.value]) }
                            { each.value == 10 && this.findLabelByValue(q10, this.state.dataItems['q10_num']) }
                            { each.value == 11 && this.findLabelByValue(q11, this.state.dataItems['q11_num']) }
                            { each.value == 12 && this.state.dataItems['q'+each.value] }
                            </div>
                          </div>
                        </div>
                        {each.value == 10 &&  this.state.dataItems['q10_num']>0 &&
                        <div className="small-12 columns">
                          <ArrowRightIcon /> Rate the symptoms of the person who was most sick:
                          {this.findLabelByValue(q10, this.state.dataItems['q10'])}
                        </div>}
                        {each.value == 11 && this.state.dataItems['q11_num']>0 &&
                        <div className="small-12 columns">
                          <ArrowRightIcon /> Rate the symptoms of the person who was most sick:
                          {this.findLabelByValue(q11, this.state.dataItems['q11'])}
                        </div>}
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                  </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ViewAssessment)
