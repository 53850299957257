import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { mini_yesno, mini_not_completed_reason,
  mini_scale1, mini_scale2, mini_q_a, mini_q_b, mini_q_c, mini_q_d , mini_q_e,
  mini_q_f, mini_q_f_v2, mini_q_g, mini_q_h, mini_q_i, mini_q_i_v2,
  mini_q_j1, mini_q_j1_v2, mini_q_j_meet_criteria, mini_q_j_meet_criteria_v2,
  mini_q_k, mini_q_l, mini_q_m, mini_q_n, mini_q_o, mini_q_q, mini_eligible,
  tobacco_scale, audit_c1_scale, audit_c2_scale, audit_c3_scale, audit_c_q,
  mini_borderline
 } from '../variables/assessments/mini.js'

import PatientHead3 from "../patient/patient_head3"
import ReactHtmlParser from 'react-html-parser'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'

class MiniResult extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          dataItems: [],
      }
  }

  componentDidMount() {
    this.getData()
    window.scrollTo(0, 0)
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/view_mini_result',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] != 0) {
        this.setState({
          dataItems: result['data'][0]
        })
      }
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4 mini_result">
        <div className="row">
          <div className="small-12 columns headerPage1">MINI Result</div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns">
                    { this.props.locationReducer.txt_message &&
                    <div className="row warning_msg7">
                      <div className="small-12 columns ">
                        <div className="float_left1"><WarningIcon /></div>
                        <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
                      </div>
                    </div>}
                    <div className="small-12 columns box5 top_space1">
                      <div>{mini_q_a.map(each =>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom">
                            <div className="small-9 columns">A{each.value}. {each.label}</div>
                            <div className="small-3 columns">
                            {this.findLabelByValue(mini_scale1, this.state.dataItems['a'+each.value])}
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                      </div>

                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom">
                            <div className="small-9 columns">B{mini_q_b[0].value}. {mini_q_b[0].label}</div>
                            <div className="small-3 columns">
                            {this.findLabelByValue(mini_scale1, this.state.dataItems.b1)}
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>
                      </div>

                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom">
                            <div className="small-9 columns">B{mini_q_b[1].value}. {mini_q_b[1].label}</div>
                            <div className="small-3 columns">
                            {this.findLabelByValue(mini_scale2, this.state.dataItems.b2)}
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>
                      </div>

                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_mini1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_q_c.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">C{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['c'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini2/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_q_d.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">D{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['d'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_e.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">E{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['e'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_f_v2.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">F{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['f'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>
                    {/*this.state.dataItems.start_timestamp <= 1679054167 &&
                    <div>{mini_q_f.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">F{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['f'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>*/}

                    <div>{mini_q_g.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">G{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['g'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_h.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">H{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['h'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_i_v2.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">I{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['i'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>
                    {/*this.state.dataItems.start_timestamp <= 1679054167 &&
                    <div>{mini_q_i.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">I{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['i'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>*/}
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini3/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_q_j1_v2.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-12 columns">J{each.value}. {each.label}</div>
                        </div>
                      </div>)}
                    </div>
                    <div>{mini_q_j_meet_criteria_v2.map(each =>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom">
                            <div className="small-9 columns"><ArrowRightIcon /> {each.label}</div>
                            <div className="small-3 columns">
                            {this.findLabelByValue(mini_scale1, this.state.dataItems['cb_j'+each.value])}
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                      </div>
                    {/*<div>{mini_q_j1.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">J{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['j'+each.value])}
                          {each.value == 1 && ' ['+this.findLabelByValue(mini_q_j_meet_criteria, this.state.dataItems['j'+each.value+'_criteria'])+']'}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>*/}
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini4/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_q_k.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">K{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['k'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_l.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">L{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['l'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_m.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">M{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['m'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini5/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_q_n.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">N{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['n'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>{mini_q_o.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">O{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_scale1, this.state.dataItems['o'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>

                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini6/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div className="small-12 columns">
                      <div className="row no_margin_bottom">
                        <div className="small-9 columns">Tobacco 30 day use: Has the patient used any tobacco product in the past 30 days?</div>
                        <div className="small-3 columns">
                        {this.findLabelByValue(tobacco_scale, this.state.dataItems['tobacco_30d_use'])}
                        </div>
                      </div>
                      {this.state.dataItems['tobacco_30d_use'] > -1 &&
                      <div className="small-12 columns sep_line1">&nbsp;</div>}
                    </div>

                    {this.state.dataItems['tobacco_30d_use'] > -1 &&
                    <div>

                    <div className="small-12 columns">
                      <div className="row no_margin_bottom">
                        <div className="small-9 columns">AUDIT-C: 1. How often do you have a drink containing alcohol?</div>
                        <div className="small-3 columns">
                        {this.findLabelByValue(audit_c1_scale, this.state.dataItems['audit_c_1'])}
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                    </div>

                    <div className="small-12 columns">
                      <div className="row no_margin_bottom">
                        <div className="small-9 columns">AUDIT-C: 2. How many standard drinks containing alcohol do you have on a typical day?</div>
                        <div className="small-3 columns">
                        {this.findLabelByValue(audit_c2_scale, this.state.dataItems['audit_c_2'])}
                        </div>
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                    </div>

                    <div className="small-12 columns">
                      <div className="row no_margin_bottom">
                        <div className="small-9 columns">AUDIT-C: 3. How often do you have 6 drinks or more on one occasion?</div>
                        <div className="small-3 columns">
                        {this.findLabelByValue(audit_c3_scale, this.state.dataItems['audit_c_3'])}
                        </div>
                      </div>
                    </div>

                    </div>}
                    <div className="small-12 columns sep_line1">&nbsp;</div>
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini7/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                    <div>{mini_borderline.map(each =>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-9 columns">{each.value}. {each.label}</div>
                          <div className="small-3 columns">
                          {this.findLabelByValue(mini_yesno, this.state.dataItems['bdl'+each.value])}
                          </div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>)}
                    </div>
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom text_align_right">
                          <a href={"/admin/assessments/edit_mini8/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                              Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>

                    <div className="small-12 columns box5 top_space1">
                      <div className="small-12 columns"><span className="bold">Result: </span>
                        {this.findLabelByValue(mini_eligible, this.state.dataItems.bd_result)}
                      </div>
                      <div className="small-12 columns"><span className="bold">Note: </span><br />
                        {ReactHtmlParser(this.state.dataItems.note)}
                      </div>
                      <div className="small-12 columns sep_line1">&nbsp;</div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_mini9/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MiniResult)
