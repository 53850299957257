import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import Spinner from 'react-spinner-material'
import '../../../../styles/assets/css/admin/list2.css'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { yes_no, yes_no_notsure, primary_interest, gen_m } from '../../../patients/variables/registration.js'

class ScreenQuestionaire extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: {},
          loading: false,
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_screen_questionnaire',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        this.setState({
          dataItems: result['data'],
          loading: true,
        })
      }
    )
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="general_information">
      {!this.state.loading &&
        <div className="row">
          <div className="small-12 columns loading2">
            <Spinner color={"#bbbbbb"} radius={50}/>
          </div>
        </div>}
      {this.state.loading &&
        <div className="row">
          <div className="small-12 columns">
            <div className="w50m_w100s title1">Screening Questionnaire</div>
            <div className="w50m_w100s title1 right_date">Submitted: {this.state.dataItems.conv_date_time}</div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns">
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Do you currently live in Massachusetts?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no, this.state.dataItems.live_ma)}
                    </div>
                  </div>
                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      What is your primary interest in the FITT-BD clinic?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(primary_interest, this.state.dataItems.primary_interest)}
                    </div>
                  </div>
                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Are you currently diagnosed with schizophrenia or schizoaffective disorder?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_notsure, this.state.dataItems.schizophrenia)}
                    </div>
                  </div>
                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Have you ever been diagnosed with bipolar disorder?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_notsure, this.state.dataItems.bipolar)}
                    </div>
                  </div>
                  {this.state.dataItems.bipolar > 1 &&
                  <div>
                    <div className="sep_line1">&nbsp;</div>
                    <div className="row">
                      <div className="small-12 medium-8 columns bold">
                      Some people have periods lasting several days or longer when they feel much
                      more excited and energetic or irritable and aggressive than usual, so much so
                      that other 	people notice and/or it causes problems at home, work or school.
                      During these periods, people often experience other symptoms: they may sleep
                      less but not feel tired, their minds may go too fast, they may be	more talkative,
                      they may be very restless or unable to sit still, and they may do things
                      that are unusual for them, like driving too fast or spending too much money.<br />
                      Have you ever had a period like this that lasted at least four days?
                      </div>
                      <div className="small-12 medium-4 columns">
                        {this.findLabelByValue(yes_no_notsure, this.state.dataItems.bipolar_lasted_a_week)}
                      </div>
                    </div>
                    <div className="sep_line1">&nbsp;</div>
                    <div className="row">
                      <div className="small-12 medium-8 columns bold">
                        Would you be willing to answer a few questions by phone to complete the screening and determine if you might have bipolar disorder?
                      </div>
                      <div className="small-12 medium-4 columns">
                        {this.findLabelByValue(yes_no, this.state.dataItems.bipolar_phone_complete_screen)}
                        {this.state.dataItems.reach_number && ' ['+this.state.dataItems.reach_number+']'}
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>

        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ScreenQuestionaire)
