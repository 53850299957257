export const daily_tracker_sleep = [ '',
  '0-2 hrs',
  '3-4 hrs',
  '5-6 hrs',
  '7-8 hrs',
  '9-10 hrs',
  '11-12 hrs',
  '12+ hrs'
]

export const daily_tracker_sleep2 = [ '',
  '0-2 hrs',
  '0-2 hrs +',
  '3-4 hrs',
  '3-4 hrs+',
  '5-6 hrs',
  '5-6 hrs+',
  '7-8 hrs',
  '7-8 hrs+',
  '9-10 hrs',
  '9-10 hrs+',
  '11-12 hrs',
  '11-12 hrs+',
  '12+ hrs',
  '12+ hrs'
]

export const weekly_tracker_sleep = [ '',
'0-2 hrs',
'0-2 hrs +',
'3-4 hrs',
'3-4 hrs+',
'5-6 hrs',
'5-6 hrs+',
'7-8 hrs',
'7-8 hrs+',
'9-10 hrs',
'9-10 hrs+',
'11-12 hrs',
'11-12 hrs+',
'12+ hrs',
'12+ hrs'
]

export const daily_tracker_medication = [ '',
  'None',
  'Some',
  'Half',
  'Most',
  'All'
]

export const weekly_tracker_medication = [ '',
  'None',
  'Some',
  'Half',
  'Most',
  'All'
]

export const daily_tracker_exercise = [ '',
  '0 min',
  '5-10 mins',
  '15-20 mins',
  '25-30 mins',
  '35-40 mins',
  '45-50 mins',
  '55-60 mins',
  '60+ mins'
]

export const daily_tracker_exercise2 = [ '',
  '0 min',
  '0 min+',
  '5-10 mins',
  '5-10 mins+',
  '15-20 mins',
  '15-20 mins+',
  '25-30 mins',
  '25-30 mins+',
  '35-40 mins',
  '35-40 mins+',
  '45-50 mins',
  '45-50 mins+',
  '55-60 mins',
  '55-60 mins+',
  '60+ mins',
  '60+ mins'
]

export const weekly_tracker_exercise = [ '',
  '0-1 hours',
  '0-1 hours+',
  '1-2 hours',
  '1-2 hours+',
  '3-4 hours',
  '3-4 hours+',
  '5-6 hours',
  '5-6 hours+',
  '7-8 hours',
  '7-8 hours+',
  '9-10 hours',
  '9-10 hours+',
  '11-12 hours',
  '11-12 hours+',
  '13-14 hours',
  '13-14 hours+',
  '14+ hours'
]

/* Chart */

export const ch_daily_weekly_mood_dep = [
  'Not at all Depressed',
  '.',
  '..',
  '...',
  'Extremely Depressed'
]

export const ch_daily_weekly_mood_manic = [
  'Not at all Manic',
  '.',
  '..',
  '...',
  'Extremely Manic'
]

export const ch_daily_weekly_anxiety = [
  'Not at all Anxious',
  '.',
  '..',
  '...',
  'Extremely Anxious'
]

export const ch_daily_weekly_mood_dep2 = [
  'Not at all',
  '.',
  '..',
  '...',
  'Extremely'
]

export const ch_daily_weekly_mood_dep3 = [
  'Extremely',
  '.',
  '..',
  '...',
  'Not at all'
]

export const ch_daily_weekly_mood_manic2 = [
  'Not at all',
  '.',
  '..',
  '...',
  'Extremely'
]

export const ch_daily_weekly_anxiety2 = [
  'Not at all',
  '.',
  '..',
  '...',
  'Extremely'
]

export const ch_daily_tracker_sleep = [
  '0-2 hrs',
  '3-4 hrs',
  '5-6 hrs',
  '7-8 hrs',
  '9-10 hrs',
  '11-12 hrs',
  '12+ hrs'
]

export const ch_weekly_tracker_sleep = [
  '0-2 hrs',
  '3-4 hrs',
  '5-6 hrs',
  '7-8 hrs',
  '9-10 hrs',
  '11-12 hrs',
  '12+ hrs'
]

export const ch_daily_tracker_medication = [
  'None',
  'Some',
  'Half',
  'Most',
  'All'
]

export const ch_weekly_tracker_medication = [
  'None',
  'Some',
  'Half',
  'Most',
  'All'
]

export const ch_daily_tracker_exercise = [
  '0 min',
  '5-10 mins',
  '15-20 mins',
  '25-30 mins',
  '35-40 mins',
  '45-50 mins',
  '55-60 mins',
  '60+ mins'
]

export const ch_weekly_tracker_exercise = [
  '0-1 hours',
  '1-2 hours',
  '3-4 hours',
  '5-6 hours',
  '7-8 hours',
  '9-10 hours',
  '11-12 hours',
  '13-14 hours',
  '14+ hours'
]
