import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { loadLocationWithMessage } from '../../../../actions/location'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'
import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../../styles/assets/css/admin/form2.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Spinner from 'react-spinner-material'
import EditIcon from '@material-ui/icons/Edit'
//import UpdateIcon from '@material-ui/icons/Update'
import CancelIcon from '@material-ui/icons/Cancel'
import Pagination from '../../../functions/pagination'
import { appt_status, appt_type_show, appt_sub_type, filter_appt_status } from '../../variables/patient'
import { reorderData } from '../../../functions/appointment.js'

class PatientApptList extends Component {
  constructor() {
      super(  );
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
        patient_status: 0,
        dataItems: [], //exampleItems,
        pageOfItems: [],
        provider: 0,
        appt_status: 6,
        list_provider: [],
        list_appt_status: [],
        warning_status: 0,
        update_id: 0,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
    //console.log(this.props.match.params.pid)
  }

  handleChange2 = (e, name) => {
    this.setState({ [name]: e.target.value })
    this.getDataList()
  }

  handleUpdateStatus = (id) => {
    document.getElementById('warning_box').style.display = 'block'
    this.setState({ update_id: id })
  }

  confirmCancel = (e) => {
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/missed_appointment',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        aid: this.state.update_id,
      }
    })
    .then((result) => {
      //console.log(result)
        if(result['data'] === this.props.match.params.pid) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/1/1', 'Appointment status updated successfully!')
          this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
          this.closeWarningBox()
          this.getDataList()
        }
      }
    )
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  getDataList = async () => {
    // Test //
    await axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_patient_appt_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      var get_provider = result['data'][1]['value']
      get_provider.push({uid: 0, fullname: 'All Providers'})
      this.setState({
        patient_status: result['data'][0]['value'].reg_step,
        list_provider: get_provider,
      })
    })
    // Close test //
    var start_date = new Date().toISOString().slice(0, 10)
    var end_date = new Date().toISOString().slice(0, 10)
    if(this.state.patient_status == 'complete_registration') {
      var get_status = 0
      var d = new Date

      if(this.state.appt_status == 2) { // upcoming
        get_status = 2
        //console.log(new Date().toISOString())
        start_date = new Date().toISOString().slice(0, 10)
        end_date = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, 10)
        //end_date.setDate(end_date.getDate())
      } else if(this.state.appt_status == 3) { // future
        get_status = 3
        start_date = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().slice(0, 10)
        //start_date.setDate(start_date.getDate() + 7)
      } else if(this.state.appt_status == 1) { // past
        get_status = 1
        end_date = new Date().toISOString().slice(0, 10)
      } else if(this.state.appt_status == 4) { // cancel
        get_status = 4
      } else if(this.state.appt_status == 5) { // no-show
        get_status = 5
      } else if(this.state.appt_status == 6) { // past & upcoming
        get_status = 6
      }

      var get_provider = 0
      if(this.state.provider > 0) {
        get_provider = this.state.provider
      }
    }

    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_appointment_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        provider: get_provider,
        appt_status: get_status,
        start_date: start_date.toString().substr(0, 10),
        end_date: end_date.toString().substr(0, 10),
      }
    })
    .then((result2) => {
      //console.log(result2['data'])
      if(result2['data'].length > 0) {
        this.setState({ dataItems: result2['data'] })
      } else {
        this.setState({ dataItems: [] })
      }
    })


  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  checkFuture (appt_date, appt_time, status) {
    //console.log(appt_date, appt_time, status)
    var now_day = new Date()
    var appt_d = (new Date(appt_date+' '+appt_time))
    if(status == 3) {
      return false
    }
    if(now_day < appt_d) {
      return true
    }
    return false
  }

  genAppointmentStatus (appt_date, appt_time, status) {
    var gen_str = ''
    var now_day = new Date()
    var in_7_day = new Date()
    in_7_day.setDate(in_7_day.getDate() + 7)
    var appt_d = (new Date(appt_date+' '+appt_time))

    if(status == 3) {
      gen_str = 'Canceled'
    } else if(status == 5) {
      gen_str = 'No-show'
    } else {
      if(now_day > appt_d) {
        gen_str = 'Past'
      } else if (now_day < appt_d) {
        if(in_7_day < appt_d) {
          gen_str = 'Future'
        } else {
          gen_str = 'Upcoming (Next 7 Days)'
        }
      }
    }
    return gen_str
  }

  render() {
    //console.log(this.state)
    return (
    <div className="patient_dashboard admin_appt_list">
      {this.state.patient_status == 0 &&
        <div className="row">
          <div className="small-12 columns loading2"><Spinner color={"#bbbbbb"} radius={50}/></div>
      </div>}
      {(this.state.patient_status == 'complete_registration' && this.state.patient_status != 0) &&
      <div>
        <div className="row">
          <div className="small-12 medium-2 columns title4">
            Appointments
          </div>
          <div className="small-12 medium-10 columns bold button_header2">
            {//this.props.patient_status >=3 &&
            <NavLink to={'/admin/make_appointment/'+this.props.match.params.pid}
            className="menu_list_active new_appt">
              New Appointment
            </NavLink>}
            <div className="w_provider">
            <FormControl variant="outlined">
              <Select value={this.state.provider} variant="outlined" label=""
                onChange={(e) => this.handleChange2(e, 'provider')} >
              {this.state.list_provider.map(each =>
                <MenuItem key={'pvd'+each.uid} value={each.uid}>{each.fullname}</MenuItem>
              )}
              </Select>
            </FormControl>
            </div>
            <div className="w_appt">
            <FormControl variant="outlined">
              <Select value={this.state.appt_status} variant="outlined" label=""
                onChange={(e) => this.handleChange2(e, 'appt_status')} >
              {filter_appt_status.map(each =>
                <MenuItem key={'apt'+each.value} value={each.value}>{each.label}</MenuItem>
              )}
              </Select>
            </FormControl>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
                </div>
              </div>}
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th className="align_left">Date / Time</th>
                    <th className="align_left">EPIC</th>
                    <th className="align_left">Sub-Type</th>
                    <th className="align_left">Provider</th>
                    <th className="align_left">Appt Status</th>
                    <th>Reschedule</th>
                    <th>Contact log</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map((item) =>
                <tr key={'d'+item.id}>
                  <td className="align_left">{item.appt_date+', '+item.appt_time} {item.mini_status}</td>
                  <td className="align_left">{item.epic_name}</td>
                  <td className="align_left">{item.sub_name}</td>
                  <td className="align_left">
                    {(item.cid > 1 && item.aid == undefined) && item.c_name}
                    {item.cid == 1 && item.c_other_name}
                    {item.aid != 1 && item.aid != undefined && item.a_name}
                  </td>
                  <td className="appt_st">
                  {this.genAppointmentStatus(item.appt_date, item.appt_time, item.status)}
                  {item.sub_type != 1 && item.ct_id == null && this.genAppointmentStatus(item.appt_date, item.appt_time, item.status).includes("Past") &&
                  <span><br />(<a onClick={()=>this.handleUpdateStatus(item.id)}>No-show?</a>)</span>}
                  {(item.sub_type == 1 && item.status < 3 && item.mini_step !== "complete") &&
                  <span><br />(<a onClick={()=>this.handleUpdateStatus(item.id)}>No-show?</a>)</span>}
                  </td>
                  <td>{(item.status != 3 && item.status != 5 && item.ct_id == null && item.sub_type != 1) &&
                    <Link to={"/admin/edit_appointment/"+item.id+"/"+item.pid}><EditIcon /></Link>}
                    {(item.sub_type == 1 && item.mini_step !== "complete") &&
                    <Link to={"/admin/edit_appointment/"+item.id+"/"+item.pid}><EditIcon /></Link>}
                  </td>
                  <td>{!this.checkFuture(item.appt_date, item.appt_time, item.status) &&
                  <Link to={"/admin/patient_log_outcome/"+this.props.match.params.pid+"/"+item.id+"/"+item.ct_id}>
                  {item.ct_id > 0 && 'Completed'}
                  {item.status != 3 && item.status != 5 && item.ct_id == null && item.reason != 125 && item.sub_type != 1 && 'Incomplete'}
                  </Link>}
                  {(item.sub_type == 1 || item.reason == 125) &&
                  <span>
                  {item.mini_status != 2 && !this.checkFuture(item.appt_date, item.appt_time, item.status) &&
                  <span><Link to={"/admin/assessments/mini1/"+this.props.match.params.pid+'/'+item.id+"/-1"}>
                  MINI</Link><br /></span>}
		  {item.mini_status == 2 && !this.checkFuture(item.appt_date, item.appt_time, item.status) &&
                  <span><Link to={"/admin/assessments/mini1/"+this.props.match.params.pid+'/'+item.id+"/-1"}>
                  MINI</Link><br /></span>}
                  </span>}
                  </td>
                </tr>
              )}
              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="8">
                    <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>}
      {this.state && this.state.dataItems && this.state.patient_status != 0 &&
        this.state.patient_status != 'complete_registration' &&
      <div className="row">
      { typeof this.props.locationReducer.txt_message !== 'undefined' &&
        this.props.locationReducer.txt_message !== "" &&
        <div className="small-12 columns">
            <div className="small-12 columns warning_msg7">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
            </div>
        </div>}
        <div className="small-12 columns align_center">
          Patient hasn't completed registration.
        </div>
      </div>}
      <div className="warning_box" id="warning_box">
        <div className="row">
          <div className="small-12 columns">
          <WarningIcon /> Are you sure this was a no-show?
          </div>
          <div className="small-12 columns margin_top_space1">
          <Button variant="contained" color="primary" className="margin_right_1" disableElevation
           onClick={this.confirmCancel} type="button">Yes</Button>
         <Button variant="contained" color="primary" disableElevation
          onClick={this.closeWarningBox} type="button">No</Button>
          </div>
        </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('PatientApptList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientApptList)
