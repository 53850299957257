import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { ans_yesno } from '../../../variables/patient.js'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";

class ContactTablet extends Component {
  constructor() {
      super();
      this.state = {
          status: 0,
          submit_status: 0,
          message: '',
          uid: 0,
          pid: 0,
          clinician: 0,
          loan_tablet: 0,
          loan_tablet_by: 0,
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_edit/get_loan_tablet',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] !== 0) {
        this.setState({
          loan_tablet: result['data'].loan_tablet,
          loan_tablet_by: result['data'].updated_tablet_by,
        })
      }
    })
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleOpenWarningBox = (e) => {
    //console.log(this.state.loan_tablet)
    if(this.state.loan_tablet == null) {
      this.setState({
        message: 'Please select the answer',
        status: 2,
      })
      window.scrollTo(0, 0)
    } else {
      document.getElementById('warning_box').style.display = 'block'
    }
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }


  handleSubmitUpdateTablet = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_edit/update_loan_tablet',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        loan_tablet: this.state.loan_tablet,
      }
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] === 1) {
          this.setState({
            message: 'Updated successfully!',
            status: 1,
            submit_status: 1,
          })
          this.closeWarningBox()
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.',
            status: 2,
          })
        }
      }
    )
  }

  render() {
    //console.log(this.state)
    return (
      <div className="admin_make_appointment">
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className={ (this.state.status === 1 && 'warning_msg8') || (this.state.status === 2 && 'warning_msg6') }>
                <div className="float_left1">
                { this.state.status === 1 && <CheckCircleOutlineIcon />}
                { this.state.status === 2 && <WarningIcon />}
                </div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
            <div className="box_border5">
              <div className="row">
                <div className="small-12 columns">
                  <div className="row top_space1">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">
                      Patient  agrees to the tablet loan:
                      </div>
                    </div>
                    <div className="small-12 columns float_left top_space1">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'tb'+each.label}>
                          <input type="radio" name="loan_tablet" value={each.value}
                          checked={this.state.loan_tablet == each.value}
                          onChange={(e) => this.handleChange(e, 'loan_tablet')}/>
                          <span>{each.label}</span>
                        </div>
                      )}
                    </div>
                    {(this.state.loan_tablet_by == null && this.state.submit_status == 0) &&
                    <div className="small-12 columns top_space1">
                      <div className="small-12 columns ">
                        <Button variant="contained" color="primary" disableElevation
                         onClick={(e)=>this.handleOpenWarningBox(e)} type="submit">
                          Submit
                        </Button>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="warning_box" id="warning_box">
          <div className="row">
            <div className="small-12 columns">
            <WarningIcon /> Are you sure you want to update this information?
            </div>
            <div className="small-12 columns margin_top_space1">
            <Button variant="contained" color="primary" className="margin_right_1" disableElevation
             onClick={(e) => this.handleSubmitUpdateTablet(e)} type="button">Yes</Button>
           <Button variant="contained" color="primary" disableElevation
            onClick={(e) => this.closeWarningBox(e)} type="button">No</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContactTablet)
