import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SignatureCanvas from 'react-signature-canvas'

class PatientWaiver extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          uid: 0,
          patient_date: new Date().toISOString().substring(0, 10),
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'patient_waiver') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    // signature //
    var signature = this.refs.mySignature //.getCanvas()
    if(signature.isEmpty()) {
      this.setState({
        message: 'Please sign your signature',
      })
      window.scrollTo(0, 0)
    } else if(this.state.patient_date === '') {
      this.setState({
        message: 'Please fill the date',
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })

      var base64img = signature.toDataURL("image/png")
      const formData = new FormData()
      formData.append('file', base64img)
      formData.append('uid', this.props.authReducer.uid)
      formData.append('patient_date', this.state.patient_date)
      formData.append('udata', JSON.stringify(this.state))

      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_patient_waiver',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify(this.state),
        data: formData
      })
      .then((result) => {
          if(result['data'] === 'communication_consent') {
            this.props.onLoadLocation('/member/communication_consent')
            this.props.history.push('/member/communication_consent')
          }

        }
      )
    }
  }

  render() {

    return (
      <div className="patient_waiver box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Waiver
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={2}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
            <div className="">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <div className="white_bg1">
                      <p>You may be responsible for partial or full payment of your visit. We do our best to keep abreast of insurer and managed care requirements, but we may not always have the most up-to-date information and/or know your policy's complete mental health benefits. We advise you to contact your insurer so that you fully understand their authorization and referral requirements.</p>
                      <p>If your insurance information is not up-to-date or has changed, make sure you call Mass General Registration to update it (866-211-6588).</p>
                      <p>Your signature below indicates that you assume financial responsibility for services received.</p>
                      <p>Thank you!</p>
                    </div>
                  </div>
                </div>
                <div className="row signature">
                  <div className="small-12 medium-6 columns show-for-medium">&nbsp;</div>
                  <div className="small-12 medium-6 columns">
                    <div className="row title_signature">
                      <div className="small-6 columns">
                        Patient Signature
                      </div>
                      <div className="small-6 columns align-right">
                        <button type="button" className="clear_button"
                           onClick={ () => this.refs.mySignature.clear() }>
                            Clear
                        </button>
                      </div>
                    </div>
                    <div className="relative_float_left">
                        <SignatureCanvas penColor='#555555' canvasProps={{className: 'signatureCanvas'}}
                         ref="mySignature" />
                    </div>
                    <div className="relative_float_left">
                    <span className="bold">Date:</span> {this.state.patient_date}
                    {/*<TextField id="patient_date" label="Date" onChange={this.handleChange2} variant="outlined" type="date" defaultValue={this.state.patient_date}
                    InputProps={{ inputProps: { name: "patient_date", min: new Date().toISOString().substring(0, 10) }}} />*/}
                    </div>
                  </div>
                </div>



            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
          {this.state.status_submit &&
            <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        {!this.state.status_submit &&
          <Button variant="contained" disabled
           onClick={this.handleSubmit} type="submit" className="submit_1">
            Submit
        </Button>}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PatientWaiver)
