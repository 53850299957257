import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { mrtf_type_change, mrtf_report, mrtf_filter_med_type } from '../../variables/medication'
import { calculateTimepoint1 } from '../../../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../../../functions/pagination'
import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'

class MrtfList extends Component {
  constructor() {
      super();
      this.state = {
          status: 0,
          message: '',
          dataItems: [], //exampleItems,
          pageOfItems: [],
          no_change_list: [],
          medication_filter_type: 2,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList(1)
  }

  getDataList = (st) => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_mrtf_medication_filter_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        med_type: this.state.medication_filter_type,
      }
    })
    .then((result) => {
      if(result['data'].length > 0) {
        if(st == 1) {
          this.setState({ dataItems: result['data'], })
        } else if(st == 2) {
          this.setState({ dataItems: result['data'], message: 'Update successfully!' })
        }
      } else {
        this.setState({ dataItems: [] })
      }
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList(1)
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  handleChangeCB = (e, id) => {
    if (e.target.checked == true) {
      var newInput = this.state.no_change_list[this.state.no_change_list.length-1]
      this.setState({
        no_change_list: [...this.state.no_change_list, id]
      })
    } else {
      var get_list = this.state.no_change_list
      var index = get_list.indexOf(id);
      if (index > -1) {
        get_list.splice(index, 1);
        this.setState({
            no_change_list: get_list
        })
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.no_change_list.length > 0) {
      var box = window.confirm("Are you sure these medications have no changing?")
      if (box == true) {
        var calTimepoint = calculateTimepoint1(this.state.dataItems[0].timeline_start)
        axios({
          method: 'post',
          url: connectNode + 'admin/patient/add_nochange_mrtf',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            cid: this.props.authReducer.uid,
            pid: this.props.match.params.pid,
            med_list: this.state.no_change_list,
            timepoint: calTimepoint.current_month,
          }
        })
        .then((result) => {
          var pid = this.props.match.params.pid
          if(result['data'] == pid) {
            this.getDataList(2)
            this.props.onLoadLocation('/admin/patient_chart/'+pid+'/2/2', 'Update successfully!')
            this.props.history.push('/admin/patient_chart/'+pid+'/2/2')
          }
        })
      }
    }
  }

  render() {
    return (
    <div className="admin_mrtf">
      <div className="row">
        <div className="small-12 medium-6 columns title4">
          MRTF
        </div>
        <div className="small-12 medium-6 columns bold button_header2">
        <FormControl variant="outlined" className="filter_med_status">
          <Select value={this.state.medication_filter_type} variant="outlined" label=""
            onChange={(e) => this.handleChange2(e, 'medication_filter_type')}
            value={this.state.medication_filter_type} >
          {mrtf_filter_med_type.map(each =>
            <MenuItem key={'apt'+each.value} value={each.value}>{each.label}</MenuItem>
          )}
          </Select>
        </FormControl>
        {this.props.authReducer.role == 3 && this.props.authReducer.sub_role == 32 &&
          <Link to={'/admin/patient_medication/add_mrtf/'+this.props.match.params.pid}
          className="menu_list_active new_med_button">
            New Medication</Link>}
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns ">
          { this.props.locationReducer.txt_message &&
          <div className="row warning_msg8">
            <div className="small-12 columns ">
              <div className="float_left1"><CheckCircleOutlineIcon /></div>
              <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
            </div>
          </div>}
          <div>
          <div className="row">
            <div className="small-12 columns">
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>Medication</th>
                    <th>Current<br />dose(mg)</th>
                    <th>Currently<br />taking</th>
                    <th>Patient updated</th>
                    <th>Clinician<br />updated</th>
                    {this.props.authReducer.role == 3 && this.props.authReducer.sub_role == 32 &&
                    <th className="no_change_header">
                      <button className="no_change_butt" onClick={this.handleSubmit}
                      type="submit">No change</button>
                    </th>}
                  </tr>
                </thead>
                <tbody>
                {!this.state.dataItems &&
                  <tr>
                    <td className="loading2"><Spinner color={"#bbbbbb"} radius={50}/></td>
                  </tr>}
                {this.state && this.state.dataItems.map((item, index) =>
                  item.med_name != '' &&
                  <tr key={'d'+index}>
                    <td>
                    <Link to={'/admin/patient_medication/mrtf/'+this.props.match.params.pid+'/'+item.id}>
                    { item.med_name }</Link></td>
                    <td>{item.y_dose}</td>
                    <td>
                    {item.mrtf_change != null && item.mrtf_change == 1 && item.mrtf_type_change == 1 && 'No'}
                    {item.mrtf_change != null && item.mrtf_change == 1 && item.mrtf_type_change != 1 && 'Yes'}
                    {item.mrtf_change != null && item.mrtf_change != 1 && 'Yes'}
                    {item.mrtf_change == null && item.currently_taking == 1 && 'Yes'}
                    {item.mrtf_change == null && item.currently_taking != 1 && 'No'}</td>
                    <td>{item.mrtf_datetime4}</td>
                    <td>
                    {/*item.mrtf_datetime3 != null && item.mrtf_datetime3+'-'}
                    {item.mrtf_datetime2 != null && item.mrtf_datetime2+'--'*/}
                    {item.mrtf_datetime3 || item.mrtf_datetime2}
                    </td>
                    {this.props.authReducer.role == 3 && this.props.authReducer.sub_role == 32 &&
                    <td>{item.currently_taking == 1 &&
                      <input type="checkbox" name="med_" value={1}
                        checked={this.state.no_change_list.find((ch) => ch === item.id)}
                        onChange={(e) => this.handleChangeCB(e, item.id)}/>}
                    </td>}
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>)}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MrtfList)
