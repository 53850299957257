import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import thunk from 'redux-thunk'
//import createLogger from 'redux-logger'
import rootReducer from '../reducers/index'

export default () => {
  //console.log('Config store')
  // วิธีการสร้าง store คือการเรียก createStore
  // โดยผ่าน reducer ตัวบนสุดหรือตัวที่เรารวม reducer ทุกตัวเข้าด้วยกัน
  // เราจะได้ store กลับออกมาเป็นผลลัพธ์
  const middlewares = [thunk, apiMiddleware]

  if (process.env.NODE_ENV !== 'production') {
    // แต่ถ้าไม่ใช่ production เจนคงสัมผัสได้...
    //middlewares.push(createLogger())
  }

  const store = createStore(
    rootReducer,
    // จะใช้ middleware อะไรก็ยัดเยียดเข้าไปใน applyMiddleware ซะเลย
    applyMiddleware(...middlewares)
  )

  if (module.hot) {
      // เมื่อใดที่โค๊ดใน reducer เปลี่ยนแปลงเราแค่ HMR มัน
      // จำได้ไหมครับในตอนต้นที่ผมบอกว่าเราแยก state ไปไว้ใน store
      // แล้วแยกวิะีการเปลี่ยน state ไปไว้ใน reducer
      // เพราะต้องการให้ทุกครั้งที่แก้โค๊ด reducer แล้ว webpack จะ HMR เฉพาะ reducer
      // โดย state ปัจจุบันยังคงอยู่
      module.hot.accept('../reducers', () => {
      import('../reducers').then((nextRootReducer) =>
        store.replaceReducer(nextRootReducer.default)
      )
    })
  }

  return store
}
