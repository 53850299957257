import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no } from '../../variables/treatment_plan.js'

class ToDoListEdit extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_status: false,
          treatment_target: 0,
          modality: 0,
          modality_other: '',
          intervention: '',
          intervention_format: 0,
          intervention_date: '',
          intervention_format_other: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_intervention_modality',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        tg_id: this.props.match.params.tg_id,
        mid: this.props.match.params.mid
     }
    })
    .then((result) => {
      this.setState({
        treatment_target: result['data'].target,
        modality: result['data'].modality_id,
        modality_other: result['data'].modality_other,
        intervention: result['data'].intervention,
        intervention_format: result['data'].intervention_format,
        intervention_date: result['data'].modality_date,
        intervention_format_other: result['data'].intervention_format_other,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      //uid: this.props.uid,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  transformDate = (getDate) => {
    var buff = getDate.split("/")
    var toDate = getDate
    if(buff.length>1) {
      toDate = buff[2]+'-'+buff[0]+'-'+buff[1]
    }
    return toDate
  }

  handleSubmit = (e) => {
    //console.log(' --- handleSubmit ---')
    //console.log(this.state)
    e.preventDefault()
    var chk = true
    if(this.state.intervention === '' || this.state.modality == 0 ||
      (this.state.modality == 21 && this.state.modality_other === '')
      || this.state.intervention_format == 0 || this.state.intervention_date === '' ||
      (this.state.intervention_format == 10 && this.state.intervention_format_other === '')) {
        //console.log('-- case 4 --')
        chk = false
    }

    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/edit_intervention',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          //tg_id: this.props.match.params.tg_id,
          mid: this.props.match.params.mid,
          user: this.state,
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.mid) {
          this.setState({
            message: 'To Do List item updated successfully!',
            message_status: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: 'Please fill all information',
        message_status: false,
      })
      window.scrollTo(0, 0)
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            To Do List [Edit]
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                  { this.state.message !== '' && !this.state.message_status &&
                  <div className="row warning_msg6">
                    <div className="small-12 columns ">
                      <div className="float_left1"><WarningIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                    </div>
                  </div>}
                  { this.state.message !== '' && this.state.message_status &&
                    <div className="row warning_msg7">
                      <div className="small-12 columns ">
                        <div className="float_left1"><CheckCircleOutlineIcon /></div>
                        <div className="txt_warning_msg1">{ this.state.message }</div>
                      </div>
                    </div>}
                    <div className="row no_margin_bottom" >
                      <div className="small-12 columns ">
                      <div className="box1_1">
                        <div className="small-12 columns bold space_top_1">To Do List item</div>
                        <div className="small-12 columns">
                        <TextField variant="outlined" type="text" className="space_top_1"
                          onChange={(e) => this.handleChange2(e, 'intervention')}
                          value={this.state.intervention}
                          InputProps={{ inputProps: { maxLength: 240 }}} />
                        </div>

                        <div className="small-12 columns bold space_top_1">Treatment Modality</div>
                        <div className="small-12 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.modality}
                              onChange={(e) => this.handleChange2(e, 'modality')}
                              variant="outlined" label="" className="width_auto">
                              {treatment_modality.map( each =>
                                <MenuItem key={'tm'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        {this.state.modality == 21 &&
                          <div className="small-12 columns">
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange2(e, 'modality_other')}
                            value={this.state.modality_other}
                            InputProps={{ inputProps: { maxLength: 95, placeholder: 'Please specify' }}} />
                          </div>}

                        <div className="small-12 columns bold space_top_1">To Do List item Format</div>
                        <div className="small-12 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.intervention_format}
                              onChange={(e) => this.handleChange2(e, 'intervention_format')}
                              variant="outlined" label="" className="width_auto">
                              {intervention_format.map( each =>
                                <MenuItem key={'if'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        {this.state.intervention_format == 10 &&
                          <div className="small-12 columns">
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange2(e, 'intervention_format_other')}
                            value={this.state.intervention_format_other}
                            InputProps={{ inputProps: { maxLength: 75, placeholder: 'Please specify' }}} />
                          </div>}
                        <div className="small-12 columns bold space_top_1">Date To Do List item assigned</div>
                        <div className="small-12 columns float_left width_auto">
                          <TextField variant="outlined" type="date" format={'MM/DD/YYYY'}
                          onChange={(e) => this.handleChange2(e, 'intervention_date')}
                          value={this.transformDate(this.state.intervention_date)} />
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="medium-6 columns">
                    <a href={'/admin/treatment_plan/treatment_plan/'+this.props.match.params.pid+'/'+this.props.match.params.tid}
                    className="top_space1 go_back">Back</a>
                  </div>
                  <div className="medium-6 columns text_align_right">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ToDoListEdit)
