export const mini_steps = [
  'A-B',
  'C',
  'D-I',
  'J',
  'K-M',
  'N-Q',
  'Result'
]

export const mini_steps_v2 = [
  {
    label: 'A-B',
    value: 1,
  }, {
    label: 'C',
    value: 2,
  }, {
    label: 'D-I',
    value: 3,
  }, {
    label: 'J',
    value: 4,
  }, {
    label: 'K-M',
    value: 5,
  }, {
    label: 'N-O',
    value: 6,
  }, {
    label: 'Substance',
    value: 7,
  }, {
    label: 'Borderline',
    value: 8,
  }, {
    label: 'Result',
    value: 9,
  },
]

export const mini_yesno = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const mini_eligible = [
  {
    label: 'Eligible',
    value: 1,
  }, {
    label: 'Ineligible',
    value: 2,
  },
]

export const mini_not_completed_reason = [
  {
    label: 'Missed appointment - Reason unknown',
    value: 1,
  }, {
    label: 'Missed appintment - Patient did not show up to appointment',
    value: 2,
  }, {
    label: 'Patient left appointment early',
    value: 3,
  }, {
    label: 'Patient was late for appointment',
    value: 4,
  }
]


export const mini_q_a = [
  {
    label: 'Major Depressive Episode (current)',
    value: 1,
  }, {
    label: 'Major Depressive Episode (recurrent)',
    value: 2,
  }, {
    label: 'Major Depressive Episode (past)',
    value: 3,
  }, {
    label: 'Major Depressive Disorder (current)',
    value: 4,
  }, {
    label: 'Major Depressive Disorder (recurrent)',
    value: 5,
  }, {
    label: 'Major Depressive Disorder (past)',
    value: 6,
  },
]

export const mini_q_b = [
  {
    label: 'Suicidality (current)',
    value: 1,
  }, {
    label: 'Suicidality risk level',
    value: 2,
  },
]

export const mini_q_c = [
  {
    label: 'Manic Episode (current)',
    value: 1,
  }, {
    label: 'Manic Episode (past)',
    value: 2,
  }, {
    label: 'Hypomanic Episode (current)',
    value: 3,
  }, {
    label: 'Hypomanic Episode (past)',
    value: 4,
  }, {
    label: 'Bipolar Disorder I (current)',
    value: 5,
  }, {
    label: 'Bipolar Disorder I (past)',
    value: 6,
  }, {
    label: 'Bipolar Disorder II (current)',
    value: 7,
  }, {
    label: 'Bipolar Disorder II (past)',
    value: 8,
  }, {
    label: 'Bipolar Disorder NOS (current)',
    value: 9,
  }, {
    label: 'Bipolar Disorder NOS (past)',
    value: 10,
  },
]

export const mini_q_d = [
  {
    label: 'Panic Disorder (current)',
    value: 1,
  }, {
    label: 'Panic Disorder (lifetime)',
    value: 2,
  },
]

export const mini_q_e = [
  {
    label: 'Agoraphobia (current)',
    value: 1,
  },
]

export const mini_q_f = [
  {
    label: 'Social Phobia/Social Anxiety Disorder (current)',
    value: 1,
  }, {
    label: 'Social Phobia/Social Anxiety Disorder (generalized)',
    value: 2,
  }, {
    label: 'Social Phobia/Social Anxiety Disorder (non-generalized)',
    value: 3,
  },
]

export const mini_q_f_v2 = [
  {
    label: 'Social Anxiety Disorder (current)',
    value: 1,
  },
]

export const mini_q_g = [
  {
    label: 'Obsessive Compulsive Disorder (current) ',
    value: 1,
  },
]

export const mini_q_h = [
  {
    label: 'Posttraumatic Stress Disorder (current)',
    value: 1,
  },
]

export const mini_q_i = [
  {
    label: 'Alcohol dependence (past 12 months)',
    value: 1,
  }, {
    label: 'Alcohol abuse (past 12 months)',
    value: 2,
  }, {
    label: 'Alcohol dependence (lifetime)',
    value: 3,
  },
  {
    label: 'Alcohol abuse (lifetime)',
    value: 4,
  },
]

export const mini_q_i_v2 = [
  {
    label: 'Alcohol use disorder (past 12 months)',
    value: 1,
  },
]

export const mini_q_j1 = [
  {
    label: 'Substance dependence stimulants (past 12 months)',
    value: 1,
  }, {
    label: 'Substance abuse stimulants (past 12 months)',
    value: 2,
  }, /*{
    label: 'Substance dependence cocaine (past 12 months)',
    value: 3,
  }, {
    label: 'Substance abuse cocaine (past 12 months)',
    value: 4,
  }, {
    label: 'Substance dependence narcotics (past 12 months)',
    value: 5,
  }, {
    label: 'Substance abuse narcotics (past 12 months)',
    value: 6,
  }, {
    label: 'Substance dependence hallucinogens (past 12 months)',
    value: 7,
  }, {
    label: 'Substance abuse hallucinogens (past 12 months)',
    value: 8,
  }, {
    label: 'Substance dependence phencyclidine (past 12 months)',
    value: 9,
  }, {
    label: 'Substance abuse phencyclidine (past 12 months)',
    value: 10,
  }, {
    label: 'Substance dependence inhalants (past 12 months)',
    value: 11,
  }, {
    label: 'Substance abuse inhalants (past 12 months)',
    value: 12,
  },*/
]

export const mini_q_j1_v2 = [
  {
    label: 'Substance use disorder (past 12 months)',
    value: 1,
  },
]

export const mini_q_j_meet_criteria = [
  {
    label: 'Stimulants',
    value: 1,
  }, {
    label: 'Cocaine',
    value: 2,
  }, {
    label: 'Narcotics',
    value: 3,
  },
  {
    label: 'Other',
    value: 4,
  },
]

export const mini_q_j_meet_criteria_v2 = [
  {
    label: 'Opioid use disorder',
    value: 1,
  }, {
    label: 'Stimulant use disorder',
    value: 2,
  }, {
    label: 'Sedative, hypnotic, or anxiolytic use disorder',
    value: 3,
  }, {
    label: 'Hallucinogen use disorder',
    value: 4,
  }, {
    label: 'Cannabis use disorder',
    value: 5,
  }, {
    label: 'Inhalant use disorder',
    value: 6,
  },
]

export const mini_q_j2 = [
  {
    label: 'Substance dependence marijuana (past 12 months)',
    value: 13,
  }, {
    label: 'Substance abuse marijuana (past 12 months)',
    value: 14,
  }, {
    label: 'Substance dependence tranquilizers (past 12 months)',
    value: 15,
  }, {
    label: 'Substance abuse tranquilizers (past 12 months)',
    value: 16,
  }, {
    label: 'Substance dependence miscellaneous drugs (past 12 months)',
    value: 17,
  }, {
    label: 'Substance abuse miscellaneous drugs (past 12 months)',
    value: 18,
  }, {
    label: 'Substance dependence stimulants (lifetime)',
    value: 19,
  }, {
    label: 'Substance abuse stimulants (lifetime)',
    value: 20,
  }, {
    label: 'Substance dependence cocaine (lifetime)',
    value: 21,
  }, {
    label: 'Substance abuse cocaine (lifetime)',
    value: 22,
  }, {
    label: 'Substance dependence narcotics (lifetime)',
    value: 23,
  }, {
    label: 'Substance abuse narcotics (lifetime)',
    value: 24,
  },
]

export const mini_q_j3 = [
  {
    label: 'Substance dependence hallucinogens (lifetime)',
    value: 25,
  }, {
    label: 'Substance abuse hallucinogens (lifetime)',
    value: 26,
  }, {
    label: 'Substance dependence phencyclidine (lifetime)',
    value: 27,
  }, {
    label: 'Substance abuse phencyclidine (lifetime)',
    value: 28,
  }, {
    label: 'Substance dependence inhalants (lifetime)',
    value: 29,
  }, {
    label: 'Substance abuse inhalants (lifetime)',
    value: 30,
  }, {
    label: 'Substance dependence marijuana (lifetime)',
    value: 31,
  }, {
    label: 'Substance abuse marijuana (lifetime)',
    value: 32,
  }, {
    label: 'Substance dependence tranquilizers (lifetime)',
    value: 33,
  }, {
    label: 'Substance abuse tranquilizers (lifetime)',
    value: 34,
  }, {
    label: 'Substance dependence miscellaneous drugs (lifetime)',
    value: 35,
  }, {
    label: 'Substance abuse miscellaneous drugs (lifetime)',
    value: 36,
  },
]

export const mini_q_k = [
  {
    label: 'Psychotic disorder (lifetime)',
    value: 1,
  }, {
    label: 'Psychotic disorder (current)',
    value: 2,
  }, {
    label: 'Mood disorder with psychotic features (lifetime)',
    value: 3,
  }, {
    label: 'Mood disorder with psychotic features (current)',
    value: 4,
  },
]

export const mini_q_l = [
  {
    label: 'Anorexia nervosa (current)',
    value: 1,
  },
]

export const mini_q_m = [
  {
    label: 'Bulimia nervosa (current)',
    value: 1,
  }, {
    label: 'Anorexia nervosa, Binge eating/purging type (current)',
    value: 2,
  }, {
    label: 'Binge-eating disorder (current)',
    value: 3,
  },
]

export const mini_q_n = [
  {
    label: 'Generalized Anxiety Disorder (current)',
    value: 1,
  },
]

export const mini_q_o = [
  {
    label: 'Medical, organic, drug cause ruled out',
    value: 1,
  },
]

export const mini_q_q = [
  {
    label: 'Attention deficit/hyperactivity disorder (combined)',
    value: 1,
  }, {
    label: 'Attention deficit/hyperactivity disorder (inattentive)',
    value: 2,
  }, {
    label: 'Attention deficit/hyperactivity disorder Meets Criteria (hyperactive/impulsive)',
    value: 3,
  },
]

export const mini_borderline = [
  {
    label: 'Have any of your closest relationships been troubled by a lot of arguments or repeated breakups?',
    value: 1,
  }, {
    label: 'Have you deliberately hurt yourself physically (e.g., punched yourself, cut yourself, burned yourself)? How about made a suicide attempt?',
    value: 2,
  }, {
    label: 'Have you had at least two other problems with impulsivity (e.g., eating binges and spending sprees, drinking too much and verbal outbursts)?',
    value: 3,
  }, {
    label: 'Have you been extremely moody?',
    value: 4,
  }, {
    label: 'Have you felt very angry a lot of the time? How about often acted in an angry or sarcastic manner?',
    value: 5,
  }, {
    label: 'Have you often been distrustful of other people?',
    value: 6,
  }, {
    label: 'Have you frequently felt unreal or as if things around you were unreal?',
    value: 7,
  }, {
    label: 'Have you chronically felt empty?',
    value: 8,
  }, {
    label: 'Have you often felt that you had no idea of who you are or that you have no identity?',
    value: 9,
  }, {
    label: 'Have you made desperate attempts to avoid feeling abandoned or being abandoned (e.g., repeatedly called someone to reassure yourself that he or she still cared, begged them not to leave you, clung to them physically)?',
    value: 10,
  },
]
export const audit_c_q = [
  {
    label: 'How often do you have a drink containing alcohol?',
    value: 1,
  }, {
    label: 'How many standard drinks containing alcohol do you have on a typical day?',
    value: 2,
  }, {
    label: 'How often do you have 6 drinks or more on one occasion?',
    value: 3,
  },
]

export const mini_scale1 = [
  {
    label: 'N/A',
    value: 0,
  }, {
    label: 'Meets Criteria',
    value: 1,
  }, {
    label: 'Does Not Meet Criteria',
    value: 2,
  }, {
    label: 'Not Explored, N/A, Cancelled, Unsure, or Missing',
    value: 3,
  },
]

export const mini_scale2 = [
  {
    label: 'N/A',
    value: 0,
  }, {
    label: 'None',
    value: 1,
  }, {
    label: 'Low',
    value: 2,
  }, {
    label: 'Moderate',
    value: 3,
  }, {
    label: 'High',
    value: 4,
  },
]

export const tobacco_scale = [
  {
    label: 'N/A',
    value: -1,
  }, {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Patient refused tobacco screen',
    value: 3,
  }, {
    label: 'Unable to assess due to cognitive impairment',
    value: 4,
  }, {
    label: 'Unable to assess due to temporary condition',
    value: 5,
  },
]

export const audit_c1_scale = [
  {
    label: 'N/A',
    value: -1,
  }, {
    label: 'Never',
    value: 0,
  }, {
    label: 'Monthly or less',
    value: 1,
  }, {
    label: '2-4 times a month',
    value: 2,
  }, {
    label: '2-3 times a week',
    value: 3,
  }, {
    label: '4 or more times a week',
    value: 4,
  },
]

export const audit_c2_scale = [
  {
    label: 'N/A',
    value: -1,
  }, {
    label: '0',
    value: 5,
  }, {
    label: '1 or 2',
    value: 0,
  }, {
    label: '3 or 4',
    value: 1,
  }, {
    label: '5 or 6',
    value: 2,
  }, {
    label: '7 to 9',
    value: 3,
  }, {
    label: '10 or more',
    value: 4,
  },
]

export const audit_c3_scale = [
  {
    label: 'N/A',
    value: -1,
  }, {
    label: 'Never',
    value: 0,
  }, {
    label: 'Less than monthly',
    value: 1,
  }, {
    label: 'Monthly',
    value: 2,
  }, {
    label: 'Weekly',
    value: 3,
  }, {
    label: 'Daily or almost daily',
    value: 4,
  },
]
