import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import Spinner from 'react-spinner-material'
import '../../../../styles/assets/css/admin/list2.css'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { dep_exp_past, pt_lastyear_spent_dep, yes_no_unknown,
         yes_no, kinds_child_abuse } from '../../../patients/variables/psychiatric_history.js'

class PsychiatricHistory extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: {},
          loading: false,
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_psychiatric_history',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: this.props.match.params.pid }
    })
    .then((result) => {
        this.setState({
          dataItems: result['data'],
          loading: true,
        })
      }
    )
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="general_information">
      {!this.state.loading &&
        <div className="row">
          <div className="small-12 columns loading2">
            <Spinner color={"#bbbbbb"} radius={50}/>
          </div>
        </div>}
      {this.state.loading &&
        <div className="row">
          <div className="small-12 columns">
            <div className="w50m_w100s title1">Psychiatric History</div>
            <div className="w50m_w100s title1 right_date">Submitted: {this.state.dataItems.submitted_time}</div>
          </div>
          <div className="small-12 columns">
            <div className="bg1">
              <div className="row">
                <div className="small-12 columns">
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Approximate age of first depressive symptoms that interfered with functioning:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.age_first_depressive}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Approximate age of first manic symptoms that interfered with functioning:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.age_first_manic}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Total number of previous depressive episodes in lifetime:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.total_dep_life}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Number of depressive episodes experienced within the past year:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(dep_exp_past, this.state.dataItems.num_dep_last_year)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Percentage of last year spent depressed:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(pt_lastyear_spent_dep, this.state.dataItems.percent_last_year_spent_dep)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Total number of previous manic or hypomanic episodes over lifetime:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.total_man_life}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Number of previous manic or hypomanic episodes in the past year:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(dep_exp_past, this.state.dataItems.num_man_last_year)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Percentage of last year spent manic or hypomanic:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(pt_lastyear_spent_dep, this.state.dataItems.percent_last_year_spent_man)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Previously hospitalized for psychiatric reasons:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.prev_hosp_psy_reason)}
                    </div>
                  </div>
                  { this.state.prev_hosp_psyc == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Number of hospitalizations:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.y_num_hosp}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Have you ever hurt yourself, not to end your life, but for another reason, like to make yourself feel better or get something to happen?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no, this.state.dataItems.hurt_yourself)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Previous suicide attempts:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.suicide_attp)}
                    </div>
                  </div>
                  { this.state.suicide_attp == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> How many times have you attempted suicide?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.suicide_attp_num}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Childhood abuse:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.childhood_abuse)}
                    </div>
                  </div>
                  { this.state.dataItems.childhood_abuse == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 columns bold">
                      <ArrowRightIcon /> Kinds of childhood abuse (check all that apply):
                    </div>
                    <div className="small-12 columns">
                      <div className="row">
                        <div className="medium-1 columns show-for-medium">&nbsp;</div>
                        { this.state.dataItems.childhood_abuse_emotional == 1 &&
                        <div className="small-12 medium-2 columns float_left1">
                            {kinds_child_abuse[0].label}
                        </div>}
                        { this.state.dataItems.childhood_abuse_physical == 1 &&
                        <div className="small-12 medium-2 columns float_left1">
                            {kinds_child_abuse[1].label}
                        </div>}
                        { this.state.dataItems.childhood_abuse_sexual == 1 &&
                        <div className="small-12 medium-2 columns float_left1">
                            {kinds_child_abuse[2].label}
                        </div>}
                        { this.state.dataItems.childhood_abuse_other == 1 &&
                        <div className="small-12 medium-2 columns float_left1">
                            {kinds_child_abuse[3].label}
                        </div>}
                        <div className="medium-3 columns show-for-medium">&nbsp;</div>
                      </div>
                    </div>
                  </div>}
                  { this.state.dataItems.childhood_abuse == 1 && this.state.dataItems.childhood_abuse_other == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Type of abuse "other" (specify):
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.childhood_abuse_other_txt}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      When you are not feeling depressed, manic, or high, do you hear voices?
                      Do you think people can read your mind, or put thoughts or messages in your head?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.read_mind)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Have you ever had difficulties with violent, disruptive, or aggressive behavior?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.difficult_w_bahavior)}
                    </div>
                  </div>

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Family history of suicidal behavior:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.fam_suicide)}
                    </div>
                  </div>
                  { this.state.dataItems.fam_suicide == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Which family member/s?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.fam_suicide_which}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Family history of violent behavior:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.fam_violent)}
                    </div>
                  </div>
                  { this.state.dataItems.fam_violent == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Which family member/s?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.fam_violent_which}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Family history of psychiatric illness/ hospitalization:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.fam_psy)}
                    </div>
                  </div>
                  { this.state.dataItems.fam_psy == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Which family member/s?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.fam_psy_which}
                    </div>
                  </div>}

                  <div className="sep_line1">&nbsp;</div>
                  <div className="row">
                    <div className="small-12 medium-8 columns bold">
                      Family history of substance abuse:
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.findLabelByValue(yes_no_unknown, this.state.dataItems.fam_abuse)}
                    </div>
                  </div>
                  { this.state.dataItems.fam_abuse == 1 &&
                  <div className="row top_space1">
                    <div className="small-12 medium-8 columns bold">
                      <ArrowRightIcon /> Which family member/s?
                    </div>
                    <div className="small-12 medium-4 columns">
                      {this.state.dataItems.fam_abuse_which}
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>

        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PsychiatricHistory)
