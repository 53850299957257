export var getNextPage = (course_id, curr_last_page, next_last_page,
                          session, next_sub_session, next_sub_session_page) => {
  var gen_link = "/member/intervention/"+course_id
  if(curr_last_page == 1) {
    gen_link += "/main"
  } else {
    gen_link += "/session/"+session+"/"+next_sub_session+"/"+next_sub_session_page
  }
  return gen_link
}
