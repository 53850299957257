import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../styles/assets/css/pages/interventions.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import Star from '@material-ui/icons/Star'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
//import ClassIcon from '@material-ui/icons/Class'
import HomeIcon from '@material-ui/icons/Home'

class CourseNavigation extends Component {
  constructor() {
      super(  );
      this.state = {
          resume: "",
          stars: 0,
          get_uri: "",
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var get_uri = window.location.pathname
    var sep_uri = get_uri.split("/")
    get_uri = sep_uri[4]

    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_navigation',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        cid: this.props.match.params.cid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      var gen_resume = ""
      if(result['data'].length > 0 && result['data'][0]) {
        gen_resume = result['data'][0].course_id + "/session/" + result['data'][0].session + "/" + result['data'][0].sub_session + "/" + result['data'][0].sub_session_page
        this.setState({
          resume: gen_resume,
          stars: result['data'][1],
          get_uri: get_uri,
        })
      }
    })
  }

  render() {
    //console.log(this.state)
    const cid = this.props.match.params.cid
    return (
      <div>
        <div className="main_page_icon">
          <Star className="library_icon" /><br />
          <div className="text_menu">Stars: {this.state.stars}</div>
        </div>
        <div className="main_page_icon">
          <a href={"/member/intervention/"+cid+"/personas"} >
          <AccountCircleIcon className="library_icon" /><br />
          <div className="text_menu">Personas</div>
          </a>
        </div>
        <div className="main_page_icon">
          <a href={"/member/intervention/"+cid+"/library"} >
          <LocalLibraryIcon className="library_icon" /><br />
          <div className="text_menu">Library</div>
          </a>
        </div>
        {this.state.get_uri == "main" &&
        <div className="main_page_icon">
          <a href={"../"+this.state.resume}>
          <PlayCircleOutlineIcon className="library_icon" /><br />
          <div className="text_menu">Resume</div>
          </a>
        </div>}
        {this.state.get_uri != "main" &&
        <div className="main_page_icon">
          <a href={"/member/intervention/"+cid+"/main"}>
          <HomeIcon className="library_icon" /><br />
          <div className="text_menu">Home</div>
          </a>
        </div>}
      </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseNavigation)
