export const smoke_often = [
  {
    label: 'More than once a day',
    value: 1,
  }, {
    label: 'Once a day',
    value: 2,
  }, {
    label: 'More than once a week',
    value: 3,
  }, {
    label: 'Once a week',
    value: 4,
  }, {
    label: 'More than once a month',
    value: 5,
  }, {
    label: 'Once a month',
    value: 6,
  }, {
    label: 'Less than once a month',
    value: 7,
  }
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }
]

export const days_per_week = [
  {
    label: '1',
    value: 1,
  }, {
    label: '2',
    value: 2,
  }, {
    label: '3',
    value: 3,
  }, {
    label: '4',
    value: 4,
  }, {
    label: '5',
    value: 5,
  }, {
    label: '6',
    value: 6,
  }, {
    label: '7',
    value: 7,
  },
]

export const reason_stopping = [
  {
    label: 'Improved',
    value: 1,
  }, {
    label: 'Poor response',
    value: 2,
  }, {
    label: 'Side effects',
    value: 3,
  }, {
    label: 'Severe intolerance',
    value: 4,
  }, {
    label: 'Financial',
    value: 5,
  },
]

export const antidepressants = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Celexa (Citalopram)',
    value: 1,
  }, {
    label: 'Clomipramine',
    value: 2,
  }, {
    label: 'Cymbalta (Duloxetine)',
    value: 3,
  }, {
    label: 'Effexor-XR (Venlafaxine)',
    value: 4,
  }, {
    label: 'Elavil (Imipramine)',
    value: 5,
  }, {
    label: 'EMSAM (Selegeline patch)',
    value: 6,
  }, {
    label: 'Lexapro (Escitalopram)',
    value: 7,
  }, {
    label: 'Luvox (Fluvoxamine)',
    value: 8,
  }, {
    label: 'Marplan (Isocarboxasid)',
    value: 9,
  }, {
    label: 'Nardil (Phenelzine)',
    value: 10,
  }, {
    label: 'Norpramine (Desipramine)',
    value: 11,
  }, {
    label: 'Pamelor (Nortriptyline)',
    value: 12,
  }, {
    label: 'Parnate (tranycylpromine)',
    value: 13,
  }, {
    label: 'Paxil (Paroxetine)',
    value: 14,
  }, {
    label: 'Prozac (Fluoxetine)',
    value: 15,
  }, {
    label: 'Remeron (Mirtazapine)',
    value: 16,
  }, {
    label: 'Serzone (Nefazodone)',
    value: 17,
  }, {
    label: 'Wellbutrin (Bupropion)',
    value: 18,
  }, {
    label: 'Zoloft (Sertraline)',
    value: 19,
  }, {
    label: 'Viibryd (vilazodone)',
    value: 20,
  }, {
    label: 'Trintellix (Vortioxetine)',
    value: 21,
  }, {
    label: 'Desvenlafaxine (Pristiq)',
    value: 22,
  }, {
    label: 'Trazodone (Desyrel)',
    value: 23,
  },
]

export const anticonvulsants = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Depakote (Valproate)',
    value: 1,
  }, {
    label: 'Gabapentin (Neurontin)',
    value: 2,
  }, {
    label: 'Lamictal (Lamotrigine)',
    value: 3,
  }, {
    label: 'Pregabalin (Lyrica) ',
    value: 4,
  }, {
    label: 'Tegretol (Carbamazepine)',
    value: 5,
  }, {
    label: 'Trileptal (Oxcarbazepine)',
    value: 6,
  },
]


export const mood_stabilizers = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Lithium',
    value: 1,
  }
]

export const antipsychotic = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Abilify (aripiprazole)',
    value: 1,
  }, {
    label: 'Clozaril (Clozapine)',
    value: 2,
  }, {
    label: 'Geodon (Ziprasidone)',
    value: 3,
  }, {
    label: 'Haldol (Haloperidol)',
    value: 4,
  }, {
    label: 'Seroquel (Quetiapine)',
    value: 5,
  }, {
   label: 'Zyprexa (Olanzapine)',
    value: 6,
  }, {
    label: 'Latuda (Lurasidone)',
    value: 7,
  }, {
    label: 'Vraylar (Cariprazine)',
    value: 8,
  }, {
    label: 'Risperdal (Risperidone)',
    value: 9,
  }, {
    label: 'Invega (Paliperidone)',
    value: 10,
  }, {
    label: 'Saphris (Asenapine)',
    value: 11,
  }, {
    label: 'Trilafon (Perphenazine)',
    value: 12,
  }, {
    label: 'Thorazine (Chlorpromazine)',
    value: 13,
  },
]

export const anxiolytics = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Xanax (alprazolam)',
    value: 1,
  }, {
    label: 'Chlordiazepoxide (Librium)',
    value: 2,
  }, {
    label: 'Clonazepam (Klonopin)',
    value: 3,
  }, {
    label: 'Diazepam (Valium)',
    value: 4,
  }, {
    label: 'Lorazepam (Ativan)',
    value: 5,
  }, {
    label: 'Oxazepam (Serax)',
    value: 6,
  },
]

export const anticholinergic = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Cogentin (benztropine)',
    value: 1,
  }, {
    label: 'Artane (trihexyphenidyl)',
    value: 2,
  },
]

export const antihistamine = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Benadryl (diphenhydramine)',
    value: 1,
  }, {
    label: 'Hydroxyzine (Vistaril)',
    value: 2,
  },
]

export const sedatives = [
  {
    label: 'Select medication',
    value: 0,
  }, {
    label: 'Eszopiclone (Lunesta)',
    value: 1,
  }, {
    label: 'Zaleplon (Sonata)',
    value: 2,
  }, {
    label: 'Zolpidem (Ambien)',
    value: 3,
  },
]

export const diagnosed = [
  {
    label: 'History of Brain Injury',
    value: 1,
  }, {
    label: 'Diabetes',
    value: 2,
  }, {
    label: 'Hypertension',
    value: 3,
  }, {
    label: 'Hyperlipidemia',
    value: 4,
  }, {
    label: 'Coronary Artery Disease',
    value: 5,
  }, {
    label: 'Previous Myocardial Infarction',
    value: 6,
  }, {
    label: 'Kidney Disease',
    value: 7,
  }, {
    label: 'Seizures',
    value: 8,
  }, {
    label: 'Hepatitis',
    value: 9,
  }, {
    label: 'Thyroid Disease',
    value: 10,
  }, {
    label: 'Head Trauma with Loss of Consciousness',
    value: 11,
  }, {
    label: 'Migraines',
    value: 12,
  }, {
    label: 'Asthma',
    value: 13,
  }, {
    label: 'Polycystic Ovarian Syndrome',
    value: 14,
  }, {
    label: 'Cancer',
    value: 15,
  }, {
    label: 'Stroke or Transient Ischemic Attack',
    value: 16,
  }, {
    label: 'Toxin Exposure',
    value: 17,
  }, {
    label: 'Encephalitis',
    value: 18,
  }, {
    label: 'CPR/artificial respiration',
    value: 19,
  }, {
    label: 'Multiple sclerosis ',
    value: 20,
  }, {
    label: 'Tremors',
    value: 21,
  }, {
    label: 'Chronic pain',
    value: 22,
  }, {
    label: 'Other',
    value: 23,
  },
]

export const diabetes = [
  {
    label: 'Insulin-dependent',
    value: 1,
  }, {
    label: 'Non-insulin dependent (NIDDM)',
    value: 2,
  },
]

export const hypertension = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Unknown',
    value: 3,
  },
]

export const med_category = [
  {
    label: '-',
    value: 0,
  }, {
    label: 'antidepressants',
    value: 1,
  }, {
    label: 'anticonvulsants',
    value: 2,
  }, {
    label: 'mood_stabilizers',
    value: 3,
  }, {
    label: 'antipsychotic',
    value: 4,
  }, {
    label: 'anxiolytics',
    value: 5,
  }, {
    label: 'anticholinergic',
    value: 6,
  }, {
    label: 'antihistamine',
    value: 7,
  }, {
    label: 'sedatives',
    value: 8,
  }, {
    label: 'other',
    value: 9,
  }, {
    label: 'nonpsyciatric',
    value: 10,
  }, {
    label: 'Stimulants',
    value: 11,
  },
]

export const mtf_filter = [
  {
    label: 'All',
    value: 1,
  }, {
    label: 'Most recent',
    value: 2,
  },
]
