import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import EmailIcon from '@material-ui/icons/Email'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Spinner from 'react-spinner-material'

class CourseProgress extends Component {
  constructor() {
      super();
      this.state = {
          mbct: [],
          loading: false,
      }
      //this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_course_progress',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        cid: 1,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        mbct: result['data'],
        loading: true,
      })
    })
  }

  formatDate =  (date) => {
    if(date) {
      var [ year, month, day ] = date.split('-')

      if (month < 10)
          month = '0' + month;
      if (day < 10)
          day = '0' + day;

      return [month, day, year].join('/')
    }
  }

  render() {
    console.log(this.state.mbct)
    this.state.mbct.map(each => {
      console.log(each.title)
    })
    return (
    <div className="general_information">
    {!this.state.loading &&
      <div className="row">
        <div className="small-12 columns loading2">
          <Spinner color={"#bbbbbb"} radius={50}/>
        </div>
      </div>}
    {this.state.loading &&
      <div className="row">
        <div className="small-12 columns">
          <div className="title1">
            MBCT
          </div>
        </div>
        <div className="small-12 columns">
          <div className="bg1">
            <div className="row">
              <div className="small-12 columns contact">
              {this.state.mbct.map(each =>
                <div className="row bottom_space1">
                  <div className="small-12 columns title3">{each.title}</div>
                  <div className="small-12 columns"><ChevronRightIcon /> Progress: {each.sub_session}/{each.cnt_sub_session}</div>
                  <div className="small-12 columns"><ChevronRightIcon /> Date: {each.start_datetime}</div>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CourseProgress)
