import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { loadLocationWithMessage } from '../../../../../actions/location'
import styled from "@emotion/styled"
import { Tree, TreeNode } from "react-organizational-chart"
import '../../../../../styles/assets/css/admin/orgchart.css'
//import { OrgChart } from "d3-org-chart"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no, todolist_status } from '../../variables/treatment_plan.js'

class TreeChart extends Component {
  constructor() {
      super()
      this.state = {
        target: [],
        interventions: [],
      }
  }

  componentDidMount() {
    this.getData(this.props.pid, this.props.tid)
  }

  getData = (pid, tid) => {
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan_history_chart',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { pid: pid, tid: tid, }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        target: result['data'],
      })

      //console.log(this.state.target)
      this.transToDoList(this.state.target)
    })
  }

  transToDoList(getData) {
    //console.log(getData)
    var intv = []
    var modalities = []
    var interventions = []
    getData.map(each => {
      //console.log(each)
      interventions = each.intervention.split('|||')
      modalities = each.modality_list.split('|||')
      interventions.map((int, index) => {
        intv.push({
          intervention: int,
          modality_list: modalities[index],
        })
      })
    })
    this.setState({
      interventions: intv,
    })
    //console.log(intv)
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const StyledNode = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #226379;
      color: #FEFFFF;
    `;
    const StyledNode2 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #3678a1;
      color: #FEFFFF;
      font-size: 12px;
    `;
    const StyledNode21 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #C2CBCE;
      color: #226379;
      font-size: 12px;
    `;
    const StyledNode3 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #aad2eb; /*#C2CBCE;*/
      color: #226379;
    `;
    const StyledNode31 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #dce5e8;
      color: #226379;
    `;
    const StyledNode4 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #dbebf2;
      color: #226379;
    `;
    const StyledNode41 = styled.div`
      padding: 5px 12px;
      border-radius: 5px;
      display: inline-block;
      border: 1px solid #FEFFFF;
      background: #eeefef;
      color: #226379;
    `;
    var interventions = []
    var modalities = []
    var modality = 0
    var modality_other = ''
    var todo_status = 0
    var buff = []
    const target_id_changed_version = 165
    //console.log(this.state.target)
    return (
    <>
    <div className="row">
      <div className="small-12 columns">
        <div className="nodeL1">
        {this.state.target[0] && this.state.target[0].tm_goal}
        {this.state.target[0] && this.state.target[0].treatment_goal_other}
        </div>
        <div className="nodeConnect1">
        {this.state.interventions.map((intv, index) => {
          //console.log(intv)
          buff = intv.modality_list.split('^^^')
          modality = buff[0]
          buff = buff[1].split('###')
          //console.log(buff)
          modality_other = buff[0]
          todo_status = buff[1]
          return(
            (todo_status == 1 || todo_status == 3) ? (
              <div className="small-12 columns leftLine1">
                <div class="inner"></div>
                <div className="nodeL21">
                  {intv.intervention}<br />[Inactive]
                </div>
                <div class="inner"></div>
                <div className="nodeL31">
                {this.findLabelByValue(treatment_modality, modality)}<br />
                {modality == 21 && ' ['+modality_other+']'}
                [Inactive]
                </div>
              </div>
            ) : (
              <div className="small-12 columns leftLine1">
                <div class="inner"></div>
                <div className="nodeL22">
                  {intv.intervention}
                </div>
                <div class="inner"></div>
                <div className="nodeL32">
                  {this.findLabelByValue(treatment_modality, modality)}<br />
                  {modality == 21 && ' ['+modality_other+']'}
                </div>
              </div>
            )
          )
        })}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="small-12 columns">
        <div className="box_border7">
          <div className="row smaller_txt1">
            <div className="small-4 columns">
              <StyledNode>&nbsp;</StyledNode> Long-Term Treatment Goal
            </div>
            <div className="small-4 columns">
              <StyledNode3>&nbsp;</StyledNode3> To Do List item
            </div>
            <div className="small-4 columns">
              <StyledNode4>&nbsp;</StyledNode4> Treatment Modality
            </div>
          </div>
          <div className="row smaller_txt1">
            <div className="small-4 columns">&nbsp;</div>
            <div className="small-4 columns">
              <StyledNode31>&nbsp;</StyledNode31> To Do List item [Inactive]
            </div>
            <div className="small-4 columns">
              <StyledNode41>&nbsp;</StyledNode41> Treatment Modality [Inactive]
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

    )
  }
}

const mapStateToProps = (state) => {
  //console.log('Treatment Plan List STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TreeChart)
