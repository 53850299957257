import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
/*import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'*/

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import validator from 'validator'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../patient/patient_head3"
import { ans_yesno, appt_type, appt_sub_type, appt_visit, frequency,
  frequency_time } from '../variables/patient.js'

class EditPatientName extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          name: '',
      }
  }

  componentDidMount() {
    this.getPatientStatus()
  }

  getPatientStatus = () => {
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/chk_p_name',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: pid
      }
    })
    .then((result) => {
      if(result['data'].fullname) {
        this.setState({
          name: result['data'].fullname
        })
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    if(this.state.name === '' ) {
      this.setState({
        message: 'Please fill patient name',
      })
      window.scrollTo(0, 0)
    } else {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/edit_p_name',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] === this.props.match.params.pid) {
            this.props.onLoadLocation('/admin/patient_chart/'+pid, 'Changed patient name successfully!')
            this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
          }

        }
      )
    }
  }

  render() {
    return (
      <div className="admin_make_appointment box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Patient Name
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Patient Name: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="text" value={this.state.name}
                          InputProps={{ inputProps: { name: "name" }}} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    <Button variant="contained" color="primary" disableElevation
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditPatientName)
