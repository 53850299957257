export const phq4_questions = [
  {
    label: 'Feeling nervous, anxious or on edge',
    value: 1,
  }, {
    label: 'Not being able to stop or control worrying',
    value: 2,
  }, {
    label: 'Little interest or pleasure in doing things',
    value: 3,
  }, {
    label: 'Feeling down, depressed, or hopeless',
    value: 4,
  },
]

export const phq4_choices = [
  {
    label: 'Not at all',
    value: 0,
  }, {
    label: 'Several says',
    value: 1,
  }, {
    label: 'More than half the days',
    value: 2,
  }, {
    label: 'Nearly every day',
    value: 3,
  },
]
