import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../../actions/location'
import GetPDF from '../../../functions/get_pdf.jsx'

class RoiPDF extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          pdf: '',
      }
  }

  componentDidMount() {
    //this.getData()
  }

  render() {
    return (
      <div className="row roi_pdf">
        <div className="small-12 columns">
          <GetPDF path="roi_pdf"  />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
}

const mapDispatchToProps = (dispatch) => ({

})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RoiPDF)
