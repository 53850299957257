import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
//import '../../../styles/assets/css/pages/dashboard.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import Pagination from '../../functions/pagination'
import { appt_status } from '../../admin/variables/patient'
import { reorderData, genAppointmentStatus } from '../../functions/appointment.js'

class AppointmentList extends Component {
  constructor() {
      super(  );
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          provider: -1,
          appt_status: -1,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_appointment_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.authReducer.uid,
        provider: this.state.provider,
        appt_status: this.state.appt_status,
      }
    })
    .then((result) => {
        if(result['data'].length > 0) {
          this.setState({
            dataItems: reorderData(result['data']),
          })
        }
      }
    )
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-10 columns headerPage1">
            Appointments
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== "" &&
              <div className="row warning_msg7">
                <div className="small-12 columns ">
                  <div className="float_left1"><CheckCircleOutlineIcon /></div>
                  <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
                </div>
              </div>
            }
            <div>
            <table className="table_data1">
              <thead className="table_head">
                <tr>
                  <th>#</th>
                  <th>Provider</th>
                  <th>Date / Time</th>
                  <th>Appt Status</th>
                </tr>
              </thead>
              <tbody>
            {this.state && this.state.pageOfItems && this.state.pageOfItems.map((item) =>
              <tr key={'d'+item.row_number}>
                <td>{item.row_number}</td>
                <td>{item.item.provider}</td>
                <td>{item.item.appt}</td>
                <td className="">{item.item.st}</td>
              </tr>
            )}
            {this.state && this.state.dataItems &&
              <tr className="pagination_block">
                <td colSpan="4">
                  <Pagination items={this.state.dataItems} pageSize={20} onChangePage={this.onChangePage} />
                </td>
              </tr>
            }
            </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppointmentList)
