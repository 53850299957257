import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../utils/global'
import { escapeHtml } from '../../../../utils/textFunctions'
import '../../../../styles/assets/css/admin/list1.css'
import { loadLocationWithMessage } from '../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import MtfList from "./medication/mtf_list"
import MrtfList from "./medication/mrtf_list"
import MedicationList from "./integrations/medication_list"

class TabMedication extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          tab_status: '1',
          uid: 0,
          pid: 0,
          doctor: 0,
          type: 0,
          date: '',
          time: '',
          duration: 0,
          patient_status: 0,
      }
  }

  componentDidMount() {
    if(this.props.match.params.secondtab > 0) {
      this.setState({
        tab_status: this.props.match.params.secondtab,
      })
    }
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      tab_status: newValue,
    })
  }

  render() {
    //console.log('sub_role: '+this.props.sub_role)
    return (
      <div className="admin_make_appointment">
        <div className="row">
          <div className="small-12 columns tab_data1">
          {//this.props.sub_role && this.props.sub_role == 32 &&
            <TabContext value={this.state.tab_status}>
              <AppBar position="static">
                <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                  <Tab label="MTF" value="1" />
                  <Tab label="MRTF" value="2" />
                  <Tab label="EPIC" value="3" />
                </TabList>
              </AppBar>
              <TabPanel value="1" index={1} >
              {this.state.tab_status == 1 && <MtfList />}
              </TabPanel>
              <TabPanel value="2" index={2} >
              {this.state.tab_status == 2 && <MrtfList />}
              </TabPanel>
              <TabPanel value="3" index={3} >
              {this.state.tab_status == 3 && <MedicationList />}
              </TabPanel>
            </TabContext>}
          {/*this.props.sub_role && this.props.sub_role < 32 &&
            <TabContext value={this.state.tab_status}>
              <AppBar position="static">
                <TabList onChange={this.handleTabChange} aria-label="simple tabs example">
                  <Tab label="MTF" value="1" />
                </TabList>
              </AppBar>
              <TabPanel value="1" index={1} ><MtfList /></TabPanel>
            </TabContext>*/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('Medication STATE: ', state)
  return {
    sub_role: state.authReducer.sub_role,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TabMedication)
