import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { cobra_1, cobra_questions } from '../variables/assessments/cobra.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import { calculateTimepoint1 } from '../../functions/timepoint.js'
import StepperAssm from './stepper_assm'

class Cobra extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1,
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'pw18',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
          q6: -1,
          q7: -1,
          q8: -1,
          q9: -1,
          q10: -1,
          q11: -1,
          q12: -1,
          q13: -1,
          q14: -1,
          q15: -1,
          q16: -1,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var timepoint = this.props.match.params.timepoint
    if(timepoint == -1) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_baseline_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { uid: this.props.authReducer.uid }
      })
      .then((result) => {
          if(result['data'] !== 'cobra') {
            this.props.history.push('/member/assessment/'+result['data']+'/'+this.props.match.params.type+'/'+timepoint)
          }
        }
      )
    } else if(timepoint == -2) {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_data',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
          frm: 'cobra',
         }
      }).then((result) => {
        if(result['data'].q1 >=0) {
          this.setState({
            status: 2,
            q1: result['data'].q1,
            q2: result['data'].q2,
            q3: result['data'].q3,
            q4: result['data'].q4,
            q5: result['data'].q5,
            q6: result['data'].q6,
            q7: result['data'].q7,
            q8: result['data'].q8,
            q9: result['data'].q9,
            q10: result['data'].q19,
            q11: result['data'].q11,
            q12: result['data'].q12,
            q13: result['data'].q13,
            q14: result['data'].q14,
            q15: result['data'].q15,
            q16: result['data'].q16,
          })
        }
      })
    } else {
      axios({
        method: 'post',
        url: connectNode + 'patient/assessment/get_assm_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          type: this.props.match.params.type,
          timepoint: this.props.match.params.timepoint,
         }
      })
      .then((result) => {
        var calTimepoint = calculateTimepoint1(result['data'].timeline_start)
        if(this.props.match.params.timepoint > calTimepoint.current_month) {
          this.props.onLoadLocation('assessment_list_2', 'Sorry, something went wrong! Please only select available assessment in this page.')
          this.props.history.push('/member/assessment_list')
        } else if(this.props.match.params.timepoint == calTimepoint.current_month) {
          // Current timepoint => Fill the form //
          if(result['data'].status !== null) {
            if(result['data'].status == 1 && result['data'].step !== 'cobra') {
              this.props.history.push('/member/assessment/'+result['data'].step+'/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
            }
            if(result['data'].status == 2) {
              // Completed //
              this.setState({ status: 2 })
            }
          } else {
            // Back up overview //
            this.props.history.push('/member/assessment/overview/'+this.props.match.params.type+'/'+this.props.match.params.timepoint)
          }
        } else {
          // Past timepoint //
          this.setState({ status: 3 })
        }
      })
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var timepoint = this.props.match.params.timepoint
    var type = this.props.match.params.type
    if(this.state.status == 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 || this.state.q6 === -1 ||
        this.state.q7 === -1 || this.state.q8 === -1 || this.state.q9 === -1 ||
          this.state.q10 === -1 || this.state.q11 === -1 || this.state.q12 === -1 ||
          this.state.q13 === -1 || this.state.q14 === -1 || this.state.q15 === -1 ||
            this.state.q16 === -1) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_cobra',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            type: type,
            timepoint: this.props.match.params.timepoint,
          }
        })
        .then((result) => {
            if(result['data'] === 'pw18') {
              this.props.onLoadLocation('/member/assessment/pw18/'+type+'/'+timepoint)
              this.props.history.push('/member/assessment/pw18/'+type+'/'+timepoint)
            }

          }
        )
      }
    } else {
        this.props.onLoadLocation('/member/assessment/'+this.state.next_step+'/'+type+'/'+timepoint)
        this.props.history.push('/member/assessment/'+this.state.next_step+'/'+type+'/'+timepoint)
    }
  }

  render() {

    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
          Cognitive Complaints in Bipolar Disorder Rating Assessment (COBRA)
          </div>
          <StepperAssm activeStep={4} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box2_2">
            <b>IMPORTANT: </b>Please complete this section in one sitting. If you leave this section or take a break before you complete it and click “Submit,” your data will be lost.
          </div>
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-8 columns">&nbsp;</div>
              {cobra_1.map(each =>
                <div className="medium-1 columns headerResize1">
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="scroll_y_1">
            {cobra_questions.map(eachQ =>
              <div className="row">
                <div className="small-12 medium-8 columns">
                  {eachQ.value}. {eachQ.label}
                </div>
                {cobra_1.map(each =>
                  <div className="small-12 medium-1 columns">
                    <input type="radio" name={"q"+eachQ.value}
                    value={each.value} onChange={(e) => this.handleChange(e)}
                    checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                    disabled={this.state.status > 1 && 'disabled'} />
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
                {eachQ.value < 16 &&
                <div className="small-12 columns">
                  <div className="sep_line3">&nbsp;</div>
                </div>}
              </div>
            )}
            </div>
        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.status === 1 ? 'Submit' : 'Next' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Cobra)
