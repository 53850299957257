import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import Cookies from 'js-cookie'
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { yes_no, yes_no_notsure, primary_interest, gen_m } from '../variables/registration.js'

import InputAdornment from '@material-ui/core/InputAdornment'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EventIcon from '@material-ui/icons/Event'
//import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PhoneIcon from '@material-ui/icons/Phone'
import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import validator from 'validator'

class ScreenQuestionaire extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          status_submit: true,
          invalid: false,
          message: '',
          name: '',
          dob: '',
          dob_d: 0,
          dob_m: 0,
          dob_y: 0,
          live_ma: 0,
          travel_to_clinic: 0,
          access_smartphone: 1,
          schizophrenia: 0,
          bipolar: 0,
          bipolar_lasted_a_week: 0,
          bipolar_phone_complete_screen: 0,
          reach_number: '',
          primary_interest: 0,
      }
  }

  componentDidMount() {
    this.getPatientStatus()
  }

  getPatientStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_screen_questionaire_complete',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.match.params.pid }
    })
    .then((result) => {
      if(result['data'].uid == this.props.match.params.pid) {
        this.props.history.push('/member/signup/'+this.props.match.params.pid)
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
      uid: this.props.uid,
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
    //console.log(this.state)
  }

  calculateAge = (d, m, y) => { // birthday is a date
    var dateString = y+'/'+m+'/'+d
    var today = new Date()
    var birthDate = new Date(dateString)
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
  }

  validatePhoneNumber = (number) => {
    return validator.isMobilePhone(number)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    var chk_status = 1
    //console.log('age: '+this.calculateAge(this.state.dob_d, this.state.dob_m, this.state.dob_y))
    if(this.calculateAge(this.state.dob_d, this.state.dob_m, this.state.dob_y) > 100) {
      chk_status = 4
    } else if(this.state.dob_d === 0 || this.state.dob_m === 0 || this.state.dob_y === 0) {
      chk_status = 6
    } else {
      if(this.calculateAge(this.state.dob_d, this.state.dob_m, this.state.dob_y) >= 18) {
        if(this.state.name.trim() !== '' && this.state.live_ma > 0 ) {
          if(this.state.live_ma == 1) {
            if(this.state.schizophrenia > 1) {
              if(this.state.bipolar == 0 || this.state.primary_interest == 0) {
                chk_status = 2
              } else {
                if(this.state.bipolar > 1) {
                  if((this.state.bipolar_lasted_a_week == 1 || this.state.bipolar_lasted_a_week == 3) && this.state.bipolar_phone_complete_screen == 0) {
                    chk_status = 2
                  } else {
                    if(this.state.bipolar_phone_complete_screen == 1 && this.state.reach_number === '') {
                      chk_status = 2
                    } else if (this.state.bipolar_phone_complete_screen == 1 && !this.validatePhoneNumber(this.state.reach_number)) {
                      chk_status = 5
                    }
                  }
                }
              }
            }
          }
        } else {
          chk_status = 2
        }
      } else {
        chk_status = 3
      }
    }

    if(chk_status == 2) {
      this.setState({
        message: 'Please fill all information.',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else if(chk_status == 3) {
      this.setState({
        message: 'Unfortunately, we can only accept patients who are 18 or older.',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else if(chk_status == 4) {
      this.setState({
        message: 'Please make sure that your date of birth is correct.',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else if(chk_status == 5) {
      this.setState({
        message: 'Please enter a valid phone number',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else if(chk_status == 6) {
      this.setState({
        message: 'Please complete all required questions.', //'Unfortunately, we can only accept patients who are 18 or older.',
        invalid: true,
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })

      var pid = this.props.match.params.pid
      var st = 0
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/add_screen_questionaire_complete',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: pid,
          user: this.state,
        }
      })
      .then((result) => {
        //console.log(pid+': '+result['data'])
        st = result['data']
        if(this.state.live_ma == 1 && this.state.primary_interest == 4 &&
           this.state.schizophrenia >= 2 && this.state.bipolar == 1) {
          //console.log('/member/signup')
          this.props.history.push('/member/signup/'+this.props.match.params.pid)
        } else {
          //console.log('/member/complete_pre_screen_questionaire')
          this.props.history.push('/member/complete_pre_screen_questionaire')
        }
      })
      if(st == pid) {

      }
    }
  }

  render() {
    var gen_d = []
    for(var i=1; i<=31; i++) { gen_d.push(i) }
    var gen_y = []
    for(var i=1920; i<=2021; i++) { gen_y.push(i) }
    var today = new Date()
    return (
    <div className="row wrapper">
    <div className="small-12 columns">
      <div className="admin_screen_questionaire box_border6">
        <div className="row">
          <div className="small-12 columns headerPage1">
            FITT-BD PRE-REGISTRATION QUESTIONNAIRE
          </div>
          <div className="small-12 columns">
            Before you begin registration for FITT-BD, please answer a few questions that will help us determine if the clinic is a good fit for you. This should take you about five minutes.
          </div>
          <div className="small-12 columns top_space1">Thank you!</div>
        </div>
        <div className="row top_space1">
          <div className="small-12 columns">
            <form id="add_new_patient" onSubmit={this.handleSubmit}>
              <div className="row">
                { this.state.message !== '' &&
                  <div className="small-12 columns warning_msg6">
                    <div className="float_left1"><WarningIcon /></div>
                    <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>
                }
              </div>
              <div className="row">
                <div className="small-12 columns box_inner2">
                  <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">1. Name</div>
                      <div className="small-12 columns float_left">
                        <TextField onChange={this.handleChange} variant="outlined"
                        error={this.state.invalid && this.state.name.length == 0}
                        type="text" defaultValue={this.state.name} className="short1"
                        InputProps={{ inputProps: { name: "name" }}} />
                      </div>
                    </div>
                  </div>

                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">2. Date of Birth</div>
                      <div className="small-12 medium-2 columns">
                      <FormControl variant="outlined"
                      error={this.state.invalid && this.state.dob_m == 0} >
                        <Select value={this.state.dob_m} variant="outlined"
                          label="" defaultValue="" name="dob_m"
                          onChange={(e) => this.handleChange2(e)}>
                          <MenuItem value='0' >Month</MenuItem>
                          {gen_m.map(each =>
                            <MenuItem key={"gen_m"+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                      <div className="small-12 medium-2 columns">
                      <FormControl variant="outlined"
                      error={this.state.invalid && this.state.dob_d == 0} >
                        <Select value={this.state.dob_d} variant="outlined"
                          label="" defaultValue="" name="dob_d"
                          onChange={(e) => this.handleChange2(e)}>
                          <MenuItem value='0' >Day</MenuItem>
                          {gen_d.map(each =>
                            <MenuItem key={"gen_d"+each} value={each}>{each}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                      <div className="small-12 medium-2 columns float_left">
                      <FormControl variant="outlined"
                      error={this.state.invalid && this.state.dob_y == 0} >
                        <Select value={this.state.dob_y} variant="outlined"
                          label="" defaultValue="" name="dob_y"
                          onChange={(e) => this.handleChange2(e)}>
                          <MenuItem value='0' >Year</MenuItem>
                          {gen_y.map(each =>
                            <MenuItem key={"gen_y"+each} value={each}>{each}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                  </div>


                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">
                        3. Do you currently live in Massachusetts?
                      </div>
                      <div className="small-12 columns">
                      {yes_no.map(each =>
                        <div className="each_radio1" key={"live_ma"+each.value}>
                          <input type="radio" name="live_ma" value={each.value}
                              checked={this.state.live_ma == each.value}
                              onChange={(e) => this.handleChange2(e)}/>
                              {each.label}
                        </div>)}
                      </div>
                    </div>
                  </div>

                  {this.state.live_ma == 2 &&
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="box_inner4">
                          <p>We’re sorry, but you must live in the state of Massachusetts in order to enroll in the FITT-BD clinic.</p>
                          <p>Thank you for your interest in FITT-BD.</p>
                        </div>
                      </div>
                    </div>}

                    {this.state.live_ma == 1 &&
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="small-12 columns bold">4. What is your primary interest in the FITT-BD clinic?</div>
                        <div className="small-12 columns">
                        {primary_interest.map(each =>
                          <div className="each_radio2" key={"primary_interest"+each.value}>
                            <input type="radio" name="primary_interest" value={each.value}
                                checked={this.state.primary_interest == each.value}
                                onChange={(e) => this.handleChange2(e)}/>
                                {each.label}
                          </div>)}
                        </div>
                      </div>
                    </div>}

                    {(this.state.primary_interest == 1 || this.state.primary_interest == 2) &&
                      <div className="row top_space2">
                        <div className="small-12 columns">
                          <div className="box_inner4">
                            <p>The FITT-BD clinic does not offer one-time consultations or consultations solely 	to make a diagnosis. If you are interested in a consultation with one of the Dauten Family Center for Bipolar Treatment Innovation providers, please call 617-643-2076.</p>
                            <p>Thank you for your interest in FITT-BD.</p>
                          </div>
                        </div>
                      </div>}

                    {this.state.primary_interest == 3 &&
                      <div className="row top_space2">
                        <div className="small-12 columns">
                          <div className="box_inner4">
                            <p>The FITT-BD clinic offers a team-based treatment program rather than the more 	traditional one-on-one patient-therapist model. If you are looking for a therapist for 	one-on-one therapy, we suggest you contact other resources in the community, such as 	the Elliot Community Health Clinic at 781-861-0890 or go to psychologytoday.com.</p>
                            <p>Thank you for your interest in FITT-BD.</p>
                          </div>
                        </div>
                      </div>}

                    {this.state.primary_interest == 5 &&
                      <div className="row top_space2">
                        <div className="small-12 columns">
                          <div className="box_inner4">
                            <p>We’re sorry, but at present we are only accepting patients who are seeking treatment	for bipolar disorder. If you are seeking treatment for another condition, please call the MGH Psychiatry Access Line at 617-724-5600.</p>
                            <p>Thank you for your interest in FITT-BD.</p>
                          </div>
                        </div>
                      </div>}

                  {this.state.live_ma == 1 && this.state.primary_interest == 4 &&
                  <div>
                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">5. Are you currently diagnosed with schizophrenia or schizoaffective disorder?</div>
                      <div className="small-12 columns">
                      {yes_no_notsure.map(each =>
                        <div className="each_radio1" key={"schizophrenia"+each.value}>
                          <input type="radio" name="schizophrenia" value={each.value}
                              checked={this.state.schizophrenia == each.value}
                              onChange={(e) => this.handleChange2(e)}/>
                              {each.label}
                        </div>)}
                      </div>
                    </div>
                  </div>
                  {this.state.schizophrenia == 1 &&
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="box_inner4">
                          <p>We're sorry, but we are not at present accepting patients with schizophrenia or schizoaffective disorder. If you are seeking treatment for these conditions, please call the MGH Psychiatry Access Line at 617-643-2076.</p>
                        </div>
                      </div>
                    </div>}

                  {this.state.schizophrenia > 1 &&
                  <div>
                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">6. Have you ever been diagnosed with bipolar disorder?</div>
                      <div className="small-12 columns">
                      {yes_no_notsure.map(each =>
                        <div className="each_radio1" key={"bipolar"+each.value}>
                          <input type="radio" name="bipolar" value={each.value}
                              checked={this.state.bipolar == each.value}
                              onChange={(e) => this.handleChange2(e)}/>
                              {each.label}
                        </div>)}
                      </div>
                    </div>
                  </div>

                  {this.state.bipolar == 1 &&
                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="box_inner4">
                        <p>You appear to be a good fit for the FITT-BD clinic. Welcome!
                        Please click Submit to register.</p>
                      </div>
                    </div>
                  </div>}

                  {this.state.bipolar > 1 &&
                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="small-12 columns bold">
                      6B. Some people have periods lasting several days or longer when they feel much
                      more excited and energetic or irritable and aggressive than usual, so much so
                      that other 	people notice and/or it causes problems at home, work or school.
                      During these periods, people often experience other symptoms: they may sleep
                      less but not feel tired, their minds may go too fast, they may be	more talkative,
                      they may be very restless or unable to sit still, and they may do things
                      that are unusual for them, like driving too fast or spending too much money.<br />
                      Have you ever had a period like this that lasted at least four days?
                      </div>
                      <div className="small-12 columns">
                      {yes_no_notsure.map(each =>
                        <div className="each_radio1" key={"bipolar_lasted_a_week"+each.value}>
                          <input type="radio" name="bipolar_lasted_a_week" value={each.value}
                              checked={this.state.bipolar_lasted_a_week == each.value}
                              onChange={(e) => this.handleChange2(e)}/>
                              {each.label}
                        </div>)}
                      </div>
                    </div>
                  </div>}

                  {this.state.bipolar > 1 && this.state.bipolar_lasted_a_week == 2 &&
                  <div className="row top_space2">
                    <div className="small-12 columns">
                      <div className="box_inner4">
                        <p>We’re sorry, but we cannot enroll patients who do not have a diagnosis of bipolar disorder. If you are interested in having a consultation to 	determine your diagnosis, please call the Dauten Family Center for Bipolar Treatment Innovation at 617-643-2076.</p>
                      </div>
                    </div>
                  </div>}

                  {this.state.bipolar > 1 && (this.state.bipolar_lasted_a_week == 1 || this.state.bipolar_lasted_a_week == 3) &&
                  <div>
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="small-12 columns bold">
                        6C. Would you be willing to answer a few questions by phone to complete the screening and determine if you might have bipolar disorder?
                        </div>
                        <div className="small-12 columns">
                        {yes_no.map(each =>
                          <div className="each_radio1" key={"bipolar_phone_complete_screen"+each.value}>
                            <input type="radio" name="bipolar_phone_complete_screen" value={each.value}
                                checked={this.state.bipolar_phone_complete_screen == each.value}
                                onChange={(e) => this.handleChange2(e)}/>
                                {each.label}
                          </div>)}
                        </div>
                      </div>
                    </div>
                    {this.state.bipolar_phone_complete_screen == 1 &&
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="small-12 columns bold">
                        Please give us the best number to reach you. You will hear from us within the next 5 business days.
                        </div>
                        <div className="small-12 columns">
                        <TextField onChange={this.handleChange}
                        variant="outlined"
                        error={this.state.invalid && this.state.bipolar_phone_complete_screen == 1
                          && this.state.reach_number.length == 0}
                        type="text" defaultValue={this.state.reach_number}
                        InputProps={{ inputProps: { name: "reach_number" }}} />
                        </div>
                      </div>
                    </div>}
                    {this.state.bipolar_phone_complete_screen == 2 &&
                    <div className="row top_space2">
                      <div className="small-12 columns">
                        <div className="box_inner4">
                          <p>We’re sorry, but we cannot enroll patients who do not have a diagnosis of bipolar disorder. If you are interested in having a consultation to 	determine your diagnosis, please call the Dauten Family Center for Bipolar Treatment Innovation at 617-643-2076.</p>
                        </div>
                      </div>
                    </div>}
                  </div>}



                  </div>}
                  </div>}

                  <div className="row top_space1">
                    <div className="small-12 columns float_right">
                    {this.state.status_submit &&
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>}
                    {!this.state.status_submit &&
                      <Button variant="contained" disabled
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>}
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ScreenQuestionaire)
