export const patient_status = [ '',
  'Registration pending', // 1
  'Created account', // 2
  'Registered', // 3
  'Withdrawn', // 4
  'Delete', // 5
  'Discharged', // 6
]

export const appt_status = [ '',
  'Upcoming', // 1
  'Edited', // 2
  'Cancelled', // 3
  'Completed', // 4
]

export const filter_patient_status = [
  {
    label: 'All status',
    value: 1,
  }, {
    label: 'Registration pending',
    value: 8,
  }, /*{
    label: 'Incomplete',
    value: 3,
  }, */ {
    label: 'Registered',
    value: 9,
  }, {
    label: 'Registered/Active/Medication management/BD screening pending/Clinical Hold/Discharge Pending',
    value: 11,
  }, {
    label: 'BD screening pending',
    value: 12,
  }, {
    label: 'Active',
    value: 2,
  }, {
    label: 'Clinical Hold',
    value: 15,
  }, {
    label: 'Ineligible',
    value: 10,
  }, {
    label: 'Withdrawn',
    value: 7,
  }, {
    label: 'Medication management',
    value: 6,
  }, {
    label: 'Discharge pending',
    value: 16,
  }, {
    label: 'Discharged',
    value: 5,
  }, /*{
    label: 'Active/Medication management',
    value: 4,
  },*/
]
