import { appt_status, appt_type_show, appt_sub_type } from '../admin/variables/patient'

export const reorderData = (dataList) => {
  var upcoming = []
  var future = []
  var past = []
  var cancel = []
  var st = ''
  var buff = {}
  dataList.map(item => {
    st = genAppointmentStatus(item.appt_date, item.appt_time, item.status, item.main_recurrent_id, item.recurrent_end_date)
    buff = {
      id: item.id,
      pid: item.pid,
      cid: item.cid,
      type: appt_type_show[item.type],
      sub_type_id: item.sub_type,
      sub_type: findLabelByValue(appt_sub_type, item.sub_type),
      provider: (item.cid == 1 && item.c_other_name) || (item.cid > 1 && item.c_name),
      appt: item.appt_date+', '+item.appt_time,
      appt_date: item.appt_date,
      appt_time: item.appt_time,
      st: st,
      status: item.status,
      ct_id: item.ct_id,
    }
    if(st == 'Upcoming' || st == 'Upcoming (Recurrent)') {
      upcoming.push(buff)
    } else if(st == 'Canceled' || st == 'Canceled (Recurrent)') {
      cancel.push(buff)
    } else if(st == 'Future' || st == 'Future (Recurrent)') {
      future.push(buff)
    } else if(st == 'Past' || st == 'No-show' || st == 'Past (Recurrent)') {
      past.push(buff)
    }
  })
  var cnt = 0
  var newOrder = []
  upcoming.reverse()
  future.reverse()
  upcoming.map(item => {
    cnt++
    newOrder.push({ row_number: cnt, item: item })
  })
  future.map(item => {
    cnt++
    newOrder.push({ row_number: cnt, item: item })
  })
  past.map(item => {
    cnt++
    newOrder.push({ row_number: cnt, item: item })
  })
  cancel.map(item => {
    cnt++
    newOrder.push({ row_number: cnt, item: item })
  })
  return newOrder
}

function genAppointmentStatus(appt_date, appt_time, status, main_recurrent_id, recurrent_end_date) {
  var gen_str = ''
  var now_day = new Date().getTime()
  var in_7_day = new Date()
  in_7_day.setDate(in_7_day.getDate() + 7)
  //now_day = Number(now_day)
  //in_7_day = Number(in_7_day)
  var appt_d = new Date(appt_date.replace(/-/g, "/")+' '+appt_time+':00')

  if(status == 3) {
    gen_str = 'Canceled'
  } else if(status == 5) {
    gen_str = 'No-show'
  } else {
    if(now_day > appt_d) {
      gen_str = 'Past'
    } else if (now_day < appt_d) {
      if(in_7_day < appt_d) {
        gen_str = 'Future'
      } else {
        gen_str = 'Upcoming'
      }
    }
    if(main_recurrent_id > 0) {
      gen_str += ' (Recurrent)'
    }
  }
  return gen_str
}

function findLabelByValue(arr, searchValue) {
  var label = ''
  var result = arr.find(obj => {
    return obj.value == searchValue
  })
  if(typeof result !== "undefined") {
    label = result.label
  }
  return label
}
