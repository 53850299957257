export const preferred_pronouns = [
  { label: 'She, Her, Hers',
    value: 1,
  },
  { label: 'He, Him, His',
    value: 2,
  },
  { label: 'They, Them, Theirs',
    value: 3,
  },
  { label: 'She/They',
    value: 4,
  },
  { label: 'He/They',
    value: 5,
  },
  { label: 'Other',
    value: 6,
  },
]

export const gender = [
  { label: 'Man',
    value: 1,
  },
  {
    label: 'Woman',
    value: 2,
  },
  {
    label: 'Transgender male/Trans man/Female-to-male',
    value: 3,
  },
  {
    label: 'Transgender female/Trans women/Male-to-female',
    value: 4,
  },
  {
    label: 'Genderqueer',
    value: 5,
  },
  {
    label: 'Multiple gender categories',
    value: 6,
  },
  {
    label: 'Unknown',
    value: 7,
  },
  {
    label: 'Prefer not to answer',
    value: 8,
  },
  {
    label: 'Other',
    value: 9,
  },
]

export const hispanic = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
  {
    label: 'Unknown',
    value: 3,
  },
  {
    label: 'Prefer not to answer',
    value: 4,
  }
]

export const race = [
  {
    label: 'Native American, American Indian, or Alaska Native',
    value: 1,
  }, {
    label: 'Asian',
    value: 2,
  }, {
    label: 'Black, African American, African, or Afro-Caribbean',
    value: 3,
  }, {
    label: 'Native Hawaiian or other Pacific Islander',
    value: 4,
  }, {
    label: 'White',
    value: 5,
  }, {
    label: 'Multiple race',
    value: 6,
  }, {
    label: 'Unknown',
    value: 7,
  }, {
    label: 'Prefer not to answer',
    value: 8,
  },
]

export const marital = [
  {
    label: 'Single',
    value: 1,
  }, {
    label: 'Married',
    value: 2,
  }, {
    label: 'Separated',
    value: 3,
  }, {
    label: 'Divorced',
    value: 4,
  }, {
    label: 'Widowed',
    value: 5,
  },
]

export const growup_with = [
  {
    label: 'Parent(s)',
    value: 1,
  }, {
    label: 'Other family members',
    value: 2,
  }, {
    label: 'Foster care',
    value: 3,
  }, {
    label: 'Other  ',
    value: 4,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }
]

export const relationship_long = [
  {
    label: 'Less than 1 year',
    value: 1,
  }, {
    label: '1 year',
    value: 2,
  }, {
    label: '2 years',
    value: 3,
  }, {
    label: '3 years',
    value: 4,
  }, {
    label: '4 years',
    value: 5,
  }, {
    label: '5 years',
    value: 6,
  }, {
    label: 'More than 5 years',
    value: 7,
  }
]

export const highest_education = [
  {
    label: 'Less than high school',
    value: 1,
  }, {
    label: 'High school graduate',
    value: 2,
  }, {
    label: 'Some college',
    value: 3,
  }, {
    label: 'College graduate',
    value: 4,
  }, {
    label: 'Graduate or professional degree',
    value: 5,
  },
]

export const live_where = [
  {
    label: 'Home',
    value: 1,
  }, {
    label: 'Residential facility',
    value: 2,
  }, {
    label: 'Temporary housing',
    value: 3,
  }, {
    label: 'Homeless',
    value: 4,
  }, {
    label: 'Other',
    value: 5,
  },
]

export const live_with = [
  {
    label: 'Alone',
    value: 1,
  }, {
    label: 'Significant other',
    value: 2,
  }, {
    label: 'Family members',
    value: 3,
  }, {
    label: 'Roommate',
    value: 4,
  }, {
    label: 'Other',
    value: 5,
  },
]

export const exercise = [
  {
    label: '150 minutes or more/week',
    value: 1,
  }, {
    label: '100-150 minutes/week',
    value: 2,
  }, {
    label: 'Less than 100 minutes/week',
    value: 3,
  }, {
    label: 'Not at all',
    value: 4,
  },
]

export const duration_longest_job = [
  {
    label: '1 year',
    value: 1,
  }, {
    label: '2 years',
    value: 2,
  }, {
    label: '3 years',
    value: 3,
  }, {
    label: '4 years',
    value: 4,
  }, {
    label: 'Over 5 years',
    value: 5,
  },
]
