import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { ismi_scale, ismi_questions } from '../variables/assessments/ismi.js'
import { calculateTimepoint1 } from '../../functions/timepoint.js'

import WarningIcon from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

import StepperSelfStigma from './stepper_self_stigma'

class Ismi extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: 1, // 1: no data, 2: completed, 3: past
          message: '',
          start_time: Math.floor(new Date().getTime()/1000),
          start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'complete',
          q1: -1,
          q2: -1,
          q3: -1,
          q4: -1,
          q5: -1,
          q6: -1,
          q7: -1,
          q8: -1,
          q9: -1,
          q10: -1,
          q11: -1,
          q12: -1,
          q13: -1,
          q14: -1,
          q15: -1,
          q16: -1,
          q17: -1,
          q18: -1,
          q19: -1,
          q20: -1,
          q21: -1,
          q22: -1,
          q23: -1,
          q24: -1,
          q25: -1,
          q26: -1,
          q27: -1,
          q28: -1,
          q29: -1,
          assm_status: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
  }

  getAssmProcess = () => {
    var assm_id = this.props.match.params.assm_id
    axios({
      method: 'post',
      url: connectNode + 'admin/self_stigma/check_self_stigma_step',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid:  this.props.authReducer.uid,
        assm_id: assm_id,
      }
    })
    .then((result) => {
      var next_step = 'ismi'
      if(result['data'].length == 0) {
        this.props.history.push('/member/assessment/self_stigma/sccs/'+assm_id)
      } else {
        if(result['data'][0].ismi_status == 1) {
          this.getCompletedData()
        } else {
          if(result['data'][0].step != 'ismi') {
            this.props.history.push('/member/assessment/self_stigma/'+result['data'][0].step+'/'+assm_id)
          }
        }
      }
    })
  }

  getCompletedData = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_ismi',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid:  this.props.authReducer.uid,
        assm_id: this.props.match.params.assm_id,
      }
    })
    .then((result) => {
      this.setState({
        q1: result['data'].q1,
        q2: result['data'].q2,
        q3: result['data'].q3,
        q4: result['data'].q4,
        q5: result['data'].q5,
        q6: result['data'].q6,
        q7: result['data'].q7,
        q8: result['data'].q8,
        q9: result['data'].q9,
        q10: result['data'].q10,
        q11: result['data'].q11,
        q12: result['data'].q12,
        q13: result['data'].q13,
        q14: result['data'].q14,
        q15: result['data'].q15,
        q16: result['data'].q16,
        q17: result['data'].q17,
        q18: result['data'].q18,
        q19: result['data'].q19,
        q20: result['data'].q20,
        q21: result['data'].q21,
        q22: result['data'].q22,
        q23: result['data'].q23,
        q24: result['data'].q24,
        q25: result['data'].q25,
        q26: result['data'].q26,
        q27: result['data'].q27,
        q28: result['data'].q28,
        q29: result['data'].q29,
        assm_status: 1,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    var assm_id = this.props.match.params.assm_id
    var next_step = this.state.next_step
    if(this.state.assm_status != 1) {
      if(this.state.q1 === -1 || this.state.q2 === -1 || this.state.q3 === -1 ||
        this.state.q4 === -1 || this.state.q5 === -1 || this.state.q6 === -1 ||
        this.state.q7 === -1 || this.state.q8 === -1 || this.state.q9 === -1 ||
        this.state.q10 === -1 || this.state.q11 === -1 || this.state.q12 === -1 ||
        this.state.q13 === -1 || this.state.q14 === -1 || this.state.q15 === -1 ||
        this.state.q16 === -1 || this.state.q17 === -1 || this.state.q18 === -1 ||
        this.state.q19 === -1 || this.state.q20 === -1 || this.state.q21 === -1 ||
        this.state.q22 === -1 || this.state.q23 === -1 || this.state.q24 === -1 ||
        this.state.q25 === -1 || this.state.q26 === -1 || this.state.q27 === -1 ||
        this.state.q28 === -1 || this.state.q29 === -1 ) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'patient/assessment/add_ismi',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            assm_id: assm_id,
          }
        })
        .then((result) => {
          //console.log(result['data'])
          if(result['data'] === next_step) {
            this.props.onLoadLocation('/member/assessment/self_stigma/'+next_step+'/'+assm_id)
            this.props.history.push('/member/assessment/self_stigma/'+next_step+'/'+assm_id)
          }
        })
      }
    } else {
      this.props.onLoadLocation('/member/assessment/self_stigma/'+next_step+'/'+assm_id)
      this.props.history.push('/member/assessment/self_stigma/'+next_step+'/'+assm_id)
    }
  }

  render() {

    return (
      <div className="assm_phq9 box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Internalized Stigma of Mental Illness Inventory (ISMI)
          </div>
          {//this.state.status == 1 &&
          <StepperSelfStigma activeStep={1} assm={'ISMI'} />}
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-8 columns">&nbsp;</div>
              {ismi_scale.map(each =>
                <div className="medium-1 columns headerResize1" key={"q"+each.value}>
                  {each.label}&nbsp;
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="">
            {ismi_questions.map(eachQ =>
              <div className="row" key={"q9_"+eachQ.value}>
                <div className="small-12 medium-8 columns">
                  {eachQ.value}. {eachQ.label}
                </div>
                {ismi_scale.map(each =>
                  <div className="small-12 medium-1 columns" key={"q"+each.value}>
                    <input type="radio" name={"q"+eachQ.value} value={each.value}
                    checked={this.state["q"+eachQ.value] == each.value && 'checked'}
                    disabled={this.state.assm_status == 1 && 'disabled'}
                    onChange={(e) => this.handleChange(e)}/>
                    <span className="show-for-small-only">{each.label}</span>
                  </div>
                )}
                {eachQ.value < 29 &&
                  <div className="small-12 columns">
                    <div className="sep_line3">&nbsp;</div>
                  </div>
                }
              </div>
            )}
            </div>

        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              { this.state.assm_status === 1 ? 'Next' : 'Submit' }
          </Button>
        </div>
      </div>
      </form>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  //console.log("timepoint: "+this.props.match.params.timepoint)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Ismi)
