import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import validator from 'validator'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../../actions/location'
import { calculateTimepoint1 } from '../../../../functions/timepoint.js'

import PatientHead3 from "../../../patient/patient_head3"
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { mrtf_type_change, mtf_type_change, yes_no, reason_change,
  reason_change_primary, mrtf_report } from '../../variables/medication'
import TextField from '@material-ui/core/TextField'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

class Mrtf extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          current_month: 0,
          mrtf: [],
          td_status: 1,
          missed_doses: '',
          change: 0,
          report: 0,
          type_of_change: 0,
          new_dose: 0,
          date_of_change: '',
          primary_reason: 0,
          primary_reason_other: '',
          secondary_reason: 0,
          secondary_reason_other: '',
          mrtf_timestamp: '',
          date: '',
          frequency: '',
          frequency_other: '',
          frequency_list: [],
          sig: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  clearField = () => {
    this.setState({
        status: false,
        message: '',
        uid: 0,
        pid: 0,
        current_month: 0,
        mrtf: [],
        td_status: 1,
        missed_doses: '',
        change: 0,
        report: 0,
        type_of_change: 0,
        new_dose: 0,
        date_of_change: '',
        primary_reason: 0,
        primary_reason_other: '',
        secondary_reason: 0,
        secondary_reason_other: '',
        mrtf_timestamp: '',
        currently_taking: 0,
    })
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_mtf_mrtf_list',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        med_id: this.props.match.params.med_id
      }
    })
    .then((result) => {
      var calTimepoint = calculateTimepoint1(result['data'][0].timeline_start)

      this.setState({
        current_month: calTimepoint.current_month,
        mrtf: result['data'],
        currently_taking: result['data'][0].currently_taking
      })
      if(result['data'] === 0) {
        this.setState({
          message: 'Something went wrong.',
        })
      } else { }
    })

    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_mtf_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        med_id: this.props.match.params.med_id, }
    })
    .then((result) => {
      var freq = result['data'][2].value
      this.setState({
        frequency_list: freq,
      })
    })
  }

  checkNull = (txt) => {
    if(txt === 'null') {
      return ''
    }
    return txt
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: this.checkNull(escapeHtml(e.target.value)),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleDateChange = (e, name) => {
    this.setState({ [name]: e })
  }

  handleNumber = (e, name) => {
    if(!validator.isFloat(e.target.value)) {
      this.setState({
        [name]: '',
      })
    } else {
      this.setState({
        [name]: e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.change === 0 || this.state.date == '') {
      this.setState({
        status: false,
        message: 'Please fill all answers',
      })
      window.scrollTo(0, 0)
    } else {
      var chk_st = 1
      if((this.state.change === 1 && (this.state.missed_doses === '' || this.state.missed_doses == 0)
      && (this.state.date_of_change === '' || this.state.report === 0 || this.state.primary_reason === 0 ))
      || (this.state.change === 1 && this.state.type_of_change > 1 && this.state.new_dose === 0)) {
        this.setState({
          status: false,
          message: 'Please fill all answers',
        })
        window.scrollTo(0, 0)
      } else {
        var user = {
          missed_doses: this.state.missed_doses,
          change: this.state.change,
          report: this.state.report,
          type_of_change: this.state.type_of_change,
          new_dose: this.state.new_dose,
          date_of_change: this.state.date_of_change,
          primary_reason: this.state.primary_reason,
          primary_reason_other: this.state.primary_reason_other,
          secondary_reason: this.state.secondary_reason,
          secondary_reason_other: this.state.secondary_reason_other,
          med_other: this.state.mrtf[0].med_other,
          date: this.state.date,
        }
        axios({
          method: 'post',
          url: connectNode + 'admin/patient/add_mrtf',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            cid: this.props.authReducer.uid,
            pid: this.props.match.params.pid,
            med_id: this.props.match.params.med_id,
            timepoint: this.state.current_month,
            user: user,
          }
        })
        .then((result) => {
            if(result['data'] == this.props.match.params.pid) {
              this.getData()
              this.setState({
                message: 'MRTF save successfully!',
                status: true,
                td_status: 1,
                missed_doses: '',
                change: 0,
                report: 0,
                type_of_change: 0,
                new_dose: 0,
                date_of_change: '',
                primary_reason: 0,
                primary_reason_other: '',
                secondary_reason: 0,
                secondary_reason_other: '',
                mrtf_timestamp: '',
                date: '',
              })
            } else {
              this.setState({
                message: 'Something went wrong. Please try again.',
                status: false,
              })
            }
          }
        )
      }

    }
  }

  addZero = (number) => {
    var txt = number
    if(number < 10)  {
      txt = '0'+number
    }
    return txt
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    var today = new Date()
    const frequencyMap = this.state.frequency_list.map(each => ({id: each.vid, name: each.name}))
    return (
      <div className="admin_mrtf box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            MRTF
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
            <form id="make_appointment" onSubmit={this.handleSubmit}>
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  { this.state.message !== '' && this.state.status === false &&
                  <div className="small-12 columns warning_msg6">
                      <div className="float_left1"><WarningIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}
                  { this.state.message !== '' && this.state.status === true &&
                    <div className="small-12 columns warning_msg8">
                      <div className="float_left1"><CheckCircleOutlineIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}

                  <div className="row ">
                  {this.props.authReducer.role == 3 && this.props.authReducer.sub_role == 32 &&
                  <div className="small-12 columns bottom_space1">
                  {this.state.currently_taking ==2 &&
                    <div><span className="bold">Medication Name: </span>
                    {this.state.mrtf.length > 0 && this.state.mrtf[0].med_other}</div>
                  }
                  {this.state.currently_taking ==1 &&
                  <div>
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Date: </div>
                    <div className="small-12 medium-8 columns float_left">
                    <DatePicker selected={this.state.date}
                    onChange={(e) => this.handleDateChange(e, 'date')}
                    className="date_picker short2" />
                    </div>
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Medication Name: </div>
                    <div className="small-12 medium-8 columns float_left">
                    {this.state.mrtf.length > 0 && this.state.mrtf[0].med_other}
                    </div>
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Total Daily Dose/Units: </div>
                    <div className="small-12 medium-8 columns float_left">
                    {this.state.mrtf.length > 0 && this.state.mrtf[0].y_dose} mg
                    </div>
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right"
                    style={{paddingTop: "0.8rem"}}>Frequency:</div>
                    <div className="small-12 medium-8 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.frequency} variant="outlined" className="short3"
                        name="med_frequencyname" defaultValue={this.state.frequency}
                        label="" onChange={(e) => this.handleChange2(e, 'frequency')}>
                        {frequencyMap.map(each =>
                          <MenuItem key={'m'+each.id} value={each.id}>{each.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>

                  {(this.state.frequency === 12) &&
                    <>
                      <div className="small-12 medium-4 columns bold_right top_space1"></div>
                      <div className="small-12 medium-8 columns float_left top_space1"
                      style={{paddingLeft: "1.1rem"}}>
                      <TextField label="" onChange={this.handleChange} name="frequency_other"
                        className="short3" variant="outlined" type="text"
                        value={this.state.frequency_other}
                        InputProps={{ inputProps: { name: "frequency_other", maxLength: 200 }}} />
                      </div>
                    </>
                   }

                  <div className="small-12 medium-4 columns bold_right top_space1">Sig:</div>
                  <div className="small-12 medium-8 columns float_left top_space1" style={{paddingLeft: "1.1rem"}}>
                      <TextField onChange={this.handleChange} name="sig"
                        className="short3" variant="outlined" type="text"
                        value={this.state.sig}
                        InputProps={{ inputProps: { name: "sig", maxLength: 200 }}} />
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Missed Doses: </div>
                    <div className="small-12 medium-8 columns float_left">
                    <TextField label="" onChange={(e) => this.handleNumber(e, 'missed_doses')} name="missed_doses"
                      className="short1" variant="outlined"
                      value={this.state.missed_doses}
                      InputProps={{ inputProps: { name: "missed_doses", maxLength: 3 }}} /> %
                    </div>
                  </div>
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_txt3">Change?: </div>
                    <div className="small-12 medium-8 columns float_left">
                      <FormControl variant="outlined">
                        <Select variant="outlined" label="" className="short1"
                          value={this.state.change} onChange={(e) => this.handleChange2(e, 'change')}>
                            {yes_no.map( each =>
                              <MenuItem key={'change'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {this.state.change == 1 &&
                  <div className="mrtf_bg1">
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">Report: </div>
                      <div className="small-12 medium-8 columns float_left">
                      <FormControl variant="outlined">
                        <Select variant="outlined" label="" className="short1"
                          value={this.state.report} onChange={(e) => this.handleChange2(e, 'report')}>
                          {mrtf_report.map( each =>
                            <MenuItem key={'mr'+each.value} value={each.value}>{each.label}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">Type of Change: </div>
                      <div className="small-12 medium-8 columns float_left">
                        <FormControl variant="outlined">
                          <Select variant="outlined" label="" className="half1"
                            value={this.state.type_of_change}
                            onChange={(e) => this.handleChange2(e, 'type_of_change')}>
                              {mrtf_type_change.map( each =>
                                <MenuItem key={'tc'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    {this.state.type_of_change >= 2 && this.state.type_of_change <= 4  &&
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">New Total Daily Dose: </div>
                      <div className="small-12 medium-8 columns float_left">
                      <TextField label="" onChange={(e) => this.handleNumber(e, 'new_dose')}
                        name="new_dose" className="short1" variant="outlined"
                        value={this.state.new_dose}
                        InputProps={{ inputProps: { name: "new_dose", maxLength: 5 }}} /> mg
                      </div>
                    </div>}
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">Date of Change: </div>
                      <div className="small-12 medium-4 columns float_left">
                      <DatePicker selected={this.state.date_of_change}
                      onChange={(e) => this.handleDateChange(e, 'date_of_change')}
                      className="date_picker" />
                      </div>
                    </div>
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">
                        <div className="stay_top1">Primary Reason for Change:</div>
                      </div>
                      <div className="small-12 medium-8 columns float_left">
                        {reason_change.map(each =>
                          <div key={'r1'+each.value}>
                          {(each.value == 1 || each.value == 2) && <div className="bold margin-top1">{each.label}</div>}
                          {each.value > 10 &&
                            <div key={'r1'+each.value} className="margin-left1">
                              <input type="radio" name="primary_reason" value={each.value}
                              checked={this.state.primary_reason == each.value && 'checked'}
                              onChange={(e) => this.handleChange(e)}/>
                              <span>{each.label}</span>
                            </div>}
                          {each.value > 2 && each.value < 10 && each.value != 8 &&
                            <div key={'r1'+each.value} className="margin-top1">
                              <input type="radio" name="primary_reason" value={each.value}
                              checked={this.state.primary_reason == each.value && 'checked'}
                              onChange={(e) => this.handleChange(e)}/>
                              <span className="bold">{each.label}</span>
                            </div>}
                          </div>
                        )}
                      </div>
                      {this.state.primary_reason == 6 &&
                      <div>
                        <div className="small-12 medium-4 columns bold_txt3">&nbsp;</div>
                        <div className="small-12 medium-8 columns float_left">
                        <TextField label="" onChange={this.handleChange} name="primary_reason_other"
                          variant="outlined" type="text" value={this.state.primary_reason_other}
                          InputProps={{ inputProps: { name: "primary_reason_other" }}} />
                        </div>
                      </div>}
                    </div>
                    <div className="small-12 columns top_space1">
                      <div className="small-12 medium-4 columns bold_txt3">
                        <div className="stay_top1">Secondary Reason for Change:</div>
                      </div>
                      <div className="small-12 medium-8 columns float_left">
                        {reason_change.map(each =>
                          <div key={'r2'+each.value} >
                          {(each.value == 1 || each.value == 2) && <div className="bold margin-top1">{each.label}</div>}
                          {each.value > 10 &&
                            <div key={'r2'+each.value} className="margin-left1">
                              <input type="radio" name="secondary_reason" value={each.value}
                              checked={this.state.secondary_reason == each.value && 'checked'}
                              onChange={(e) => this.handleChange(e)}/>
                              <span>{each.label}</span>
                            </div>}
                          {each.value > 2 && each.value < 10 &&
                            <div key={'r1'+each.value} className="margin-top1">
                              <input type="radio" name="secondary_reason" value={each.value}
                              checked={this.state.secondary_reason == each.value && 'checked'}
                              onChange={(e) => this.handleChange(e)}/>
                              <span className="bold">{each.label}</span>
                            </div>}
                          </div>
                        )}
                      </div>
                      {this.state.secondary_reason == 6 &&
                      <div>
                        <div className="small-12 medium-4 columns bold_txt3">&nbsp;</div>
                        <div className="small-12 medium-8 columns float_left">
                        <TextField label="" onChange={this.handleChange} name="secondary_reason_other"
                          variant="outlined" type="text" value={this.state.secondary_reason_other}
                          InputProps={{ inputProps: { name: "secondary_reason_other" }}} />
                        </div>
                      </div>}
                    </div>
                  </div>}

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns">&nbsp;</div>
                    <div className="small-12 medium-8 columns">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                  </div>}

                  </div>}

                  {this.props.authReducer.sub_role != 32 &&
                    <div className="small-12 columns top_space1 bottom_space1">
                    <span className="bold">Medication Name: </span>{this.state.mrtf.length > 0 && this.state.mrtf[0].med_other}
                    </div>}

                  <div className="small-12 columns history_log">
                  <table>
                    <thead><tr>
                      <th >History</th>
                    </tr></thead>
                    <tbody>
                    {this.state.mrtf.map((each, index) =>
                    <tr key={'med'+index}>
                      <td className="align_left">
                      {each.mrtf_date != null &&
                        <span>
                        <b>Date: </b>{each.mrtf_date}<br />
                        </span>}
                      {each.date_time != null &&
                        <span><b>Updated: </b>{each.date_time != null && each.p_name + ', ' + each.date_time}<br /></span>}
                      {each.mrtf_timestamp != null &&
                        <span>
                        <b>Updated: </b>{each.c_name + ', ' + each.mrtf_datetime}<br />
                        </span>}
                      {each.mrtf_created_timestamp != null &&
                        <span>
                        <b>Updated: </b>{each.c_name + ', ' + each.mrtf_created_datetime}<br />
                        </span>}
                      <b>{each.mrtf_date != null && "Total Dose/Units"}{each.mrtf_date == null && "Dose/Units"}: </b>
                         {each.y_dose} mg<br />
                       {each.frequency != null &&
                         <span><b>Frequency: </b>{each.f_name}
                         {each.frequency == 12 && '['+each.frequency_other+']'}<br /></span>}
                      {each.mrtf_created_timestamp != null &&
                        <span><b>Change: </b>New medication</span>}
                      {each.date_time != null &&
                        <span>
                        <b>Change: </b>{this.findLabelByValue(mtf_type_change, each.chang_status)}<br />
                        </span>
                      }
                      {each.date_time != null && each.chang_status == 3 &&
                        <span>
                        <b>When did you stop taking?: </b>{each.stop_date}<br />
                        </span>
                      }
                      {each.sig != null &&
                        <span>
                        <b>Sig: </b>{each.sig}<br />
                        </span>
                      }
                      {each.mrtf_timestamp != null &&
                        <span><b>Missed dose: </b>
                      {each.mrtf_missed_doses != null && each.mrtf_missed_doses + '%'}
                      {each.mrtf_missed_doses == null && '-'}<br /></span>
                      }
                      {each.mrtf_timestamp != null &&
                        <span><b>Change: </b>
                      {each.mrtf_change == null && each.mrtf_timestamp == null && '-'}
                      {each.mrtf_change == null && each.mrtf_timestamp != null && 'New medication'}
                      {each.mrtf_change == 1 && 'Yes'}
                      {each.mrtf_change == 2 && 'No'}<br /></span>
                      }
                      {each.mrtf_change == 1 &&
                      <div>
                        <b>Report: </b>{this.findLabelByValue(mrtf_report, each.mrtf_report)}
                      </div>}
                      {each.mrtf_change == 1 &&
                      <div>
                        <b>Type of Change: </b>{this.findLabelByValue(mrtf_type_change, each.mrtf_type_change)}<br />
                        <b>Date of Change: </b>{each.mrtf_date_of_change}<br />
                        {each.mrtf_type_change > 1 &&
                          <span><b>New Doses: </b>{each.mrtf_new_dose} mg<br /></span>
                        }
                        <b>Primary Reason for Change: </b>
                        {each.mrtf_primary_reason > 10 && each.mrtf_primary_reason < 20 && 'Symptoms '}
                        {each.mrtf_primary_reason > 20 && each.mrtf_primary_reason < 30 && 'Side effects '}
                        {this.findLabelByValue(reason_change, each.mrtf_primary_reason)}
                        {each.mrtf_primary_reason == 6 && ': ' + each.mrtf_primary_reason_other}<br />
                        <b>Secondary Reason for Change: </b>
                        {each.mrtf_secondary_reason > 10 && each.mrtf_secondary_reason < 20 && 'Symptoms '}
                        {each.mrtf_secondary_reason > 20 && each.mrtf_secondary_reason < 30 && 'Side effects '}
                        {this.findLabelByValue(reason_change, each.mrtf_secondary_reason)}
                        {each.mrtf_secondary_reason == 6 && ': ' + each.mrtf_secondary_reason_other}
                      </div>
                      }
                      </td>
                    </tr>)}
                    </tbody>
                  </table>
                  </div>
                  {//this.state.mrtf_timestamp != '' &&
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns">&nbsp;</div>
                    <div className="small-12 medium-8 columns text_align_right">
                      <Link to={"/admin/patient_chart/"+this.props.match.params.pid+"/2/2"} className="a_link1">Go Back</Link>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>)}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Mrtf)
