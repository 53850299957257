import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import ReactAudioPlayer from 'react-audio-player'
import { loadLocationWithMessage } from '../../../actions/location'

import Button from '@material-ui/core/Button'
import '../../../styles/assets/css/pages/video_player.css'
import Image from '../../../images/integrate1.jpeg'

class Intro13 extends Component {
  handleSubmit = (e) => {
    this.props.history.push('/introduction/introduction14')
  }
  handleBack = (e) => {
    this.props.history.push('/introduction/introduction12')
  }
  render() {
    return (
      <div className="row wrapper">
        <div className="small-12 columns">
          <div className="admin_screen_questionaire box_border_video1">
            <div className="title_2 margin-bottom1">
              <b>I</b>NTEGRATIVE
            </div>
            <div className="row">
              <div className="small-12 medium-6 columns">
                <img src={Image} alt="team" className="w_90percent" />
              </div>
              <div className="small-12 medium-6 columns content2 margin-top2">
                <p>By “integrative,” we mean that we treat you as a whole person.</p>
                <p>Sure, we work with you to stabilize your moods.</p>
                <p>But we also look at your sleep patterns, your diet, and your exercise.</p>
                <p>We focus on your total well-being.</p>
              </div>
            </div>
            <div className="row">
              <div className="small-4 columns back_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleBack} type="button" >
                  Previous
                </Button>
              </div>
              <div className="small-4 columns next_button2">
                <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Intro13)
