import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { mini_steps, mini_q_d , mini_q_e, mini_q_f_v2, mini_q_g, mini_q_h, mini_q_i_v2
  , mini_scale1 } from '../variables/assessments/mini.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperMINI from './stepper'

class Mini3 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'mini4',
          d1: 0,
          d2: 0,
          e1: 0,
          f1: 0,
          cb_f1: 0,
          f2: 0,
          f3: 0,
          g1: 0,
          h1: 0,
          i1: 0,
          i2: 0,
          i3: 0,
          i4: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getMiniData('edit')
    } else {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_mini_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].mini_step !== 'mini3') {
            if(result['data'].mini_step == 'mini1' || result['data'].mini_step == 'mini2'
              || result['data'].mini_step == 'mini3') {
                this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
            } else {
              axios({
                method: 'post',
                url: connectNode + 'admin/assessment/get_mini_data',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                  pid: this.props.match.params.pid,
                }
              })
              .then((result) => {
                this.setState({
                  completed_status: true,
                  appt_id: 1,
                  d1: result['data'].d1,
                  d2: result['data'].d2,
                  e1: result['data'].e1,
                  f1: result['data'].f1,
                  cb_f1: result['data'].cb_f1,
                  f2: result['data'].f2,
                  f3: result['data'].f3,
                  g1: result['data'].g1,
                  h1: result['data'].h1,
                  i1: result['data'].i1,
                  i2: result['data'].i2,
                  i3: result['data'].i3,
                  i4: result['data'].i4,
                })
              })
            }
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      }
    )}
  }

  getMiniData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_mini_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      var cb = 0
      if(result['data'].cb_f1 != null) {
        cb = result['data'].cb_f1
      }
      this.setState({
        completed_status: true,
        appt_id: 1,
        edit_status: get_edit_status,
        d1: result['data'].d1 == null ? 0 : result['data'].d1,
        d2: result['data'].d2 == null ? 0 : result['data'].d2,
        e1: result['data'].e1 == null ? 0 : result['data'].e1,
        f1: result['data'].f1 == null ? 0 : result['data'].f1,
        cb_f1: cb,
        f2: result['data'].f2 == null ? 0 : result['data'].f2,
        f3: result['data'].f3 == null ? 0 : result['data'].f3,
        g1: result['data'].g1 == null ? 0 : result['data'].g1,
        h1: result['data'].h1 == null ? 0 : result['data'].h1,
        i1: result['data'].i1 == null ? 0 : result['data'].i1,
        i2: result['data'].i2 == null ? 0 : result['data'].i2,
        i3: result['data'].i3 == null ? 0 : result['data'].i3,
        i4: result['data'].i4 == null ? 0 : result['data'].i4,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint

    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/update_mini3',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        timepoint: timepoint,
      }
    })
    .then((result) => {
        if(result['data'] === this.state.next_step) {
          this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
        }
      }
    )

  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_mini3',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/assessments/mini_result/'+pid+'/-1', 'Edit successfully!')
          this.props.history.push('/admin/assessments/mini_result/'+pid+'/-1')
        }
      }
    )
  }

  render() {
    //console.log(this.state)
    return (
      <div className="assm_mini box_border4">
        { this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
          {//!this.props.match.params.task == 'edit' &&
          <StepperMINI activeStep={2} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { this.state.appt_id > 0 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1">
              <div className="row show-for-medium bold">
                <div className="medium-6 columns">&nbsp;</div>
                {mini_scale1.map(each =>
                  each.value > 0 &&
                  <div className="medium-2 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>

              <div className="scroll_box1">
                <div className="scroll_box1_content">
                {mini_q_d.map(eachQ =>
                  <div className="row" key={'qd'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      D{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'sd'+each.label}>
                        <input type="radio" name={"d"+eachQ.value} value={each.value}
                        checked={this.state['d'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 10 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                {mini_q_e.map(eachQ =>
                  <div className="row" key={'qe'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      E{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'se'+each.label}>
                        <input type="radio" name={"e"+eachQ.value} value={each.value}
                        checked={this.state['e'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 10 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                {mini_q_f_v2.map(eachQ =>
                  <div className="row" key={'qf'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      F{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'sf'+each.label}>
                        <input type="radio" name={"f"+eachQ.value} value={each.value}
                        checked={this.state['f'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    <div className="small-12 columns">&nbsp; &nbsp; &nbsp;
                      <input type="checkbox" value="1" name={'cb_f'+eachQ.value}
                        checked={this.state.cb_f1}
                        onChange={(e) => this.handleChange7(e)} /> &nbsp;
                        Restricted to performance SAD only
                    </div>
                    {eachQ.value < 10 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                {mini_q_g.map(eachQ =>
                  <div className="row" key={'qg'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      G{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'sg'+each.label}>
                        <input type="radio" name={"g"+eachQ.value} value={each.value}
                        checked={this.state['g'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 10 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                {mini_q_h.map(eachQ =>
                  <div className="row" key={'qh'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      H{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'sh'+each.label}>
                        <input type="radio" name={"h"+eachQ.value} value={each.value}
                        checked={this.state['h'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 10 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                {mini_q_i_v2.map(eachQ =>
                  <div className="row" key={'qi'+eachQ.label}>
                    <div className="small-12 medium-6 columns">
                      I{eachQ.value}. {eachQ.label}
                    </div>
                    {mini_scale1.map(each =>
                      each.value > 0 &&
                      <div className="small-12 medium-2 columns align_center" key={'si'+each.label}>
                        <input type="radio" name={"i"+eachQ.value} value={each.value}
                        checked={this.state['i'+eachQ.value] == each.value && 'checked'}
                        disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                        onChange={(e) => this.handleChange(e)}/>
                        <span className="show-for-small-only">{each.label}</span>
                      </div>
                    )}
                    {eachQ.value < 4 &&
                    <div className="small-12 columns">
                      <div className="sep_line2">&nbsp;</div>
                    </div>}
                  </div>
                )}
                </div>
              </div>
            </div>

          <div className="small-12 columns align_right">
            {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('mini4')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleEdit(e)} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Mini3)
