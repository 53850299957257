import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import { Link } from "react-router-dom"
import { connectNode } from '../../../../utils/global'

import '../../../../styles/assets/css/admin/list2.css'
import PatientHead3 from "../../patient/patient_head3"
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import FormSCCS from '../../../../pdf/SCCS.pdf'
import FormISMI from '../../../../pdf/ISMI.pdf'
import { sccs_scale, sccs_questions } from '../../../patients/variables/assessments/sccs.js'
import { ismi_scale, ismi_questions } from '../../../patients/variables/assessments/ismi.js'

class ViewAssessmentSelfStigma extends Component {
  constructor() {
      super();
      this.state = {
          dataItems: {},
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    var uri = ''
    if(this.props.match.params.assm == 'sccs') {
      uri = 'get_sccs'
    } else if(this.props.match.params.assm == 'ismi') {
      uri = 'get_ismi'
    }
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/'+uri,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        assm_id: this.props.match.params.assm_id,
      }
    })
    .then((result) => {
      this.setState({
        dataItems: result['data'],
      })
    })

  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  convert_date_time = (datetime) => {
    var getdate = datetime.substr(5, 2)+'/'+datetime.substr(8, 2)+'/'+datetime.substr(0, 4)
    getdate += ', '+datetime.substr(11, 8)
    return getdate
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            {this.props.match.params.assm == 'sccs' && 'Self-Concept Clarity Scale'}
            {this.props.match.params.assm == 'ismi' && 'Internalized Stigma of Mental Illness Inventory (ISMI)'}
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns">
                    <div className="small-11 columns title4">
                      Due date: {this.state.dataItems.due_date}
                    </div>
                    <div className="small-1 columns">
                      {this.props.match.params.assm == 'sccs' &&
                        <Link to={FormSCCS} target="_blank"><PictureAsPdfIcon /></Link>}
                      {this.props.match.params.assm == 'ismi' &&
                        <Link to={FormISMI} target="_blank"><PictureAsPdfIcon /></Link>}
                    </div>
                    <div className="small-12 columns">
                      Start: {this.state.dataItems.start_datetime} | Finish: {this.state.dataItems.end_datetime && this.convert_date_time(this.state.dataItems.end_datetime)}
                    </div>
                    <div className="small-12 columns sep_line2">&nbsp;</div>
                    {this.props.match.params.assm == 'sccs' &&
                    <div>{sccs_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-10 columns">{each.value}. {each.label}</div>
                              <div className="small-2 columns">{this.state.dataItems['q'+each.value]}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                    {this.props.match.params.assm == 'ismi' &&
                    <div>{ismi_questions.map(each =>
                        <div>
                          <div className="small-12 columns">
                            <div className="row no_margin_bottom">
                              <div className="small-10 columns">{each.value}. {each.label}</div>
                              <div className="small-2 columns">{this.findLabelByValue(ismi_scale, this.state.dataItems['q'+each.value])}</div>
                            </div>
                          </div>
                          <div className="small-12 columns sep_line1">&nbsp;</div>
                        </div>)}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ViewAssessmentSelfStigma)
