import React from "react";
import Mrtf from "../components/admin/patient/patient_chart/medication/mrtf"
import AddMrtf from "../components/admin/patient/patient_chart/medication/add_mrtf"
import EditMTF from "../components/admin/patient/patient_chart/medication/edit_mtf"
import MrtfReconcile from "../components/admin/patient/patient_chart/medication/mrtf_reconcile"

const ADMIN_PSYCHIATRIST_ROUTES = [
  {   //path: "/admin/patient_medication/mrtf/:pid/:timepoint/:med_id",
      path: "/admin/patient_medication/mrtf/:pid/:med_id",
      key: "mrtf",
      title: "MRTF",
      exact: true,
      requireAuth: true,
      component: () => <Mrtf />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_medication/add_mrtf/:pid",
      key: "add_mrtf",
      title: "Add MRTF",
      exact: true,
      requireAuth: true,
      component: () => <AddMrtf />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_medication/edit_mrtf/:pid/:med_id",
      key: "edit_mrtf",
      title: "Edit MTF",
      exact: true,
      requireAuth: true,
      component: () => <EditMTF />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  {   path: "/admin/patient_medication/mrtf_reconcile/:pid/:med_id",
      key: "mrtf_reconcile",
      title: "Medication Reconciliation",
      exact: true,
      requireAuth: true,
      component: () => <MrtfReconcile />,
      showtitle: 'true',
      ficon: 'fi-pencil',
  },
  ];

  export default ADMIN_PSYCHIATRIST_ROUTES;
