import React, { Component } from 'react'
import '../../styles/assets/css/pages/dashboard.css'

class Assessments extends Component {
  render() {
    return (
      <div>Assessments</div>
    );
  }
}

export default Assessments;
