import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { pn_checkin_frequency } from '../../../variables/patient.js'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";

class PnCheckin extends Component {
  constructor() {
      super();
      this.state = {
          status: 0,
          message: '',
          uid: 0,
          pid: 0,
          checkin: 0,
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_checkin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'].checkin > 0) {
        this.setState({
          checkin: result['data'].checkin,
        })
      }
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/edit_checkin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        checkin: this.state.checkin,
      }
    })
    .then((result) => {
        //console.log('Callback: '+result['data'])
        if(result['data'] == this.props.match.params.pid) {
          this.setState({
            message: 'Changed successfully!',
            status: 1,
          })
        } else {
          this.setState({
            message: 'Something went wrong. Please try again.',
            status: 2,
          })
        }
      }
    )
  }

  render() {
    //console.log(this.state.admin_list)
    return (
      <div className="admin_make_appointment">
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
              <div className={ (this.state.status === 1 && 'warning_msg8') || (this.state.status === 2 && 'warning_msg6') }>
                <div className="float_left1">
                { this.state.status === 1 && <CheckCircleOutlineIcon />}
                { this.state.status === 2 && <WarningIcon />}
                </div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            }
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <div className="row top_space1">
                      <div className="small-12 columns">
                        <div className="small-12 medium-3 columns bold_txt3">Check-in: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.checkin} variant="outlined" label=""
                              onChange={(e) => this.handleChange2(e, 'checkin')}>
                                {pn_checkin_frequency.map( each =>
                                  <MenuItem key={'cn'+each.value} value={each.value}>{each.label}</MenuItem>
                                )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-3 columns">&nbsp;</div>
                        <div className="small-12 medium-9 columns ">
                          <Button variant="contained" color="primary" disableElevation
                           onClick={this.handleSubmit} type="submit">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PnCheckin)
