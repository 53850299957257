export const dep_exp_past = [
  {
    label: '0 - 2',
    value: 1,
  }, {
    label: '3 - 5',
    value: 2,
  }, {
    label: '6 - 9',
    value: 3,
  }, {
    label: '10+',
    value: 4,
  }, {
    label: 'Unknown',
    value: 5,
  },
]

export const pt_lastyear_spent_dep = [
  {
    label: '0 - 20%',
    value: 1,
  }, {
    label: '21 - 40%',
    value: 2,
  }, {
    label: '41 - 60%',
    value: 3,
  }, {
    label: '61 - 80%',
    value: 4,
  }, {
    label: '81 - 100%',
    value: 5,
  }, {
    label: 'Unknown',
    value: 6,
  },
]

export const yes_no_unknown = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Unknown',
    value: 3,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  },
]

export const kinds_child_abuse = [
  {
    label: 'Emotional',
    value: 1,
  }, {
    label: 'Physical',
    value: 2,
  }, {
    label: 'Sexual',
    value: 3,
  }, {
    label: 'Other',
    value: 4,
  },
]
