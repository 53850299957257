import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink} from "react-router-dom"
import axios from 'axios'
import { connectNode, loadingProps } from '../../../utils/global'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PostAddIcon from '@material-ui/icons/PostAdd'
import Spinner from 'react-spinner-material'
import '../../../styles/assets/css/admin/list1.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Pagination from '../../functions/pagination'
import ReactHtmlParser from 'react-html-parser'
import { filter_patient_status } from './variables/patient_1.js'

class PatientList extends Component {
  constructor() {
      super();
      //var exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
      this.state = {
          dataItems: [], //exampleItems,
          pageOfItems: [],
          show_filter_status: 11,
      }
      this.onChangePage = this.onChangePage.bind(this)
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/register_patient/get_patient_list_filter',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        show_filter_status: this.state.show_filter_status,
      }
    })
    .then((result) => {
        var val = []
        if(result['data'].length > 0) {
          val = result['data']
        }
        this.setState({
          dataItems: val,
        })
      }
    )
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    }, () => {
      this.getDataList()
    })
  }

  onChangePage(pageOfItems) {
    this.setState({ pageOfItems: pageOfItems })
  }

  render() {
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-2 columns headerPage1">
            Patients
          </div>
          <div className="small-12 medium-10 columns">
            <FormControl variant="outlined" className="filter_p_status">
              <Select value={this.state.show_filter_status} variant="outlined" label=""
                onChange={(e) => this.handleChange2(e, 'show_filter_status')}
                value={this.state.show_filter_status} >
              {filter_patient_status.map(each =>
                <MenuItem key={'apt'+each.value} value={each.value}>{each.label}</MenuItem>
              )}
              </Select>
            </FormControl>
            <NavLink to='/admin/add_patient' className="menu_list_active new_bttn new_p_btn">
              New Patient
            </NavLink>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns ">
            { typeof this.props.locationReducer.txt_message !== 'undefined' &&
              this.props.locationReducer.txt_message !== '' &&
              <div className="warning_msg3">
                <div className="float_left1"><CheckCircleOutlineIcon /></div>
                <div className="txt_warning_msg1">{ this.props.locationReducer.txt_message }</div>
              </div>
            }
            <div>
              <table className="table_data1">
                <thead className="table_head">
                  <tr>
                    <th>ID</th>
                    <th>MRN</th>
                    <th>Name</th>
                    <th>Primary Clinician</th>
                    <th>Status</th>
                    <th>Intake Appt</th>
                    <th>Days Enrolled</th>
                    <th>Days Registered</th>
                  </tr>
                </thead>
                <tbody>
              {!this.state.dataItems &&
                <tr>
                  <td className="loading1"><Spinner color={"#bbbbbb"} radius={50}/></td>
                </tr>}
              {this.state && this.state.dataItems && this.state.pageOfItems.map(item =>
                <tr key={item.uid}>
                  <td>{item.uid}</td>
                  <td>{item.mrn}</td>
                  <td className="align-left"><a href={"/admin/patient_chart/"+item.uid+"/1/1"}>{item.fullname}</a></td>
                  <td>{item.c_name !== null && item.c_name}{item.c_name === null && '-'}</td>
                  <td>
                    {item.status == 1 && 'Registration pending'}
                    {item.status != 1 && item.st_name }
                  </td>
                  <td>
                    {item.mini_cname != null && item.mini_cname}
                    {item.mini_cname == null && item.c_other_name}
                    {(item.mini_cname != null || item.c_other_name != null) && <br />}
                    {item.intake_clinician != null && item.mini_start_time.substring(0, 10)}
                    {item.intake_clinician == null && '-'}
                  </td>
                  <td>{item.diff_days}</td>
                  <td>{item.diff_regist_days}</td>
                </tr>
              )}

              {this.state && this.state.dataItems &&
                <tr className="pagination_block">
                  <td colSpan="9">
                    <Pagination items={this.state.dataItems} pageSize={30} onChangePage={this.onChangePage} />
                  </td>
                </tr>
              }
              </tbody>
              </table>
            </div>
          </div>
          <div className="small-12 columns ">
            <div className="bold">Note:</div>
            <ul>
              <li>Registration pending: Did not complete registration</li>
              <li>Registered: Completed registration</li>
              <li>Active: Completed MINI (Eligible)</li>
              <li>Ineligible: Completed MINI (Ineligible)</li>
              <li>Withdrawn: Completed registration and withdrew / Completed MINI and withdrew / Start treatment and withdrew</li>
              <li>Medication Management</li>
              <li>Discharged</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('PatientList STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('PatientList mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientList)
