export const cgi_type = [
  {
    label: 'Admission Assessment',
    value: 1,
  }, {
    label: 'Follow-Up Visit',
    value: 2,
  },
]

export const cgi_mood = [
  {
    label: 'Depressed',
    value: 1,
  }, {
    label: 'Euthymic',
    value: 2,
  }, {
    label: 'Dysphoric Mania',
    value: 3,
  }, {
    label: 'Euphoric Mania',
    value: 4,
  }, {
    label: 'Cycling',
    value: 5,
  },
]

export const mood_state_long = [
  {
    label: '',
    value: 0,
  }, {
    label: '1',
    value: 1,
  }, {
    label: '2',
    value: 2,
  }, {
    label: '3',
    value: 3,
  }, {
    label: '4',
    value: 4,
  }, {
    label: '5',
    value: 5,
  }, {
    label: '6',
    value: 6,
  }, {
    label: '7',
    value: 7,
  }, {
    label: '8',
    value: 8,
  }, {
    label: '9',
    value: 9,
  }, {
    label: '10',
    value: 10,
  }, {
    label: '11',
    value: 11,
  }, {
    label: '12',
    value: 12,
  }, {
    label: '13',
    value: 13,
  }, {
    label: '14',
    value: 14,
  }, {
    label: '15',
    value: 15,
  }, {
    label: '16',
    value: 16,
  }, {
    label: '17',
    value: 17,
  }, {
    label: '18',
    value: 18,
  }, {
    label: '19',
    value: 19,
  }, {
    label: '20',
    value: 20,
  }, {
    label: '21',
    value: 21,
  }, {
    label: '22',
    value: 22,
  }, {
    label: '23',
    value: 23,
  }, {
    label: '24',
    value: 24,
  }, {
    label: '25',
    value: 25,
  }, {
    label: '25+',
    value: 26,
  },
]

export const mood_state_long_type = [
  {
    label: 'Days',
    value: 1,
  }, {
    label: 'Weeks',
    value: 2,
  }, {
    label: 'Months',
    value: 3,
  }, {
    label: 'Years',
    value: 4,
  },
]

export const severity_illness = [
  {
    label: '',
    value: 0,
  }, {
    label: '1: Normal',
    value: 1,
  }, {
    label: '2: Borderline mentally ill',
    value: 2,
  }, {
    label: '3: Mildly ill',
    value: 3,
  }, {
    label: '4: Moderately ill',
    value: 4,
  }, {
    label: '5: Markedly ill',
    value: 5,
  }, {
    label: '6: Severely ill',
    value: 6,
  }, {
    label: '7: Among the most extremely ill patients',
    value: 7,
  },
]

export const improvement = [
  {
    label: '',
    value: 0,
  }, {
    label: '1: Very much improved',
    value: 1,
  }, {
    label: '2: Much improved',
    value: 2,
  }, {
    label: '3: Minimally improved',
    value: 3,
  }, {
    label: '4: No change',
    value: 4,
  }, {
    label: '5: Minimally worse',
    value: 5,
  }, {
    label: '6: Much worse',
    value: 6,
  }, {
    label: '7: Very much worse',
    value: 7,
  },
]
