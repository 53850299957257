import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { NavLink, withRouter } from "react-router-dom"
import axios from 'axios'
import { connectNode } from '../../../utils/global'

import PostAddIcon from '@material-ui/icons/PostAdd'
import '../../../styles/assets/css/pages/interventions.css'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import Star from '@material-ui/icons/Star'
import CourseNavigation from './course_navigation.jsx'

class CoursePersonas extends Component {
  constructor() {
      super(  );
      this.state = {
          main_title: '',
          image: '',
          sessions: [],
          stars: 0,
      }
  }

  componentDidMount() {
    this.getDataList()
  }

  getDataList = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/courses/get_main_page',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        cid: this.props.match.params.cid,
      }
    })
    .then((result) => {
      //console.log(result['data'])
      if(result['data'].length > 0) {
        this.setState({
          main_title: result['data'][0][0].main_title,
          stars: result['data'][1],
        })
      }
    })
  }

  render() {
    //console.log(this.state.sessions)
    return (
      <div className="patient_dashboard box_border4">
        <div className="row">
          <div className="small-12 medium-6 columns headerPage1">
            {this.state.main_title}
          </div>
          <div className="small-12 medium-6 columns align_right">
            <CourseNavigation />
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
          <div className="small-12 columns block1 library_page">
            <div className="course_title_topic2">Personas</div>
            <div>
              <div class="top_space1">
                  <div class="row">
                      <div class="small-12 medium-3 columns">
                          <img src="/mbct/sue.jpg" />
                      </div>
                      <div class="small-12 medium-9 columns">
                          <b>Sue</b> is 39 years old. She works long hours and often feels exhausted by the time she arrives home at the end of the day. Sue has a lot of responsibilities at work and at home and she takes them very seriously. She also has a daughter getting ready to leave the house for college. She tends to be hard on herself and can be very self-critical. Some days are better than others, but Sue feels like most of the time she is sad and disappointed in herself. She is taking an antidepressant medication, but would like to find additional ways to help improve her mood. Sue likes the body breaths and self-compassion meditations.
                      </div>
                  </div>
              </div>
              <div class="top_space1">
                  <div class="row">
                      <div class="small-12 medium-3 columns">
                          <img src="/mbct/sarah.jpg" />
                      </div>
                      <div class="small-12 medium-9 columns">
                          <b>Sarah</b> is 27 years old. She recently lost her job and is having a difficult time getting motivated to look for a new one. She is anxious about her situation and can get down on herself. She can also be forgetful and distracted.  Sarah recently joined a gym and is trying to go regularly. She hopes that learning mindfulness will help her to better cope with the stress in her life.
                      </div>
                  </div>
              </div>
              <div class="top_space1">
                  <div class="row">
                      <div class="small-12 medium-3 columns">
                          <img src="/mbct/jessica.jpg" />
                      </div>
                      <div class="small-12 medium-9 columns">
                          <b>Jessica</b> is 13 years old. She lives at home with her parents. She is often distracted and has trouble focusing, which makes it hard for her to get her homework done. She also can feel overwhelmed by all the things going on around her. Jessica sometimes feels bad about this and is critical of herself. She notices the critical, judgmental thoughts when she is doing mindfulness exercises and hopes to learn how to move past them. She also sometimes feels self-conscious and uncomfortable when she does the exercises.
                      </div>
                  </div>
              </div>
              <div class="top_space1">
                  <div class="row">
                      <div class="small-12 medium-3 columns">
                          <img src="/mbct/steve.jpg" />
                      </div>
                      <div class="small-12 medium-9 columns">
                          <b>Steve</b> is 49 years old. He works work full-time and has a demanding boss. He and his wife have two teenage children, so even when Steve is home from work, he is often busy driving his children to sports practices and helping around the house. Steve reports that he often feels frazzled and overwhelmed. It can seem like there is just not enough time in the day to get everything done. He has noticed that his mind often feels like it is moving “a mile a minute” and that there are too many errands, work due dates, and responsibilities for him to keep track of. He sometimes gets angry and has trouble paying attention due to these worries. Steven is hoping to learn how to relax, stay in the present and enjoy his everyday life.
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
)}}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CoursePersonas)
