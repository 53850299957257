import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/list2.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ListSubheader } from '@material-ui/core'
import PatientHead3 from "../../../patient/patient_head3"
import { treatment_targets, treatment_modality, completion, reason_change_target,
  intervention_format, adherence_reason, yes_no, todolist_status } from '../../variables/treatment_plan.js'

class TreatmentTargetEdit extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          message_status: false,
          getData: [],
          treatment_target: 0,
          target_other: '',
          notes: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    // Target List //
    axios({
      method: 'post',
      url: connectNode + 'admin/treatment_plan/get_treatment_plan_target',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        plan_id: this.props.match.params.tid,
        tg_id: this.props.match.params.tg_id,
      }
    })
    .then((result) => {
      var get_data = result['data']
      this.setState({
        getData: get_data,
        treatment_target: get_data.target,
        target_other: get_data.target_other,
        notes: get_data.notes,
      })
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleSubmit = (e) => {
    //console.log(this.state.getData)
    //console.log(this.state.treatment_target)
    e.preventDefault()
    var chk = true

    if(chk) {
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/treatment_plan/edit_treatment_plan',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          tg_id: this.props.match.params.tg_id,
          treatment_target: this.state.treatment_target,
          target_other: this.state.target_other,
          notes: this.state.notes,
        }
      })
      .then((result) => {
        if(result['data'] === this.props.match.params.tg_id) {
          this.setState({
            message: 'Intermediate goal updated successfully!',
            message_status: true,
          })
          window.scrollTo(0, 0)
        }
      })
    } else {
      this.setState({
        message: 'Please fill all information',
        message_status: false,
      })
      window.scrollTo(0, 0)
    }
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_new_treatment_plan box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Treatment : Intermediate Goal [Edit]
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1 space_bottom1">
                    { this.state.message !== '' && !this.state.message_status &&
                    <div className="row warning_msg6">
                      <div className="small-12 columns ">
                        <div className="float_left1"><WarningIcon /></div>
                        <div className="txt_warning_msg1">{ this.state.message }</div>
                      </div>
                    </div>}
                    { this.state.message !== '' && this.state.message_status &&
                    <div className="row warning_msg7">
                      <div className="small-12 columns ">
                        <div className="float_left1"><CheckCircleOutlineIcon /></div>
                        <div className="txt_warning_msg1">{ this.state.message }</div>
                      </div>
                    </div>}
                    { this.state.message !== '' &&
                    <div className="row"><div className="small-12 columns">&nbsp;</div></div>}
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns">
                        <div className="small-12 columns"><span className="bold">Long-Term Treatment Goal: </span>
                        {this.state.getData.tm_goal}
                        {this.state.getData.treatment_goal_other}
                        </div>
                      </div>
                    </div>
                    <div className="row no_margin_bottom">
                      <div className="small-12 columns top_space1">
                        <div className="small-12 columns">
                          <span className="bold">Intermediate Goal: </span>
                          <FormControl variant="outlined">
                            <Select value={this.state.treatment_target}
                              onChange={(e) => this.handleChange2(e, 'treatment_target')} name="treatment_target"
                              variant="outlined" label="" className="width_auto">
                              {treatment_targets.map( each => {
                                if (each.value < 4)
                                  return(<ListSubheader key={'tt'+each.value}>{each.label}</ListSubheader>)
                                if (each.value > 3)
                                  return(<MenuItem key={'tt'+each.value} value={each.value}>{each.label}</MenuItem>)
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        {this.state.treatment_target == 4 &&
                          <div className="small-12 columns">
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange2(e, 'target_other')}
                            value={this.state.target_other}
                            InputProps={{ inputProps: { maxLength: 190, placeholder: 'Please specify others' }}} />
                          </div>}
                        <div className="small-12 columns top_space1">
                          <span className="bold">Intermediate Goal Notes: </span>
                          <TextField variant="outlined" type="text" className="space_top_1"
                            onChange={(e) => this.handleChange2(e, 'notes')}
                            value={this.state.notes}
                            InputProps={{ inputProps: { maxLength: 240, placeholder: 'Notes' }}} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="medium-6 columns">
                      <a href={'/admin/treatment_plan/treatment_plan/'+this.props.match.params.pid+'/'+this.props.match.params.tid}
                      className="top_space1 go_back">Back</a>
                    </div>
                    <div className="medium-6 columns text_align_right">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TreatmentTargetEdit)
