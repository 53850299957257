import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SignatureCanvas from 'react-signature-canvas'

class TreatmentConsent extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          uid: 0,
          patient_consent: 0,
          patient_name: '',
          patient_date: new Date().toISOString().substring(0, 10),
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        //console.log(result['data'])
        if(result['data'].reg_step !== 'treatment_consent') {
          this.props.history.push('/member/'+result['data'].reg_step)
        } else {
          this.setState({ patient_name: result['data'].fullname})
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    // signature //
    var signature = this.refs.mySignature //.getCanvas()
    if(this.state.patient_date === '' || this.state.patient_name === ''
       || this.state.patient_consent === 0) {
      this.setState({
        message: 'Please fill all information',
      })
      window.scrollTo(0, 0)
    } else if(signature.isEmpty()) {
      this.setState({
        message: 'Please sign your signature',
      })
      window.scrollTo(0, 0)
    } else {
      var base64img = signature.toDataURL("image/png")
      const formData = new FormData()
      formData.append('file', base64img)
      formData.append('uid', this.props.authReducer.uid)
      formData.append('patient_date', this.state.patient_date)
      formData.append('patient_name', this.state.patient_name)
      formData.append('patient_consent', this.state.patient_consent)
      formData.append('udata', JSON.stringify(this.state))

      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_treatment_consent',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        body: JSON.stringify(this.state),
        data: formData
      })
      .then((result) => {
          //console.log(result['data'])
          if(result['data'] === 'information_sheet') {
            this.props.onLoadLocation('/member/information_sheet')
            this.props.history.push('/member/information_sheet')
            //this.props.onLoadLocation('/member/roi')
            //this.props.history.push('/member/roi')
          }
        }
      )
    }
  }

  render() {

    return (
      <div className="treatment_consent box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Treatment Consent Form
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={4}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
            <div className="">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns bold">
                    <p>As a patient at the FITT-BD clinic, I agree to the following, to be done to the best of my ability:</p>
                  </div>
                  <div className="small-12 columns ">
                  <ol>
                  <li>I consent to treatment by my team of providers.</li>
                  <li>I will  make my best effort to keep my appointments as scheduled.</li>
                  <li>I will work with my team of providers to develop a treatment plan.</li>
                  <li>I will make my best effort to follow my treatment plan.</li>
                  <li>I will make my best effort to do online self-assessments in a timely manner.</li>
                  </ol>
                  </div>
                  <div className="small-12 columns ">
                    <p><input type="checkbox" value="1" name="patient_consent"
                      onChange={(e) => this.handleChange7(e)} /> &nbsp;
                      I agree to the above, which I have read and understand.</p>
                  </div>
                </div>
                <div className="row signature">
                  <div className="small-12 medium-6 columns show-for-medium">
                    &nbsp;
                    {/*this.state.signature !== null && <img src={this.state.signature} />*/}
                  </div>
                  <div className="small-12 medium-6 columns">
                    <div className="row title_signature">
                      <div className="small-6 columns">
                        Patient Signature
                      </div>
                      <div className="small-6 columns align-right">
                        <button type="button" className="clear_button"
                           onClick={ () => this.refs.mySignature.clear() }>
                            Clear
                        </button>
                      </div>
                    </div>
                    <div className="relative_float_left">
                        <SignatureCanvas penColor='#555555' canvasProps={{className: 'signatureCanvas'}}
                         ref="mySignature" />
                    </div>
                    <div className="relative_float_left tm_date">
                    <span className="bold">Name:</span> {this.state.patient_name}
                    {/*<TextField id="patient_name" label="Patient First name, Last name" onChange={this.handleChange} variant="outlined"
                    InputProps={{
                        maxLength: 60,
                        inputProps: { name: "patient_name" }
                      }}  />*/}
                    </div>
                    <div className="relative_float_left tm_date">
                    <span className="bold">Date:</span> {this.state.patient_date}
                    {/*<TextField id="patient_date" label="Date" onChange={this.handleChange2} variant="outlined" type="date" defaultValue={this.state.patient_date}
                    InputProps={{ inputProps: { name: "patient_date", max: new Date().toISOString().substring(0, 10) }}} /*/}</div>
                  </div>
                </div>



            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TreatmentConsent)
