import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter, NavLink } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { ListSubheader } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../../../patient/patient_head3"
import { contact, type, reason, outcome, yes_no } from '../../variables/contact_logs.js'
import { completion, adherence_reason } from '../../variables/treatment_plan.js'

//import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import ReactHtmlParser from 'react-html-parser'

class TeamNotes extends Component {
  constructor() {
      super();
      this.quill = require('react-quill')
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          date_log: new Date().toISOString().substring(0, 10),
          contact: 0,
          type: 0,
          reason: 0,
          reason_other: '',
          reason_list: [],
          outcome: [{ id:1, outcome: 0, outcome_txt: '' }],
          outcome_list: [],
          comments: '',
          dataList: [],
          update_treatment_plan: 0,
          getTreatmentPlan: [],
          update_mrtf: 0,
          getMRTF: [],
          editMode: false
      }
  }

  componentDidMount() {
    this.getData()
  }

  get_outcome_and_reasons = (editMode = false) => {
    axios({
      method: 'post',
      url: connectNode + 'functions/patient/get_dd_variable2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { v_type: 8, }
    })
    .then((result) => {
      var get_reasons = result['data']
      var get_outcome = []
      axios({
        method: 'post',
        url: connectNode + 'functions/patient/get_dd_variable',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { v_type: 9, }
      })
      .then((result) => {
        get_outcome = result['data']
        //console.log(result['data'])
        this.setState({
          reason_list: get_reasons,
          outcome_list: get_outcome,
        });
        if (editMode === true) this.setEditMode();
      }
    )})
  }

  getData = () => {
    if(this.props.match.params.ct_id > 0) {
      axios({
        method: 'post',
        url: connectNode + 'admin/contact_log/get_contact_log',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          pid: this.props.match.params.pid,
          appt_id: this.props.match.params.appt_id,
          ct_id: this.props.match.params.ct_id,
        }
      })
      .then((result) => {
        if(result['data'].length > 0) {
          this.setState({ dataList: result['data'], })
        }
      })
    } else this.get_outcome_and_reasons(false);
  }

  setEditMode = () => {
    const data = this.state.dataList[0];
    const outcomeArray = data.outcome.split(",");
    const outcomeList = this.state.outcome_list;
    this.setState({
      ...data, editMode: true, date_log: data.date_contact, outcome: outcomeArray.map((x,i) => {
        const listValue = outcomeList.find(y => y.name == x);
        return {id: i + 1, outcome: listValue.vid, outcome_txt: ''}
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: escapeHtml(e.target.value),
    })
  }

  handleChange3 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = escapeHtml(e.target.value)
    this.setState({
      [name]: getStateVal
    })
  }

  handleChange4 = (contents) => {
    this.setState({
      comments: contents,
    })
  }

  handleChange5 = (e, name, id, field) => {
    var getStateVal = this.state[name]
    getStateVal[id][field] = e.target.value
    this.setState({
      [name]: getStateVal
    })
    //console.log(this.state.[name])
  }

  appendOutcome = (name, fields) => {
    var newInput = fields[fields.length-1]
    //console.log(newInput)
    var newInputID = newInput.id + 1
    //console.log(fields)
    this.setState(prevState => (
      { [name]: prevState[name].concat([{
          id: newInputID, outcome: 0, outcome_txt: '',
        }])
      }
    ))
  }

  removeInputSet1 = (name, id, fields) => {
    var getIndex = fields.findIndex(obj => obj.id === id)
    if (getIndex > 0) {
      fields.splice(getIndex, 1);
    }
    this.setState({ [name]: fields })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if(this.state.contact == 0 || this.state.type == 0 ||
      this.state.reason == 0 || this.state.outcome[0].outcome == 0) {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      var chk_outcome = true
      this.state.outcome.map(each => {
        if(each.outcome == 0) {
          chk_outcome = false
        }
      })
      if(!chk_outcome) {
        this.setState({
          message: 'Please select outcome',
        })
        window.scrollTo(0, 0)
      } else {
        this.setState({
          status_submit: false,
          message: '',
        })
        var pid = this.props.match.params.pid
        var appt_id = 0
        if(this.props.match.params.appt_id > 0) {
          appt_id = this.props.match.params.appt_id
        }
        axios({
          method: 'post',
          url: connectNode + 'admin/contact_log/add_contact_log',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            pid: pid,
            user: this.state,
            appt_id: appt_id,
            ct_id: this.props.match.params.ct_id
          }
        })
        .then((result) => {
          //console.log(result['data'])
          if(result['data'] > 0) {
            setTimeout(() => {
              this.props.onLoadLocation('/admin/patient_chart/'+pid, 'Saved successfully!')
              //window.location.href = '/admin/patient_chart/'+pid+'/1/2'
              this.props.history.push('/admin/patient_chart/'+pid+'/1/2')
            }, 900)
          }
        })
      }
    }
  }

  findLabelByValue = (arr, searchValue) => {
    var label = ''
    var result = arr.find(obj => {
      return obj.value == searchValue
    })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    const ReactQuill = this.quill
    return (
      <div className="admin_log_outcome box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Team Meeting Note
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>
            }
            <div className="box_border5">
              <form id="log_outcome" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                {(this.state.dataList.length == 0 || this.state.editMode) &&
                <div>
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                      <div className="small-12 medium-1 columns bold_txt3">Date:</div>
                      <div className="small-12 medium-3 columns no_padding_left_float_left">
                      <TextField id="date_log" label="" onChange={this.handleChange}
                        variant="outlined" type="date" defaultValue={this.state.date_log}
                        InputProps={{ inputProps: { name: "date_log" }}} />
                      </div>
                      <div className="small-12 medium-8 columns">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="small-12 columns bold">Note:</div>
                    </div>
                    <div className="row">
                      <div className="small-12 columns ">
                      <ReactQuill name="comments" modules={{toolbar: false}}
                        theme='snow' value={this.state.comments}
                        onChange={this.handleChange4}
                        style={{height: '300px'}} />
                      </div>
                    </div>
                  </div>
                </div>}
                </div>

                <div className="row top_space1">
                  <div className="small-12 medium-6 columns">
                    <a onClick={() => this.props.history.goBack()} className="go_back">Go Back</a>
                  </div>
                  <div className="small-12 medium-6 columns text_align_right">
                    <Button variant="contained" color="primary"
                     onClick={this.handleSubmit} type="submit">
                      Save Draft
                    </Button> &nbsp;
                    <Button variant="contained" color="primary"
                     onClick={this.handleSubmit} type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TeamNotes)
