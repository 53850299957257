export const contact = [
  {
    label: 'Patient',
    value: 1,
  }, {
    label: 'Admin staff',
    value: 2,
  },{
    label: 'Frontend Person',
    value: 12,
  }, {
    label: 'Patient Navigator',
    value: 11,
  }, {
    label: 'Clinical/billable staff',
    value: 3,
  }, {
    label: 'Clinical/non-billable staff',
    value: 4,
  }, {
    label: 'Support person from contract',
    value: 5,
  }, {
    label: 'Family/Friend',
    value: 6,
  }, {
    label: 'Outside provider',
    value: 7,
  }, {
    label: 'Peer Specialist',
    value: 8,
  },  {
    label: 'Scheduled appointment',
    value: 10,
  }, {
    label: 'Other',
    value: 9,
  },
]

export const type = [
  {
    label: 'Virtual',
    value: 1,
  }, {
    label: 'In person ',
    value: 2,
  }, {
    label: 'Phone ',
    value: 3,
  }, {
    label: 'Email',
    value: 4,
  }, {
    label: 'Text',
    value: 5,
  }, {
    label: 'Other',
    value: 6,
  },
]

/*
{
  label: 'Group therapy',
  value: 101,
}, {
  label: 'Individual therapy',
  value: 102,
}, {
  label: 'Side effects (New/Improved/Worsened)',
  value: 108,
}, {
  label: 'Symptoms (New/Improved/ Worsened)',
  value: 109,
}, {
  label: 'Neuropsychological evaluation',
  value: 103,
}, {
  label: 'Neuropsychological feedback',
  value: 104,
}, {
  label: 'Psychopharmacology consult',
  value: 105,
}, {
  label: 'Psychopharmacology ongoing',
  value: 106,
},
*/
export const reason = [
  {
    label: 'Resource specialist check-in',
    value: 107,
  }, {
    label: 'Urgent',
    value: 110,
  }, {
    label: 'Assessment questions',
    value: 201,
  }, {
    label: 'Intervention questions',
    value: 202,
  }, {
    label: 'No-show follow-up',
    value: 203,
  }, {
    label: 'Schedule/cancel/change appointment',
    value: 204,
  }, {
    label: 'Technical/access issue',
    value: 205,
  }, {
    label: 'OTHER (write in)',
    value: 301,
  },
]

export const outcome = [
  {
    label: 'Treatment plan modified',
    value: 1,
  }, {
    label: 'Patient contract modified',
    value: 2,
  }, {
    label: 'Skills acquisition/ongoing practice',
    value: 3,
  }, {
    label: 'Validation/Encouragement',
    value: 4,
  }, {
    label: 'Psychoeducation/Knowledge ',
    value: 5,
  }, {
    label: 'Problem solving implementation',
    value: 6,
  }, {
    label: 'Technical support',
    value: 7,
  }, {
    label: 'New intervention assigned',
    value: 8,
  }, {
    label: 'Self-guided intervention modified',
    value: 9,
  }, {
    label: 'Psychotherapy modified',
    value: 10,
  }, {
    label: 'Medication modified',
    value: 11,
  }, {
    label: 'Intervention other modified',
    value: 12,
  }, {
    label: 'Neuropsych feedback',
    value: 13,
  }, {
    label: 'Need to discuss with team',
    value: 14,
  }, {
    label: 'Sent information/resources/referral',
    value: 15,
  }, {
    label: 'Scheduled an individual appointment',
    value: 16,
  }, {
    label: 'Scheduled family/support meeting',
    value: 17,
  }, {
    label: 'Other',
    value: 18,
  },
]

export const adherence = [
  {
    label: 'Fit',
    value: 1,
  }, {
    label: 'Engagement',
    value: 2,
  }, {
    label: 'Knowledge',
    value: 3,
  }, {
    label: 'Implement',
    value: 4,
  },
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }
]
