import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import validator from 'validator'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import PatientHead3 from "../../../patient/patient_head3"
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { mrtf_type_change, yes_no, reason_change } from '../../variables/medication'
import { calculateTimepoint1 } from '../../../../functions/timepoint.js'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectLine } from '../../../../shared/select_line'
import DatePicker from "react-datepicker"

//import { makeStyles } from "@material-ui/core/styles";

class EditMTF extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          message: '',
          uid: 0,
          pid: 0,
          med_id: 0,
          med_name: '',
          med_other: '',
          med_category: '',
          med_dose: '',
          frequency: '',
          frequency_other: '',
          sig: '',
          curr_month: 0,
          medication_names: [],
          frequency_list: [],
          date: '',
          update_id: 0,
          currently_taking: 1,
          stop_date: '',
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/assessment/get_mtf_info',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
        med_id: this.props.match.params.med_id, }
    })
    .then((result) => {
      var mtf = result['data'][0].value
      var med = result['data'][1].value
      var freq = result['data'][2].value
      var med_other = ''
      var med_id = mtf.med_id
      if((mtf.med_category > 0 && mtf.med_category < 9) || (mtf.med_category > 0 && mtf.med_category > 10)) {
        med_id = mtf.medication_id
      } else if(mtf.med_category == 9 || mtf.med_category == 10) {
        med_id = 59
        med_other = mtf.med_other
      }
      this.setState({
        pid: this.props.match.params.pid,
        med_name: mtf.med_other,
        med_id: med_id,
        med_category: mtf.med_category,
        med_other: med_other,
        med_dose: mtf.y_dose,
        frequency: mtf.frequency,
        frequency_other: mtf.frequency_other,
        sig: mtf.sig,
        curr_month: 0,
        medication_names: med,
        frequency_list: freq,
        date: mtf.conv_date1 + mtf.conv_date2 + mtf.conv_date3,
        currently_taking: mtf.currently_taking,
        stop_date: mtf.stop_date,
      })
    })
  }

  checkNull = (txt) => {
    if(txt === 'null') {
      return ''
    }
    return txt
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: this.checkNull(escapeHtml(e.target.value)),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleNumber = (e, name) => {
    if(!validator.isFloat(e.target.value)) {
      this.setState({
        [name]: '',
      })
    } else {
      this.setState({
        [name]: e.target.value
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.med_name === '' || this.state.med_dose === '' || this.state.med_dose == 0) {
      this.setState({
        status: false,
        message: 'Please fill all answers',
      })
      window.scrollTo(0, 0)
    } else {
        const med = this.state.medication_names.find(x => x.id === this.state.med_name);
        //const medCategory = med.med_category;
        axios({
          method: 'post',
          url: connectNode + 'admin/patient/edit_medication_mtf',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            mhm_id: this.props.match.params.med_id,
            uid: this.props.authReducer.uid,
            pid: this.props.match.params.pid,
            med_id: this.state.med_id,
            med_category: this.state.med_category,
            med_name: this.state.med_other, //medCategory === 9 || medCategory === 10 ? this.state.med_other : med.med_name,
            med_dose: this.state.med_dose,
            frequency: this.state.frequency,
            frequency_other: this.state.frequency_other,
            sig: this.state.sig,
            date: this.state.date,
            currently_taking: this.state.currently_taking,
            stop_date: this.state.stop_date,
          }
        })
        .then((result) => {
            if(result['data'] == this.props.match.params.med_id) {
              this.setState({
                message: 'Update medication successfully!',
                status: true,
              })
              //this.props.onLoadLocation('patient_chart', 'Update medication successfully!')
              //this.props.history.push('/admin/patient_chart/'+this.props.match.params.pid+'/4/1')
            } else {
              this.setState({
                message: 'Something went wrong. Please try again.',
                status: false,
              })
            }
          }
        )


    }
  }

  addZero = (number) => {
    var txt = number
    if(number < 10)  {
      txt = '0'+number
    }
    return txt
  }

  handleUpdateStatus = () => {
    document.getElementById('warning_box').style.display = 'block'
    this.setState({ update_id: this.props.match.params.med_id })
  }

  confirmDelete = (e) => {
    var pid = this.props.match.params.pid
    var med_id =
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/delete_medication',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: pid,
        med_id: this.state.update_id,
      }
    })
    .then((result) => {
      //console.log(result)
        if(result['data'] === this.state.update_id) {
          this.props.onLoadLocation('/admin/patient_chart/'+pid+'/4/1', 'Medication deleted!')
          this.props.history.push('/admin/patient_chart/'+pid+'/4/1')
        }
      }
    )
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  render() {
    var today = new Date()
    const medicationNamesMap = this.state.medication_names.map(each => ({id: each.id, name: each.med_name}));
    const frequencyMap = this.state.frequency_list.map(each => ({id: each.vid, name: each.name}));
    const medCategory = this.state.medication_names.find(x => x.id === this.state.med_name)?.med_category;
    return (
      <div className="admin_log_to_do box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            MTF [Edit]
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
            <form id="make_appointment" onSubmit={this.handleSubmit}>
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  { this.state.message !== '' && this.state.status === false &&
                  <div className="small-12 columns warning_msg6">
                      <div className="float_left1"><WarningIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}
                  { this.state.message !== '' && this.state.status === true &&
                    <div className="small-12 columns warning_msg8">
                      <div className="float_left1"><CheckCircleOutlineIcon /></div>
                      <div className="txt_warning_msg1">{ this.state.message }</div>
                  </div>}

                  <div className="row ">
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right">Date: </div>
                    <div className="small-12 medium-8 columns float_left">
                    {this.state.date}
                    </div>
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right top_space1">
                    Medication Name:</div>
                    <div className="small-12 medium-8 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.med_id} variant="outlined" className="short3"
                        name="med_name" defaultValue={this.state.med_id}
                        label="" onChange={(e) => this.handleChange2(e, 'med_id')}>
                        {medicationNamesMap.map(each =>
                          <MenuItem key={'m'+each.id} value={each.id}>{each.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>

                    {(this.state.med_id === 59) &&
                    <>
                      <div className="small-12 medium-4 columns bold_right top_space1"></div>
                      <div className="small-12 medium-8 columns float_left top_space1">
                      <TextField label="" onChange={this.handleChange} name="med_other"
                        className="short3" variant="outlined" type="text"
                        value={this.state.med_other}
                        InputProps={{ inputProps: { name: "med_other", placeholder: 'Medication name' }}} />
                      </div>
                    </>
                   }
                  </div>
                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right top_space1">Total Daily Dose: </div>
                    <div className="small-12 medium-8 columns float_left">
                    <TextField label="" onChange={(e) => this.handleNumber(e, 'med_dose')}
                      name="med_dose" className="short2" variant="outlined"
                      value={this.state.med_dose}
                      InputProps={{ inputProps: { name: "med_dose" }}} />
                      <span className="top_space3">mg</span>
                    </div>
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns bold_right"
                    style={{paddingTop: "0.8rem"}}>Frequency:</div>
                    <div className="small-12 medium-8 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.frequency} variant="outlined" className="short3"
                        name="med_frequencyname" defaultValue={this.state.frequency}
                        label="" onChange={(e) => this.handleChange2(e, 'frequency')}>
                        {frequencyMap.map(each =>
                          <MenuItem key={'m'+each.id} value={each.id}>{each.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    </div>
                  </div>

                  {(this.state.frequency === 12) &&
                    <>
                      <div className="small-12 medium-4 columns bold_right top_space1"></div>
                      <div className="small-12 medium-8 columns float_left top_space1"
                      style={{paddingLeft: "1.1rem"}}>
                      <TextField label="" onChange={this.handleChange} name="frequency_other"
                        className="short3" variant="outlined" type="text"
                        value={this.state.frequency_other}
                        InputProps={{ inputProps: { name: "frequency_other", maxLength: 200 }}} />
                      </div>
                    </>
                   }

                   <div className="small-12 medium-4 columns bold_right top_space1">Currently taking:</div>
                   <div className="small-12 medium-8 columns float_left top_space1" style={{paddingLeft: "1.1rem"}}>
                     <input type="radio" name="currently_taking" value="1"
                     checked={this.state.currently_taking == 1 && 'checked'}
                     onChange={(e) => this.handleChange2(e, 'currently_taking')}/> Yes<br />
                     <input type="radio" name="currently_taking" value="2"
                     checked={this.state.currently_taking == 2 && 'checked'}
                     onChange={(e) => this.handleChange2(e, 'currently_taking')}/> No
                   </div>

                   {this.state.currently_taking == 2 && <>
                   <div className="small-12 medium-4 columns bold_right top_space1">When did you stop taking?:</div>
                   <div className="small-12 medium-8 columns float_left top_space1" style={{paddingLeft: "1.1rem"}}>
                    <TextField label="" type="date" variant="outlined" className="mtf_stop_date" value={this.state.stop_date}
                   onChange={(e) => this.handleChange2(e, 'stop_date')} />
                   </div></>}

                  <div className="small-12 medium-4 columns bold_right top_space1">Sig:</div>
                  <div className="small-12 medium-8 columns float_left top_space1" style={{paddingLeft: "1.1rem"}}>
                      <TextField onChange={this.handleChange} name="sig"
                        className="short3" variant="outlined" type="text"
                        value={this.state.sig}
                        InputProps={{ inputProps: { name: "sig", maxLength: 200 }}} />
                  </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-4 columns">&nbsp;</div>
                    <div className="small-12 medium-8 columns">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={this.handleSubmit} type="submit">
                        Submit
                      </Button>
                      <Button variant="contained" color="primary" disableElevation
                       onClick={()=>this.handleUpdateStatus()} type="button" className="paddingLeft1">
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
      </div>
      <div className="warning_box" id="warning_box">
        <div className="row">
          <div className="small-12 columns">
          <WarningIcon /> Are you sure you want to delete this medication?
          </div>
          <div className="small-12 columns margin_top_space1">
          <Button variant="contained" color="primary" className="margin_right_1" disableElevation
           onClick={()=>this.confirmDelete(this.state.update_id)} type="button">Yes</Button>
         <Button variant="contained" color="primary" disableElevation
          onClick={this.closeWarningBox} type="button">No</Button>
          </div>
        </div>
      </div>
    </div>)}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditMTF)
