import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form1.css'
import { loadLocationWithMessage } from '../../../actions/location'

import '../../../styles/assets/css/pages/stepper1.css'
import { registration_steps } from '../variables/registration.js'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class ClinicPolicies extends Component {
  constructor() {
      super()
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          uid: 0,
          accept: 0,
      }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange7 = (e) => {
    var val = 0
    if (e.target.checked) {
      val = 1
    }
    this.setState({
      [e.target.name]: val,
    })
  }

  componentDidMount() {
    this.getRegistraionProcess()
  }

  getRegistraionProcess = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/registration/get_registration_process',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
        if(result['data'].reg_step !== 'clinic_policies') {
          this.props.history.push('/member/'+result['data'].reg_step)
        }
      }
    )
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if(this.state.accept === 0) {
      this.setState({
        message: 'Please click on the Accept box below',
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'patient/registration/update_registration_process',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          end_step: 'clinic_policies_end',
          start_step: 'patient_waiver_start',
          next_step: 'patient_waiver',
        }
      })
      .then((result) => {
          if(result['data'] === 'patient_waiver') {
            this.props.onLoadLocation('/member/patient_waiver')
            this.props.history.push('/member/patient_waiver')
          }

        }
      )
    }
  }

  render() {

    return (
      <div className="clinic_policies box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Clinic Policies
          </div>
          <div className="small-12 columns stepper">
            <Stepper alternativeLabel nonLinear activeStep={1}>
              {registration_steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        <div className="row">
          <div className="small-12 columns box1">
            <div className="">
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="small-12 columns">
                    <p className="bold">Office Hours</p>
                    <p>The FITT-BD Clinic is open Monday through Friday from 9:00 am to 5:00 pm. We are closed on most major holidays. Our phone number is (617) 726-6928. If you leave a message on the phone or refill prescription line when the office is closed, we will not get the message until the next business day.</p>

                    <p className="bold">Cancellations</p>
                    <p>We require 24 hours notice if you need to cancel or reschedule an appointment.</p>

                    <p className="bold">Prescription Refills</p>
                    <p>If your prescribing physician is part of the FITT-BD clinic or the Dauten Center, you may call our prescription refill line at (617) 643-6586 and request a refill. Please state your name, medical record number or date of birth, your doctor's name, and the medications you need, as well as the name and phone number of your pharmacy. Please remember that it can take up to two business days for our staff to call in your prescription.</p>

                    <p className="bold">In Case of Emergencies</p>
                    <p>Please call 911 or go to your nearest emergency room.</p>

                    <p className="no_bottom_space bold">Please indicate that you have read and understand by clicking on the Accept box below:</p>

                    <p>
                    <input type="checkbox" value="1" name="accept"
                      onChange={(e) => this.handleChange7(e)} />
                      Accept</p>
                  </div>
                </div>
            </form>
          </div>
        </div>
        <div className="small-12 columns align_right">
        {this.state.status_submit &&
          <Button variant="contained" color="primary" disableElevation
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        {!this.state.status_submit &&
          <Button variant="contained" disabled
             onClick={this.handleSubmit} type="submit" className="submit_1">
              Submit
          </Button>}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ClinicPolicies)
