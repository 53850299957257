import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../../../utils/global'
import { escapeHtml } from '../../../../../utils/textFunctions'
import '../../../../../styles/assets/css/admin/form1.css'
import { loadLocationWithMessage } from '../../../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { ans_yesno } from '../../../variables/patient.js'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
//import { makeStyles } from "@material-ui/core/styles";

class ContactDetermination extends Component {
  constructor() {
      super();
      this.state = {
          status: 0,
          submit_status: 0,
          message: '',
          uid: 0,
          pid: 0,
          determine_bipolar: 0,
          screen_q1: 0,
          screen_q2: 0,
          screen_q3a: 0,
          screen_q3b: 0,
          screen_q3c: 0,
          screen_q3d: 0,
          screen_q3e: 0,
          screen_q3f: 0,
          screen_q3g: 0,
          screen_q3h: 0,
          screen_q3i: 0,
      }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_edit/get_determine_bipolar',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] !== 0) {
        this.setState({
          determine_bipolar: result['data'].determine_bipolar,
          determine_by: result['data'].determine_by,
          screen_q1: result['data'].q1,
          screen_q2: result['data'].q2,
          screen_q3a: result['data'].q3a,
          screen_q3b: result['data'].q3b,
          screen_q3c: result['data'].q3c,
          screen_q3d: result['data'].q3d,
          screen_q3e: result['data'].q3e,
          screen_q3f: result['data'].q3f,
          screen_q3g: result['data'].q3g,
          screen_q3h: result['data'].q3h,
          screen_q3i: result['data'].q3i,
          p_created: result['data'].p_created,
        })
      }
    })
  }

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleOpenWarningBox = (e) => {
    //console.log(this.state.determine_bipolar)
    if(this.state.determine_bipolar == null) {
      this.setState({
        message: 'Please select the answer',
        status: 2,
      })
      window.scrollTo(0, 0)
    } else {
      document.getElementById('warning_box').style.display = 'block'
      window.scrollTo(0, 0)
    }
  }

  closeWarningBox = (e) => {
    document.getElementById('warning_box').style.display = 'none'
  }

  handleSubmitUpdateBD = (e) => {
    e.preventDefault()
    if(this.state.determine_bipolar == 0 || this.state.screen_q1 == 0 ||
      this.state.screen_q2 == 0 || this.state.screen_q3 == 0 ||
      this.state.screen_q3a == 0 || this.state.screen_q3b == 0 ||
      this.state.screen_q3c == 0 || this.state.screen_q3d == 0 ||
      this.state.screen_q3e == 0 || this.state.screen_q3f == 0 ||
      this.state.screen_q3g == 0 || this.state.screen_q3h == 0 ||
      this.state.screen_q3i == 0) {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      axios({
        method: 'post',
        url: connectNode + 'admin/patient_edit/update_determine_bipolar',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          determine_bipolar: this.state.determine_bipolar,
          user: this.state,
        }
      })
      .then((result) => {
          //console.log('Callback: '+result['data'])
          if(result['data'] === 1) {
            this.setState({
              message: 'updated successfully!',
              status: 1,
              submit_status: 1,
            })
            this.closeWarningBox()
          } else {
            this.setState({
              message: 'Something went wrong. Please try again.',
              status: 2,
            })
          }
        }
      )
    }
  }

  render() {
    return (
    <div className="admin_make_appointment">
      <div className="row">
        <div className="small-12 columns ">
          { this.state.message !== '' &&
            <div className={ (this.state.status === 1 && 'warning_msg8') || this.state.status === 2 && 'warning_msg6'} >
              <div className="float_left1">
              { this.state.status === 1 && <CheckCircleOutlineIcon />}
              { this.state.status === 2 && <WarningIcon />}
              </div>
              <div className="txt_warning_msg1">{ this.state.message }</div>
            </div>
          }
          <div className="row">
            <div className="small-12 columns title4">
              Bipolar Screening Questions
            </div>
          </div>
          <div className="box_border5">
            <div className="row">
              <div className="small-12 columns">
                {this.state.p_created > 1646674635 &&
                <div className="row">
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns bold">
                    I will be going through a few standard questions with you to better understand what you're going through and to see if our studies may be beneficial to you.
                  </div>
                  <div className="small-12 columns top_space1">
                    <div className="sep_line1">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                  1. Some people have periods lasting several days or longer when
                  they feel much more excited and full of energy than usual.
                  Their minds go too fast. They talk a lot. They are very restless
                  or unable to sit still. And they sometime do things that are
                  unusual for them, such as driving too fast or spending too much
                  money. Have you ever had a period like this lasting several days
                  or longer?
                  </div>
                  <div className="small-12 columns top_space1">
                  {ans_yesno.map(each =>
                    <div className="small-12 medium-6 columns " key={'q1'+each.label}>
                      <input type="radio" name="screen_q1" value={each.value}
                      checked={this.state.screen_q1 == each.value}
                      onChange={(e) => this.handleChange(e, 'screen_q1')}/>
                      <span>{each.label}</span>
                    </div>)}
                  </div>
                  <div className="small-12 columns top_space1">
                    <div className="sep_line1">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                  2. Have you ever had a period lasting several days or longer
                  when most of the time you were so irritable or grouchy that you
                  started arguments, shouted at people, or hit people?
                  </div>
                  <div className="small-12 columns top_space1">
                  {ans_yesno.map(each =>
                    <div className="small-12 medium-6 columns " key={'q2'+each.label}>
                      <input type="radio" name="screen_q2" value={each.value}
                      checked={this.state.screen_q2 == each.value}
                      onChange={(e) => this.handleChange(e, 'screen_q2')}/>
                      <span>{each.label}</span>
                    </div>)}
                  </div>
                  <div className="small-12 columns top_space1">
                    <div className="sep_line1">&nbsp;</div>
                  </div>
                  <div className="small-12 columns bold">
                    3. Think of an episode when you had the largest number of changes like these at the same time. During that episode...
                  </div>
                  <div className="small-12 columns">
                    <div className="row small-12 columns bg1 top_space1">
                      <div className="small-12 columns">
                        3a. Were you so irritable that you started arguments, shouted at people, or hit people?
                      </div>
                      <div className="small-12 columns top_space1">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3a'+each.label}>
                          <input type="radio" name="screen_q3a" value={each.value}
                          checked={this.state.screen_q3a == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3a')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3b. Did you become so restless or fidgety that you paced up and down or couldn't stand still?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3b'+each.label}>
                          <input type="radio" name="screen_q3b" value={each.value}
                          checked={this.state.screen_q3b == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3b')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3c. Did you do anything else that wasn't usual for you -- like
                        talking about things that you would normally keep private,
                        or acting in ways that you'd usually find embarrassing?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3c'+each.label}>
                          <input type="radio" name="screen_q3c" value={each.value}
                          checked={this.state.screen_q3c == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3c')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3d. Did you try to do things that were impossible to do,
                        like taking on large amounts of work?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3d'+each.label}>
                          <input type="radio" name="screen_q3d" value={each.value}
                          checked={this.state.screen_q3d == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3d')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3e. Did you constantly keep changing your plans or activities?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3e'+each.label}>
                          <input type="radio" name="screen_q3e" value={each.value}
                          checked={this.state.screen_q3e == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3e')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3f. Did you find it hard to keep your mind on what you were doing?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3f'+each.label}>
                          <input type="radio" name="screen_q3f" value={each.value}
                          checked={this.state.screen_q3f == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3f')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3g. Did your thoughts seem to jump from one thing to another
                        or race through your head so fast you couldn't keep track of them?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3g'+each.label}>
                          <input type="radio" name="screen_q3g" value={each.value}
                          checked={this.state.screen_q3g == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3g')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3h. Did you sleep far less than usual and still not get tired or sleepy?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3h'+each.label}>
                          <input type="radio" name="screen_q3h" value={each.value}
                          checked={this.state.screen_q3h == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3h')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        3i. Did you spend so much more money than usual that it caused you to have financial trouble?
                      </div>
                      <div className="small-12 columns">
                      {ans_yesno.map(each =>
                        <div className="small-12 medium-6 columns " key={'q3i'+each.label}>
                          <input type="radio" name="screen_q3i" value={each.value}
                          checked={this.state.screen_q3i == each.value}
                          onChange={(e) => this.handleChange(e, 'screen_q3i')}/>
                          <span>{each.label}</span>
                        </div>)}
                      </div>
                      <div className="small-12 columns">
                        <div className="sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        Total number of "Yes" responses. If 5 or more, it is possible they may have bipolar disorder. However, please continue with screen to assess for other mood symptoms.
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="row">
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>
                  <div className="small-12 columns">
                    <div className="small-12 columns bold">
                    Patient  is likely to have bipolar disorder:
                    </div>
                  </div>
                  <div className="small-12 columns float_left top_space1">
                    {ans_yesno.map(each =>
                      <div className="small-12 medium-6 columns " key={'tb'+each.label}>
                        <input type="radio" name="determine_bipolar" value={each.value}
                        checked={this.state.determine_bipolar == each.value}
                        onChange={(e) => this.handleChange(e, 'determine_bipolar')}/>
                        <span>{each.label}</span>
                      </div>)}
                  </div>
                  {(this.state.determine_by == null && this.state.submit_status === 0) &&
                  <div className="small-12 columns top_space1">
                    <div className="small-12 columns top_space1">
                      <Button variant="contained" color="primary" disableElevation
                       onClick={(e) => this.handleOpenWarningBox(e)} type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="warning_box" id="warning_box">
        <div className="row">
          <div className="small-12 columns">
          <WarningIcon /> Are you sure you want update this information?
          </div>
          <div className="small-12 columns margin_top_space1">
          <Button variant="contained" color="primary" className="margin_right_1" disableElevation
           onClick={(e) => this.handleSubmitUpdateBD(e)} type="button">Yes</Button>
         <Button variant="contained" color="primary" disableElevation
          onClick={(e) => this.closeWarningBox(e)} type="button">No</Button>
          </div>
        </div>
      </div>
    </div>
  )}
}

const mapStateToProps = (state) => {
  //console.log('AddPatient STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ContactDetermination)
