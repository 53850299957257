import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { calculateTimepoint1 } from '../../functions/timepoint.js'
import moment from 'moment'
import '../../../styles/assets/css/pages/dashboard.css'
import { NavLink } from "react-router-dom"
import { IoIosSquare } from 'react-icons/io'
import { RiPencilFill } from 'react-icons/ri'
import CheckIcon from '@material-ui/icons/Check'
import TerrainIcon from '@material-ui/icons/Terrain'
import ReactTooltip from 'react-tooltip'
//import TerrainIcon from '@mui/icons-material/Terrain'

class Badges extends Component {
  constructor() {
    super()
    this.state = {
      num_daily: 0,
      num_weekly: 0,
      num_who5: 0,
      num_monthly: 0,
      num_mtf: 0,
      new_badge_daily: 0,
      new_badge_weekly: 0,
      new_badge_who5: 0,
      new_badge_monthly: 0,
      new_badge_mtf: 0,
      num_cnt_daily: 0,
      num_cnt_weekly: 0,
      num_cnt_who5: 0,
      num_cnt_monthly: 0,
      num_cnt_mtf: 0,
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_badges_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { uid: this.props.authReducer.uid }
    })
    .then((result) => {
      //console.log(result['data'])
      this.setState({
        num_daily: result['data'][0][0],
        num_weekly: result['data'][1][0],
        num_who5: result['data'][2][0],
        num_monthly: result['data'][3][0],
        num_mtf: result['data'][4][0],
        new_badge_daily: result['data'][0][1],
        new_badge_weekly: result['data'][1][1],
        new_badge_who5: result['data'][2][1],
        new_badge_monthly: result['data'][3][1],
        new_badge_mtf: result['data'][4][1],
        num_cnt_daily: result['data'][0][2],
        num_cnt_weekly: result['data'][1][2],
        num_cnt_who5: result['data'][2][2],
        num_cnt_monthly: result['data'][3][2],
        num_cnt_mtf: result['data'][4][2],
      })
    })
  }

  render() {
    console.log(this.state)
    return (
      <div className="box_border box_space_top2 badges_box2">
        <div className="row">
          <div className="small-8 columns head2">
            Badges
          </div>
          {/* First Row */}
          <div className="small-12 columns">
          {/* Daily tracker */}
          {this.state.num_daily >= 1 &&
          <div class="each_badge"><NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges1'>
            <img src={((this.state.num_daily == 1 && this.state.new_badge_daily != 1) && "./badges/daily_tracker_badge.png")
            || ((this.state.num_daily == 1 && this.state.new_badge_daily == 1) && "./badges/daily_tracker_bg.png")
            || ((this.state.num_daily > 1 && this.state.new_badge_daily != 1) && "./badges/daily_tracker_tag.png")
            || ((this.state.num_daily > 1 && this.state.new_badge_daily == 1) && "./badges/daily_tracker_tag_bg.png")}
            className="badges1" />
            {this.state.num_daily > 1 && <div className="number_badges">{this.state.num_daily}</div>}
          </NavLink>
          {this.state.num_cnt_daily > 0 &&
          <ReactTooltip id='badges1' type='warning' effect='solid'>
            <span>{this.state.num_cnt_daily} daily trackers this week</span>
          </ReactTooltip>}
          </div>}
          {/* Weekly tracker */}
          {this.state.num_weekly >= 1 &&
          <div class="each_badge"><NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges2'>
            <img src={((this.state.num_weekly == 1 && this.state.new_badge_weekly <= 7) && "./badges/DBSA_bg.png")
            || ((this.state.num_weekly == 1 && this.state.new_badge_weekly > 7) && "./badges/DBSA_badge.png")
            || ((this.state.num_weekly > 1 && this.state.new_badge_weekly <= 7) && "./badges/DBSA_tag_bg.png")
            || ((this.state.num_weekly > 1 && this.state.new_badge_weekly > 7) && "./badges/DBSA_tag.png")} className="badges1" />
            {this.state.num_weekly > 1 && <div className="number_badges">{this.state.num_weekly}</div>}
          </NavLink>
          {this.state.num_cnt_weekly > 0 &&
          <ReactTooltip id='badges2' type='warning' effect='solid'>
            <span>{this.state.num_cnt_weekly} weekly trackers this month</span>
          </ReactTooltip>}
          </div>}
          {/* Who-5 tracker */}
          {this.state.num_who5 >= 1 &&
          <div class="each_badge"><NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges3'>
            <img src={((this.state.num_who5 == 1 && this.state.new_badge_who5 <= 7) && "./badges/who5_badge_bg.png")
            || ((this.state.num_who5 == 1 && this.state.new_badge_who5 > 7) && "./badges/who5_badge.png")
            || ((this.state.num_who5 > 1 && this.state.new_badge_who5 <= 7) && "./badges/who5_tag_bg.png")
            || ((this.state.num_who5 > 1 && this.state.new_badge_who5 > 7) && "./badges/who5_tag.png")} className="badges1" />
            {this.state.num_who5 > 1 && <div className="number_badges">{this.state.num_who5}</div>}
          </NavLink>
          {this.state.num_cnt_who5 > 0 &&
          <ReactTooltip id='badges3' type='warning' effect='solid'>
            <span>{this.state.num_cnt_who5} Who-5 this month</span>
          </ReactTooltip>}
          </div>}
          {/* Monthly tracker */}
          {this.state.num_monthly >= 1 &&
          <div class="each_badge"><NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges4'>
            <img src={((this.state.num_monthly == 1 && this.state.new_badge_monthly <= 7) && "./badges/monthly_assessment_badge_bg.png")
            || ((this.state.num_monthly == 1 && this.state.new_badge_monthly > 7) && "./badges/monthly_assessment_badge.png")
            || ((this.state.num_monthly > 1 && this.state.new_badge_monthly <= 7) && "./badges/monthly_assessment_tag_bg.png")
            || ((this.state.num_monthly > 1 && this.state.new_badge_monthly > 7) && "./badges/monthly_assessment_tag.png")} className="badges1" />
            {this.state.num_monthly > 1 && <div className="number_badges">{this.state.num_monthly}</div>}
          </NavLink>
          {this.state.num_cnt_monthly > 0 &&
          <ReactTooltip id='badges4' type='warning' effect='solid'>
            <span>Completed monthly assessment</span>
          </ReactTooltip>}
          </div>}
          {/* MTF */}
          {this.state.num_mtf >= 1 &&
          <div class="each_badge"><NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges5'>
            <img src={(this.state.num_monthly == 1 && "./badges/MTF_badge.png")
            || (this.state.num_monthly > 1 && "./badges/MTF_tag.png")} className="badges1" />
            {this.state.num_monthly && <div className="number_badges">{this.state.num_monthly}</div>}
          </NavLink>
          {this.state.num_cnt_mtf > 0 &&
          <ReactTooltip id='badges5' type='warning' effect='solid'>
            <span>Completed MTF monthly assessment</span>
          </ReactTooltip>}
          </div>}
          </div>
          {/* Second Row */}
          {false &&
          <div className="small-12 columns">
          {/* To to list */}
          <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges6'>
            <img src="./badges/todo_badge.png" className="badges1" />
          </NavLink>
          <ReactTooltip id='badges6' type='warning' effect='solid'>
            <span>To do list</span>
          </ReactTooltip>
          {/* Sleep */}
          <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges7'>
            <img src="./badges/sleep_badge.png" className="badges1" />
          </NavLink>
          <ReactTooltip id='badges7' type='warning' effect='solid'>
            <span>Sleep</span>
          </ReactTooltip>
          {/* Exercise */}
          <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges8'>
            <img src="./badges/fitness_badge.png" className="badges1" />
          </NavLink>
          <ReactTooltip id='badges8' type='warning' effect='solid'>
            <span>Exercise</span>
          </ReactTooltip>
          {/* Nutrition */}
          <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges9'>
            <img src="./badges/nutrition_badge.png" className="badges1" />
          </NavLink>
          <ReactTooltip id='badges9' type='warning' effect='solid'>
            <span>Nutrition</span>
          </ReactTooltip>
          {/* Book */}
          <NavLink to="#" onClick={e => e.preventDefault()} data-tip data-for='badges10'>
            <img src="./badges/bookworm_badge.png" className="badges1 new_badge" />
          </NavLink>
          <ReactTooltip id='badges10' type='warning' effect='solid'>
            <span>Book</span>
          </ReactTooltip>
          </div>}
          {(( this.state.new_badge_daily + this.state.new_badge_weekly + this.state.new_badge_who5
            + this.state.new_badge_monthly + this.state.new_badge_mtf) >= 0 ) &&
          <div className="small-12 columns">
          Congratulations! You've earned another badge.
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Badges)
