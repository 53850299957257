export const registration_steps = [
  'Overview',
  'Clinic Policies',
  'Patient Waiver',
  'Communication Preferences',
  'Treatment Consent',
  'Data Repository', //'Information Sheet',
  'Release of Information',
  'General Information',
  'Medical History',
  'Psychiatric History'
]

export const yes_no = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }
]

export const yes_no_notsure = [
  {
    label: 'Yes',
    value: 1,
  }, {
    label: 'No',
    value: 2,
  }, {
    label: 'Not sure',
    value: 3,
  }
]

export const primary_interest = [
  {
    label: 'Consultation',
    value: 1,
  }, {
    label: 'Diagnosis',
    value: 2,
  }, {
    label: 'Find an individual therapist',
    value: 3,
  }, {
    label: 'Treatment for bipolar 	disorder',
    value: 4,
  }, {
    label: 'Treatment for something other than bipolar disorder (such as substance 	abuse, eating disorder, or OCD)',
    value: 5,
  }
]

export const gen_m = [
  {
    label: 'January',
    value: 1,
  }, {
    label: 'February',
    value: 2,
  }, {
    label: 'March',
    value: 3,
  }, {
    label: 'April',
    value: 4,
  }, {
    label: 'May',
    value: 5,
  }, {
    label: 'June',
    value: 6,
  }, {
    label: 'July',
    value: 7,
  }, {
    label: 'August',
    value: 8,
  }, {
    label: 'September',
    value: 9,
  }, {
    label: 'October',
    value: 10,
  }, {
    label: 'November',
    value: 11,
  }, {
    label: 'December',
    value: 12,
  },
]
