export const cobra_questions = [
  {
    label: 'Do you have difficulties remembering people\'s names?',
    value: 1,
  }, {
    label: 'Do you have difficulties finding objects of daily use (keys, glasses, wristwatch...)?',
    value: 2,
  }, {
    label: 'Do you find it difficult to remember situations that were important to you?',
    value: 3,
  }, {
    label: 'Is it hard for you to place important events in time?',
    value: 4,
  }, {
    label: 'Do you find it hard to concentrate when reading a book or a newspaper?',
    value: 5,
  }, {
    label: 'Do you have problems recalling what you have read or have been told recently?',
    value: 6,
  }, {
    label: 'Do you have the feeling that you do not finish what you begin?',
    value: 7,
  }, {
    label: 'Does it take you longer than normal to complete your daily tasks?',
    value: 8,
  }, {
    label: 'Have you ever felt disoriented in the street?',
    value: 9,
  }, {
    label: 'When people remind you of a conversation or a comment you heard, do you get the impression that it is the first time you hear it?',
    value: 10,
  }, {
    label: 'Is it sometimes difficult for you to find the words to express your ideas?',
    value: 11,
  }, {
    label: 'Are you easily distracted?',
    value: 12,
  }, {
    label: 'Do you find it hard to do simple mental calculations?',
    value: 13,
  }, {
    label: 'Do you get the impression that you cannot follow a conversation?',
    value: 14,
  }, {
    label: 'Have you noticed that you find it difficult to learn new information?',
    value: 15,
  }, {
    label: 'Do you struggle to keep focused on a particular task for a long time?',
    value: 16,
  },
]

export const cobra_1 = [
  {
    label: 'Never',
    value: 0,
  }, {
    label: 'Sometimes',
    value: 1,
  }, {
    label: 'Often',
    value: 2,
  }, {
    label: 'Always',
    value: 3,
  },
]
