import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/admin/form3.css'
import { loadLocationWithMessage } from '../../../actions/location'

import WarningIcon from '@material-ui/icons/Warning'
/*import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'*/

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import moment from 'moment'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import TimePicker from "rc-time-picker"
import 'rc-time-picker/assets/index.css'
//import { makeStyles } from "@material-ui/core/styles";
import PatientHead3 from "../patient/patient_head3"
import { ans_yesno, appt_type, appt_sub_type, appt_sub_type2, appt_visit, frequency,
  frequency_time } from '../variables/patient.js'
import { ListSubheader } from '@material-ui/core'

class MakeAppointment extends Component {
  constructor() {
      super();
      this.state = {
          status: false,
          status_submit: true,
          message: '',
          //message_open: false,
          clinician_list: [],
          clinician: 0,
          clinician_other: '',
          clinician_initial: '',
          epic_type: 0,
          sub_type: 0,
          frequency: 1,
          recurrent: 0,
          recurrent_end_date: '',
          visit: 0,
          date: '',
          time: '',
          duration: 0,
          billable: 0,
          appt_epic_list: [],
          appt_cat_list: [],
          type: 0,
          reason: 0,
          reason_other: '',
          reason_list: [],
      }
  }

  componentDidMount() {
    this.getPatientStatus()
  }

  get_outcome_and_reasons = (editMode = false) => {
    axios({
      method: 'post',
      url: connectNode + 'functions/patient/get_dd_variable2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: { v_type: 8, }
    })
    .then((result) => {
      this.setState({
        reason_list: result['data']
      })
    })
  }

  getPatientStatus = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/chk_appt_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid
      }
    })
    .then((result) => {
        if(result['data'] === 0) {
          this.setState({
            appt_epic_list: appt_type,
            appt_cat_list: [{
              label: 'Intake Appointment',
              value: 1,
            }],
            type: 1,
          })
        } else {
          var get_sub_type = appt_sub_type2
          if(result['data']) {
            //console.log(result['data'])
            result['data'].map(each => {
              if(each.status == 1 && each.sub_type == 1) {
                get_sub_type = appt_sub_type
              }
            })
          }

          //var splice_appt_sub_type = appt_sub_type.splice(0, 1)

          if(this.props.authReducer.sub_role == 21) {
            get_sub_type = [{
              label: 'Peer Support',
              value: 14,
            }]
          }
          this.setState({
            appt_epic_list: appt_type,
            appt_cat_list: get_sub_type,
            type: 2,
          })
        }
      }
    )
    // get clinician list //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_clinician_list2',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
    }).then((result) => {
      if(result['data'].length > 0) {
        this.setState({
          clinician_list: result['data']
        })
      }
    })
    // get last Appointment //
    axios({
      method: 'post',
      url: connectNode + 'admin/patient/get_last_appointment',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid
      }
    }).then((result) => {
      if(result['data']) {
        this.setState({
          clinician: result['data'].cid,
          clinician_other: result['data'].c_other_name,
          clinician_initial: result['data'].c_other_initial,
          epic_type: result['data'].type,
          sub_type: result['data'].sub_type,
          frequency: result['data'].frequency,
          recurrent: result['data'].recurrent,
          recurrent_end_date: result['data'].recurrent_end_date,
          visit: result['data'].visit,
          //date: result['data'].appt_date,
          //time: result['data'].appt_time,
          duration: result['data'].duration,
          billable: result['data'].billable,
        })
      }
    })
    // get reason //
    this.get_outcome_and_reasons(false)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: escapeHtml(e.target.value),
    })
  }

  handleChange2 = (e, name) => {
    this.setState({
      [name]: e.target.value
    })
  }

  handleChange3 = (e, name) => {
    if(e.target.value == 6) {
      this.setState({
        [name]: e.target.value,
        clinician_list: [{
          fullname: 'Amy Peters',
          uid: 5,
          sub_role: 1,
        }],
      })

    } else {
      // get clinician list //
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/get_clinician_list2',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
      }).then((result) => {
        if(result['data'].length > 0) {
          this.setState({
            clinician_list: result['data'],
            [name]: e.target.value,
          })
        }
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //console.log(this.state)
    if(this.state.clinician == 0 || this.state.epic_type == 0
      || this.state.visit == 0 || this.state.date === '' || this.state.time === ''
      || this.state.duration == 0 || this.state.billable == 0 ||
      (this.state.clinician === 1 && (this.state.clinician_other === ''))
      || (this.state.reason > 1 && this.state.frequency == 0)
      || (this.state.reason > 1 && this.state.frequency == 2 && this.state.recurrent == 0 && this.state.recurrent_end_date === '')) {
        this.setState({
          message: 'Please fill all information',
        })
        window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      var pid = this.props.match.params.pid
      axios({
        method: 'post',
        url: connectNode + 'admin/patient/make_appointment',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: pid,
          user: this.state,
        }
      })
      .then((result) => {
          if(result['data'] === this.props.match.params.pid) {
            this.props.onLoadLocation('/admin/patient_chart/'+pid, 'Appointment created successfully!')
            this.props.history.push('/admin/patient_chart/'+pid+'/1/1')
          }

        }
      )
    }
  }

  formatDate =  (date) => {
    var d = new Date(date)
    var month = '' + (d.getMonth() + 1)
    var day = '' + d.getDate()
    var year = d.getFullYear()

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
  }

  handleDateChange = (e, name) => {
    this.setState({ [name]: e })
  }

  render() {
    var recurrent_week = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    //console.log(this.state)
    return (
      <div className="admin_make_appointment box_border4">
        <div className="row">
          <div className="small-12 columns headerPage1">
            Appointment
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            { this.state.message !== '' &&
            <div className="row warning_msg6">
              <div className="small-12 columns ">
                <div className="float_left1"><WarningIcon /></div>
                <div className="txt_warning_msg1">{ this.state.message }</div>
              </div>
            </div>}
            <div className="box_border5">
              <form id="make_appointment" onSubmit={this.handleSubmit}>
                <PatientHead3 />
                <div className="row">
                  <div className="small-12 columns box_inner2 top_space1">
                    <div className="row">
                    <div className="small-12 columns">
                      <div className="small-12 medium-2 columns bold_txt3">EPIC: </div>
                      <div className="small-12 medium-6 columns float_left">
                        <FormControl variant="outlined">
                          <Select value={this.state.epic_type} variant="outlined" label=""
                            onChange={(e) => this.handleChange2(e, 'epic_type')}>
                            {this.state.appt_epic_list.map( each =>
                              <MenuItem key={'ep_type'+each.value} value={each.value}>{each.label}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                  <div className="small-12 columns top_space1">
                    <div className="small-12 medium-2 columns bold_txt3">Reason:</div>
                    <div className="small-12 medium-6 columns float_left">
                    <FormControl variant="outlined">
                      <Select value={this.state.reason} variant="outlined" name="reason"
                        label="" onChange={(e) => this.handleChange2(e, 'reason')}>
                        {this.state.reason_list.map((each, index) => {
                          if (each.vid == 100 || each.vid == 200)
                            return(<ListSubheader key={'sh'+index}>{each.name}</ListSubheader>)
                          if (each.vid != 100 && each.vid != 200)
                            return(<MenuItem key={'rs'+each.vid} value={each.vid}>{each.name}</MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                    </div>
                  </div>
                  {(this.state.reason == 301 || this.state.reason == 121) &&
                    <div className="small-12 columns top_space1 admin_log_outcome">
                      <div className="small-12 medium-2 columns">&nbsp;</div>
                      <div className="small-12 medium-6 columns float_left reason_list">
                        <TextField onChange={this.handleChange} name="reason_other"
                          variant="outlined" type="text" defaultValue={this.state.reason_other}
                          InputProps={{ inputProps: { name: "reason_other", maxLength: 250, placeholder: 'Please specify' }}} />
                      </div>
                    </div>}

                      {this.state.reason > 1 && this.state.reason != 125 &&
                      <div className="small-12 columns">
                        <div className="small-12 medium-2 columns bold_txt3">Frequency: </div>
                        <div className="small-12 medium-5 columns float_left top_space2">
                          {frequency.map(each =>
                          <div className="small-12 columns top_space2" key={'fq'+each.value} >
                          <input type="radio" value={each.value}
                            checked={this.state.frequency == each.value}
                            onChange={(e) => this.handleChange2(e, 'frequency')}/>
                            {each.label}</div>
                          )}
                          {this.state.frequency === '2' &&
                            <div>
                              <div className="sep_line_sub1">&nbsp;</div>
                              <div className="bullet1">
                                <div className="medium-3 columns no_padding">Repeats</div>
                                <div className="small-12 medium-9 columns no_padding_left">
                                    <FormControl variant="outlined">
                                    <Select value={this.state.recurrent} variant="outlined" label=""
                                      onChange={(e) => this.handleChange2(e, 'recurrent')}>
                                      {recurrent_week.map( each =>
                                        <MenuItem key={'fqt'+each} value={each}>Every {each} week{each > 1 && 's'}</MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="bullet1">
                                <div className="small-3 columns no_padding">End date</div>
                                <div className="small-9 columns no_padding_left">
                                  <DatePicker selected={this.state.recurrent_end_date}
                                  onChange={(e) => this.handleDateChange(e, 'recurrent_end_date')}
                                  className="date_picker" />
                                </div>
                              </div>
                              <div className="sep_line_sub1">&nbsp;</div>
                            </div>
                          }
                        </div>
                      </div>
                      }
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Provider: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.clinician} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange2(e, 'clinician')}>
                              {this.state.clinician_list.map( each =>
                                <MenuItem key={'cn'+each.sub_role+each.uid} value={each.uid}>{each.fullname}</MenuItem>
                              )}
                              <MenuItem key='cn_other' value='1' >Other</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {this.state.clinician == 1 &&
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Provider Name: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="text" defaultValue={this.state.clinician_other}
                          InputProps={{ inputProps: { name: "clinician_other" }}} />
                        </div>
                        {/*<div className="small-12 medium-1 columns bold_txt3">Initial: </div>
                        <div className="small-12 medium-2 columns float_left no_padding_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="text" defaultValue={this.state.clinician_initial}
                          InputProps={{ inputProps: { name: "clinician_initial" }}} />
                        </div>*/}
                      </div>}
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Format: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <FormControl variant="outlined">
                            <Select value={this.state.visit} variant="outlined"
                              label="" defaultValue=""
                              onChange={(e) => this.handleChange2(e, 'visit')}>
                              {appt_visit.map( each =>
                                <MenuItem key={'appt_visit'+each.value} value={each.value}>{each.label}</MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      {/*this.state.visit == 1 &&
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Virtual Link: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <TextField onChange={this.handleChange} variant="outlined"
                          defaultValue={this.state.visit_link}
                          InputProps={{ inputProps: { name: "visit_link" }}} />
                        </div>
                      </div>*/}

                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Date: </div>
                        <div className="small-12 medium-5 columns float_left">
                          <DatePicker selected={this.state.date}
                          onChange={(e) => this.handleDateChange(e, 'date')} className="date_picker" />
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Time: </div>
                        <div className="small-12 medium-5 columns float_left">
                        <TimePicker name="time"
                        use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                        onChange={e => this.handleDateChange(e.format('HH:mm'), 'time')} />
                        </div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Duration: </div>
                        <div className="small-11 medium-5 columns">
                          <TextField onChange={this.handleChange} variant="outlined"
                          type="number" defaultValue={this.state.duration} value={this.state.duration}
                          InputProps={{ inputProps: { name: "duration" }}} />
                        </div>
                        <div className="small-1 medium-1 columns vertical_middle float_left">mins</div>
                      </div>
                      <div className="small-12 columns top_space1">
                        <div className="small-12 medium-2 columns bold_txt3">Billable: </div>
                        <div className="small-11 medium-5 columns float_left">
                          {ans_yesno.map(each =>
                          <div className="radio1" key={'bill_'+each.value}>
                          <input type="radio" value={each.value}
                            checked={this.state.billable == each.value}
                            onChange={(e) => this.handleChange2(e, 'billable')}/>
                            {each.label}</div>)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row top_space1">
                  <div className="small-12 columns text_align_right">
                    {!this.state.status_submit &&
                    <Button variant="contained" disabled
                     onClick={this.handleSubmit} type="submit">Submit</Button>}
                     {this.state.status_submit &&
                     <Button variant="contained" color="primary"
                      onClick={this.handleSubmit} type="submit">Submit</Button>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MakeAppointment)
