import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { mini_steps, mini_q_k, mini_q_l, mini_q_m, mini_scale1 } from '../variables/assessments/mini.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperMINI from './stepper'

class Mini7 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'mini6',
          k1: 0,
          k2: 0,
          k3: 0,
          k4: 0,
          l1: 0,
          m1: 0,
          m2: 0,
          m3: 0,
      }
  }

  componentDidMount() {
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getMiniData('edit')
    } else {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_mini_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
        //console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].mini_step !== 'mini5') {
            if(result['data'].mini_step == 'mini1' || result['data'].mini_step == 'mini2'
              || result['data'].mini_step == 'mini3' || result['data'].mini_step == 'mini4') {
                this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
            } else {
              axios({
                method: 'post',
                url: connectNode + 'admin/assessment/get_mini_data',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                  pid: this.props.match.params.pid,
                }
              })
              .then((result) => {
                this.setState({
                  completed_status: true,
                  appt_id: 1,
                  k1: result['data'].k1,
                  k2: result['data'].k2,
                  k3: result['data'].k3,
                  k4: result['data'].k4,
                  l1: result['data'].l1,
                  m1: result['data'].m1,
                  m2: result['data'].m2,
                  m3: result['data'].m3,
                })
              })
            }
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      }
    )}
  }

  getMiniData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_mini_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      this.setState({
        completed_status: true,
        appt_id: 1,
        edit_status: get_edit_status,
        k1: result['data'].k1 == null ? 0 : result['data'].k1,
        k2: result['data'].k2 == null ? 0 : result['data'].k2,
        k3: result['data'].k3 == null ? 0 : result['data'].k3,
        k4: result['data'].k4 == null ? 0 : result['data'].k4,
        l1: result['data'].l1 == null ? 0 : result['data'].l1,
        m1: result['data'].m1 == null ? 0 : result['data'].m1,
        m2: result['data'].m2 == null ? 0 : result['data'].m2,
        m3: result['data'].m3 == null ? 0 : result['data'].m3,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    /*if(this.state.k1 === 0 || this.state.k2 === 0 || this.state.k3 === 0 ||
      this.state.k4 === 0 || this.state.l1 === 0 || this.state.m1 === 0 ||
      this.state.m2 === 0) {
      this.setState({
        message: 'Please select all answers ',
      })
      window.scrollTo(0, 0)
    } else {*/
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/update_mini7',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          user: this.state,
          pid: pid,
          timepoint: timepoint,
        }
      })
      .then((result) => {
          if(result['data'] === this.state.next_step) {
            this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
            this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          }
        }
      )
    //}
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_mini5',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/assessments/mini_result/'+pid+'/-1', 'Edit successfully!')
          this.props.history.push('/admin/assessments/mini_result/'+pid+'/-1')
        }
      }
    )
  }

  render() {

    return (
      <div className="assm_mini box_border4">
        { this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
          {//!this.props.match.params.task == 'edit' &&
          <StepperMINI activeStep={4} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { this.state.appt_id > 0 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box1">
              <div className="row show-for-medium bold">
                <div className="medium-6 columns">&nbsp;</div>
                {mini_scale1.map(each =>
                  each.value > 0 &&
                  <div className="medium-2 columns headerResize1" key={'sch'+each.label}>
                    {each.label}
                  </div>
                )}
                <div className="small-12 columns">
                  <div className="sep_line1">&nbsp;</div>
                </div>
              </div>

              {mini_q_k.map(eachQ =>
                <div className="row" key={'qk'+eachQ.label}>
                  <div className="small-12 medium-6 columns">
                    K{eachQ.value}. {eachQ.label}
                  </div>
                  {mini_scale1.map(each =>
                    each.value > 0 &&
                    <div className="small-12 medium-2 columns align_center" key={'sk'+each.label}>
                      <input type="radio" name={"k"+eachQ.value} value={each.value}
                      checked={this.state['k'+eachQ.value] == each.value && 'checked'}
                      disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                  )}
                  {eachQ.value < 5 &&
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>}
                </div>
              )}
              {mini_q_l.map(eachQ =>
                <div className="row" key={'ql'+eachQ.label}>
                  <div className="small-12 medium-6 columns">
                    L{eachQ.value}. {eachQ.label}
                  </div>
                  {mini_scale1.map(each =>
                    each.value > 0 &&
                    <div className="small-12 medium-2 columns align_center" key={'sl'+each.label}>
                      <input type="radio" name={"l"+eachQ.value} value={each.value}
                      checked={this.state['l'+eachQ.value] == each.value && 'checked'}
                      disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                  )}
                  {eachQ.value < 4 &&
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>}
                </div>
              )}
              {mini_q_m.map(eachQ =>
                <div className="row" key={'qm'+eachQ.label}>
                  <div className="small-12 medium-6 columns">
                    M{eachQ.value}. {eachQ.label}
                  </div>
                  {mini_scale1.map(each =>
                    each.value > 0 &&
                    <div className="small-12 medium-2 columns align_center" key={'sm'+each.label}>
                      <input type="radio" name={"m"+eachQ.value} value={each.value}
                      checked={this.state['m'+eachQ.value] == each.value && 'checked'}
                      disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                      onChange={(e) => this.handleChange(e)}/>
                      <span className="show-for-small-only">{each.label}</span>
                    </div>
                  )}
                  {eachQ.value < 3 &&
                  <div className="small-12 columns">
                    <div className="sep_line2">&nbsp;</div>
                  </div>}
                </div>
              )}
            </div>

          <div className="small-12 columns align_right">
          {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('mini6')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleEdit(e)} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Mini7)
