export const pw18_questions = [
  {
    label: 'I like most parts of my personality.',
    value: 1,
  }, {
    label: 'When I look at the story of my life, I am pleased with how things have turned out so far.',
    value: 2,
  }, {
    label: 'Some people wander aimlessly through life, but I am not one of them.',
    value: 3,
  }, {
    label: 'The demands of everyday life often get me down.',
    value: 4,
  }, {
    label: 'In many ways I feel disappointed about my achievements in life.',
    value: 5,
  }, {
    label: 'Maintaining close relationships has been difficult and frustrating for me.',
    value: 6,
  }, {
    label: 'I live life one day at a time and don\'t really think about the future.',
    value: 7,
  }, {
    label: 'In general, I feel I am in charge of the situation in which I live.',
    value: 8,
  }, {
    label: 'I am good at managing the responsibilities of daily life.',
    value: 9,
  }, {
    label: 'I sometimes feel as if I\'ve done all there is to do in life.',
    value: 10,
  }, {
    label: 'For me, life has been a continuous process of learning, changing, and growth.',
    value: 11,
  },  {
    label: 'I think it is important to have new experiences that challenge how I think about myself and the world.',
    value: 12,
  },  {
    label: 'People would describe me as a giving person, willing to share my time with others.',
    value: 13,
  },  {
    label: 'I gave up trying to make big improvements or changes in my life a long time ago.',
    value: 14,
  },  {
    label: 'I tend to be influenced by people with strong opinions.',
    value: 15,
  },  {
    label: 'I have not experienced many warm and trusting relationships with others.',
    value: 16,
  },  {
    label: 'I have confidence in my own opinions, even if they are different from the way most other people think.',
    value: 17,
  },  {
    label: 'I judge myself by what I think is important, not by the values of what others think is important.',
    value: 18,
  },
]

export const pw18_scale = [
  {
    label: 'Strongly agree',
    value: 1,
  }, {
    label: 'Somewhat agree',
    value: 2,
  }, {
    label: 'A little agree',
    value: 3,
  }, {
    label: 'Neither agree nor disagree',
    value: 4,
  }, {
    label: 'A little disagree',
    value: 5,
  }, {
    label: 'Somewhat disagree',
    value: 6,
  }, {
    label: 'Strongly disagree',
    value: 7,
  },
]
