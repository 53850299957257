import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthService from '../../services/auth/auth.service.js'
import { logoutUser } from '../../actions/auth'
//import { connectNode } from '../../utils/global.js'
import { Link, NavLink } from "react-router-dom"
import { loadLocation } from '../../actions/location'

import { IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import Drawer from "@material-ui/core/Drawer"
//import NavDrawer from "./NavDrawer"
import Image from '../../images/fitt-bd-logo.png'

import DashboardIcon from '@material-ui/icons/Dashboard'
import BookIcon from '@material-ui/icons/Book'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SettingsIcon from '@material-ui/icons/Settings'
import { CgMail } from 'react-icons/cg'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'

import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import DescriptionIcon from '@material-ui/icons/Description'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false,
      login: false
    };
  }

  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    });
  };

  onReloadPages = () => {
    this.props.onLoadPages()
  }

  handleLogout() {
    AuthService.logout()
    this.onReloadPages()
    this.onReloadLocation('/login')
    document.location.href = '/login'
  }

  onReloadLocation = (route) => {
    this.props.onLoadLocation(route)
  }

  handlePage(route) {
    this.onReloadLocation(route)
  }

  render() {
    var url_origin = window.location.origin
    return (
      <div className="header">
        <div className="wrapper row">
          <div className="header_logo">
            <img src={Image} alt="main_logo"/>
          </div>
          <div className="topBarContact show-for-medium">
            <div className="row">
              <div className="small-12 columns align-right">
              {this.props.uid > 0 && 'Hi, '+this.props.username}
              {/*!(this.props.role) &&
              <span>
                <Link to="/" className="main_menu_active">HOME</Link>
                <Link to="/about" >ABOUT</Link>
                <Link to="/blog" >BLOG</Link>
                <Link to="/contact" >CONTACT</Link>
              </span>*/}
              { /*this.props.uid == null && (
                <Link to="/login" className="highlight_button" >SIGN IN</Link>)*/}
              { this.props.uid > 0 && (this.props.role >= 1 && this.props.role <= 3) && (
                <Link to="/admin/patient_list" >PATIENTS</Link>)}
              { this.props.uid > 0 && this.props.role == 5 && this.props.p_st === 6 && (
                <Link to="/dashboard" >DASHBOARD</Link>)}
              { this.props.uid > 0 && (
                <button onClick={(e) => {this.handleLogout('home')}} className="highlight_button2" >
                LOGOUT</button>)}
              </div>
            </div>
          </div>
          { this.props.uid > 0 &&
            <div className="topBarContact show-for-small-only">
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={this.toggleDrawer(true)} >
              <MenuIcon />
            </IconButton>
            {/* <NavDrawer
              drawerOpened={this.state.drawerOpened}
              toggleDrawer={this.toggleDrawer} /> */}
            <Drawer
              anchor="right"
              open={this.state.drawerOpened}
              onClose={this.toggleDrawer(false)}
            >
              <div
                onClick={this.toggleDrawer(false)}
                onKeyDown={this.toggleDrawer(false)}
              >
                <div className="menu_list">
                  <Link to="/dashboard">
                    <DashboardIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/assessment_list">
                    <AssessmentIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/patient_contract_list">
                    <DescriptionIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/member/appointment_list">
                    <CalendarTodayIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <a href='mailto:fitt-bd@mgh.harvard.edu?subject=Contact FITT-BD Team' activeClassName="menu_list_active">
                    <CgMail />
                  </a>
                </div>
                <div className="menu_list">
                  <Link to="/user/settings">
                    <SettingsIcon />
                  </Link>
                </div>
                <div className="menu_list">
                  <Link to="/#" onClick={(e) => {this.handleLogout('home')}}>
                    <ExitToAppIcon />
                  </Link>
                </div>
              </div>
            </Drawer>
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uid: state.authReducer.uid,
    role: state.authReducer.role,
    p_st: state.authReducer.p_st,
    username: state.authReducer.username,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages() {
    dispatch(logoutUser())
  },
  onLoadLocation(route) {
    dispatch(loadLocation(route))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
